import { Grid } from "@mui/material";
import React from "react";
import ShopProductWidget from "../../../components/widgets/ShopProductWidget";

const ShopProducts = ({ shopProducts }) => {
    console.log(shopProducts);
    return (
        <>
            <Grid container spacing={4} padding={3}>
                {shopProducts.map((product, index) => (
                    <Grid item sm={4} key={index}>
                        <ShopProductWidget product={product} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default ShopProducts;
