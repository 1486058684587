import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomCard2 from '../../components/cards/CustomCard2';
import PageLayout from '../layouts/PageLayout';
import { useSelector } from 'react-redux';
import { practicalTrainingsSelector } from '../../states/features/selectors';
import { filter, isEmpty, size } from 'lodash';
import NoContent from '../../components/NoContent';
import { useParams } from 'react-router-dom';
import FieldWidgetOne from '../../components/widgets/FieldWidgetOne';
import PracticalTrainingSidebar from '../../components/sidebars/PracticalTrainingSidebar';
import CountBadge from '../../components/CountBadge';

const PracticalTrainingByStatus = () => {
    // ################ CONST ####################
    const { status: status } = useParams();

    // ################ Comp state ####################
    const [ptByStatus, setPtByStatus] = useState([]);

    // ################ Redux state ####################
    const { practicalTrainings } = useSelector(practicalTrainingsSelector);

    // ############## FUNC ###################
    const getPTByStatus = () => {
        let filteredPracticalTrainings = [];
        if (status === 'drafted') {
            filteredPracticalTrainings = filter(
                practicalTrainings,
                ({ status }) => status === 'draft',
            );
        }
        if (status === 'published') {
            filteredPracticalTrainings = filter(
                practicalTrainings,
                ({ status }) => status === 'published',
            );
        }
        if (status === 'closed') {
            filteredPracticalTrainings = filter(
                practicalTrainings,
                ({ status }) => status === 'closed',
            );
        }
        if (status === 'suspended') {
            filteredPracticalTrainings = filter(
                practicalTrainings,
                ({ status }) => status === 'suspended',
            );
        }

        setPtByStatus(filteredPracticalTrainings);
    };

    // ############### useEffect ###############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getPTByStatus();
        }

        return () => {
            isSubscribed = false;
        };
    }, [practicalTrainings]);

    return (
        <>
            <PageLayout
                head={`${status} practical trainings`}
                sidebar={<PracticalTrainingSidebar />}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard2
                            head={`All ${status} practical trainings`}
                            height={'70vh'}
                            borderBottom
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(ptByStatus)}
                                            item={`${status}  PTs`}
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(ptByStatus) ? (
                                <>
                                    <NoContent
                                        height={360}
                                        message={`No ${status} practical trainings`}
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {ptByStatus.map((training) => (
                                        <Grid item sm={6} key={training.id}>
                                            <FieldWidgetOne
                                                training={training}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default PracticalTrainingByStatus;
