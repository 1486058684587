import React, { useState } from 'react';
import { Box, Grid, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, Image, Save } from '@mui/icons-material';
import CustomFileInput from '../../../components/Forms/CustomFileInput';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { colors } from '../../../assets/utils/constants';
import CustomTextArea from '../../../components/Forms/CustomTextArea';
import { useNavigate } from 'react-router-dom';
import CustomSelectInput from '../../../components/Forms/CustomSelectInput';
import { useCreatePromotionMutation } from '../../../api/promotions/promotionsAPI';
import { addPromotion } from '../../../states/features/promotions/promotionsSlice';
import {
    promotionCustomersSelector,
    subscriptionPackagesSelector,
} from '../../../states/features/selectors';
import { filter, isEmpty } from 'lodash';
import NoContent from '../../../components/NoContent';

// ######### validation schema #################
const createPromotionValidationSchema = Yup.object({
    file: Yup.string().required('banner is a required field'),
    title: Yup.string().required(),
    content: Yup.string().required(),
    customer: Yup.string().required(),
});

const AddPromotion = ({ handleClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // ############# Comp state ################
    const [filePreview, setFilePreview] = useState('');

    // ############## Redux state ###################
    const { customers } = useSelector(promotionCustomersSelector);
    const { subscriptionPackages } = useSelector(subscriptionPackagesSelector);
    // => Promo connect packages
    const promoConnectPackages = filter(
        subscriptionPackages,
        ({ type, status }) => type === 'Promo connect' && status === 1,
    );

    // ############# RTK ##################
    const [
        createPromotion,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useCreatePromotionMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleClose();
        navigate(`/promotions/${data.id}`);
        dispatch(addPromotion(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Box>
                {isEmpty(customers) ? (
                    <NoContent
                        height={250}
                        message={
                            'No active promotion customer added. Please add customer first.'
                        }
                    />
                ) : isEmpty(promoConnectPackages) ? (
                    <NoContent
                        height={250}
                        message={
                            'No active promo connect subscription package. Please add package first.'
                        }
                    />
                ) : (
                    <Formik
                        initialValues={{
                            file: '',
                            title: '',
                            content: '',
                            customer: '',
                        }}
                        validationSchema={createPromotionValidationSchema}
                        onSubmit={(values) => {
                            let payload = new FormData();
                            payload.append('banner_image', values.file);
                            payload.append('title', values.title);
                            payload.append('content', values.content);
                            payload.append('customer_id', values.customer);
                            createPromotion(payload);
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Grid
                                    container
                                    justifyContent={'center'}
                                    columnSpacing={2}
                                >
                                    <Grid item sm={12}>
                                        <Grid
                                            container
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                        >
                                            <Grid item sm={12} sx={{}}>
                                                {filePreview ? (
                                                    <>
                                                        <img
                                                            src={filePreview}
                                                            alt="img"
                                                            style={{
                                                                width: '100%',
                                                                height: '10em',
                                                                objectFit:
                                                                    'contain',
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Image
                                                            sx={{
                                                                width: '100%',
                                                                height: '7em',
                                                                objectFit:
                                                                    'contain',
                                                                opacity: 0.7,
                                                                color:
                                                                    colors.secondary,
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                sm={12}
                                                sx={{
                                                    height: '100%',
                                                    mb: 3,
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                                }}
                                            >
                                                <Box>
                                                    <CustomFileInput
                                                        size={'small'}
                                                        name="file"
                                                        type="file"
                                                        formik={formik}
                                                        accept="image/*"
                                                        setFilePreview={
                                                            setFilePreview
                                                        }
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6} xs={6}>
                                        <CustomTextField
                                            name="title"
                                            type={'text'}
                                            label={'Promotion title'}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={6}>
                                        <CustomSelectInput
                                            type="text"
                                            name="customer"
                                            label="Select customer"
                                            formik={formik}
                                            color="primary"
                                        >
                                            {customers.map((customer) => (
                                                <MenuItem
                                                    value={customer.id}
                                                    key={customer.id}
                                                >
                                                    {customer.name}
                                                </MenuItem>
                                            ))}
                                        </CustomSelectInput>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <CustomTextArea
                                            name="content"
                                            type={'text'}
                                            label={'Promotion content'}
                                            rows={2}
                                            maxChar={100}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={12}
                                        sx={{
                                            mt: 2,
                                        }}
                                    >
                                        <CustomSubmitButton
                                            loading={isLoading}
                                            startIcon={<Save />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            onClick={formik.handleSubmit}
                                            btnColor={'primary'}
                                            sx={{ py: 2 }}
                                        >
                                            Save promotion
                                        </CustomSubmitButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                )}
            </Box>
        </>
    );
};

export default AddPromotion;
