import React from 'react';
import { ArrowRightAltTwoTone } from '@mui/icons-material';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { Link } from 'react-router-dom';
import ActivateDeactivateBlog from '../../pages/blogs/CRUD/ActivateDeactivateBlog';
import moment from 'moment/moment';

const BlogPostWidget = ({ blog }) => {
    return (
        <>
            <Box
                sx={{
                    borderRadius: 5,
                    overflow: 'hidden',
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                }}
            >
                <Box>
                    <Avatar
                        src={blog.blog_image}
                        variant="square"
                        sx={{
                            height: 160,
                            width: '100%',
                            img: {
                                objectFit: 'contain',
                            },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        p: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                    }}
                >
                    <Typography
                        noWrap
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            pb: 1,
                            opacity: 0.8,
                            textTransform: 'uppercase',
                        }}
                    >
                        {blog.title}
                    </Typography>
                    <Typography
                        noWrap
                        sx={{ fontSize: 15, opacity: 0.8, width: 300 }}
                    >
                        {blog.content}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography sx={{ fontSize: 15, color: colors.info }}>
                            <strong>Posted:</strong>{' '}
                            {moment(blog.created_at).format('MMM Do YYYY')}
                        </Typography>
                        <Box>
                            <ActivateDeactivateBlog blog={blog} />
                            <Link to={`/blogs/${blog.id}`}>
                                <Button
                                    size="small"
                                    sx={{
                                        '& .arrow': {
                                            transition: '.3s all ease-in-out',
                                        },
                                        ':hover': {
                                            '& .arrow': {
                                                mr: -2,
                                                transition:
                                                    '.3s all ease-in-out',
                                            },
                                        },
                                    }}
                                >
                                    <ArrowRightAltTwoTone
                                        className="arrow"
                                        sx={{ fontSize: 35 }}
                                    />
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default BlogPostWidget;
