import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    paymentMethods: [],
};

// !########## SLICE #############
const paymentMethodsSlice = createSlice({
    name: 'paymentMethodsSlice',
    initialState,
    reducers: {
        setPaymentMethods: (state, actions) => {
            state.paymentMethods = actions.payload;
        },
        addPaymentMethod: (state, actions) => {
            const payment_method = actions.payload;
            isEmpty(state.paymentMethods)
                ? (state.paymentMethods = [payment_method, ...[]])
                : (state.paymentMethods = [
                      payment_method,
                      ...state.paymentMethods,
                  ]);
        },
    },
});

export const {
    setPaymentMethods,
    addPaymentMethod,
} = paymentMethodsSlice.actions;
export default paymentMethodsSlice.reducer;
