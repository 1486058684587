import { Box, Chip, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { paymentStatusColor } from '../../helpers/statusColorHelper';
import strHelper, { removeSpclChar } from '../../helpers/strHelper';

const IncomeWidget = ({ title, total, status }) => {
    return (
        <>
            <Box
                sx={{
                    height: 100,
                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor4})`,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 4,
                    position: 'relative',
                    boxShadow: `1px 1px 10px 0px ${colors.bgColor3}`,
                }}
            >
                <Box
                    sx={{
                        width: 15,
                        background: `linear-gradient(90deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                        position: 'absolute',
                        top: 20,
                        bottom: 20,
                        left: 0,
                        borderTopRightRadius: 100,
                        borderBottomRightRadius: 100,
                    }}
                ></Box>
                <Box
                    sx={{
                        width: 15,
                        background: `linear-gradient(360deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                        position: 'absolute',
                        top: 20,
                        bottom: 20,
                        right: 0,
                        borderTopLeftRadius: 100,
                        borderBottomLeftRadius: 100,
                    }}
                ></Box>
                {status && (
                    <Box sx={{ position: 'absolute', top: 5, right: 5 }}>
                        <Chip
                            label={removeSpclChar(
                                strHelper().strCapitalizeFirstChar(status),
                            )}
                            color={paymentStatusColor(status)}
                        />
                    </Box>
                )}
                <Typography
                    sx={{
                        fontSize: 25,
                        fontWeight: 'bold',
                        color: colors.success,
                    }}
                >
                    {total}
                    <sup style={{ fontSize: 16 }}>TZS</sup>
                </Typography>
                <Typography
                    sx={{ fontSize: 13, fontWeight: 'bold', opacity: 0.8 }}
                >
                    {title}
                </Typography>
            </Box>
        </>
    );
};

export default IncomeWidget;
