import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { departmentsEndpoints } from './settingEndpoints';
import { settingAPI } from './settingAPI';

export const departmentsAPI = createApi({
    reducerPath: 'departmentsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getDepartments: builder.query({
            query: () => `${departmentsEndpoints.GET_DEPARTMENTS}`,
        }),
        // => ADD
        addDepartment: builder.mutation({
            query: (payload) => ({
                url: `${departmentsEndpoints.ADD_DEPARTMENT}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
        // => UPDATE
        updateDepartment: builder.mutation({
            query: (payload) => ({
                url: `${departmentsEndpoints.UPDATE_DEPARTMENT}/` + payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateDepartment: builder.mutation({
            query: (payload) => ({
                url: `${departmentsEndpoints.ACTIVATE_DEPARTMENT}/` + payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateDepartment: builder.mutation({
            query: (payload) => ({
                url: `${departmentsEndpoints.DEACTIVATE_DEPARTMENT}/` + payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteDepartment: builder.mutation({
            query: (payload) => ({
                url: `${departmentsEndpoints.DELETE_DEPARTMENT}/` + payload,
                method: `DELETE`,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
    }),
});

export const {
    useGetDepartmentsQuery,
    useAddDepartmentMutation,
    useUpdateDepartmentMutation,
    useActivateDepartmentMutation,
    useDeactivateDepartmentMutation,
    useDeleteDepartmentMutation,
} = departmentsAPI;
