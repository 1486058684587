import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { Add } from '@mui/icons-material';
import CustomDialog from '../Dialogies/CustomDialog';
import AddBlogPost from '../../pages/blogs/CRUD/AddBlogPost';
import { useSelector } from 'react-redux';
import { blogsSelector } from '../../states/features/selectors';
import { filter, size } from 'lodash';

const BlogsSidebar = () => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const { blogs } = useSelector(blogsSelector);
    return (
        <>
            {/* ############## Dialogy ############## */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`Add new blog post`}
            >
                <AddBlogPost handleClose={handleClose} />
            </CustomDialog>

            <Box>
                <Button
                    fullWidth
                    sx={{
                        height: 100,
                        borderRadius: `15px !important`,
                        border: `2px double ${colors.bgColor5}`,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                    }}
                    onClick={() => handleClickOpen()}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Add
                            color="info"
                            sx={{
                                p: 1,
                                fontSize: 40,
                                borderRadius: 50,
                                mb: 1,
                                border: `2px dotted ${colors.info}`,
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                opacity: 0.8,
                            }}
                        >
                            Add blog post
                        </Typography>
                    </Box>
                </Button>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    px: 1.5,
                    py: 2,
                    borderRadius: 2,
                    mt: 5,
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                }}
            >
                <Box
                    sx={{
                        height: 150,
                        width: 150,
                        borderRadius: 50,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        border: `5px solid ${colors.bgColor5}`,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 35,
                            fontWeight: 'bold',
                            opacity: 0.8,
                            color: colors.info,
                        }}
                    >
                        {size(blogs)}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            opacity: 0.8,
                        }}
                    >
                        Posted blogs
                    </Typography>
                </Box>
                <Box
                    sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Typography
                        sx={{
                            color: colors.info,
                            fontSize: 25,
                            borderBottom: `3px solid ${colors.bgColor5}`,
                        }}
                    >
                        {size(filter(blogs, ({ status }) => status === true))}{' '}
                        <sup style={{ color: colors.warning, fontSize: 14 }}>
                            Active blogs
                        </sup>
                    </Typography>
                    <Typography
                        sx={{
                            color: colors.info,
                            fontSize: 25,
                            borderBottom: `3px solid ${colors.bgColor5}`,
                        }}
                    >
                        {size(filter(blogs, ({ status }) => status !== true))}{' '}
                        <sup style={{ color: colors.warning, fontSize: 14 }}>
                            In-Active blogs
                        </sup>
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default BlogsSidebar;
