import {
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../../assets/utils/constants';
import { isEmpty } from 'lodash';
import {
    QuestionMark,
    Remove,
    Unpublished,
    Verified,
} from '@mui/icons-material';
import CustomDialog from '../../../components/Dialogies/CustomDialog';
import DenyBenAccount from '../CRUD/DenyBenAccount';
import VerifyBenAccount from '../CRUD/VerifyBenAccount';
import moment from 'moment/moment';

// -> BoxTable
const BoxTable = styled(Box)({
    'border': `1px solid ${colors.bgColor5}`,
    'paddingTop': 5,
    'paddingBottom': 5,
    'paddingLeft': 15,
    'borderRadius': 2,
    '.title': {
        fontSize: 15,
        fontWeight: 'bold',
    },
    '.value': {
        fontSize: 14,
    },
});

const BenGeneralDetails = ({ beneficiary }) => {
    // ############## Comp State ##################
    const [confimationType, setConfimationType] = useState(false);
    const [confirmationDialogy, setConfirmationDialogy] = useState(false);

    // ############## Redux State ##################
    const contactPerson = beneficiary?.contactPerson;

    // ############## FUNC #####################
    // => Handle docs open
    const handleConfimationDialogOpen = (type) => {
        setConfimationType(type);
        setConfirmationDialogy(true);
    };
    // => Dialogy closer
    const handleConfimationDialogClose = () => {
        setConfirmationDialogy(false);
    };

    return (
        <>
            {/* ################# Dialogy ################## */}
            <CustomDialog
                openDialog={confirmationDialogy}
                handleDialogClose={handleConfimationDialogClose}
                head={
                    confimationType === 'verify'
                        ? 'Verify beneficiary verification request'
                        : 'Deny beneficiary verification request'
                }
            >
                {confimationType === 'verify' ? (
                    <>
                        <VerifyBenAccount
                            closeDialogy={handleConfimationDialogClose}
                            cancel={handleConfimationDialogClose}
                            beneficiary={beneficiary}
                        />
                    </>
                ) : confimationType === 'denied' ? (
                    <>
                        <DenyBenAccount
                            closeDialogy={handleConfimationDialogClose}
                            cancel={handleConfimationDialogClose}
                            beneficiary={beneficiary}
                        />
                    </>
                ) : (
                    <></>
                )}
            </CustomDialog>

            <Grid container padding={2} spacing={2}>
                {/* ################ Personal details ############## */}
                <Grid item sm={6}>
                    <Box sx={{}}>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                color: colors.info,
                            }}
                        >
                            Personal details
                        </Typography>
                        <Grid container>
                            <Grid item sm={6}>
                                <BoxTable>
                                    <Typography className="title">
                                        Full name
                                    </Typography>
                                    <Typography
                                        sx={{ pb: 0.5 }}
                                        className="value"
                                    >{`${beneficiary.first_name} ${beneficiary.middle_name} ${beneficiary.last_name}`}</Typography>
                                </BoxTable>
                            </Grid>
                            <Grid item sm={6}>
                                <BoxTable>
                                    <Typography className="title">
                                        Verification status
                                    </Typography>
                                    <Box>
                                        <Chip
                                            color={
                                                beneficiary.verificationStatus ===
                                                'pending'
                                                    ? 'warning'
                                                    : beneficiary.verificationStatus ===
                                                      'requested'
                                                    ? 'info'
                                                    : beneficiary.verificationStatus ===
                                                      'verified'
                                                    ? 'success'
                                                    : beneficiary.verificationStatus ===
                                                      'denied'
                                                    ? 'error'
                                                    : 'default'
                                            }
                                            size="small"
                                            label={
                                                beneficiary.verificationStatus
                                            }
                                        />
                                        {!isEmpty(beneficiary.deny_reason) && (
                                            <Tooltip
                                                title={beneficiary.deny_reason}
                                                sx={{ float: 'right' }}
                                                placement="top"
                                                arrow
                                            >
                                                <IconButton
                                                    size="small"
                                                    color="error"
                                                >
                                                    <QuestionMark
                                                        sx={{ fontSize: 16 }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </BoxTable>
                            </Grid>
                            <Grid item sm={6}>
                                <BoxTable>
                                    <Typography className="title">
                                        Gender
                                    </Typography>
                                    <Typography className="value">
                                        {beneficiary.gender}
                                    </Typography>
                                </BoxTable>
                            </Grid>
                            <Grid item sm={6}>
                                <BoxTable>
                                    <Typography className="title">
                                        Birth date
                                    </Typography>
                                    <Typography className="value">
                                        {isEmpty(beneficiary.birth_date) ? (
                                            <Remove />
                                        ) : (
                                            moment(
                                                beneficiary.birth_date,
                                            ).format('MMM Do YYYY')
                                        )}
                                    </Typography>
                                </BoxTable>
                            </Grid>
                            <Grid item sm={12}>
                                <BoxTable>
                                    <Typography className="title">
                                        user bio
                                    </Typography>
                                    <Typography className="value">
                                        {isEmpty(beneficiary.user_bio) ? (
                                            <Remove />
                                        ) : (
                                            beneficiary.user_bio
                                        )}
                                    </Typography>
                                </BoxTable>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                {/* ################## Contact person ################### */}
                <Grid item sm={6}>
                    <Box sx={{}}>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                color: colors.info,
                            }}
                        >
                            Contact person details
                        </Typography>
                        <Grid container>
                            <Grid item sm={12}>
                                <BoxTable>
                                    <Typography className="title">
                                        Full name
                                    </Typography>
                                    <Typography className="value">{`${contactPerson.first_name} ${contactPerson.middle_name} ${contactPerson.last_name}`}</Typography>
                                </BoxTable>
                            </Grid>
                            <Grid item sm={6}>
                                <BoxTable>
                                    <Typography className="title">
                                        Gender
                                    </Typography>
                                    <Typography className="value">
                                        {contactPerson.gender}
                                    </Typography>
                                </BoxTable>
                            </Grid>
                            <Grid item sm={6}>
                                <BoxTable>
                                    <Typography className="title">
                                        Relation
                                    </Typography>
                                    <Typography className="value">
                                        {contactPerson.relation}
                                    </Typography>
                                </BoxTable>
                            </Grid>
                            <Grid item sm={6}>
                                <BoxTable>
                                    <Typography className="title">
                                        Location
                                    </Typography>
                                    <Typography className="value">
                                        {contactPerson.region}
                                    </Typography>
                                </BoxTable>
                            </Grid>
                            <Grid item sm={6}>
                                <BoxTable>
                                    <Typography className="title">
                                        Phone number
                                    </Typography>
                                    <Typography className="value">
                                        {contactPerson.phone_number}
                                    </Typography>
                                </BoxTable>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                {/* ################### Action buttons ################### */}
                {beneficiary.verificationStatus === 'requested' && (
                    <Grid item sm={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                width: '100%',
                                mt: 5,
                            }}
                        >
                            <Button
                                startIcon={<Verified />}
                                color="success"
                                variant="contained"
                                onClick={() => {
                                    let type = 'verify';
                                    handleConfimationDialogOpen(type);
                                }}
                            >
                                Verify beneficiary
                            </Button>
                            <Button
                                startIcon={<Unpublished />}
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    let type = 'denied';
                                    handleConfimationDialogOpen(type);
                                }}
                            >
                                Deny beneficiary
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default BenGeneralDetails;
