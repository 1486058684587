import React from 'react';
import { Grid } from '@mui/material';
import OrganizationTable from '../../components/Tables/OrganizationTable';
import CustomCard from '../../components/cards/CustomCard';
import { useSelector } from 'react-redux';
import { filter, size } from 'lodash';
import PageLayout from '../layouts/PageLayout';
import { organizationsSelector } from '../../states/features/selectors';
import CountBadge from '../../components/CountBadge';
import Infobox1 from '../../components/infoboxes/InfoBox1';

const Organizations = () => {
    // ############### REDUCER STATE #################
    const { organizations } = useSelector(organizationsSelector);
    const companies = filter(organizations, [
        'organization_type.name',
        'Company',
    ]);
    const institutions = filter(organizations, [
        'organization_type.name',
        'Institution',
    ]);

    const infoBoxData = [
        {
            id: 1,
            title1: 'Institution',
            title2: 'Registered',
            total: size(institutions),
            url: '/organizations/institutions',
        },
        {
            id: 2,
            title1: 'Companies',
            title2: 'Registered',
            total: size(companies),
            url: '/organizations/companies',
        },
    ];

    return (
        <>
            <PageLayout head={'Registered organizations'}>
                <Grid container spacing={2}>
                    {infoBoxData.map((data, index) => (
                        <Grid item sm={6}>
                            <Infobox1 data={data} />
                        </Grid>
                    ))}
                    <Grid item sm={12}>
                        <CustomCard
                            head={'All registered organizations'}
                            action={
                                <CountBadge
                                    total={size(organizations)}
                                    item={'Organizations'}
                                />
                            }
                        >
                            <Grid container>
                                <Grid item sm={12}>
                                    <OrganizationTable
                                        height={406}
                                        organizations={organizations}
                                    />
                                </Grid>
                            </Grid>
                        </CustomCard>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Organizations;
