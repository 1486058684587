import React from "react";
import { Form, Formik } from "formik";
import CustomTextField from "../../../components/Forms/CustomTextField";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { ManageAccounts } from "@mui/icons-material";
import * as Yup from "yup";
import CustomTextArea from "../../../components/Forms/CustomTextArea";
import { useDispatch } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import { useUpdateRegionMutation } from "../../../api/settings/regionsAPI";
import { setRegions } from "../../../states/features/settings/regionsSlice";

// -> validate settings form
const validate = Yup.object({
    name: Yup.string().required(),
    description: Yup.string().nullable(),
});

const UpdateRegion = ({ handleClose, refetch, editedValues }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        updateRegion,
        { isLoading, isSuccess, isError, error, data: updateData },
    ] = useUpdateRegionMutation();

    // ############# RTK response ##################
    const rtkActions = (data) => {
        refetch();
        handleClose(data);
        dispatch(setRegions(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updateData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    id: editedValues.id,
                    name: editedValues.name,
                    description: editedValues.description,
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    updateRegion(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <CustomTextField label="Name" name="name" type="text" />
                        <CustomTextArea
                            label="Description"
                            name="description"
                        />
                        <CustomSubmitButton
                            startIcon={<ManageAccounts />}
                            onClick={formik.handleSubmit}
                            loading={isLoading}
                            btnColor={"info"}
                            sx={{ py: 1.5 }}
                        >
                            Update setting
                        </CustomSubmitButton>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateRegion;
