import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
    regions: [],
};

// !########## SLICE #############
const regionsSlice = createSlice({
    name: "regionsSlice",
    initialState,
    reducers: {
        setRegions: (state, actions) => {
            state.regions = actions.payload;
        },
        addRegion: (state, actions) => {
            const region = actions.payload;
            isEmpty(state.regions)
                ? (state.regions = [region, ...[]])
                : (state.regions = [region, ...state.regions]);
        },
    },
});

export const { setRegions, addRegion } = regionsSlice.actions;
export default regionsSlice.reducer;
