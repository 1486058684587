import { Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/constants";
import { filter, size } from "lodash";
import { subscriptionPackagesSelector } from "../../states/features/selectors";
import { useSelector } from "react-redux";
import SubPackageInfoBox from "../infoboxes/SubPackageInfoBox";

const SubscriptionPackageSidebar = () => {
    // ############# Redux state #############
    const { subscriptionPackages } = useSelector(subscriptionPackagesSelector);

    // ############# FUNC ####################
    const packages = [
        {
            id: 1,
            title: "Talent tap",
            total: size(
                filter(
                    subscriptionPackages,
                    ({ type }) => type === "Talent tap"
                )
            ),
            url: "/subscription-packages/types/talent-tap",
        },
        {
            id: 2,
            title: "Market mingle",
            total: size(
                filter(
                    subscriptionPackages,
                    ({ type }) => type === "Market mingle"
                )
            ),
            url: "/subscription-packages/types/market-mingle",
        },
        {
            id: 3,
            title: "Promo connect",
            total: size(
                filter(
                    subscriptionPackages,
                    ({ type }) => type === "Promo connect"
                )
            ),
            url: "/subscription-packages/types/promo-connect",
        },
    ];

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    px: 1.5,
                    py: 1,
                    borderRadius: 2,
                    mb: 2,
                    boxShadow: `1px -1px 10px 0px ${colors.bgColor4}`,
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                }}
            >
                <Box
                    sx={{
                        height: 120,
                        width: 120,
                        borderRadius: 50,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        border: `5px solid ${colors.bgColor4}`,
                        boxShadow: `0px 10px 15px ${colors.bgColor5}`,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 35,
                            fontWeight: "bold",
                            opacity: 0.8,
                            color: colors.info,
                        }}
                    >
                        {size(subscriptionPackages)}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            opacity: 0.8,
                            fontStyle: "italic",
                        }}
                    >
                        {"Packages"}
                    </Typography>
                </Box>
            </Box>
            {packages.map((data) => (
                <Box sx={{ mb: 2 }} key={data.id}>
                    <SubPackageInfoBox data={data} />
                </Box>
            ))}
        </>
    );
};

export default SubscriptionPackageSidebar;
