import React from 'react';
import { colors } from '../../../assets/utils/constants';
import { AppBar, Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import RequredDetails from './RequredDetails';
import ORGDescription from './ORGDescription';
import ORGDocument from './ORGDocument';
import ORGKeyPerson from './ORGKeyPerson';
import {
    BarChart,
    DocumentScannerOutlined,
    Group,
    ManageAccounts,
} from '@mui/icons-material';
import ORGJobs from './ORGJobs';
import ORGStudents from './ORGStudents';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        p: 2,
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        'id': `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

// !################# MAIN FUNC ###################
function MainContent({ organization, orgDocs, orgKeyPerson, refetchProfile }) {
    // -> tabs state
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <Grid
                container
                sx={{
                    background: colors.bgColor,
                }}
            >
                <Grid item sm={12}>
                    <AppBar
                        sx={{
                            position: 'sticky',
                            top: 64,
                            boxShadow: 'none',
                        }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab
                                label={
                                    <Typography
                                        noWrap
                                        sx={{
                                            fontSize: 14,
                                            textTransform: 'uppercase',
                                            color: colors.secondary,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ManageAccounts sx={{ mr: 1 }} />
                                        General settings
                                    </Typography>
                                }
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={
                                    <Typography
                                        noWrap
                                        sx={{
                                            fontSize: 14,
                                            textTransform: 'uppercase',
                                            color: colors.secondary,
                                            display: 'flex',
                                        }}
                                    >
                                        <DocumentScannerOutlined
                                            sx={{ mr: 1 }}
                                        />
                                        Organization Docs
                                    </Typography>
                                }
                                {...a11yProps(1)}
                            />
                            <Tab
                                label={
                                    <Typography
                                        noWrap
                                        sx={{
                                            fontSize: 14,
                                            textTransform: 'uppercase',
                                            color: colors.secondary,
                                            display: 'flex',
                                        }}
                                    >
                                        <BarChart sx={{ mr: 1 }} />
                                        ORG Jobs
                                    </Typography>
                                }
                                {...a11yProps(2)}
                            />
                            <Tab
                                label={
                                    <Typography
                                        noWrap
                                        sx={{
                                            fontSize: 14,
                                            textTransform: 'uppercase',
                                            color: colors.secondary,
                                            display: 'flex',
                                        }}
                                    >
                                        <Group sx={{ mr: 1 }} />
                                        ORG students
                                    </Typography>
                                }
                                {...a11yProps(3)}
                            />
                            {/* <Tab
                                label={
                                    <Typography
                                        noWrap
                                        sx={{
                                            fontSize: 14,
                                            textTransform: 'uppercase',
                                            color: colors.secondary,
                                            display: 'flex',
                                        }}
                                    >
                                        <BarChart sx={{ mr: 1 }} />
                                        ORG PT's
                                    </Typography>
                                }
                                {...a11yProps(2)}
                            /> */}
                            {/* <Tab
                                label={
                                    <Typography
                                        noWrap
                                        sx={{
                                            fontSize: 14,
                                            textTransform: 'uppercase',
                                            color: colors.secondary,
                                            display: 'flex',
                                        }}
                                    >
                                        <ContactPage sx={{ mr: 1 }} />
                                        Oganization controls
                                    </Typography>
                                }
                                {...a11yProps(3)}
                            /> */}
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <Grid
                            container
                            justifyContent={'space-between'}
                            padding={2}
                            rowGap={4}
                            sx={{
                                overflowY: 'auto',
                                position: 'relative',
                                minHeight: 495,
                            }}
                        >
                            {/* ################### Required details ############ */}
                            <Grid item sm={12} xs={12}>
                                <RequredDetails
                                    organization={organization}
                                    refetchProfile={refetchProfile}
                                />
                            </Grid>

                            {/* ################### ORG Description ########## */}
                            <Grid item sm={12} xs={12}>
                                <ORGDescription organization={organization} />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {/* ################## Documents tab ############################# */}
                    <TabPanel value={value} index={1}>
                        <Grid
                            container
                            justifyContent={'space-between'}
                            rowGap={4}
                            padding={2}
                            sx={{
                                overflowY: 'auto',
                                position: 'relative',
                                minHeight: 495,
                            }}
                        >
                            {/* ################### Verification Documents ########## */}
                            <Grid item md={12} sm={12} xs={12}>
                                <ORGDocument
                                    orgDocs={orgDocs}
                                    refetchProfile={refetchProfile}
                                />
                            </Grid>
                            {/* ################### Key person ########## */}
                            <Grid item md={12} sm={12} xs={12}>
                                <ORGKeyPerson
                                    orgKeyPerson={orgKeyPerson}
                                    refetchProfile={refetchProfile}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {/* ################## Job tab ############################# */}
                    <TabPanel value={value} index={2}>
                        <Grid
                            container
                            justifyContent={'space-between'}
                            rowGap={4}
                            sx={{
                                overflowY: 'auto',
                                position: 'relative',
                                minHeight: 495,
                            }}
                        >
                            <Grid item sm={12}>
                                <ORGJobs organization={organization} />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {/* ################## Students tab ############################# */}
                    <TabPanel value={value} index={3}>
                        <Grid
                            container
                            justifyContent={'space-between'}
                            rowGap={4}
                            sx={{
                                overflowY: 'auto',
                                position: 'relative',
                                minHeight: 495,
                            }}
                        >
                            <Grid item sm={12}>
                                <ORGStudents organization={organization} />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {/* ################## Controll tab ############################# */}
                    {/* <TabPanel value={value} index={3}>
                        <Grid
                            container
                            justifyContent={'space-between'}
                            rowGap={4}
                            sx={{
                                overflowY: 'auto',
                                position: 'relative',
                                minHeight: 495,
                            }}
                        >
                            <Grid item sm={12}>
                                <Typography>Controlls</Typography>
                            </Grid>
                        </Grid>
                    </TabPanel> */}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default MainContent;
