import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import NoContent from "../../../components/NoContent";
import { filter, isEmpty, size } from "lodash";
import JobsSidebar from "../../../components/sidebars/JobSidebar";
import { jobsSelector } from "../../../states/features/selectors";
import PageLayout from "../../layouts/PageLayout";
import CountBadge from "../../../components/CountBadge";
import JobFilters from "../../../components/filters/JobFilters";
import { paginator } from "../../../helpers/paginationHelper";
import CustomPagination from "../../../components/paginations/CustomPagination";
import EmployerJobWidget from "../../../components/widgets/EmployerJobWidget";

const EmployerJobsByStatus = () => {
    // ############ CONST ###################
    const { status } = useParams();
    const pageHead = () => {
        if (status === "draft") {
            return "Drafted employer jobs";
        } else if (status === "published") {
            return "Published employer jobs";
        } else if (status === "unpublished") {
            return "Unpublished employer jobs";
        } else if (status === "closed") {
            return "Closed employer jobs";
        } else if (status === "expired") {
            return "Expired employer jobs";
        } else if (status === "banned") {
            return "Banned employer jobs";
        } else if (status === "pending") {
            return "Pending verified employer jobs";
        }
    };
    // ############## Comp state ##################
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [jobs, setJobs] = useState([]);

    // ################## redux state #####################
    // -> job state
    const { allEmployerJobs: allJobs } = useSelector(jobsSelector);
    const jobByStatus = filter(allJobs, ["status", status]);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Filter by job name
    const filterByJobName = (search) => {
        let filteredJobs = [];
        if (search) {
            filteredJobs = jobByStatus.filter((job) => {
                return job.title.toLowerCase().includes(search.toLowerCase());
            });
            return setJobs(filteredJobs);
        }
        return setJobs(jobByStatus);
    };

    // => Filter by category
    const filterByIsFor = (isFor) => {
        let filteredJobs = [];
        if (isFor) {
            filteredJobs = jobByStatus.filter((job) => {
                return job.isFor.toLowerCase().includes(isFor.toLowerCase());
            });
            return setJobs(filteredJobs);
        }
        return setJobs(jobByStatus);
    };

    // => Pagination data
    const paginatedJobs = paginator(jobs, page, perPage);

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setJobs(jobByStatus);
        }

        return () => {
            isSubscribed = false;
        };
    }, [allJobs]);

    return (
        <React.Fragment>
            <PageLayout head={pageHead()} sidebar={<JobsSidebar />}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomPagination
                            head={pageHead()}
                            height={"64.2vh"}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedJobs}
                            action={
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <JobFilters
                                        filterByJobName={filterByJobName}
                                        filterByIsFor={filterByIsFor}
                                    />
                                    <CountBadge
                                        total={size(jobs)}
                                        item={"Jobs"}
                                    />
                                </Box>
                            }
                        >
                            {isEmpty(jobs) ? (
                                <>
                                    <NoContent
                                        height={300}
                                        message={`No ${pageHead()}`}
                                    />
                                </>
                            ) : (
                                <Grid container spacing={1}>
                                    {paginatedJobs.data.map((job) => (
                                        <Grid item sm={12} xs={12} key={job.id}>
                                            <EmployerJobWidget job={job} />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </React.Fragment>
    );
};

export default EmployerJobsByStatus;
