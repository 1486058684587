import { LocationOn, MoreVert } from '@mui/icons-material';
import {
    Avatar,
    Box,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { Link } from 'react-router-dom';

const OrganizationJobWidget = ({ organization }) => {
    return (
        <>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    m: 0.5,
                    border: `1px solid ${colors.bgColor5}`,
                    borderRadius: 2,
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        pl: 1,
                        mb: 0.5,
                    }}
                >
                    <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                        <ListItemAvatar>
                            <Avatar
                                alt="logo"
                                src={organization.organization_logo}
                                variant="square"
                                sx={{
                                    borderRadius: 2,
                                    width: 50,
                                    height: 50,
                                    border: `1px solid ${colors.bgColor5}`,
                                    p: 0.5,
                                    img: {
                                        objectFit: 'contain',
                                    },
                                }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                '.MuiListItemText-primary': {
                                    fontSize: 13,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                },
                            }}
                            primaryTypographyProps={{
                                color: colors.primary,
                            }}
                            primary={organization.name}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: 12,
                                        }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        <LocationOn
                                            sx={{
                                                fontSize: 12,
                                                color: colors.secondary,
                                            }}
                                        />
                                        {organization.location}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Box>
                        <Tooltip title={'More details'} arrow>
                            <Link
                                to={`/organizations/${
                                    organization.organization_type.name === 'Company'
                                        ? 'companies'
                                        : 'institutions'
                                }/details/${organization.id}`}
                            >
                                <IconButton
                                    size="small"
                                    color="info"
                                    sx={{
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                                    }}
                                >
                                    <MoreVert />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    {/* <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: colors.info,
                            }}
                        >
                            Posted Jobs
                        </Typography>
                        <Typography sx={{ fontSize: 12 }}>80</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: colors.success,
                            }}
                        >
                            Active Jobs
                        </Typography>
                        <Typography sx={{ fontSize: 12 }}>80</Typography>
                    </Box> */}
                </Box>
            </Box>
        </>
    );
};

export default OrganizationJobWidget;
