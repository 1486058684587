import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    employers: [],
    employerDetails: {},
    employerJobs: [],
};
const employersSlice = createSlice({
    name: 'employersSlice',
    initialState,
    reducers: {
        // => Set employers
        setEmployers: (state, action) => {
            state.employers = action.payload;
        },

        // => Set employer details
        setEmployerDetails: (state, action) => {
            state.employerDetails = action.payload;
        },

        // => Set employers
        setEmployerJobs: (state, action) => {
            state.employerJobs = action.payload;
        },
    },
});

export const {
    setEmployers,
    setEmployerDetails,
    setEmployerJobs,
} = employersSlice.actions;
export default employersSlice.reducer;
