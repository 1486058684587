// => GET all
const GET_ALL_BENEFICIARIES = '/beneficiaries';

// => GET single
const GET_SINGLE_BENEFICIARY = '/beneficiaries';

// => VERIFY beneficiary
const VERIFY_BENEFICIARY = '/beneficiaries/verify';

// => DENY beneficiary
const DENY_BENEFICIARY = '/beneficiaries/deny';

export const beneficiaryEndpoints = {
    GET_ALL_BENEFICIARIES,
    GET_SINGLE_BENEFICIARY,
    VERIFY_BENEFICIARY,
    DENY_BENEFICIARY,
};
