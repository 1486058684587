import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    jobCategories: [],
};

// !########## SLICE #############
const jobCategoriesSlice = createSlice({
    name: 'jobCategoriesSlice',
    initialState,
    reducers: {
        setJobCategories: (state, actions) => {
            state.jobCategories = actions.payload;
        },
        addJobCategory: (state, actions) => {
            const job_category = actions.payload;
            isEmpty(state.jobCategories)
                ? (state.jobCategories = [job_category, ...[]])
                : (state.jobCategories = [
                      job_category,
                      ...state.jobCategories,
                  ]);
        },
    },
});

export const { setJobCategories, addJobCategory } = jobCategoriesSlice.actions;
export default jobCategoriesSlice.reducer;
