import React from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../assets/utils/constants';
import PageHeader from '../../components/PageHeader';
import ConfigarationSidebar from '../../components/sidebars/ConfigarationSidebar';
import { settingsSelector } from '../../states/features/selectors';
import { setSettings } from '../../states/features/settings/settingsSlice';
import { useGetAllSettingsQuery } from '../../api/settings/settingAPI';
import useRTK from '../../hooks/useRTK';
import { setBlogs } from '../../states/features/blogs/blogsSlice';
import { useGetBlogsQuery } from '../../api/blogs/blogsAPI';

const ConfigurationLayout = ({ children, head, noSidebar }) => {
    // ################## CONST ###################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Redux state ############
    const { settings } = useSelector(settingsSelector);

    // ############# RTK ##################
    // => Settings
    const {
        isLoading: settingsLoading,
        isSuccess: settingsSuccess,
        data: settingsData,
    } = useGetAllSettingsQuery();
    const rtkSettingActions = (data) => {
        dispatch(setSettings(data));
    };
    RTK.useRTKFetch(settingsSuccess, settingsData, rtkSettingActions);
    // => Blogs
    const {
        isSuccess: blogSuccess,
        data: blogsData,
    } = useGetBlogsQuery();
    const rtkBlogActions = (data) => {
        dispatch(setBlogs(data));
    };
    RTK.useRTKFetch(blogSuccess, blogsData, rtkBlogActions);

    return (
        <>
            <Grid
                container
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3}) !important`,
                }}
            >
                {head && (
                    <Grid item sm={12}>
                        <PageHeader head={head} />
                    </Grid>
                )}
                <Grid item sm={12}>
                    <Grid container spacing={2} sx={{ px: 2, pt: 2 }}>
                        <Grid item sm={noSidebar ? 12 : 9}>
                            <Box>{children}</Box>
                        </Grid>
                        <Grid item sm={3}>
                            <Box
                                sx={{
                                    height: head ? `79vh` : `88vh`,
                                    position: 'sticky',
                                    top: 80,
                                    py: 1.5,
                                    p: 1.5,
                                    overflow: 'auto',
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2}) !important`,
                                    borderRadius: 4,
                                }}
                            >
                                {!settingsLoading && (
                                    <ConfigarationSidebar settings={settings} />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ConfigurationLayout;
