import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    students: [],
    ORGStudents: [],
    studentDetails: {},
};
const studentsSlice = createSlice({
    name: 'studentsSlice',
    initialState,
    reducers: {
        // => Set students
        setStudents: (state, action) => {
            state.students = action.payload;
        },

        // => Set students
        setORGStudents: (state, action) => {
            state.ORGStudents = action.payload;
        },

        // => Set beneficiar details
        setStudentDetails: (state, action) => {
            state.studentDetails = action.payload;
        },
    },
});

export const {
    setStudents,
    setStudentDetails,
    setORGStudents,
} = studentsSlice.actions;
export default studentsSlice.reducer;
