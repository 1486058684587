import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { removeAuthUser, setAuthUser } from "../states/features/auth/authSlice";
// import {
//     removeAuthUserProfile,
//     setAuthUserProfile,
// } from '../states/features/profile/profileSlice';

// !####### MAIN FUNC ##########
const useAuth = () => {
    // => REDUCER STATE
    const { isAuthenticated, authorizationType, authToken } = useSelector(
        (state) => state.authReducer
    );

    // ############# CONST #############
    const cookies = new Cookies();
    const dispatch = useDispatch();

    // ############# FUNC #############
    // => SAVE authenticated user
    const saveAuthenticatedUser = (payload) => {
        let user = payload.user;
        let token = payload.token;
        cookies.set("user", user);
        cookies.set("token", token);
    };

    // => GET authenticated user
    const authenticatedUser = () => {
        let user = cookies.get("user");
        let token = cookies.get("token");

        const auth = {
            user,
            token,
        };

        return auth;
    };

    // => REMOVE authenticated user
    const removeAuthenticatedUser = () => {
        let user = cookies.get("user");
        let token = cookies.get("token");
        if (isEmpty(user) && isEmpty(token)) {
            return false;
        }
        cookies.remove("user");
        cookies.remove("token");
        dispatch(removeAuthUser());
        // dispatch(removeAuthUserProfile());
        return true;
    };

    // => PERSIST authenticated user
    const persistAuthenticatedUser = () => {
        const user = authenticatedUser().user;
        const token = authenticatedUser().token;
        if (!isEmpty(user) && !isEmpty(token)) {
            let data = {
                user,
                token,
            };
            dispatch(setAuthUser(data));
            // dispatch(setAuthUserProfile(data));
            return true;
        }
        return false;
    };

    // => Reset Store

    // => LOGIN
    const login = (data) => {
        dispatch(setAuthUser(data));
        // dispatch(setAuthUserProfile(data));
        saveAuthenticatedUser(data);
    };

    // => LOGOUT
    const logout = () => {
        removeAuthenticatedUser();
    };

    // -> CHECK AUTHENTICATION
    let authenticated = !isEmpty(authenticatedUser().token)
        ? true
        : isAuthenticated;
    // authenticated = true;

    // -> CHECK AUTHORIZATION
    let authorization = authorizationType;

    // -> USER TOKEN
    let token = authToken;

    // -> AUTH RETURN
    const auth = {
        token,
        authenticated,
        authorization,
        login,
        logout,
        authenticatedUser,
        saveAuthenticatedUser,
        removeAuthenticatedUser,
        persistAuthenticatedUser,
    };

    return auth;
};

export default useAuth;
