import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { adminBaseURL } from "../baseUrl/baseURL";
import { transactionsEndpoints } from "./transactionsEndpoints";
import { headers } from "../baseUrl/setHeaders";

export const transactionsAPI = createApi({
    reducerPath: "transactionsAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ["Transactions"],
    endpoints: (builder) => ({
        // => GET all
        getAllTransactions: builder.query({
            query: () => `${transactionsEndpoints.GET_ALL_TRANSACTIONS}`,
            providesTags: ["Transactions"],
        }),
    }),
});

export const { useLazyGetAllTransactionsQuery } = transactionsAPI;
