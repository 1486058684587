import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscriptionPackages: [],
    freeTrialPackages: [],
};

// !########## SLICE #############
const subscriptionPackagesSlice = createSlice({
    name: "subscriptionPackagesSlice",
    initialState,
    reducers: {
        setSubscriptionPackages: (state, actions) => {
            state.subscriptionPackages = actions.payload;
        },
        setFreeTrialPackages: (state, actions) => {
            state.freeTrialPackages = actions.payload;
        },
    },
});

export const { setSubscriptionPackages, setFreeTrialPackages } =
    subscriptionPackagesSlice.actions;
export default subscriptionPackagesSlice.reducer;
