// => GET all
const GET_ALL_TRAININGS = '/practical-trainings';

// => GET single
const GET_SINGLE_TRAINING = '/practical-trainings';

// => GET applicants
const GET_TRAINING_APPLICANTS = '/practical-trainings/applicants';

// => SUSPEND training
const SUSPEND_TRAINING = '/practical-trainings/suspend';

// => RESUME Training
const RESUME_TRAINING = '/practical-trainings/resume';

export const practicalTrainingEndpoints = {
    GET_ALL_TRAININGS,
    GET_SINGLE_TRAINING,
    GET_TRAINING_APPLICANTS,
    SUSPEND_TRAINING,
    RESUME_TRAINING,
};
