import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import CustomCard2 from '../../../components/cards/CustomCard2';
import ShopDocument from './ShopDocument';
import VerifyShop from '../CRUD/VerifyShop';
import DenyShop from '../CRUD/DenyShop';
import CustomDialog from '../../../components/Dialogies/CustomDialog';
import { Lock, LockOpen, Unpublished, Verified } from '@mui/icons-material';
import ResumeShop from '../CRUD/ResumeShop';
import SuspendShop from '../CRUD/SuspendShop';

const GeneralDetails = ({ shop }) => {
    // ############## Comp State ##################
    const [confimationType, setConfimationType] = useState(false);
    const [confirmationDialogy, setConfirmationDialogy] = useState(false);

    // ############## FUNC #####################
    // => Handle docs open
    const handleConfimationDialogOpen = (type) => {
        setConfimationType(type);
        setConfirmationDialogy(true);
    };
    // => Dialogy closer
    const handleConfimationDialogClose = () => {
        setConfirmationDialogy(false);
    };

    return (
        <div>
            {/* ################# Dialogy ################## */}
            <CustomDialog
                openDialog={confirmationDialogy}
                handleDialogClose={handleConfimationDialogClose}
                head={
                    confimationType === 'verify'
                        ? 'Verify shop verification request'
                        : confimationType === 'suspend'
                        ? 'Verify shop suspend'
                        : confimationType === 'resume'
                        ? 'Verify shop un-suspend'
                        : 'Deny shop verification request'
                }
            >
                {confimationType === 'verify' ? (
                    <>
                        <VerifyShop
                            closeDialogy={handleConfimationDialogClose}
                            cancel={handleConfimationDialogClose}
                            shop={shop}
                        />
                    </>
                ) : confimationType === 'denied' ? (
                    <>
                        <DenyShop
                            closeDialogy={handleConfimationDialogClose}
                            cancel={handleConfimationDialogClose}
                            shop={shop}
                        />
                    </>
                ) : confimationType === 'suspend' ? (
                    <>
                        <SuspendShop
                            closeDialogy={handleConfimationDialogClose}
                            cancel={handleConfimationDialogClose}
                            shop={shop}
                        />
                    </>
                ) : confimationType === 'resume' ? (
                    <>
                        <ResumeShop
                            closeDialogy={handleConfimationDialogClose}
                            cancel={handleConfimationDialogClose}
                            shop={shop}
                        />
                    </>
                ) : (
                    <></>
                )}
            </CustomDialog>

            <Grid container spacing={3}>
                <Grid item sm={6}>
                    <CustomCard2 head={'Shop description'} height={'23vh'}>
                        <Typography>{shop.description}</Typography>
                    </CustomCard2>
                </Grid>
                <Grid item sm={6}>
                    <CustomCard2
                        head={'Shop document'}
                        height={'23vh'}
                        bodyPadding={1}
                    >
                        <ShopDocument shop={shop} />
                    </CustomCard2>
                </Grid>
                {/* ################### Action buttons ################### */}
                {shop.status === 'pending' && (
                    <Grid item sm={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                width: '100%',
                                mt: 1,
                            }}
                        >
                            <Button
                                startIcon={<Verified />}
                                color="success"
                                variant="contained"
                                onClick={() => {
                                    let type = 'verify';
                                    handleConfimationDialogOpen(type);
                                }}
                            >
                                Verify shop
                            </Button>
                            <Button
                                startIcon={<Unpublished />}
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    let type = 'denied';
                                    handleConfimationDialogOpen(type);
                                }}
                            >
                                Deny shop
                            </Button>
                        </Box>
                    </Grid>
                )}
                {shop.isVerified === 1 && (
                    <Grid item sm={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                width: '100%',
                                mt: 1,
                            }}
                        >
                            {shop.status === 'verified' ? (
                                <Button
                                    startIcon={<Lock />}
                                    color="warning"
                                    variant="contained"
                                    onClick={() => {
                                        let type = 'suspend';
                                        handleConfimationDialogOpen(type);
                                    }}
                                >
                                    Suspend shop
                                </Button>
                            ) : (
                                <Button
                                    startIcon={<LockOpen />}
                                    color="success"
                                    variant="contained"
                                    onClick={() => {
                                        let type = 'resume';
                                        handleConfimationDialogOpen(type);
                                    }}
                                >
                                    Resume shop
                                </Button>
                            )}
                        </Box>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default GeneralDetails;
