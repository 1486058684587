import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    organizations: [],
    orgProfile: {},
};

// !########## SLICE #############
const organizationsSlice = createSlice({
    name: 'organizationsSlice',
    initialState,
    reducers: {
        setOrganizations: (state, actions) => {
            state.organizations = actions.payload;
        },
        setOrgProfile: (state, actions) => {
            state.orgProfile = actions.payload;
        },
    },
});

export const { setOrganizations, setOrgProfile } = organizationsSlice.actions;
export default organizationsSlice.reducer;
