// => GET all
const GET_ALL_SHOPS = '/shops';

// => GET single
const GET_SINGLE_SHOP = '/shops';

// => SUSPEND SHOP
const SUSPEND_SHOP = '/shops/suspend';

// => RESUME SHOP
const RESUME_SHOP = '/shops/resume';

// => VERIFY SHOP
const VERIFY_SHOP = '/shops/verify';

// => DENY SHOP
const DENY_SHOP = '/shops/deny';

export const shopEndpoints = {
    GET_ALL_SHOPS,
    GET_SINGLE_SHOP,
    SUSPEND_SHOP,
    RESUME_SHOP,
    VERIFY_SHOP,
    DENY_SHOP,
};
