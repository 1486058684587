import React from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { colors } from '../../../../assets/utils/constants';
import { DoneAll } from '@mui/icons-material';
import { useApproveOrganizationMutation } from '../../../../api/organizations/organizationAPI';
import { setOrgProfile } from '../../../../states/features/organizations/organizationsSlice';
import useRTK from '../../../../hooks/useRTK';

const VerifyOrganization = ({
    user,
    closeDialogy,
    refetchProfile,
    orgDocs,
    orgKeyPerson,
}) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK #####################
    const [
        verifyOrganization,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useApproveOrganizationMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        closeDialogy();
        refetchProfile();
        dispatch(setOrgProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <>
            <Grid container sx={{ textAlign: 'center' }}>
                <Grid item sm={12}>
                    <Box className="warning-message">
                        <Typography
                            sx={{
                                m: 2,
                                fontSize: 16,
                                opacity: 0.8,
                                color: colors.primary,
                                textAlign: 'left',
                            }}
                        >
                            <strong style={{ color: 'red' }}>Note: </strong>
                            <br />
                            Please ensure that all the information provided is
                            accurate and up-to-date. Verifying false information
                            may result to have wrong organization.{' '}
                            <strong>This process is irreversible</strong>.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {user.org_logo &&
                    user.description &&
                    user.phone_number_verified_at &&
                    user.email_verified_at &&
                    !isEmpty(orgDocs) &&
                    orgDocs.verification_status === 'verified' &&
                    !isEmpty(orgKeyPerson) &&
                    !isEmpty(orgKeyPerson.document) &&
                    orgKeyPerson.document?.verification_status ===
                        'verified' ? (
                        <>
                            <Box sx={{ p: 2, m: 2 }}>
                                <Button
                                    variant="contained"
                                    sx={{ mt: 2 }}
                                    color="success"
                                    size="small"
                                    startIcon={<DoneAll />}
                                    disabled={isLoading ? true : false}
                                    onClick={() => {
                                        verifyOrganization(user.id);
                                    }}
                                >
                                    Approve {user.organization_name}
                                    {isLoading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: colors.primary,
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Typography
                                className="error-message"
                                sx={{ p: 3, m: 3 }}
                            >
                                Please make sure all {user.organization_name}{' '}
                                required informations are submited and documents
                                are verified in order to make organization
                                approval.
                            </Typography>
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default VerifyOrganization;
