import React from 'react';
import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { Save } from '@mui/icons-material';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import CustomTextArea from '../../../components/Forms/CustomTextArea';
import { useUpdateFaqMutation } from '../../../api/faqs/faqsAPI';
import { setFaqs } from '../../../states/features/faqs/faqsSlice';

const createFaqValidationSchema = Yup.object({
    question: Yup.string().required(),
    answer: Yup.string().required(),
});

const UpdateFaq = ({ handleClose, faq }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        updateFaqRTK,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useUpdateFaqMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleClose();
        dispatch(setFaqs(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Box>
                <Formik
                    initialValues={{
                        faq_id: faq ? faq.id : '',
                        question: faq ? faq.question : '',
                        answer: faq ? faq.answer : '',
                    }}
                    validationSchema={createFaqValidationSchema}
                    onSubmit={(payload) => {
                        updateFaqRTK(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid container justifyanswer={'center'}>
                                <Grid item sm={12} xs={12}>
                                    <CustomTextField
                                        name="question"
                                        type={'text'}
                                        label={'Question'}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <CustomTextArea
                                        name="answer"
                                        type={'text'}
                                        label={'Answer'}
                                        rows={6}
                                        maxChar={1000}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        startIcon={<Save />}
                                        onClick={formik.handleSubmit}
                                        btnColor={'primary'}
                                    >
                                        Update faq
                                    </CustomSubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default UpdateFaq;
