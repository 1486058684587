import React from 'react';
import { LocationOn } from '@mui/icons-material';
import {
    Avatar,
    Box,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';

const OnFieldStudentWidget = () => {
    return (
        <>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pl: 1,
                    mb: 0.5,
                }}
            >
                <ListItem alignItems="flex-start" sx={{ p: 0.5 }}>
                    <ListItemAvatar>
                        <Avatar
                            alt="Business Op"
                            src=""
                            variant="square"
                            sx={{ borderRadius: 2, height: 60, width: 60 }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            '.MuiListItemText-primary': {
                                fontSize: 13,
                                fontWeight: 'bold',
                                opacity: 0.8,
                            },
                        }}
                        primaryTypographyProps={{
                            color: colors.primary,
                            ml: 1.5,
                        }}
                        primary={'Issac Adam Juma'}
                        secondaryTypographyProps={{ ml: 1.5 }}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: 13,
                                    }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    Computer science
                                </Typography>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: 13,
                                        opacity: 0.7,
                                    }}
                                    component="span"
                                    variant="body2"
                                    color={colors.info}
                                >
                                    <LocationOn sx={{ fontSize: 14 }} />
                                    <strong>VETA Chang'ombe, Arusha</strong>
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Box
                    sx={{
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>
                            Remain time
                        </Typography>
                        <Typography
                            className="error-message"
                            sx={{ fontSize: 10, fontWeight: 'bold' }}
                        >
                            2 Weeks to go
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default OnFieldStudentWidget;
