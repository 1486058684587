import React from "react";
import { Grid } from "@mui/material";
import CustomCard2 from "../../components/cards/CustomCard2";
import PageLayout from "../layouts/PageLayout";
import OccupationCard from "../../components/cards/OccupationCard";
import { useGetSingleStudentQuery } from "../../api/students/studentsAPI";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import { setStudentDetails } from "../../states/features/students/studentsSlice";
import ContentLoader from "../../components/ContentLoader";
import InnerDataFetchingError from "../../components/InnerDataFetchingError";
import { studentsSelector } from "../../states/features/selectors";
import GeneralDetails from "./components/GeneralDetails";
import Institution from "./components/Institution";
import { isEmpty } from "lodash";

const StudentDetails = () => {
    // ############## CONST ################
    const RTK = useRTK();
    const { student_id } = useParams();
    const dispatch = useDispatch();

    // ############# Redux state ###########
    const { studentDetails: student } = useSelector(studentsSelector);

    // ############### RTK ################
    const {
        isLoading,
        isSuccess,
        isError,
        refetch,
        data: PTData,
    } = useGetSingleStudentQuery(student_id);
    // => Response
    const rtkAction = (data) => {
        dispatch(setStudentDetails(data));
    };
    RTK.useRTKFetch(isSuccess, PTData, rtkAction);

    return (
        <>
            <PageLayout head={"Student account details"} noSidebar>
                {isLoading ? (
                    <CustomCard2 height={620}>
                        <ContentLoader />
                    </CustomCard2>
                ) : isError ? (
                    <>
                        <InnerDataFetchingError
                            height={550}
                            handleDataRefetch={refetch}
                        />
                    </>
                ) : (
                    <Grid container justifyContent={"center"} spacing={2}>
                        <Grid item sm={5}>
                            <GeneralDetails student={student} />
                        </Grid>
                        <Grid item sm={7}>
                            <Grid
                                container
                                rowSpacing={{ md: 3, xs: 2 }}
                                columnSpacing={2}
                            >
                                <Grid item xs={12} sm={12}>
                                    {!isEmpty(student) && (
                                        <Institution student={student} />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomCard2
                                        head={"Program | Occupation | Section"}
                                        height={330}
                                    >
                                        {!isEmpty(student) && (
                                            <OccupationCard student={student} />
                                        )}
                                    </CustomCard2>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomCard2
                                        head={"Attended trainings"}
                                        height={330}
                                    ></CustomCard2>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </PageLayout>
        </>
    );
};

export default StudentDetails;
