import React from 'react';
import { Box, MenuItem } from '@mui/material';
import CustomTextFieldFilter from '../Forms/CustomTextFieldFilter';
import CustomSelectFilter from '../Forms/CustomSelectFilter';

const JobFilters = ({ filterByIsFor, filterByJobName }) => {
    const isForTypes = [
        {
            id: 1,
            name: 'individual',
        },
        {
            id: 2,
            name: 'company',
        },
        {
            id: 3,
            name: 'group',
        },
    ];
    return (
        <>
            <Box
                sx={{
                    height: `100%`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mr: 2,
                }}
            >
                <CustomTextFieldFilter
                    width={200}
                    label={'Job title'}
                    handleFilter={filterByJobName}
                />
                <CustomSelectFilter
                    width={200}
                    handleFilter={filterByIsFor}
                    compValue={''}
                >
                    <MenuItem sx={{ fontSize: 12 }} value="">
                        <em>All jobs</em>
                    </MenuItem>
                    {isForTypes?.map((isFor) => (
                        <MenuItem
                            sx={{ fontSize: 12 }}
                            value={isFor.name}
                            key={isFor.name}
                        >
                            {isFor.name}
                        </MenuItem>
                    ))}
                </CustomSelectFilter>
            </Box>
        </>
    );
};

export default JobFilters;
