import React from 'react';
import IncomeWidget from '../widgets/IncomeWidget';
import CustomCard2 from '../cards/CustomCard2';
import { Box, Typography } from '@mui/material';
import { numFormater } from '../../helpers/numFormater';
import { colors } from '../../assets/utils/constants';
import { size } from 'lodash';

const JobSidebar = () => {
    return (
        <>
            <CustomCard2 head={'Jobs stats'} height={'100%'}>
                <Box>
                    <IncomeWidget
                        total={numFormater(50000)}
                        title={'Actual jobs revenue'}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        px: 1.5,
                        borderRadius: 2,
                        mt: 3,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    }}
                >
                    <Box
                        sx={{
                            height: 140,
                            width: 140,
                            borderRadius: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            border: `5px solid ${colors.bgColor4}`,
                            boxShadow: `0px 10px 15px ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 35,
                                fontWeight: 'bold',
                                opacity: 0.8,
                                color: colors.info,
                            }}
                        >
                            {size(2)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                opacity: 0.8,
                                fontStyle: 'italic',
                            }}
                        >
                            Jobs
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography
                            sx={{
                                color: colors.info,
                                fontSize: 20,
                                borderBottom: `3px solid ${colors.bgColor5}`,
                            }}
                        >
                            {1}{' '}
                            <sup
                                style={{
                                    color: colors.success,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}
                            >
                                On-Subscription
                            </sup>
                        </Typography>
                        <Typography
                            sx={{
                                color: colors.info,
                                fontSize: 20,
                                borderBottom: `3px solid ${colors.bgColor5}`,
                            }}
                        >
                            {1}{' '}
                            <sup
                                style={{
                                    color: colors.info,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}
                            >
                                Requested
                            </sup>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography
                            sx={{
                                color: colors.info,
                                fontSize: 20,
                                borderBottom: `3px solid ${colors.bgColor5}`,
                            }}
                        >
                            {1}{' '}
                            <sup
                                style={{
                                    color: colors.warning,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}
                            >
                                Suspended
                            </sup>
                        </Typography>
                        <Typography
                            sx={{
                                color: colors.info,
                                fontSize: 20,
                                borderBottom: `3px solid ${colors.bgColor5}`,
                            }}
                        >
                            {1}{' '}
                            <sup
                                style={{
                                    color: 'red',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}
                            >
                                Denied
                            </sup>
                        </Typography>
                    </Box>
                </Box>
            </CustomCard2>
        </>
    );
};

export default JobSidebar;
