import React from 'react';
import { Typography, Box } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { numFormater } from '../../helpers/numFormater';

const PromotionSubPackageWidget = ({ subPackage }) => {
    return (
        <div>
            <Typography
                sx={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    color: colors.info,
                    opacity: 0.8,
                    py: 1,
                }}
            >
                {subPackage.package.name}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    justifyContent: 'center',
                    borderRadius: 2,
                    border: `2px dotted ${colors.secondary}`,
                    boxShadow: `1px 1px 3px 2px ${colors.bgColor4}`,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 18,
                        textAlign: 'center',
                        p: 0.5,
                        fontWeight: 'bold',
                        color: colors.info,
                    }}
                >
                    {numFormater(subPackage.package.price)}
                    <sup
                        style={{
                            fontSize: 11,
                            color: colors.success,
                        }}
                    >
                        TZS
                    </sup>
                </Typography>
                <Typography>{'/'}</Typography>
                <Typography
                    sx={{
                        fontSize: 18,
                        textAlign: 'center',
                        p: 0.5,
                        fontWeight: 'bold',
                        color: colors.info,
                    }}
                >
                    {subPackage.package.duration} Days
                </Typography>
            </Box>
        </div>
    );
};

export default PromotionSubPackageWidget;
