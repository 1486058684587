import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { adminBaseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { freeTrialPackageEndpoints } from "./freeTrialPackageEndpoints";

export const freeTrialPackagesAPI = createApi({
    reducerPath: "freeTrialPackagesAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ["Packages"],
    endpoints: (builder) => ({
        // => GET all free trials
        getAllFreeTrialPackages: builder.query({
            query: () => `${freeTrialPackageEndpoints.GET_ALL_FREE_TRIALS}`,
            providesTags: ["Packages"],
        }),

        // => CREATE free trial
        createFreeTrialPackage: builder.mutation({
            query: (payload) => ({
                url: `${freeTrialPackageEndpoints.CREATE_FREE_TRIAL}`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (args, error) => (error ? [] : ["Packages"]),
        }),

        // => UPDATE free trial
        updateFreeTrialPackage: builder.mutation({
            query: (payload) => ({
                url: `${freeTrialPackageEndpoints.UPDATE_FREE_TRIAL}/${payload.package_id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: (args, error) => (error ? [] : ["Packages"]),
        }),

        // => ACTIVATE free trial
        activateFreeTrialPackage: builder.mutation({
            query: (payload) => ({
                url: `${freeTrialPackageEndpoints.ACTIVATE_FREE_TRIAL}/${payload}`,
                method: "PUT",
            }),
            invalidatesTags: (args, error) => (error ? [] : ["Packages"]),
        }),

        // => DEACTIVATE free trial
        deactivateFreeTrialPackage: builder.mutation({
            query: (payload) => ({
                url: `${freeTrialPackageEndpoints.DEACTIVATE_FREE_TRIAL}/${payload}`,
                method: "PUT",
            }),
            invalidatesTags: (args, error) => (error ? [] : ["Packages"]),
        }),

        // => DELETE free trial
        deleteFreeTrialPackage: builder.mutation({
            query: (payload) => ({
                url: `${freeTrialPackageEndpoints.DELETE_FREE_TRIAL}/${payload}`,
                method: "DELETE",
            }),
            invalidatesTags: (args, error) => (error ? [] : ["Packages"]),
        }),

        // => CREATE benefit
        createFreeTrialPackageBenefit: builder.mutation({
            query: (payload) => ({
                url: `${freeTrialPackageEndpoints.CREATE_BENEFIT}`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (args, error) => (error ? [] : ["Packages"]),
        }),

        // => UPDATE benefit
        updateFreeTrialPackageBenefit: builder.mutation({
            query: (payload) => ({
                url: `${freeTrialPackageEndpoints.UPDATE_BENEFIT}/${payload.benefit_id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: (args, error) => (error ? [] : ["Packages"]),
        }),

        // => DELETE benefit
        deleteFreeTrialPackageBenefit: builder.mutation({
            query: (payload) => ({
                url: `${freeTrialPackageEndpoints.DELETE_BENEFIT}/${payload}`,
                method: "DELETE",
            }),
            invalidatesTags: (args, error) => (error ? [] : ["Packages"]),
        }),
    }),
});

export const {
    useGetAllFreeTrialPackagesQuery,
    useCreateFreeTrialPackageMutation,
    useUpdateFreeTrialPackageMutation,
    useActivateFreeTrialPackageMutation,
    useDeactivateFreeTrialPackageMutation,
    useDeleteFreeTrialPackageMutation,
    // => Benefit
    useCreateFreeTrialPackageBenefitMutation,
    useUpdateFreeTrialPackageBenefitMutation,
    useDeleteFreeTrialPackageBenefitMutation,

    // => Lazy
    useLazyGetAllFreeTrialPackagesQuery,
} = freeTrialPackagesAPI;
