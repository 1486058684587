import { DeleteForever } from '@mui/icons-material';
import React from 'react';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { useDispatch } from 'react-redux';
import { useDeleteAllNotificationsMutation } from '../../../api/notifications/notificationsAPI';
import useRTK from '../../../hooks/useRTK';
import { setNotifications } from '../../../states/features/notifications/notificationsSlice';
import { useNavigate } from 'react-router-dom';

const DeleteAllNotifications = () => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // ############# RTK ##################
    const [
        deleteAllAction,
        {
            isLoading: deleteAllLoading,
            isSuccess: deleteAllSuccess,
            isError: deleteAllIsError,
            error: deleteAllError,
            data: deleteAllData,
        },
    ] = useDeleteAllNotificationsMutation();

    // => Res
    // ############# useEffect ##################
    const rtkActions = () => {
        dispatch(setNotifications([]));
        setTimeout(() => {
            navigate('/notifications');
        }, 500);
    };
    RTK.useRTKResponse(
        deleteAllSuccess,
        deleteAllIsError,
        deleteAllError,
        deleteAllData,
        rtkActions,
    );

    return (
        <div>
            <CustomSubmitButton
                variant="outlined"
                btnColor="error"
                startIcon={<DeleteForever />}
                mt={true}
                loading={deleteAllLoading}
                sx={{ fontSize: 11 }}
                onClick={deleteAllAction}
            >
                Delete all
            </CustomSubmitButton>
        </div>
    );
};

export default DeleteAllNotifications;
