import { Button, CircularProgress, Switch, Tooltip } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import useRTK from '../../../../hooks/useRTK';
import { colors } from '../../../../assets/utils/constants';
import {
    useActivateSocialNetworkMutation,
    useDeactivateSocialNetworkMutation,
} from '../../../../api/helpCenter/helpCenterAPI';
import { setSocialNetworks } from '../../../../states/features/helpCenter/helpCenterSlice';

const ActivateDeactivateNetwork = ({ network }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        activateAction,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivateSocialNetworkMutation();
    const [
        deactivateAction,
        {
            isLoading: deactivateLoading,
            isSuccess: deactivateSuccess,
            isError: deactivateIsError,
            error: deactivateError,
            data: deactivateData,
        },
    ] = useDeactivateSocialNetworkMutation();

    // ############# useEffect ##################
    // => Activate res
    const rtkActions = (data) => {
        dispatch(setSocialNetworks(data));
    };
    RTK.useRTKResponse(
        activateSuccess,
        activateIsError,
        activateError,
        activateData,
        rtkActions,
    );
    // => Deactivate res
    RTK.useRTKResponse(
        deactivateSuccess,
        deactivateIsError,
        deactivateError,
        deactivateData,
        rtkActions,
    );
    return (
        <>
            <Tooltip title={network.status ? 'Deactivate' : 'Activate'} arrow>
                <Button
                    variant="outlined"
                    color="secondary"
                    disabled={
                        activateLoading || deactivateLoading ? true : false
                    }
                    sx={{
                        fontSize: 13,
                        p: 0,
                        fontWeight: 'bold',
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                    }}
                    onClick={() =>
                        network.status
                            ? deactivateAction(network.id)
                            : activateAction(network.id)
                    }
                >
                    {activateLoading || deactivateLoading ? (
                        <>
                            <CircularProgress
                                size={20}
                                color="primary"
                                sx={{
                                    my: 0.25,
                                }}
                            />
                        </>
                    ) : (
                        <Switch
                            size="small"
                            color={network.status ? 'success' : 'error'}
                            checked={network.status ? true : false}
                        />
                    )}
                </Button>
            </Tooltip>
        </>
    );
};

export default ActivateDeactivateNetwork;
