// -> Checking phone number credibility
export const phoneCODEVerify = (method, phone) => {
    if (method === 'tigo') {
        if (
            phone.startsWith('071') ||
            phone.startsWith('067') ||
            phone.startsWith('065') ||
            phone.startsWith('077')
        ) {
            return true;
        } else {
            return false;
        }
    } else if (method === 'airtel') {
        if (
            phone.startsWith('068') ||
            phone.startsWith('069') ||
            phone.startsWith('078')
        ) {
            return true;
        } else {
            return false;
        }
    } else if (method === 'halotel') {
        if (phone.startsWith('062') || phone.startsWith('061')) {
            return true;
        } else {
            return false;
        }
    } else if (method === 'Mpesa') {
        if (
            phone.startsWith('074') ||
            phone.startsWith('075') ||
            phone.startsWith('076')
        ) {
            return true;
        } else {
            return false;
        }
    } else if (method === 'azam') {
        return true;
    } else {
        return false;
    }
};
