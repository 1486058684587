import React, { useState } from "react";
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import {
    ArrowRightAltOutlined,
    Image,
    VerifiedUserOutlined,
} from "@mui/icons-material";
import CustomFileInput from "../../../components/Forms/CustomFileInput";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { colors } from "../../../assets/utils/constants";
import { useUpdateProfilePictureMutation } from "../../../api/auth/profileAPI";
import useRTK from "../../../hooks/useRTK";
import { setUpdatedProfile } from "../../../states/features/auth/authSlice";

// ################## VALIDATION ####################
// * -> logo
const profilePictuteValidationSchema = Yup.object().shape({
    file: Yup.mixed().required("Profile picture field is required"),
});

// !############## MAIN FUNC ################
const UpdateProfilePicture = ({ user, handleDialogClose }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Comp State ##########
    // -> handle file preview
    const [filePreview, setFilePreview] = useState("");

    // ############## RTK ##################
    const [
        updateProfilePicture,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useUpdateProfilePictureMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setUpdatedProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    file: "",
                }}
                validationSchema={profilePictuteValidationSchema}
                onSubmit={(payload) => {
                    let id = user.id;
                    const data = new FormData();
                    data.append("id", id);
                    data.append("profile_picture", payload.file);
                    updateProfilePicture(data);
                    console.log(data);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container justifyContent={"center"}>
                            <Grid item sm={12}>
                                {filePreview ? (
                                    <>
                                        <img
                                            src={filePreview}
                                            alt="img"
                                            style={{
                                                width: "100%",
                                                height: "10em",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Image
                                            sx={{
                                                width: "100%",
                                                height: "7em",
                                                objectFit: "contain",
                                                opacity: 0.7,
                                                color: colors.bgColor5,
                                            }}
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid item sm={12}>
                                <CustomFileInput
                                    name="profile_picture"
                                    type="file"
                                    formik={formik}
                                    accept="image/*"
                                    setFilePreview={setFilePreview}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <CustomSubmitButton
                                startIcon={<VerifiedUserOutlined />}
                                endIcon={<ArrowRightAltOutlined />}
                                btnColor={`secondary`}
                                variant={"contained"}
                                loading={isLoading}
                                onClick={() => {
                                    formik.handleSubmit();
                                }}
                                sx={{ py: 1.5, fontSize: 16 }}
                            >
                                Update profile picture
                            </CustomSubmitButton>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateProfilePicture;
