import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { settingEndpoints } from './settingEndpoints';

export const settingAPI = createApi({
    reducerPath: 'settingAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Settigns', 'Pricing'],
    endpoints: (builder) => ({
        getAllSettings: builder.query({
            query: () => `${settingEndpoints.GET_ALL_SETTINGS}`,
            providesTags: ['Settings'],
        }),
        getJobPostPricing: builder.query({
            query: () => `${settingEndpoints.GET_JOB_POST_FRAMEWORK}`,
            providesTags: ['Pricing'],
        }),
        updateJobPostPricing: builder.mutation({
            query: (payload) => ({
                url: `${settingEndpoints.GET_JOB_POST_FRAMEWORK}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['Pricing'],
        }),

        // => Get beem balance
        getBeemBalance: builder.query({
            query: () => `${settingEndpoints.GET_SMS_BALANCE}`,
        }),
    }),
});

export const {
    useGetAllSettingsQuery,
    useGetJobPostPricingQuery,
    useUpdateJobPostPricingMutation,
    useGetBeemBalanceQuery,

    // => Lazy
    useLazyGetAllSettingsQuery,
} = settingAPI;
