import React from 'react';
import {
    AttachEmailOutlined,
    Edit,
    LocationOn,
    PhoneForwardedOutlined,
} from '@mui/icons-material';
import {
    Avatar,
    Badge,
    Box,
    Button,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';
import CustomDialog from '../Dialogies/CustomDialog';
import UpdateCustomer from '../../pages/promotions/CRUD/UpdateCustomer';
import ActivateDeactivateCustomer from '../../pages/promotions/CRUD/ActivateDeactivateCustomer';
import DeleteCustomer from '../../pages/promotions/CRUD/DeleteCustomer';

const PromotionCustomerWidget3 = ({ customer }) => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            {/* ############## Dialogy ############## */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`Update customer details`}
            >
                <UpdateCustomer customer={customer} handleClose={handleClose} />
            </CustomDialog>
            <Box
                sx={{
                    'width': '100%',
                    'borderRadius': 2,
                    'overflow': 'hidden',
                    'border': `4px solid ${colors.bgColor4}`,
                    'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    'transition': '.3s all ease-in-out',
                    ':hover': {
                        transition: '.3s all ease-in-out',
                        border: `4px solid ${colors.secondary}`,
                    },
                }}
            >
                <ListItemButton
                    sx={{
                        'mb': 1,
                        'transition': '.3s all ease-in-out',
                        '& .dp': {
                            mr: 2,
                            transition: '.3s all ease-in-out',
                        },
                        'bgcolor': colors.primary,
                        ':hover': {
                            cursor: 'default',
                            bgcolor: colors.primary,
                        },
                    }}
                >
                    <ListItem
                        sx={{
                            p: 0,
                            position: 'relative',
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                src={customer.logo_url}
                                className="dp"
                                sx={{
                                    height: 60,
                                    width: 60,
                                    border: `2px solid ${colors.secondary}`,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        color: colors.secondary,
                                        opacity: 0.8,
                                    }}
                                >
                                    {customer.name}
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        display: 'flex',
                                        alignItems: 'center',
                                        opacity: 0.8,
                                        color: colors.secondary,
                                    }}
                                >
                                    <LocationOn
                                        color="secondary"
                                        sx={{ fontSize: 14 }}
                                    />{' '}
                                    {customer.location}
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 40,
                        }}
                    >
                        <Badge
                            badgeContent={customer.customer_type}
                            color={
                                customer.customer_type === 'company'
                                    ? 'info'
                                    : 'secondary'
                            }
                        />
                    </Box>
                </ListItemButton>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: 1,
                        pb: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: 120,
                                mr: 2,
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                opacity: 0.9,
                            }}
                        >
                            <a href={`tel:${customer.phone_number}`}>
                                <Button fullWidth size="small" color="info">
                                    <PhoneForwardedOutlined
                                        sx={{ fontSize: 20 }}
                                    />
                                </Button>
                            </a>
                        </Box>
                        <Box
                            sx={{
                                width: 120,
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: colors.white,
                                borderRadius: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                opacity: 0.9,
                            }}
                        >
                            <a href={`mailTo:${customer.email}`}>
                                <Button fullWidth size="small" color="info">
                                    <AttachEmailOutlined
                                        sx={{ fontSize: 20 }}
                                    />
                                </Button>
                            </a>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            pr: 2,
                        }}
                    >
                        <Box>
                            {customer.status ? (
                                <Typography
                                    sx={{
                                        bgcolor: colors.success,
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        px: 3,
                                        borderRadius: 50,
                                        mr: 3,
                                        background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                                        border: `3px dotted ${colors.success}`,
                                    }}
                                >
                                    Active
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        bgcolor: colors.success,
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        px: 3,
                                        borderRadius: 50,
                                        mr: 3,
                                        background: `linear-gradient(180deg, ${colors.danger}, ${colors.danger})`,
                                        border: `3px dotted red`,
                                    }}
                                >
                                    In-Active
                                </Typography>
                            )}
                        </Box>
                        <ActivateDeactivateCustomer customer={customer} />
                        <Button
                            size="small"
                            variant="outlined"
                            color="warning"
                            startIcon={<Edit />}
                            sx={{ mx: 2 }}
                            onClick={() => {
                                handleClickOpen();
                            }}
                        >
                            Edit
                        </Button>
                        <DeleteCustomer customer={customer} />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PromotionCustomerWidget3;
