import React from "react";
import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { Save } from "@mui/icons-material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import CustomTextArea from "../../../components/Forms/CustomTextArea";
import { setFreeTrialPackages } from "../../../states/features/subscriptionPackages/subscriptionPackagesSlice";
import { useCreateFreeTrialPackageBenefitMutation } from "../../../api/subscriptionPackages/freeTrialPackagesAPI";

const CreateBenefitSchema = Yup.object({
    free_trial_id: Yup.string().required(),
    benefit: Yup.string().max(200),
});

const CreateFreeTrialBenefit = ({ handleDialogClose, freeTrialPackage }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        createPackage,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useCreateFreeTrialPackageBenefitMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setFreeTrialPackages(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Box>
                <Formik
                    initialValues={{
                        free_trial_id: freeTrialPackage.id,
                        benefit: "",
                    }}
                    validationSchema={CreateBenefitSchema}
                    onSubmit={(payload) => {
                        createPackage(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid
                                container
                                justifyContent={"center"}
                                columnSpacing={2}
                            >
                                <Grid item sm={12} xs={12}>
                                    <CustomTextArea
                                        name="benefit"
                                        type={"text"}
                                        label={"Free trial benefit"}
                                        rows={5}
                                        maxChar={200}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        startIcon={<Save />}
                                        onClick={formik.handleSubmit}
                                        btnColor={"primary"}
                                        sx={{
                                            py: 2,
                                        }}
                                    >
                                        Save benefit
                                    </CustomSubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default CreateFreeTrialBenefit;
