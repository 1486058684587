import React from 'react';
import { Box, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ErrorMessage, useField } from 'formik';

const CustomInput = styled(TextField)({});

const CustomPhoneTextField = ({
    label,
    type,
    value,
    cuPadding,
    getInputValue,
    ...props
}) => {
    const [field, meta] = useField(props);
    return (
        <>
            <Box
                sx={{
                    borderRadius: 1.5,
                    overflow: 'hidden',
                }}
            >
                <CustomInput
                    // InputProps={{
                    //     startAdornment: (
                    //         <InputAdornment position="start">+255</InputAdornment>
                    //     ),
                    // }}
                    error={meta.touched && meta.error ? true : false}
                    label={label}
                    variant="filled"
                    fullWidth
                    type={type}
                    placeholder={field.label}
                    {...field}
                    {...props}
                    size={'small'}
                    focused={type === 'date' || type === 'time' ? true : false}
                    sx={{
                        input: {
                            pr: cuPadding ? cuPadding : '',
                        },
                    }}
                    onKeyUp={() =>
                        getInputValue ? getInputValue(field.value) : null
                    }
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomPhoneTextField;
