import React from 'react';
import { Grid } from '@mui/material';
import OrganizationTable from '../../../components/Tables/OrganizationTable';
import CustomCard from '../../../components/cards/CustomCard';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import { size } from 'lodash';
import PageLayout from '../../layouts/PageLayout';
import { organizationsSelector } from '../../../states/features/selectors';
import CountBadge from '../../../components/CountBadge';
import { useParams } from 'react-router-dom';

const CompaniesByStatus = () => {
    // ################# CONST #####################
    const { status } = useParams();

    // ############### REDUCER STATE #################
    const { organizations } = useSelector(organizationsSelector);
    const companies = filter(organizations, ['org_type', 'Company']);

    // ################ FUNC ###################
    const getOrgByStatus = (companies) => {
        let filteredCompanies = [];
        if (status === 'on-progress') {
            filteredCompanies = filter(companies, ['is_verified', 'pending']);
        }
        if (status === 'request') {
            filteredCompanies = filter(companies, ['is_verified', 'on_progress']);
        }
        if (status === 'verified') {
            filteredCompanies = filter(companies, ['is_verified', 'verified']);
        }
        if (status === 'denied') {
            filteredCompanies = filter(companies, ['is_verified', 'denied']);
        }
        return filteredCompanies;
    };
    const companyByStatus = getOrgByStatus(companies);

    return (
        <>
            <PageLayout head={`${status} companies`}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard
                            head={`All ${status} companies`}
                            action={
                                <CountBadge
                                    total={size(companyByStatus)}
                                    item={'Companies'}
                                />
                            }
                        >
                            <Grid container>
                                <Grid item sm={12}>
                                    <OrganizationTable
                                        height={522}
                                        organizations={companyByStatus}
                                    />
                                </Grid>
                            </Grid>
                        </CustomCard>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default CompaniesByStatus;
