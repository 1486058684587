import React, { useState } from 'react';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, Save } from '@mui/icons-material';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { colors } from '../../../assets/utils/constants';
import CustomSelectInput from '../../../components/Forms/CustomSelectInput';
import { usePromotionSubscriptionMutation } from '../../../api/promotions/promotionsAPI';
import { setPromotionDetails } from '../../../states/features/promotions/promotionsSlice';
import {
    promotionCustomersSelector,
    subscriptionPackagesSelector,
} from '../../../states/features/selectors';
import { filter, isEmpty } from 'lodash';
import NoContent from '../../../components/NoContent';
import { numFormater } from '../../../helpers/numFormater';

// ######### validation schema #################
const promotionSubscriptionValidationSchema = Yup.object({
    subscription_package_id: Yup.string().required(),
});

const PromotionSubscription = ({ promotion, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp state ################
    const [selectedPackage, setselectedPackage] = useState({});

    // ############## Redux state ###################
    const { customers } = useSelector(promotionCustomersSelector);
    const { subscriptionPackages } = useSelector(subscriptionPackagesSelector);
    // => Promo connect packages
    const promoConnectPackages = filter(
        subscriptionPackages,
        ({ type, status }) => type === 'Promo connect' && status === 1,
    );

    // ############# FUNC ##################
    const getSelectedValue = (payload) => {
        const selectedPackage = filter(
            promoConnectPackages,
            ({ id }) => id === payload,
        );
        setselectedPackage(selectedPackage[0]);
    };

    // ############# RTK ##################
    const [
        createPromotionSubscription,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = usePromotionSubscriptionMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setPromotionDetails(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Box>
                {isEmpty(customers) ? (
                    <NoContent
                        height={250}
                        message={
                            'No active promotion customer added. Please add customer first.'
                        }
                    />
                ) : isEmpty(promoConnectPackages) ? (
                    <NoContent
                        height={250}
                        message={
                            'No active promo connect subscription package. Please add package first.'
                        }
                    />
                ) : (
                    <Formik
                        initialValues={{
                            promotion_id: promotion.id,
                            subscription_package_id: '',
                        }}
                        validationSchema={promotionSubscriptionValidationSchema}
                        onSubmit={(payload) => {
                            createPromotionSubscription(payload);
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Grid
                                    container
                                    justifyContent={'center'}
                                    columnSpacing={2}
                                >
                                    <Grid
                                        item
                                        sm={12}
                                        sx={{
                                            height: '100%',
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                borderTopLeftRadius: 25,
                                                borderTopRightRadius: 25,
                                                p: 2,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 20,
                                                    fontWeight: 'bold',
                                                    p: 0.5,
                                                    color: colors.warning,
                                                }}
                                            >
                                                Subscription package
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: 18,
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    textTransform: 'uppercase',
                                                    color: colors.info,
                                                    opacity: 0.8,
                                                    py: 1,
                                                }}
                                            >
                                                {selectedPackage.name}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    justifyContent: 'center',
                                                    py: 1,
                                                    boxShadow: `0px 1px 3px 2px ${colors.bgColor4}`,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: 18,
                                                        textAlign: 'center',
                                                        p: 0.5,
                                                        fontWeight: 'bold',
                                                        color: colors.info,
                                                    }}
                                                >
                                                    {numFormater(
                                                        selectedPackage.price,
                                                    )}
                                                    <sup
                                                        style={{
                                                            fontSize: 11,
                                                            color:
                                                                colors.success,
                                                        }}
                                                    >
                                                        TZS
                                                    </sup>
                                                </Typography>
                                                <Typography>{'/'}</Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: 18,
                                                        textAlign: 'center',
                                                        p: 0.5,
                                                        fontWeight: 'bold',
                                                        color: colors.info,
                                                    }}
                                                >
                                                    {selectedPackage.duration}{' '}
                                                    Days
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12} xs={12} sx={{ my: 5 }}>
                                        <CustomSelectInput
                                            type="text"
                                            name="subscription_package_id"
                                            label="Promo conect package"
                                            formik={formik}
                                            color="primary"
                                            handler={getSelectedValue}
                                        >
                                            {promoConnectPackages.map(
                                                (promoPackage) => (
                                                    <MenuItem
                                                        value={promoPackage.id}
                                                        key={promoPackage.id}
                                                    >
                                                        {promoPackage.name}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </CustomSelectInput>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <CustomSubmitButton
                                            loading={isLoading}
                                            startIcon={<Save />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            onClick={formik.handleSubmit}
                                            btnColor={'primary'}
                                            sx={{ py: 2 }}
                                        >
                                            Save subscription
                                        </CustomSubmitButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                )}
            </Box>
        </>
    );
};

export default PromotionSubscription;
