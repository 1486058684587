import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { faqsEndpoints } from './faqsEndpoints';

export const faqsAPI = createApi({
    reducerPath: 'faqsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Faqs'],
    endpoints: (builder) => ({
        // => GET all
        getFaqs: builder.query({
            query: () => `${faqsEndpoints.GET_ALL}`,
            providesTags: ['Faqs'],
        }),
        // => CREATE
        createFaq: builder.mutation({
            query: (payload) => ({
                url: `${faqsEndpoints.CREATE_FAQ}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ['Faqs'],
        }),
        // => UPDATE
        updateFaq: builder.mutation({
            query: (payload) => ({
                url: `${faqsEndpoints.UPDATE_FAQ}/` + payload.faq_id,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ['Faqs'],
        }),
        // => ACTIVATE
        activateFaq: builder.mutation({
            query: (payload) => ({
                url: `${faqsEndpoints.ACTIVATE_FAQ}/` + payload,
                method: `PUT`,
            }),
            // invalidatesTags: ['Faqs'],
        }),
        // => DEACTIVATE
        deactivateFaq: builder.mutation({
            query: (payload) => ({
                url: `${faqsEndpoints.DEACTIVATE_FAQ}/` + payload,
                method: `PUT`,
            }),
            // invalidatesTags: ['Faqs'],
        }),
        // => DELETE
        deleteFaq: builder.mutation({
            query: (payload) => ({
                url: `${faqsEndpoints.DELETE_FAQ}/` + payload,
                method: `DELETE`,
            }),
            invalidatesTags: ['Faqs'],
        }),
    }),
});

export const {
    useGetFaqsQuery,
    useCreateFaqMutation,
    useUpdateFaqMutation,
    useActivateFaqMutation,
    useDeactivateFaqMutation,
    useDeleteFaqMutation,
} = faqsAPI;
