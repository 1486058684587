import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { filter, isEmpty } from 'lodash';
import { size } from 'lodash';
import { promotionsSelector } from '../../states/features/selectors';
import CountBadge from '../../components/CountBadge';
import { useParams } from 'react-router-dom';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import PromotionWidget from '../../components/widgets/PromotionWidget';
import PromotionSidebar from '../../components/sidebars/PromotionSidebar';
import NoContent from '../../components/NoContent';

const PromotionByStatus = () => {
    // ################# CONST #####################
    const { status } = useParams();

    // ############## Redux state ###################
    const { promotions } = useSelector(promotionsSelector);

    const getPromotions = () => {
        let filteredPromotions = [];
        if (status === 'all') {
            filteredPromotions = promotions;
        } else if (status === 'active') {
            filteredPromotions = filter(
                promotions,
                ({ isActive: qStatus }) => qStatus === 1,
            );
        } else if (status === 'in-active') {
            filteredPromotions = filter(
                promotions,
                ({ isActive: qStatus }) => qStatus === 0,
            );
        }
        return filteredPromotions;
    };

    const promotionByStatus = getPromotions();

    return (
        <>
            <PageLayout
                head={`${status} promotions`}
                sidebar={<PromotionSidebar />}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard2
                            head={`All ${
                                status !== 'all' ? status : ''
                            } promotions`}
                            height={'70vh'}
                            borderBottom
                            action={
                                <CountBadge
                                    total={size(promotionByStatus)}
                                    item={'promotions'}
                                />
                            }
                        >
                            {isEmpty(promotionByStatus) ? (
                                <>
                                    <NoContent
                                        message={`No ${
                                            status !== 'all' ? status : ''
                                        } promotion available`}
                                    />
                                </>
                            ) : (
                                <>
                                    <Grid container spacing={2}>
                                        {promotionByStatus.map((promotion) => (
                                            <Grid
                                                item
                                                sm={4}
                                                key={promotion.id}
                                            >
                                                <PromotionWidget
                                                    promotion={promotion}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </>
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default PromotionByStatus;
