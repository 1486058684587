import React from 'react';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ManageAccounts } from '@mui/icons-material';
import * as Yup from 'yup';
import CustomTextArea from '../../../components/Forms/CustomTextArea';
import { useDispatch } from 'react-redux';
import { DialogActions } from '@mui/material';
import useRTK from '../../../hooks/useRTK';
import { setJobCategories } from '../../../states/features/settings/jobCategoriesSlice';
import { useUpdateJobCategoryMutation } from '../../../api/settings/jobCategoriesAPI';

// -> validate settings form
const validate = Yup.object({
    name: Yup.string().required(),
    description: Yup.string().nullable(),
});

const UpdateJobCategory = ({ handleClose, refetch, editedValues }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        updateJobCategory,
        { isLoading, isSuccess, isError, error, data: updateData },
    ] = useUpdateJobCategoryMutation();

    // ############# RTK response ##################
    const rtkActions = (data) => {
        refetch();
        handleClose(data);
        dispatch(setJobCategories(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updateData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    id: editedValues.id,
                    name: editedValues.name,
                    description: editedValues.description,
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    updateJobCategory(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <CustomTextField label="Name" name="name" type="text" />
                        <CustomTextArea
                            label="Description"
                            name="description"
                        />
                        <DialogActions>
                            <CustomSubmitButton
                                startIcon={<ManageAccounts />}
                                onClick={formik.handleSubmit}
                                loading={isLoading}
                            >
                                Update setting
                            </CustomSubmitButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateJobCategory;
