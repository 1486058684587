import React from 'react';
import { Box, TextField } from '@mui/material';

const PromoCustomerFilter = ({ handleFilter, width }) => {
    return (
        <>
            <Box sx={{ display: 'flex', width: width }}>
                <Box sx={{ width: '100%' }}>
                    <TextField
                        size="small"
                        label="Search customer"
                        fullWidth
                        onKeyUp={(e) => handleFilter(e.target.value)}
                    />
                </Box>
            </Box>
        </>
    );
};

export default PromoCustomerFilter;
