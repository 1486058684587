import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { studentsSelector } from '../../../states/features/selectors';
import CustomCard from '../../../components/cards/CustomCard';
import ContentLoader from '../../../components/ContentLoader';
import NoContent from '../../../components/NoContent';
import { isEmpty, size } from 'lodash';
import { useGetORGStudentsQuery } from '../../../api/students/studentsAPI';
import StudentsTable from '../../../components/Tables/StudentsTable';
import { setORGStudents } from '../../../states/features/students/studentsSlice';
import CountBadge from '../../../components/CountBadge';

const ORGStudents = ({ organization }) => {
    // ############## RTK ############
    const dispatch = useDispatch();
    const RTK = useRTK();

    // ############## Redux state ############
    const { ORGStudents } = useSelector(studentsSelector);

    // ############## RTK ############
    const { isLoading, isSuccess, data: studentData } = useGetORGStudentsQuery(
        organization.id,
    );
    const rtkActions = (data) => {
        dispatch(setORGStudents(data));
    };
    RTK.useRTKFetch(isSuccess, studentData, rtkActions);

    return (
        <>
            <CustomCard
                head={'All organization students'}
                height={450}
                action={
                    <CountBadge item={'Students'} total={size(ORGStudents)} />
                }
            >
                {isLoading ? (
                    <>
                        <ContentLoader />
                    </>
                ) : isEmpty(ORGStudents) ? (
                    <>
                        <NoContent message={'No institution student added'} />
                    </>
                ) : (
                    <StudentsTable height={'50vh'} students={ORGStudents} />
                )}
            </CustomCard>
        </>
    );
};

export default ORGStudents;
