import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { identificationTypesEndpoints } from './settingEndpoints';
import { settingAPI } from './settingAPI';

export const identificationTypesAPI = createApi({
    reducerPath: 'identificationTypesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getIdentificationTypes: builder.query({
            query: () =>
                `${identificationTypesEndpoints.GET_IDENTIFICATION_TYPES}`,
        }),
        // => ADD
        addIdentificationType: builder.mutation({
            query: (payload) => ({
                url: `${identificationTypesEndpoints.ADD_IDENTIFICATION_TYPE}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
        // => UPDATE
        updateIdentificationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${identificationTypesEndpoints.UPDATE_IDENTIFICATION_TYPE}/` +
                    payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateIdentificationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${identificationTypesEndpoints.ACTIVATE_IDENTIFICATION_TYPE}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateIdentificationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${identificationTypesEndpoints.DEACTIVATE_IDENTIFICATION_TYPE}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteIdentificationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${identificationTypesEndpoints.DELETE_IDENTIFICATION_TYPE}/` +
                    payload,
                method: `DELETE`,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
    }),
});

export const {
    useGetIdentificationTypesQuery,
    useAddIdentificationTypeMutation,
    useUpdateIdentificationTypeMutation,
    useActivateIdentificationTypeMutation,
    useDeactivateIdentificationTypeMutation,
    useDeleteIdentificationTypeMutation,
} = identificationTypesAPI;
