import React from 'react';
import { Verified, LocationOn, Unpublished } from '@mui/icons-material';
import {
    Avatar,
    Box,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';

const BeneficiaryWidget2 = ({ beneficiary }) => {
    return (
        <>
            <Box
                sx={{
                    'width': '100%',
                    'borderRadius': 3,
                    'overflow': 'hidden',
                    'border': `4px solid ${colors.secondary}`,
                    'background': `linear-gradient(180deg, ${colors.secondary}, ${colors.bgColor3})`,
                    'transition': '.3s all ease-in-out',
                    ':hover': {
                        transition: '.3s all ease-in-out',
                        border: `4px solid ${colors.secondary}`,
                    },
                }}
            >
                <ListItemButton
                    sx={{
                        'px': 0.6,
                        'mb': 1,
                        'transition': '.3s all ease-in-out',
                        '& .dp': {
                            mr: 2,
                            transition: '.3s all ease-in-out',
                        },
                        'bgcolor': colors.primary,
                        ':hover': {
                            cursor: 'default',
                            bgcolor: colors.primary,
                        },
                    }}
                >
                    <ListItem
                        sx={{
                            p: 0,
                            position: 'relative',
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                src={`${beneficiary.profile_picture}`}
                                className="dp"
                                sx={{
                                    height: 60,
                                    width: 60,
                                    border: `2px solid ${colors.secondary}`,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        color: colors.secondary,
                                        opacity: 0.8,
                                    }}
                                >
                                    {`${beneficiary.first_name} ${beneficiary.last_name}`}
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        display: 'flex',
                                        alignItems: 'center',
                                        opacity: 0.8,
                                        color: colors.secondary,
                                    }}
                                >
                                    <LocationOn
                                        color="secondary"
                                        sx={{ fontSize: 14 }}
                                    />{' '}
                                    {`${beneficiary.region} - ${beneficiary.district}`}
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: 40,
                                flexDirection: 'column',
                            }}
                        >
                            {beneficiary.is_verified === 1 ? (
                                <>
                                    <Verified
                                        sx={{ fontSize: 22, mb: 0.5 }}
                                        color="secondary"
                                    />
                                </>
                            ) : (
                                <>
                                    <Unpublished
                                        sx={{ fontSize: 22, mb: 0.5 }}
                                        color="warning"
                                    />
                                </>
                            )}
                        </Box>
                        <Box>
                            <Typography
                                noWrap
                                sx={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontSize: 20,
                                    color: colors.secondary,
                                }}
                            >
                                {`${beneficiary.slug_id}`}
                            </Typography>
                        </Box>
                    </Box>
                </ListItemButton>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        pb: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: 50,
                                mx: 0.5,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                borderRadius: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                opacity: 0.9,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        mt: 0.5,
                                        color: colors.warning,
                                    }}
                                >
                                    On-going Jobs
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        color: colors.info,
                                    }}
                                >
                                    89
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        mt: 0.5,
                                        color: colors.success,
                                    }}
                                >
                                    Completed Jobs
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        color: colors.info,
                                    }}
                                >
                                    89
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default BeneficiaryWidget2;
