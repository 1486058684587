import { Box } from '@mui/material';
import React from 'react';
import { getExtension } from '../helpers/getFileExtension';
import { colors } from '../assets/utils/constants';

const DocumentPreview = ({ document }) => {
    return (
        <>
            <Box
                sx={{
                    bgcolor: colors.bgColor1,
                }}
            >
                {getExtension(document) === 'pdf' ? (
                    <Box
                        sx={{
                            width: '100%',
                            height: 600,
                        }}
                    >
                        <embed
                            src={document}
                            type="application/pdf"
                            style={{
                                width: '100%',
                                height: 600,
                            }}
                        ></embed>
                    </Box>
                ) : getExtension(document) === 'png' ||
                  getExtension(document) === 'jpg' ||
                  getExtension(document) === 'jpeg' ? (
                    <Box
                        sx={{
                            width: '100%',
                            height: 600,
                        }}
                    >
                        <img
                            src={document}
                            alt=""
                            style={{
                                width: '100%',
                                height: 600,
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: '100%',
                            height: 600,
                        }}
                    >
                        <embed
                            src={document}
                            type="application/pdf"
                            style={{
                                width: '100%',
                                height: 600,
                            }}
                        ></embed>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default DocumentPreview;
