import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ManageAccounts } from '@mui/icons-material';
import * as Yup from 'yup';
import CustomTextArea from '../../../components/Forms/CustomTextArea';
import { useDispatch } from 'react-redux';
import { DialogActions, Grid, MenuItem } from '@mui/material';
import useRTK from '../../../hooks/useRTK';
import { useAddPaymentMethodMutation } from '../../../api/settings/paymentMethodsAPI';
import { addPaymentMethod } from '../../../states/features/settings/paymentMethodsSlice';
import CustomSelectInput from '../../../components/Forms/CustomSelectInput';
import { filter } from 'lodash';

// -> validate settings form
const validate = Yup.object({
    // name: Yup.string().required(),
    method: Yup.string().required(),
    provider: Yup.string().required(),
    description: Yup.string().nullable(),
});

const AddPaymentMethod = ({ handleClose, refetch }) => {
    // ############# Comp state #############
    const [providerOptions, setProviderOptions] = useState([]);

    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();
    const methods = [
        {
            name: 'Card payment',
            value: 'Bank',
        },
        {
            name: 'Mobile payment',
            value: 'Mobile',
        },
    ];
    const providers = [
        {
            name: 'Vodacom',
            value: 'Mpesa',
            method: 'Mobile',
        },
        {
            name: 'Airtel',
            value: 'Airtel',
            method: 'Mobile',
        },
        {
            name: 'Tigo',
            value: 'Tigo',
            method: 'Mobile',
        },
        {
            name: 'Halotel',
            value: 'Halopesa',
            method: 'Mobile',
        },
        {
            name: 'Azam',
            value: 'Azampesa',
            method: 'Mobile',
        },
        {
            name: 'Master card',
            value: 'Mastercard',
            method: 'Bank',
        },
        {
            name: 'Visa card',
            value: 'Visacard',
            method: 'Bank',
        },
    ];

    // ############ FUNC ##################
    const handleProviderChange = (selectedMethod) => {
        let fiteredProviders = filter(
            providers,
            ({ method }) => method === selectedMethod,
        );
        setProviderOptions(fiteredProviders);
    };

    // ############# RTK ##################
    const [
        createPaymentMethods,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useAddPaymentMethodMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        refetch();
        handleClose();
        dispatch(addPaymentMethod(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    // name: '',
                    method: '',
                    provider: '',
                    description: '',
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    createPaymentMethods(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container columnSpacing={4}>
                            {/* <Grid item sm={6}>
                                <CustomTextField
                                    label="Name"
                                    name="name"
                                    type="text"
                                />
                            </Grid> */}
                            <Grid item sm={6}>
                                <CustomSelectInput
                                    type="text"
                                    name="method"
                                    label="Select method"
                                    formik={formik}
                                    color="primary"
                                    handler={handleProviderChange}
                                >
                                    {methods.map((method) => (
                                        <MenuItem
                                            value={method.value}
                                            key={method.name}
                                        >
                                            {method.name}
                                        </MenuItem>
                                    ))}
                                </CustomSelectInput>
                            </Grid>
                            <Grid item sm={6}>
                                <CustomSelectInput
                                    type="text"
                                    name="provider"
                                    label="Select provider"
                                    formik={formik}
                                    color="primary"
                                >
                                    {providerOptions.map((provider) => (
                                        <MenuItem
                                            value={provider.value}
                                            key={provider.name}
                                        >
                                            {provider.name}
                                        </MenuItem>
                                    ))}
                                </CustomSelectInput>
                            </Grid>
                            <Grid item sm={12}>
                                <CustomTextArea
                                    label="Description"
                                    name="description"
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <CustomSubmitButton
                                startIcon={<ManageAccounts />}
                                onClick={formik.handleSubmit}
                                loading={isLoading}
                            >
                                Add setting
                            </CustomSubmitButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddPaymentMethod;
