import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { Link } from 'react-router-dom';
import StudentMiniWidget from '../widgets/StudentMiniWidget';
import { Remove, RemoveRedEye } from '@mui/icons-material';

const columns = [
    { id: 's_n', label: 'S/N', minWidth: 0, align: 'center' },
    { id: 'student', label: 'Student', align: 'left' },
    { id: 'section', label: 'Section | Occupation', align: 'center' },
    { id: 'start', label: 'Start', align: 'center' },
    { id: 'finish', label: 'Finish', align: 'center' },
    {
        id: 'regStatus',
        label: 'Reg. Status',
        align: 'center',
    },
    {
        id: 'action',
        label: '',
    },
];

function createData(s_n, student, section, start, finish, regStatus, action) {
    return {
        s_n,
        student,
        section,
        start,
        finish,
        regStatus,
        action,
    };
}

const StudentsTable = ({ students, height }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = students?.map((student, index) => {
        return createData(
            <Typography sx={{ fontWeight: 'bold', opacity: 0.6 }}>
                {++index}
            </Typography>,
            <StudentMiniWidget student={student} />,
            <Typography sx={{ fontSize: 15, opacity: 0.8 }}>
                {student?.section ? (
                    student?.section?.section?.name
                ) : (
                    <Remove />
                )}
            </Typography>,
            <Typography
                sx={{
                    fontSize: 13,
                    opacity: 0.8,
                    fontWeight: 'bold',
                    color: colors.success,
                }}
            >
                {student?.start_year}
            </Typography>,
            <Typography
                sx={{
                    fontSize: 13,
                    opacity: 0.8,
                    fontWeight: 'bold',
                    color: 'red',
                }}
            >
                {student?.finish_year}
            </Typography>,
            <Chip
                label={student.regStatus}
                color={student.regStatus === 'on-progress' ? 'info' : 'success'}
                size="small"
                sx={{
                    textTransform: 'capitalize',
                    fontSize: 12,
                    fontWeight: 'bold',
                    px: 1,
                    opacity: 0.8,
                }}
            />,
            <Link to={`${student.id}`}>
                <Tooltip title="Student details" arrow>
                    <IconButton size="small">
                        <RemoveRedEye color="info" />
                    </IconButton>
                </Tooltip>
            </Link>,
        );
    });

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
            <TableContainer
                sx={{
                    height: height,
                    overflow: 'auto',
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                }}
            >
                <Table stickyHeader aria-label="sticky table" sx={{}}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        fontSize: 12,
                                        color: colors.primary,
                                        whiteSpace: 'nowrap',
                                        opacity: 0.8,
                                    }}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                        }}
                    >
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage,
                            )
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    sx={{ py: 0 }}
                                                >
                                                    {column.format &&
                                                    typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[1, 2, 5, 10]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                }}
            />
        </Paper>
    );
};

export default StudentsTable;
