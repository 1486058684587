import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/constants";
import { VerticalBarChart } from "../charts/VerticalBarChart";
import { useSelector } from "react-redux";
import { transactionsSelector } from "../../states/features/selectors";
import { numFormater } from "../../helpers/numFormater";

const RevenueCard = () => {
    // ################ Redux State ###############
    const { transactionsOverview } = useSelector(transactionsSelector);

    const revenueResources = [
        {
            id: 1,
            name: "Promotions",
            total: transactionsOverview.total_PC,
        },
        {
            id: 2,
            name: "Job posting fee",
            total: transactionsOverview.total_JP,
        },
        {
            id: 3,
            name: "Fundi subscriptions",
            total: transactionsOverview.total_TT,
        },
        {
            id: 4,
            name: "Shops subscriptions",
            total: transactionsOverview.total_MM,
        },
    ];
    
    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={9}>
                    <Box
                        sx={{
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 25,
                                fontWeight: "bold",
                                color: colors.info,
                            }}
                        >
                            {numFormater(
                                transactionsOverview.total_transaction
                            )}
                            <sup
                                style={{
                                    fontSize: 15,
                                    color: colors.success,
                                    fontWeight: "bold",
                                }}
                            >
                                TZS
                            </sup>
                        </Typography>
                        <Typography sx={{ color: colors.primary }}>
                            Total revenue
                        </Typography>
                    </Box>
                    <Box>
                        <VerticalBarChart title={"Revenue stats"} />
                    </Box>
                </Grid>
                <Grid item sm={3}>
                    <Grid container spacing={1}>
                        {revenueResources.map((resource) => (
                            <Grid item sm={12} key={resource.id}>
                                <Box
                                    sx={{
                                        p: 1,
                                        borderLeft: `2px solid ${colors.info}`,
                                        borderRadius: 2,
                                        background: `linear-gradient(90deg, ${colors.secondary}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 15,
                                            fontWeight: "bold",
                                            color: colors.warning,
                                        }}
                                    >
                                        {numFormater(resource.total)}
                                        <sup
                                            style={{
                                                fontSize: 10,
                                                color: colors.success,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            TZS
                                        </sup>
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            opacity: 0.9,
                                        }}
                                        noWrap
                                    >
                                        {resource.name}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default RevenueCard;
