import React, { useState } from 'react';
import {
    Badge,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { colors } from '../../../assets/utils/constants';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from '../../../components/ContentLoader';
import ConfigurationLayout from '../../layouts/ConfigurationLayout';
import CustomDialog from '../../../components/Dialogies/CustomDialog';
import { isEmpty } from 'lodash';
import { paymentMethodsSelector } from '../../../states/features/selectors';
import CustomSettingsCard from '../../../components/cards/CustomSettingsCard';
import { Add } from '@mui/icons-material';
import NoContent from '../../../components/NoContent';
import ActionButtons from './ActionButtons';
import useRTK from '../../../hooks/useRTK';
import { useGetPaymentMethodsQuery } from '../../../api/settings/paymentMethodsAPI';
import { setPaymentMethods } from '../../../states/features/settings/paymentMethodsSlice';
import AddPaymentMethod from './AddPaymentMethod';
import UpdatePaymentMethod from './UpdatePaymentMethod';

const PaymentMethods = () => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp state #############
    const [open, setOpen] = React.useState(false);
    const [editedValues, setEditedValues] = useState({});
    const [isEdit, setIsedit] = useState(false);

    // ############# Redux state #############
    const { paymentMethods } = useSelector(paymentMethodsSelector);

    // ############# RTK ##################
    const {
        isLoading,
        isSuccess,
        refetch: refetchPaymentMethods,
        data: responseData,
    } = useGetPaymentMethodsQuery();

    // ############## FUNC #####################
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleAdd = (value) => {
        setIsedit(false);
    };
    const handleEdit = (value) => {
        setIsedit(true);
        let payload = {
            id: value.id,
            name: value.name,
            description: value.description,
        };
        setEditedValues(payload);
        handleClickOpen();
    };

    // ############# RTK response ##################
    const rtkActions = (data) => {
        dispatch(setPaymentMethods(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkActions);

    return (
        <>
            {/* =============== Dialogy ================== */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`${isEdit ? 'Edit' : 'Add'} payment method`}
            >
                {isEdit ? (
                    <UpdatePaymentMethod
                        editedValues={editedValues}
                        handleClose={handleClose}
                        refetch={refetchPaymentMethods}
                    />
                ) : (
                    <AddPaymentMethod
                        handleClose={handleClose}
                        refetch={refetchPaymentMethods}
                    />
                )}
            </CustomDialog>

            <ConfigurationLayout head={`Manage payment methods`}>
                <CustomSettingsCard
                    head={'Payment method'}
                    action={
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<Add />}
                            onClick={() => {
                                handleAdd();
                                handleClickOpen();
                            }}
                        >
                            Add
                        </Button>
                    }
                >
                    {isLoading ? (
                        <>
                            <ContentLoader />
                        </>
                    ) : (
                        <>
                            <TableContainer
                                component={Paper}
                                sx={{ boxShadow: 'none' }}
                            >
                                {isEmpty(paymentMethods) ? (
                                    <NoContent
                                        message={'No payment method added'}
                                    />
                                ) : (
                                    <>
                                        <Table>
                                            <TableHead
                                                sx={{
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                <TableRow
                                                    sx={{
                                                        '&:last-child td, &:last-child th': {
                                                            border: 0,
                                                            fontWeight: 'bold',
                                                            padding: 1.5,
                                                        },
                                                    }}
                                                >
                                                    <TableCell
                                                        sx={{
                                                            width: 0,
                                                        }}
                                                    >
                                                        S/N
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Method
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Provider
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Description
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Status
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {paymentMethods.map(
                                                    (row, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{
                                                                '&:last-child td, &:last-child th': {
                                                                    border: 0,
                                                                },
                                                                'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                                            }}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                            >
                                                                {++index}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.method ===
                                                                'mobile'
                                                                    ? 'Card payment'
                                                                    : 'Mobile payment'}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.provider}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {
                                                                    row.description
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Badge
                                                                    className="badge"
                                                                    badgeContent=""
                                                                    color={
                                                                        parseInt(
                                                                            row.status,
                                                                        ) === 1
                                                                            ? 'success'
                                                                            : 'error'
                                                                    }
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <ActionButtons
                                                                    row={row}
                                                                    refetch={
                                                                        refetchPaymentMethods
                                                                    }
                                                                    handleEdit={
                                                                        handleEdit
                                                                    }
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ),
                                                )}
                                            </TableBody>
                                        </Table>
                                    </>
                                )}
                            </TableContainer>
                        </>
                    )}
                </CustomSettingsCard>
            </ConfigurationLayout>
        </>
    );
};
export default PaymentMethods;
