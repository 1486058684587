import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    contacts: {},
    socialNetworks: [],
};

const helpCenterSlice = createSlice({
    name: 'helpCenterSlice',
    initialState,
    reducers: {
        setContacts: (state, action) => {
            state.contacts = action.payload;
        },
        setSocialNetworks: (state, action) => {
            state.socialNetworks = action.payload;
        },
    },
});

export const { setContacts, setSocialNetworks } = helpCenterSlice.actions;
export default helpCenterSlice.reducer;
