import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
    relationTypes: [],
};

// !########## SLICE #############
const relationTypesSlice = createSlice({
    name: "relationTypesSlice",
    initialState,
    reducers: {
        setRelationTypes: (state, actions) => {
            state.relationTypes = actions.payload;
        },
        addRelationType: (state, actions) => {
            const relation_type = actions.payload;
            isEmpty(state.relationTypes)
                ? (state.relationTypes = [relation_type, ...[]])
                : (state.relationTypes = [
                      relation_type,
                      ...state.relationTypes,
                  ]);
        },
    },
});

export const { setRelationTypes, addRelationType } = relationTypesSlice.actions;
export default relationTypesSlice.reducer;
