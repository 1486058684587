import React from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../../assets/utils/constants';
import strHelper from '../../../helpers/strHelper';

const PromoDescriptions = ({ promotion }) => {
    return (
        <div>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    p: 1,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 14,
                        fontWeight: 'bold',
                        p: 1,
                        color: colors.warning,
                    }}
                >
                    Promotion description
                </Typography>
                <Typography
                    sx={{
                        fontSize: 15,
                        opacity: 0.9,
                        ml: 1,
                    }}
                >
                    {strHelper().strCapitalizeFirstChar(promotion.content)}
                </Typography>
            </Box>
        </div>
    );
};

export default PromoDescriptions;
