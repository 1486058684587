import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { removeArrObj } from '../../../helpers/arrayHelpers';

const initialState = {
    customers: [],
    customerDetails: {},
};

// !########## SLICE #############
const promotionCustomersSlice = createSlice({
    name: 'promotionCustomersSlice',
    initialState,
    reducers: {
        setCustomers: (state, actions) => {
            state.customers = actions.payload;
        },
        setCustomerDetails: (state, actions) => {
            state.customerDetails = actions.payload;
        },
        addCustomer: (state, actions) => {
            let customer = actions.payload;
            isEmpty(state.customers)
                ? (state.customers = [customer, ...[]])
                : (state.customers = [customer, ...state.customers]);
        },
        updateCustomer: (state, actions) => {
            let newCustomer = actions.payload;
            state.customerDetails = newCustomer;
            // let customers = updateArrObj('id', newCustomer, state.customers);
            // state.customers = customers;
        },
        removeCustomer: (state, actions) => {
            let payload = actions.payload;
            let customers = removeArrObj('id', payload, state.customers);
            state.customers = customers;
        },
    },
});

export const {
    setCustomers,
    setCustomerDetails,
    addCustomer,
    updateCustomer,
    removeCustomer,
} = promotionCustomersSlice.actions;
export default promotionCustomersSlice.reducer;
