import React from 'react';
import { size } from 'lodash';
import { useSelector } from 'react-redux';
import InfoBox from './cards/InfoBox';
import {
    beneficiarySelector,
    employersSelector,
    jobsSelector,
    organizationsSelector,
    practicalTrainingsSelector,
    shopsSelector,
} from '../states/features/selectors';

const InfoBoxes = () => {
    // ############### REDUCER STATE #################
    const { organizations } = useSelector(organizationsSelector);
    const { organizationJobs, employerJobs } = useSelector(jobsSelector);
    const { beneficiaries } = useSelector(beneficiarySelector);
    const { employers } = useSelector(employersSelector);
    const { practicalTrainings } = useSelector(practicalTrainingsSelector);
    const { shops } = useSelector(shopsSelector);

    const infoBoxContent = [
        {
            id: 1,
            name: 'Organizations',
            total: organizations ? size(organizations) : 0,
            url: '/organizations',
        },
        {
            id: 3,
            name: 'Employers',
            total: size(employers),
            url: '/employers',
        },
        {
            id: 4,
            name: 'Beneficiaries',
            total: size(beneficiaries),
            url: '/beneficiaries',
        },
        {
            id: 5,
            name: 'Jobs',
            total: size(organizationJobs) + size(employerJobs),
            url: '/jobs',
        },
        {
            id: 6,
            name: 'P-Training',
            total: size(practicalTrainings),
            url: '/practical-trainings',
        },
        {
            id: 7,
            name: 'Shops',
            total: size(shops),
            url: '/shops',
        },
    ];
    return (
        <>
            {infoBoxContent?.map((box) => (
                <InfoBox
                    key={box.id}
                    name={box.name}
                    total={box.total}
                    url={box.url}
                />
            ))}
        </>
    );
};

export default InfoBoxes;
