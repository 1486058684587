import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    Grid,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import { colors } from '../../../assets/utils/constants';
import { LocationOn, Phone } from '@mui/icons-material';
import FieldWidgetOne from '../../../components/widgets/FieldWidgetOne';
import { useSelector } from 'react-redux';
import { practicalTrainingsSelector } from '../../../states/features/selectors';
import { filter, isEmpty } from 'lodash';
import CustomCard2 from '../../../components/cards/CustomCard2';
import OccupationCard from '../../../components/cards/OccupationCard';

// ##################### MUI STYLED ########################
const InfoBox = styled(Box)({
    'background': colors.bgColor,
    'borderRadius': 10,
    'boxShadow': '3px 3px 3px 3px rgba(46, 119, 174,0.2)',
    '.title': {
        fontSize: 13,
        fontWeight: 'bold',
        color: colors.primary,
        textAlign: 'center',
        padding: 8,
        opacity: 0.8,
    },
    '.detail': {
        fontSize: 13,
        textAlign: 'center',
        padding: 8,
    },
});

const TrainingApplicantDetails = ({ applicant }) => {
    // ############# Redux state #############
    const { practicalTrainings } = useSelector(practicalTrainingsSelector);

    // ############## FUNC ###############
    const getAppliedTraining = () => {
        let appiedTraining = filter(
            practicalTrainings,
            ({ id }) => id === applicant.appliedTraining.training_id,
        );
        return appiedTraining[0];
    };
    const appliedTraining = getAppliedTraining();

    return (
        <>
            <Grid container padding={2} spacing={2} justifyContent={'flex-end'}>
                <Grid item sm={12}>
                    <Box
                        className="neo-up"
                        sx={{
                            display: 'flex',
                            position: 'relative',
                            background: colors.bgColor,
                            p: 2,
                            borderRadius: 5,
                            borderBottom: `4px solid ${colors.secondary}`,
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <Avatar
                                src={applicant.profile_picture}
                                sx={{
                                    height: 85,
                                    width: 85,
                                    borderRadius: 50,
                                    border: `3px solid ${colors.bgColor5}`,
                                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                                }}
                                variant="square"
                            />
                        </Box>
                        <Box
                            sx={{
                                height: 100,
                                width: '100%',
                                display: 'flex',
                                alignItems: 'start',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                pl: 3,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                    color: colors.info,
                                }}
                            >
                                {`${applicant.first_name} ${applicant?.middle_name} ${applicant.last_name}`}
                            </Typography>
                            <Typography sx={{ opacity: 0.8 }}>
                                {applicant.institute.organization_name}
                            </Typography>
                            <Typography
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    opacity: 0.8,
                                }}
                            >
                                <LocationOn
                                    color="warning"
                                    sx={{ fontSize: 14 }}
                                />{' '}
                                {applicant.address}
                            </Typography>
                            <Box
                                className="neo-up"
                                sx={{
                                    bgcolor: colors.bgColor,
                                    position: 'absolute',
                                    right: 20,
                                    textAlign: 'center',
                                    borderRadius: 4,
                                    p: 2,
                                }}
                            >
                                <InfoBox>
                                    <Typography className="title">
                                        Applicant Contacts
                                    </Typography>
                                    <Divider />
                                    <Box
                                        sx={{
                                            p: 0.5,
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Tooltip title={'Call applicant'} arrow>
                                            <a
                                                href={`tel: ${applicant.phone_number}`}
                                            >
                                                <Button
                                                    color="info"
                                                    variant="outlined"
                                                    startIcon={
                                                        <Phone
                                                            sx={{
                                                                fontSize: 16,
                                                            }}
                                                        />
                                                    }
                                                >
                                                    {applicant.phone_number}
                                                </Button>
                                            </a>
                                        </Tooltip>
                                    </Box>
                                </InfoBox>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {/* ############# APPLIED TRAINING ############# */}
                <Grid item sm={7}>
                    <CustomCard2
                        head={'Applied practical training'}
                        height={'43vh'}
                        action={
                            <Chip
                                size="small"
                                sx={{
                                    textTransform: 'capitalize',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    px: 1,
                                    opacity: 0.8,
                                }}
                                color={
                                    applicant?.appliedTraining.status ===
                                    'pending'
                                        ? 'warning'
                                        : applicant?.appliedTraining.status ===
                                              'approved' ||
                                          applicant?.appliedTraining
                                              .onTrainingStatus === 'completed'
                                        ? 'success'
                                        : applicant?.appliedTraining.status ===
                                          'declined'
                                        ? 'error'
                                        : 'secondary'
                                }
                                label={
                                    applicant?.appliedTraining
                                        .onTrainingStatus === 'on-training'
                                        ? applicant?.appliedTraining
                                              .onTrainingStatus
                                        : applicant?.appliedTraining
                                              .onTrainingStatus === 'completed'
                                        ? applicant?.appliedTraining
                                              .onTrainingStatus
                                        : applicant?.appliedTraining?.status
                                }
                            />
                        }
                    >
                        <FieldWidgetOne training={appliedTraining} />
                    </CustomCard2>
                </Grid>

                {/* ############# INSTITUTE ############# */}
                <Grid item sm={5}>
                    <CustomCard2
                        head={'Program | Occupation | Section'}
                        height={'43vh'}
                    >
                        {!isEmpty(applicant) && (
                            <OccupationCard student={applicant} />
                        )}
                    </CustomCard2>
                </Grid>
            </Grid>
        </>
    );
};

export default TrainingApplicantDetails;
