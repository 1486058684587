import React from 'react';
import IncomeWidget from '../widgets/IncomeWidget';
import CustomCard2 from '../cards/CustomCard2';
import { Box, Button, Typography } from '@mui/material';
import { numFormater } from '../../helpers/numFormater';
import { colors } from '../../assets/utils/constants';
import { filter, size } from 'lodash';
import { useSelector } from 'react-redux';
import {
    shopsSelector,
    subscriptionPackagesSelector,
} from '../../states/features/selectors';
import { Link } from 'react-router-dom';

const ShopSidebar = () => {
    // ############# Redux state #############
    const { shops } = useSelector(shopsSelector);
    const { subscriptionPackages } = useSelector(subscriptionPackagesSelector);

    // => Shops by status
    const getShopsByStatus = () => {
        // => Verified
        let verifiedShops = filter(shops, ({ isVerified }) => isVerified === 1);
        // => On-subscription
        let onSubscriptionShops = filter(
            shops,
            ({ isSubscribed }) => isSubscribed === 1,
        );
        // => Requested
        let requestedShops = filter(
            shops,
            ({ status }) => status === 'pending',
        );
        // => Suspended
        let suspendedShops = filter(
            shops,
            ({ status }) => status === 'suspended',
        );
        // => Denied
        let deniedShops = filter(shops, ({ status }) => status === 'denied');
        return {
            verifiedShops,
            onSubscriptionShops,
            requestedShops,
            suspendedShops,
            deniedShops,
        };
    };
    const shopsByStatus = getShopsByStatus();

    return (
        <>
            <CustomCard2 head={'Shops stats'} height={'100%'}>
                <Box>
                    <IncomeWidget
                        total={numFormater(50000)}
                        title={'Market Mingle revenue'}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        px: 1.5,
                        borderRadius: 2,
                        mt: 3,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    }}
                >
                    <Box
                        sx={{
                            height: 140,
                            width: 140,
                            borderRadius: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            border: `5px solid ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 35,
                                fontWeight: 'bold',
                                opacity: 0.8,
                                color: colors.info,
                            }}
                        >
                            {size(shops)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                opacity: 0.8,
                            }}
                        >
                            Shops
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography
                            sx={{
                                color: colors.info,
                                fontSize: 20,
                                borderBottom: `3px solid ${colors.bgColor5}`,
                            }}
                        >
                            {size(shopsByStatus.requestedShops)}{' '}
                            <sup
                                style={{
                                    color: colors.info,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}
                            >
                                Requested
                            </sup>
                        </Typography>
                        <Typography
                            sx={{
                                color: colors.info,
                                fontSize: 20,
                                borderBottom: `3px solid ${colors.bgColor5}`,
                            }}
                        >
                            {size(shopsByStatus.verifiedShops)}{' '}
                            <sup
                                style={{
                                    color: colors.success,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}
                            >
                                Verified
                            </sup>
                        </Typography>
                        <Typography
                            sx={{
                                color: colors.info,
                                fontSize: 20,
                                borderBottom: `3px solid ${colors.bgColor5}`,
                            }}
                        >
                            {size(shopsByStatus.deniedShops)}{' '}
                            <sup
                                style={{
                                    color: 'red',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}
                            >
                                Denied
                            </sup>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography
                            sx={{
                                color: colors.info,
                                fontSize: 20,
                                borderBottom: `3px solid ${colors.bgColor5}`,
                            }}
                        >
                            {size(shopsByStatus.onSubscriptionShops)}{' '}
                            <sup
                                style={{
                                    color: colors.info,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}
                            >
                                On-subscription
                            </sup>
                        </Typography>
                        <Typography
                            sx={{
                                color: colors.info,
                                fontSize: 20,
                                borderBottom: `3px solid ${colors.bgColor5}`,
                            }}
                        >
                            {size(shopsByStatus.suspendedShops)}{' '}
                            <sup
                                style={{
                                    color: colors.warning,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}
                            >
                                Suspended
                            </sup>
                        </Typography>
                    </Box>
                </Box>
            </CustomCard2>
            <Box
                sx={{
                    bgcolor: 'red',
                    display: 'flex',
                    mt: 2,
                    borderRadius: 3,
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        bgcolor: colors.primary,
                        width: '60%',
                        height: 120,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: ' center',
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            sx={{
                                fontSize: 35,
                                fontWeight: 'bold',
                                fontStyle: 'italic',
                                color: colors.secondary,
                            }}
                        >
                            {size(
                                filter(
                                    subscriptionPackages,
                                    ({ type }) => type === 'Market mingle',
                                ),
                            )}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: colors.secondary,
                                fontWeight: 'bold',
                            }}
                        >
                            packages
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        bgcolor: colors.secondary,
                        width: '100%',
                        height: 120,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: ' center',
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                            Market Mingle
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                            Subscription Packages
                        </Typography>
                        <Link to={'/subscription-packages'}>
                            <Button
                                size="small"
                                variant="contained"
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                Manage
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ShopSidebar;
