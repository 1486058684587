import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { employerEndpoints } from './employerEndpoints';

export const employersAPI = createApi({
    reducerPath: 'employersAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getAllEmployers: builder.query({
            query: () => `${employerEndpoints.GET_ALL_EMPLOYERS}`,
        }),

        // => GET single
        getSingleEmployer: builder.query({
            query: (payload) =>
                `${employerEndpoints.GET_SINGLE_EMPLOYER}/${payload}`,
        }),
    }),
});

export const {
    useGetSingleEmployerQuery,

    // => Lazy
    useLazyGetAllEmployersQuery,
} = employersAPI;
