import React from "react";
import { Form, Formik } from "formik";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { ManageAccounts } from "@mui/icons-material";
import * as Yup from "yup";
import CustomTextArea from "../../../components/Forms/CustomTextArea";
import { useDispatch } from "react-redux";
import { DialogActions, MenuItem } from "@mui/material";
import useRTK from "../../../hooks/useRTK";
import { setRelationTypes } from "../../../states/features/settings/relationTypesSlice";
import { useUpdateRelationTypeMutation } from "../../../api/settings/relationTypeAPI";
import CustomSelectInput from "../../../components/Forms/CustomSelectInput";

// -> validate settings form
const validate = Yup.object({
    name: Yup.string().required(),
    description: Yup.string().nullable(),
});

const UpdateRelationType = ({ handleClose, refetch, editedValues }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();
    const relations = [
        {
            name: "Father",
        },
        {
            name: "Mother",
        },
        {
            name: "Brother",
        },
        {
            name: "Sister",
        },
        {
            name: "Uncle",
        },
        {
            name: "Antie",
        },
        {
            name: "Cousin",
        },
    ];

    // ############# RTK ##################
    const [
        updateRelationType,
        { isLoading, isSuccess, isError, error, data: updateData },
    ] = useUpdateRelationTypeMutation();

    // ############# RTK response ##################
    const rtkActions = (data) => {
        refetch();
        handleClose(data);
        dispatch(setRelationTypes(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updateData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    id: editedValues.id,
                    name: editedValues.name,
                    description: editedValues.description,
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    updateRelationType(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <CustomSelectInput
                            type="text"
                            name="name"
                            label="Select relation"
                            formik={formik}
                            color="primary"
                        >
                            {relations.map((relation) => (
                                <MenuItem
                                    key={relation.name}
                                    value={relation.name}
                                >
                                    {relation.name}
                                </MenuItem>
                            ))}
                        </CustomSelectInput>
                        <CustomTextArea
                            label="Description"
                            name="description"
                        />
                        <DialogActions>
                            <CustomSubmitButton
                                startIcon={<ManageAccounts />}
                                onClick={formik.handleSubmit}
                                loading={isLoading}
                            >
                                Update setting
                            </CustomSubmitButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateRelationType;
