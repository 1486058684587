import React from "react";
import { Form, Formik } from "formik";
import CustomTextField from "../../../components/Forms/CustomTextField";
import CustomSelectInput from "../../../components/Forms/CustomSelectInput";
import { Grid, MenuItem } from "@mui/material";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { ManageAccounts } from "@mui/icons-material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useUpdateAdminMutation } from "../../../api/admins/adminsAPI";
import { updateAdminDetails } from "../../../states/features/admins/adminsSlice";
import useRTK from "../../../hooks/useRTK";

// ============= update profile validation =====================
const updateAdminValidation = Yup.object({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
    role: Yup.string().required("Role is required field"),
    gender: Yup.string().required("This field is required"),
    email: Yup.string().email().required("This field is required"),
    phone_number: Yup.string()
        .required("This field is required")
        .max(10, "Phone number should contain 10 integers")
        .min(10, "Phone number should contain 10 integers"),
});

const UpdateAdmin = ({ user, handleClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        updateAdmin,
        {
            isLoading,
            isSuccess: updateSuccess,
            isError,
            error,
            data: updateData,
        },
    ] = useUpdateAdminMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleClose();
        dispatch(updateAdminDetails(data));
    };
    RTK.useRTKResponse(updateSuccess, isError, error, updateData, rtkActions);

    return (
        <>
            <Formik
                initialValues={{
                    admin_id: user.id,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    phone_number: user.phone_number,
                    email: user.email,
                    gender: user.gender,
                    role: user.role,
                }}
                validationSchema={updateAdminValidation}
                onSubmit={(payload) => {
                    updateAdmin(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container columnSpacing={2}>
                            <Grid item sm={6}>
                                <CustomTextField
                                    className="form-input"
                                    label="First name"
                                    type="text"
                                    name="first_name"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    className="form-input"
                                    label="Last name"
                                    type="text"
                                    name="last_name"
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2}>
                            <Grid item sm={6}>
                                <CustomTextField
                                    className="form-input"
                                    label="Phone number"
                                    type="text"
                                    name="phone_number"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    className="form-input"
                                    label="Email"
                                    type="emai"
                                    name="email"
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2}>
                            <Grid item sm={6}>
                                <CustomSelectInput
                                    label="Gender"
                                    type="text"
                                    name="gender"
                                    formik={formik}
                                    color={"primary"}
                                >
                                    <MenuItem value={"male"}>Male</MenuItem>
                                    <MenuItem value={"female"}>Female</MenuItem>
                                </CustomSelectInput>
                            </Grid>
                            <Grid item sm={6}>
                                <CustomSelectInput
                                    label="Role"
                                    type="text"
                                    name="role"
                                    formik={formik}
                                    color={"primary"}
                                >
                                    <MenuItem value={"Super admin"}>
                                        Super admin
                                    </MenuItem>
                                    <MenuItem value={"Sub admin"}>
                                        Sub admin
                                    </MenuItem>
                                </CustomSelectInput>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    loading={isLoading}
                                    startIcon={<ManageAccounts />}
                                    onClick={formik.handleSubmit}
                                    btnColor={"info"}
                                    sx={{ py: 2 }}
                                >
                                    Update profile
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateAdmin;
