import React, { useState } from "react";
import {
    Badge,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { colors } from "../../../assets/utils/constants";
import Paper from "@mui/material/Paper";

import { useDispatch, useSelector } from "react-redux";
import ContentLoader from "../../../components/ContentLoader";
import ConfigurationLayout from "../../layouts/ConfigurationLayout";
import CustomDialog from "../../../components/Dialogies/CustomDialog";
import { isEmpty } from "lodash";
import { relationTypesSelector } from "../../../states/features/selectors";
import CustomSettingsCard from "../../../components/cards/CustomSettingsCard";
import { Add, Remove } from "@mui/icons-material";
import NoContent from "../../../components/NoContent";
import ActionButtons from "./ActionButtons";
import useRTK from "../../../hooks/useRTK";
import { useGetRelationTypesQuery } from "../../../api/settings/relationTypeAPI";
import { setRelationTypes } from "../../../states/features/settings/relationTypesSlice";
import AddRelationType from "./AddRelationType";
import UpdateRelationType from "./UpdateRelationType";

const RelationTypes = () => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp state #############
    const [open, setOpen] = React.useState(false);
    const [editedValues, setEditedValues] = useState({});
    const [isEdit, setIsedit] = useState(false);

    // ############# Redux state #############
    const { relationTypes } = useSelector(relationTypesSelector);

    // ############# RTK ##################
    const {
        isLoading,
        isSuccess,
        refetch: refetchRelationTypes,
        data: responseData,
    } = useGetRelationTypesQuery();

    // ############## FUNC #####################
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleAdd = (value) => {
        setIsedit(false);
    };
    const handleEdit = (value) => {
        setIsedit(true);
        let payload = {
            id: value.id,
            name: value.name,
            description: value.description,
        };
        setEditedValues(payload);
        handleClickOpen();
    };

    // ############# RTK response ##################
    const rtkActions = (data) => {
        dispatch(setRelationTypes(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkActions);

    return (
        <>
            {/* =============== Dialogy ================== */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`${isEdit ? "Edit" : "Add"} relation types`}
            >
                {isEdit ? (
                    <UpdateRelationType
                        editedValues={editedValues}
                        handleClose={handleClose}
                        refetch={refetchRelationTypes}
                    />
                ) : (
                    <AddRelationType
                        handleClose={handleClose}
                        refetch={refetchRelationTypes}
                    />
                )}
            </CustomDialog>

            <ConfigurationLayout head={`Manage relation types`}>
                <CustomSettingsCard
                    head={"Relation types"}
                    action={
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<Add />}
                            onClick={() => {
                                handleAdd();
                                handleClickOpen();
                            }}
                        >
                            Add
                        </Button>
                    }
                >
                    {isLoading ? (
                        <>
                            <ContentLoader />
                        </>
                    ) : (
                        <>
                            <TableContainer
                                component={Paper}
                                sx={{ boxShadow: "none" }}
                            >
                                {isEmpty(relationTypes) ? (
                                    <NoContent
                                        message={"No relation types added"}
                                    />
                                ) : (
                                    <>
                                        <Table>
                                            <TableHead
                                                sx={{
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                <TableRow
                                                    sx={{
                                                        "&:last-child td, &:last-child th":
                                                            {
                                                                border: 0,
                                                                fontWeight:
                                                                    "bold",
                                                                padding: 1.5,
                                                            },
                                                    }}
                                                >
                                                    <TableCell
                                                        sx={{
                                                            width: 0,
                                                        }}
                                                    >
                                                        S/N
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Name
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Description
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Status
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {relationTypes.map(
                                                    (row, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{
                                                                "&:last-child td, &:last-child th":
                                                                    {
                                                                        border: 0,
                                                                    },
                                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                                            }}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                            >
                                                                {++index}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {!isEmpty(
                                                                    row.description
                                                                ) ? (
                                                                    <>
                                                                        {
                                                                            row.description
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Remove
                                                                            sx={{
                                                                                opacity: 0.6,
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Badge
                                                                    className="badge"
                                                                    badgeContent=""
                                                                    color={
                                                                        parseInt(
                                                                            row.status
                                                                        ) === 1
                                                                            ? "success"
                                                                            : "error"
                                                                    }
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <ActionButtons
                                                                    row={row}
                                                                    refetch={
                                                                        refetchRelationTypes
                                                                    }
                                                                    handleEdit={
                                                                        handleEdit
                                                                    }
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </>
                                )}
                            </TableContainer>
                        </>
                    )}
                </CustomSettingsCard>
            </ConfigurationLayout>
        </>
    );
};
export default RelationTypes;
