import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { Image, Save } from '@mui/icons-material';
import CustomFileInput from '../../../components/Forms/CustomFileInput';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { colors } from '../../../assets/utils/constants';
import { useUpdateBlogMutation } from '../../../api/blogs/blogsAPI';
import { updateBlog } from '../../../states/features/blogs/blogsSlice';
import CustomTextArea from '../../../components/Forms/CustomTextArea';

const updateBlogValidationSchema = Yup.object({
    file: Yup.string(),
    title: Yup.string().required(),
    content: Yup.string().required(),
});

const UpdateBlogPost = ({ handleClose, blog }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp state ################
    const [filePreview, setFilePreview] = useState(blog.blog_image);

    // ############# RTK ##################
    const [
        updateBlogDetails,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useUpdateBlogMutation(blog.id);

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleClose();
        dispatch(updateBlog(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Box>
                <Formik
                    initialValues={{
                        file: blog.file,
                        title: blog.title,
                        content: blog.content,
                    }}
                    validationSchema={updateBlogValidationSchema}
                    onSubmit={(values) => {
                        let payload = new FormData();
                        payload.append('blog_id', blog.id);
                        payload.append('blog_image', values.file);
                        payload.append('title', values.title);
                        payload.append('content', values.content);
                        updateBlogDetails(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid container justifyContent={'center'}>
                                <Grid item sm={12}>
                                    <Grid
                                        container
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Grid item sm={7} sx={{}}>
                                            {filePreview ? (
                                                <>
                                                    <img
                                                        src={filePreview}
                                                        alt="img"
                                                        style={{
                                                            width: '100%',
                                                            height: '10em',
                                                            objectFit:
                                                                'contain',
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Image
                                                        sx={{
                                                            width: '100%',
                                                            height: '7em',
                                                            objectFit:
                                                                'contain',
                                                            opacity: 0.7,
                                                            color:
                                                                colors.secondary,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            sm={5}
                                            sx={{
                                                height: '100%',
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                            }}
                                        >
                                            <CustomFileInput
                                                name="file"
                                                type="file"
                                                formik={formik}
                                                accept="image/*"
                                                setFilePreview={setFilePreview}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <CustomTextField
                                        name="title"
                                        type={'text'}
                                        label={'Blog title'}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <CustomTextArea
                                        name="content"
                                        type={'text'}
                                        label={'Blog content'}
                                        rows={6}
                                        maxChar={1000}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        startIcon={<Save />}
                                        onClick={formik.handleSubmit}
                                        btnColor={'primary'}
                                    >
                                        Update blog
                                    </CustomSubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default UpdateBlogPost;
