import React from 'react';
import CustomCard2 from '../../components/cards/CustomCard2';

const LogsViwer = () => {
    return (
        <>
            <CustomCard2 bodyPadding={0}>
                <iframe
                    // src="http://127.0.0.1:8000/log-viewer"
                    src="https://secure.admin.wazawamax.co.tz/log-viewer"
                    loading="lazy"
                    style={{ height: '90.5vh', width: '100%' }}
                ></iframe>
            </CustomCard2>
        </>
    );
};

export default LogsViwer;
