import React from "react";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { colors } from "../../assets/utils/constants";
import { Person, RemoveRedEye } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { numFormater } from "../../helpers/numFormater";
import strHelper from "../../helpers/strHelper";
import moment from "moment";
import ActivateDeactivatePromotion from "../../pages/promotions/CRUD/ActivateDeactivatePromotion";
import { isEmpty } from "lodash";
import ActivateDeactivatePromotion2 from "../../pages/promotions/CRUD/ActivateDeactivatePromotion2";

const PromotionWidget = ({ promotion }) => {
    return (
        <>
            <Box
                sx={{
                    borderRadius: 4,
                    overflow: "hidden",
                    boxShadow: `1px 1px 10px 0px ${colors.bgColor5}`,
                    border: `5px solid ${colors.bgColor4}`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    transition: `.2s all ease-in-out`,
                    ":hover": {
                        mt: -0.4,
                        border: `5px solid ${colors.secondary}`,
                        transition: `.2s all ease-in-out`,
                    },
                }}
            >
                <Box sx={{ position: "relative" }}>
                    <Avatar
                        src={promotion.banner_image}
                        variant="square"
                        sx={{
                            width: "100%",
                            height: 120,
                            opacity: 0.8,
                        }}
                    />
                    <Box
                        sx={{
                            width: 100,
                            borderBottom: `15px solid ${colors.bgColor1}`,
                            borderLeft: 0,
                            borderRight: `25px solid transparent`,
                            position: "absolute",
                            bottom: 0,
                        }}
                    ></Box>
                </Box>
                <Box
                    sx={{
                        p: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                color: colors.primary,
                                opacity: 0.8,
                            }}
                        >
                            {strHelper().strCapitalizeFirstChar(
                                promotion.title
                            )}
                        </Typography>
                    </Box>
                    <Box sx={{}}>
                        {isEmpty(promotion?.currSubscription) ? (
                            <>
                                <Typography
                                    sx={{
                                        textAlign: "center",
                                        py: 1,
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        color: colors.success,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                                    }}
                                >
                                    No subscription
                                </Typography>
                            </>
                        ) : (
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    py: 1,
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    color: colors.success,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                                }}
                            >
                                {numFormater(
                                    promotion?.currSubscription?.transaction
                                        ?.amount
                                )}
                                <sup style={{ fontSize: 12 }}>TZS</sup> /{" "}
                                {`${promotion?.currSubscription?.package?.duration} days`}
                            </Typography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            mb: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Link
                            to={`/promotions/customers/${promotion.customer_id}`}
                        >
                            <Typography
                                noWrap
                                sx={{
                                    fontSize: 14,
                                    color: colors.info,
                                    display: "flex",
                                    alignItems: "center",
                                    width: 130,
                                }}
                            >
                                <Person sx={{ fontSize: 15 }} />
                                By: {promotion.customer.name}
                            </Typography>
                        </Link>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ActivateDeactivatePromotion2
                                noBorder
                                promotion={promotion}
                            />
                            <Link to={`/promotions/${promotion.id}`}>
                                <IconButton size="small" color="info">
                                    <RemoveRedEye />
                                </IconButton>
                            </Link>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            mb: 0.5,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography sx={{ fontSize: 13 }}>
                            <strong
                                style={{
                                    color: colors.success,
                                }}
                            >
                                Start :
                            </strong>{" "}
                            {promotion.currSubscription &&
                            !isEmpty(promotion.currSubscription.start_date)
                                ? moment(
                                      promotion.currSubscription.start_date
                                  ).format("MMM Do YYYY")
                                : "Not activated"}
                        </Typography>
                        <Typography sx={{ fontSize: 13 }}>
                            <strong style={{ color: "red" }}>End :</strong>{" "}
                            {promotion.currSubscription &&
                            !isEmpty(promotion.currSubscription.end_date)
                                ? moment(
                                      promotion.currSubscription.end_date
                                  ).format("MMM Do YYYY")
                                : "Not activated"}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PromotionWidget;
