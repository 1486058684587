import React from "react";
import { Badge, Box, Grid, Typography } from "@mui/material";
import { filter } from "lodash";
import { size } from "lodash";
import { colors } from "../../../assets/utils/constants";
import { Link } from "react-router-dom";

const reginstituteStats = (institutions) => {
    const stats = [
        {
            label: "On-progress institutions",
            total: size(filter(institutions, ["is_verified", "pending"])),
            color: colors.warning,
            url: "on-progress",
        },
        {
            label: "Verification Request",
            total: size(
                filter(
                    institutions,
                    ({ is_verified }) =>
                        is_verified === "on_progress" ||
                        is_verified === "requested"
                )
            ),
            color: colors.info,
            url: "request",
        },
        {
            label: "Verified institutions",
            total: size(filter(institutions, ["is_verified", "verified"])),
            color: colors.success,
            url: "verified",
        },
        {
            label: "Denied institutions",
            total: size(filter(institutions, ["is_verified", "denied"])),
            color: colors.danger,
            url: "denied",
        },
    ];

    return stats;
};

const InstitutionStatusesInfoboxes = ({ institutions }) => {
    const stats = reginstituteStats(institutions);

    return (
        <>
            <Box
                sx={{
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    padding: 2,
                    borderBottom: `15px solid ${colors.secondary}`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                }}
            >
                <Grid container spacing={2}>
                    {stats?.map((stat, index) => (
                        <Grid item sm={3} key={index}>
                            <Link to={stat.url}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        border: `1px solid ${colors.bgColor5}`,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        paddingLeft: 1,
                                        paddingRight: 3,
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                        borderRadius: 2,
                                        color: colors.primary,
                                        alignItems: "center",
                                        transition: `.3s all ease-in-out`,
                                        borderLeft: `5px solid ${stat.color}`,
                                        ":hover": {
                                            transition: `.3s all ease-in-out`,
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            color: colors.primary,
                                            opacity: 0.8,
                                            width: "90%",
                                        }}
                                        noWrap
                                    >
                                        {stat.label}
                                    </Typography>
                                    <Typography>
                                        <Badge
                                            badgeContent={stat.total}
                                            showZero
                                            color="primary"
                                            sx={{ opacity: 0.8 }}
                                        />
                                    </Typography>
                                </Box>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default InstitutionStatusesInfoboxes;
