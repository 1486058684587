import React, { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { colors } from '../../../assets/utils/constants';
import Paper from '@mui/material/Paper';

import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from '../../../components/ContentLoader';
import ConfigurationLayout from '../../layouts/ConfigurationLayout';
import CustomDialog from '../../../components/Dialogies/CustomDialog';
import { isEmpty } from 'lodash';
import { useGetUserStatusQuery } from '../../../api/settings/userStatusAPI';
import { getRTKResponseData } from '../../../helpers/RTKHelpers';
import { setUserStatus } from '../../../states/features/settings/userStatusSlice';
import { userStatusSelector } from '../../../states/features/selectors';
import AddUserStatus from './AddUserStatus';
import CustomSettingsCard from '../../../components/cards/CustomSettingsCard';
import { Add } from '@mui/icons-material';
import NoContent from '../../../components/NoContent';
import ActionButtons from './ActionButtons';
import UpdateUserStatus from './UpdateUserStatus';

const UserStatus = () => {
    // ############# CONST ##################
    const dispatch = useDispatch();

    // ############# Comp state #############
    const [open, setOpen] = React.useState(false);
    const [editedValues, setEditedValues] = useState({});
    const [isEdit, setIsedit] = useState(false);

    // ############# Redux state #############
    const { userStatus } = useSelector(userStatusSelector);

    // ############# RTK ##################
    const {
        isLoading,
        isSuccess,
        refetch: refetchUserStatus,
        data: userStatusData,
    } = useGetUserStatusQuery();

    // ############## FUNC #####################
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleAdd = (value) => {
        setIsedit(false);
    };
    const handleEdit = (value) => {
        setIsedit(true);
        let payload = {
            id: value.id,
            name: value.name,
            description: value.description,
        };
        setEditedValues(payload);
        handleClickOpen();
    };

    // ############# useEffect ##################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(setUserStatus(getRTKResponseData(userStatusData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, userStatusData, dispatch]);

    return (
        <>
            {/* =============== Dialogy ================== */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`${isEdit ? 'Edit' : 'Add'} user status`}
            >
                {isEdit ? (
                    <UpdateUserStatus
                        editedValues={editedValues}
                        handleClose={handleClose}
                        refetch={refetchUserStatus}
                    />
                ) : (
                    <AddUserStatus
                        handleClose={handleClose}
                        refetch={refetchUserStatus}
                    />
                )}
            </CustomDialog>

            <ConfigurationLayout head={`Manage user status`}>
                <CustomSettingsCard
                    head={'User status'}
                    action={
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<Add />}
                            onClick={() => {
                                handleAdd();
                                handleClickOpen();
                            }}
                        >
                            Add
                        </Button>
                    }
                >
                    {isLoading ? (
                        <>
                            <ContentLoader />
                        </>
                    ) : (
                        <>
                            <TableContainer
                                component={Paper}
                                sx={{ boxShadow: 'none' }}
                            >
                                {isEmpty(userStatus) ? (
                                    <NoContent
                                        message={'No user status added'}
                                    />
                                ) : (
                                    <>
                                        <Table>
                                            <TableHead
                                                sx={{
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                <TableRow
                                                    sx={{
                                                        '&:last-child td, &:last-child th': {
                                                            border: 0,
                                                            fontWeight: 'bold',
                                                            padding: 1.5,
                                                        },
                                                    }}
                                                >
                                                    <TableCell
                                                        sx={{
                                                            width: 0,
                                                        }}
                                                    >
                                                        S/N
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Name
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Description
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Status
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {userStatus.map(
                                                    (row, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{
                                                                '&:last-child td, &:last-child th': {
                                                                    border: 0,
                                                                },
                                                                'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                                            }}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                            >
                                                                {++index}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {
                                                                    row.description
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Badge
                                                                    className="badge"
                                                                    badgeContent=""
                                                                    color={
                                                                        parseInt(
                                                                            row.status,
                                                                        ) === 1
                                                                            ? 'success'
                                                                            : 'error'
                                                                    }
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <ActionButtons
                                                                    row={row}
                                                                    refetch={
                                                                        refetchUserStatus
                                                                    }
                                                                    handleEdit={
                                                                        handleEdit
                                                                    }
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ),
                                                )}
                                            </TableBody>
                                        </Table>
                                    </>
                                )}
                            </TableContainer>
                        </>
                    )}
                </CustomSettingsCard>
            </ConfigurationLayout>
        </>
    );
};
export default UserStatus;
