import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { groupStatusEndpoints } from './settingEndpoints';
import { settingAPI } from './settingAPI';

export const groupStatusAPI = createApi({
    reducerPath: 'groupStatusAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getGroupStatus: builder.query({
            query: () => `${groupStatusEndpoints.GET_GROUP_STATUSES}`,
        }),
        // => ADD
        addGroupStatus: builder.mutation({
            query: (payload) => ({
                url: `${groupStatusEndpoints.ADD_GROUP_STATUS}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
        // => UPDATE
        updateGroupStatus: builder.mutation({
            query: (payload) => ({
                url:
                    `${groupStatusEndpoints.UPDATE_GROUP_STATUS}/` +
                    payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateGroupStatus: builder.mutation({
            query: (payload) => ({
                url:
                    `${groupStatusEndpoints.ACTIVATE_GROUP_STATUS}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateGroupStatus: builder.mutation({
            query: (payload) => ({
                url:
                    `${groupStatusEndpoints.DEACTIVATE_GROUP_STATUS}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteGroupStatus: builder.mutation({
            query: (payload) => ({
                url:
                    `${groupStatusEndpoints.DELETE_GROUP_STATUS}/` +
                    payload,
                method: `DELETE`,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
    }),
});

export const {
    useGetGroupStatusQuery,
    useAddGroupStatusMutation,
    useUpdateGroupStatusMutation,
    useActivateGroupStatusMutation,
    useDeactivateGroupStatusMutation,
    useDeleteGroupStatusMutation,
} = groupStatusAPI;
