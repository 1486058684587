import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { adminBaseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { districtsEndpoints } from "./settingEndpoints";
import { settingAPI } from "./settingAPI";

export const districtsAPI = createApi({
    reducerPath: "districtsAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getDistricts: builder.query({
            query: () => `${districtsEndpoints.GET_DISTRICTS}`,
        }),
        // => ADD
        addDistrict: builder.mutation({
            query: (payload) => ({
                url: `${districtsEndpoints.ADD_DISTRICT}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(["Settings"]));
            },
        }),
        // => UPDATE
        updateDistrict: builder.mutation({
            query: (payload) => ({
                url: `${districtsEndpoints.UPDATE_DISTRICT}/` + payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateDistrict: builder.mutation({
            query: (payload) => ({
                url: `${districtsEndpoints.ACTIVATE_DISTRICT}/` + payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateDistrict: builder.mutation({
            query: (payload) => ({
                url: `${districtsEndpoints.DEACTIVATE_DISTRICT}/` + payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteDistrict: builder.mutation({
            query: (payload) => ({
                url: `${districtsEndpoints.DELETE_DISTRICT}/` + payload,
                method: `DELETE`,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(["Settings"]));
            },
        }),
    }),
});

export const {
    useGetDistrictsQuery,
    useAddDistrictMutation,
    useUpdateDistrictMutation,
    useActivateDistrictMutation,
    useDeactivateDistrictMutation,
    useDeleteDistrictMutation,
} = districtsAPI;
