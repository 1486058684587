import React, { useState } from "react";
import {
    Badge,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { colors } from "../../../assets/utils/constants";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import ContentLoader from "../../../components/ContentLoader";
import ConfigurationLayout from "../../layouts/ConfigurationLayout";
import CustomDialog from "../../../components/Dialogies/CustomDialog";
import { isEmpty } from "lodash";
import CustomSettingsCard from "../../../components/cards/CustomSettingsCard";
import { Add, Remove } from "@mui/icons-material";
import NoContent from "../../../components/NoContent";
import ActionButtons from "./ActionButtons";
import useRTK from "../../../hooks/useRTK";
import {
    districtsSelector,
    regionsSelector,
} from "../../../states/features/selectors";
import AddDistrict from "./AddDistrict";
import UpdateDistrict from "./UpdateDistrict";
import { useGetDistrictsQuery } from "../../../api/settings/districtsAPI";
import { useGetRegionsQuery } from "../../../api/settings/regionsAPI";
import { setDistricts } from "../../../states/features/settings/districtsSlice";
import { setRegions } from "../../../states/features/settings/regionsSlice";

const Districts = () => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp state #############
    const [open, setOpen] = React.useState(false);
    const [editedValues, setEditedValues] = useState({});
    const [isEdit, setIsedit] = useState(false);

    // ############# Redux state #############
    const { regions } = useSelector(regionsSelector);
    const { districts } = useSelector(districtsSelector);

    // ############# RTK ##################
    const {
        isLoading,
        isSuccess,
        refetch: refetchDistricts,
        data: responseData,
    } = useGetDistrictsQuery();
    const { isSuccess: regionSuccess, data: regionData } = useGetRegionsQuery();

    // ############## FUNC #####################
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleAdd = (value) => {
        setIsedit(false);
    };
    const handleEdit = (value) => {
        setIsedit(true);
        let payload = {
            id: value.id,
            name: value.name,
            description: value.description,
            region: value.region,
        };
        setEditedValues(payload);
        handleClickOpen();
    };

    // ############# RTK response ##################
    const rtkActions = (data) => {
        dispatch(setDistricts(data));
    };
    const rtkActions2 = (data) => {
        dispatch(setRegions(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkActions);
    RTK.useRTKFetch(regionSuccess, regionData, rtkActions2);

    return (
        <>
            {/* =============== Dialogy ================== */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`${isEdit ? "Edit" : "Add"} district`}
            >
                {isEdit ? (
                    <UpdateDistrict
                        regions={regions}
                        editedValues={editedValues}
                        handleClose={handleClose}
                        refetch={refetchDistricts}
                    />
                ) : (
                    <AddDistrict
                        regions={regions}
                        handleClose={handleClose}
                        refetch={refetchDistricts}
                    />
                )}
            </CustomDialog>

            <ConfigurationLayout head={`Manage districts`}>
                <CustomSettingsCard
                    head={"Manage districts"}
                    action={
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<Add />}
                            onClick={() => {
                                handleAdd();
                                handleClickOpen();
                            }}
                        >
                            Add
                        </Button>
                    }
                >
                    {isLoading ? (
                        <>
                            <ContentLoader />
                        </>
                    ) : (
                        <>
                            <TableContainer
                                component={Paper}
                                sx={{ boxShadow: "none" }}
                            >
                                {isEmpty(districts) ? (
                                    <NoContent message={"No district added"} />
                                ) : (
                                    <>
                                        <Table>
                                            <TableHead
                                                sx={{
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                <TableRow
                                                    sx={{
                                                        "&:last-child td, &:last-child th":
                                                            {
                                                                border: 0,
                                                                fontWeight:
                                                                    "bold",
                                                                padding: 1.5,
                                                            },
                                                    }}
                                                >
                                                    <TableCell
                                                        sx={{
                                                            width: 0,
                                                        }}
                                                    >
                                                        S/N
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Name
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Region
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Description
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Status
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {districts.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{
                                                            "&:last-child td, &:last-child th":
                                                                {
                                                                    border: 0,
                                                                },
                                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                                        }}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {++index}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row.region.name}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {!isEmpty(
                                                                row.description
                                                            ) ? (
                                                                <>
                                                                    {
                                                                        row.description
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Remove
                                                                        sx={{
                                                                            opacity: 0.6,
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Badge
                                                                className="badge"
                                                                badgeContent=""
                                                                color={
                                                                    parseInt(
                                                                        row.status
                                                                    ) === 1
                                                                        ? "success"
                                                                        : "error"
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <ActionButtons
                                                                row={row}
                                                                refetch={
                                                                    refetchDistricts
                                                                }
                                                                handleEdit={
                                                                    handleEdit
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </>
                                )}
                            </TableContainer>
                        </>
                    )}
                </CustomSettingsCard>
            </ConfigurationLayout>
        </>
    );
};
export default Districts;
