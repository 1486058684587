import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { isEmpty, size } from 'lodash';
import ConfigurationLayout from '../layouts/ConfigurationLayout';
import ConfigurationFilter from '../../components/filters/ConfigurationFilter';
import CustomCard2 from '../../components/cards/CustomCard2';
import {
    blogsSelector,
    faqsSelector,
    settingsSelector,
} from '../../states/features/selectors';
import { colors } from '../../assets/utils/constants';
import SettingWidget from '../../components/widgets/SettingWidget';
import ContentLoader from '../../components/ContentLoader';
import {
    ChatBubble,
    PaymentOutlined,
    People,
    QuestionAnswer,
} from '@mui/icons-material';
import SubConfigurationWidget from '../../components/widgets/SubConfigurationWidget';
import { useGetFaqsQuery } from '../../api/faqs/faqsAPI';
import useRTK from '../../hooks/useRTK';
import { setFaqs } from '../../states/features/faqs/faqsSlice';

// !#################### MAIN FUNC ##################
const Settings = () => {
    // ############## CONST ############
    const dispatch = useDispatch();
    const RTK = useRTK();

    // ############### Comp state ##############
    const [filteredSettings, setFilteredSettings] = React.useState([]);

    // ############## Redux state ############
    const { settings } = useSelector(settingsSelector);
    const { blogs } = useSelector(blogsSelector);
    const { faqs } = useSelector(faqsSelector);

    // ############## RTK ############
    const { isSuccess, data: blogsData } = useGetFaqsQuery();
    const rtkActions = (data) => {
        dispatch(setFaqs(data));
    };
    RTK.useRTKFetch(isSuccess, blogsData, rtkActions);

    // ############### FUNC ###############
    // => Settings filter =============
    const settingsFilter = (query) => {
        let currentSettings = Object.values(settings);
        if (query) {
            currentSettings = currentSettings.filter((data) => {
                return data.name.toLowerCase().includes(query.toLowerCase());
            });
        }
        return setFilteredSettings(currentSettings);
    };

    // ############### useEffect ###############
    // => set settings
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setFilteredSettings(Object.values(settings));
        }
        return () => {
            isSubscribed = false;
        };
    }, [settings, setFilteredSettings]);

    const subConfigurations = [
        {
            id: 1,
            title: 'Blogs managements',
            total: size(blogs) + ' Blogs',
            url: '/blogs',
            icon: <ChatBubble className="icon" />,
        },
        {
            id: 2,
            title: "FAQ's managements",
            total: size(faqs) + " Faq's",
            url: '/faqs',
            icon: <QuestionAnswer className="icon" />,
        },
        {
            id: 3,
            title: 'Contact managements',
            total: 'Social And Physical',
            url: '/contacts',
            icon: <People className="icon" />,
        },
        {
            id: 4,
            title: 'Job post pricing',
            total: 'Posting fee',
            url: '/job-post-pricing',
            icon: <PaymentOutlined className="icon" />,
        },
    ];

    return (
        <>
            <ConfigurationLayout head={'System configaration'}>
                <CustomCard2 height={623}>
                    <Grid container sx={{ mb: 3 }} spacing={3}>
                        {subConfigurations.map((configuration) => (
                            <Grid item sm={3} key={configuration.id}>
                                <SubConfigurationWidget
                                    configuration={configuration}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    {false ? (
                        <>
                            <ContentLoader />
                        </>
                    ) : (
                        <Grid container spacing={2}>
                            {!isEmpty(settings) ? (
                                <>
                                    <Grid item sm={12}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                                                p: 2,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <ConfigurationFilter
                                                handleFilter={settingsFilter}
                                            />
                                        </Box>
                                    </Grid>
                                    {filteredSettings.map((setting, index) => (
                                        <SettingWidget
                                            key={index}
                                            setting={setting}
                                        />
                                    ))}
                                </>
                            ) : (
                                <>
                                    <Grid
                                        item
                                        sm={12}
                                        sx={{
                                            height: 200,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography sx={{ fontSize: 20 }}>
                                            No setting added
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )}
                </CustomCard2>
            </ConfigurationLayout>
        </>
    );
};

export default Settings;
