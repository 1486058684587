import React from 'react';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../../components/Forms/CustomSubmitButton';
import { ManageAccounts } from '@mui/icons-material';
import * as Yup from 'yup';
import CustomTextArea from '../../../../components/Forms/CustomTextArea';
import { useDispatch } from 'react-redux';
import { DialogActions, Grid } from '@mui/material';
import useRTK from '../../../../hooks/useRTK';
import { useUpdateContactsMutation } from '../../../../api/helpCenter/helpCenterAPI';
import { setContacts } from '../../../../states/features/helpCenter/helpCenterSlice';

// -> validate settings form
const validate = Yup.object({
    phone: Yup.string()
        .required()
        .max(10, 'Phone number must be atmost 10 character')
        .min(10, 'Phone number must be atleast 10 character'),
    email: Yup.string().required().email(),
    address: Yup.string().required(),
    map_url: Yup.string()
        .required('Map url is required field')
        .url('Mapu url must be valid url'),
});

const UpdateContacts = ({ handleClose, contacts }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        updateContacts,
        { isLoading, isSuccess, isError, error, data: updateData },
    ] = useUpdateContactsMutation();

    // ############# RTK response ##################
    const rtkActions = (data) => {
        handleClose();
        dispatch(setContacts(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updateData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    id: contacts ? contacts.id : '',
                    phone: contacts ? contacts.phone : '',
                    email: contacts ? contacts.email : '',
                    address: contacts ? contacts.address : '',
                    map_url: contacts ? contacts.map_url : '',
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    updateContacts(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container columnSpacing={2}>
                            <Grid item sm={6}>
                                <CustomTextField
                                    label="Phone number"
                                    name="phone"
                                    type="text"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    label="Email"
                                    name="email"
                                    type="email"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomTextField
                                    label="Address"
                                    name="address"
                                    type="text"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomTextArea
                                    label="Google Map url"
                                    name="map_url"
                                    rows={3}
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <CustomSubmitButton
                                startIcon={<ManageAccounts />}
                                onClick={formik.handleSubmit}
                                loading={isLoading}
                            >
                                Update setting
                            </CustomSubmitButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateContacts;
