import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ManageAccounts } from '@mui/icons-material';
import * as Yup from 'yup';
import CustomTextArea from '../../../components/Forms/CustomTextArea';
import { useUpdateUserRoleMutation } from '../../../api/settings/userRolesAPI';
import { setUserRoles } from '../../../states/features/settings/userRolesSlice';
import {
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../helpers/RTKHelpers';
import { useDispatch } from 'react-redux';
import { successNotify } from '../../../helpers/notify';
import { DialogActions } from '@mui/material';

// -> validate settings form
const validate = Yup.object({
    name: Yup.string().required(),
    description: Yup.string().nullable(),
});

const UpdateUserRoles = ({ handleClose, refetch, editedValues }) => {
    // ############# CONST ##################
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        updateUserRole,
        { isLoading, isSuccess: updateSuccess, data: updateData },
    ] = useUpdateUserRoleMutation();

    // ############# useEffect ##################
    if (updateSuccess) {
        handleClose();
    }
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (updateSuccess) {
                refetch();
                successNotify(getRTKSuccessMessage(updateData));
                dispatch(setUserRoles(getRTKResponseData(updateData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [updateSuccess, updateData, refetch, dispatch]);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    id: editedValues.id,
                    name: editedValues.name,
                    description: editedValues.description,
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    updateUserRole(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <CustomTextField label="Name" name="name" type="text" />
                        <CustomTextArea
                            label="Description"
                            name="description"
                        />
                        <DialogActions>
                            <CustomSubmitButton
                                startIcon={<ManageAccounts />}
                                onClick={formik.handleSubmit}
                                loading={isLoading}
                            >
                                Update setting
                            </CustomSubmitButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateUserRoles;
