import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    groupStatus: [],
};

// !########## SLICE #############
const groupStatusSlice = createSlice({
    name: 'groupStatusSlice',
    initialState,
    reducers: {
        setGroupStatus: (state, actions) => {
            state.groupStatus = actions.payload;
        },
        addGroupStatus: (state, actions) => {
            const group_status = actions.payload;
            isEmpty(state.groupStatus)
                ? (state.groupStatus = [group_status, ...[]])
                : (state.groupStatus = [
                      group_status,
                      ...state.groupStatus,
                  ]);
        },
    },
});

export const {
    setGroupStatus,
    addGroupStatus,
} = groupStatusSlice.actions;
export default groupStatusSlice.reducer;
