import React from 'react';
import {
    Button,
    CircularProgress,
    Switch,
    Tooltip,
    Chip,
    Box,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { colors } from '../../../assets/utils/constants';
import {
    useActivatePromotionMutation,
    useDeactivatePromotionMutation,
} from '../../../api/promotions/promotionsAPI';
import { updatePromotion } from '../../../states/features/promotions/promotionsSlice';

const ActivateDeactivatePromotion = ({ promotion, noBorder }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        activateAction,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivatePromotionMutation();
    const [
        deactivateAction,
        {
            isLoading: deactivateLoading,
            isSuccess: deactivateSuccess,
            isError: deactivateIsError,
            error: deactivateError,
            data: deactivateData,
        },
    ] = useDeactivatePromotionMutation();

    // => Activate res
    // ############# useEffect ##################
    const rtkActions = (data) => {
        dispatch(updatePromotion(data));
    };
    RTK.useRTKResponse(
        activateSuccess,
        activateIsError,
        activateError,
        activateData,
        rtkActions,
    );
    RTK.useRTKResponse(
        deactivateSuccess,
        deactivateIsError,
        deactivateError,
        deactivateData,
        rtkActions,
    );
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Chip
                    color={promotion.isActive === 1 ? 'success' : 'warning'}
                    label={promotion.isActive === 1 ? 'Active' : 'In-active'}
                    size="small"
                    sx={{
                        textTransform: 'capitalize',
                        fontWeight: 'bold',
                        opacity: 0.8,
                        px: 1,
                        mr: 3,
                    }}
                />
                <Tooltip
                    title={
                        promotion.isActive === 1
                            ? 'Deactivate promotion'
                            : 'Activate promotion'
                    }
                    arrow
                >
                    <Button
                        variant="outlined"
                        color="secondary"
                        disabled={
                            activateLoading || deactivateLoading ? true : false
                        }
                        sx={{
                            padding: 0.2,
                            mr: 1,
                            border: noBorder
                                ? `none !important`
                                : `1px dotted ${colors.info} !important`,
                        }}
                        onClick={() =>
                            promotion.isActive === 1
                                ? deactivateAction(promotion.id)
                                : activateAction(promotion.id)
                        }
                    >
                        {activateLoading || deactivateLoading ? (
                            <>
                                <CircularProgress
                                    size={20}
                                    color="primary"
                                    sx={{
                                        my: 0.25,
                                    }}
                                />
                            </>
                        ) : (
                            <Switch
                                size="small"
                                color={
                                    promotion.isActive === 1
                                        ? 'success'
                                        : 'error'
                                }
                                checked={
                                    promotion.isActive === 1 ? true : false
                                }
                            />
                        )}
                    </Button>
                </Tooltip>
            </Box>
        </>
    );
};

export default ActivateDeactivatePromotion;
