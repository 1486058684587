import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Badge, Chip } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { filter, isEmpty } from "lodash";
import {
    beneficiarySelector,
    jobsSelector,
    organizationsSelector,
    shopsSelector,
} from "../states/features/selectors";
import DashORGTable from "./dash/DashORGTable";
import DashJobsTable from "./dash/DashJobsTable";
import DashShopsTable from "./dash/DashShopsTable";
import DashBenTable from "./dash/DashBenTable";
import CustomDialog from "./Dialogies/CustomDialog";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0, position: "relative" }}>{children}</Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function PendingActivities2() {
    // ############### Comp state #################
    const [value, setValue] = React.useState(0);
    const [orgVerReq, setOrgVerReq] = React.useState([]);
    const [benVerReq, setBenVerReq] = React.useState([]);
    const [jobs, setJobs] = React.useState([]);
    const [shops, setShops] = React.useState([]);
    const [open, setOpen] = React.useState(false);

    // ############### Redux state #################
    const { organizations } = useSelector(organizationsSelector);
    const { beneficiaries } = useSelector(beneficiarySelector);
    const { allEmployerJobs: allJobs } = useSelector(jobsSelector);
    const { shops: allShops } = useSelector(shopsSelector);

    // ############### FUNC #################
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const getVerificationOrgReq = () => {
        let verReq = filter(
            organizations,
            ({ is_verified }) =>
                is_verified === "requested" || is_verified === "on_progress"
        );
        setOrgVerReq(verReq);
    };
    const getVerificationBenReq = () => {
        let verReq = filter(
            beneficiaries,
            ({ is_verified, verificationStatus }) =>
                is_verified === 0 && verificationStatus === "requested"
        );
        setBenVerReq(verReq);
    };
    const getVerificationJobReq = () => {
        let verReq = filter(
            allJobs,
            ({ is_approved, status }) =>
                is_approved === 0 && status === "pending"
        );
        setJobs(verReq);
    };
    const getVerificationShopsReq = () => {
        let verReq = filter(
            allShops,
            ({ isVerified, status }) => isVerified === 0 && status === "pending"
        );
        setShops(verReq);
    };

    // ############## USEEFFECT ######################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getVerificationBenReq();
            getVerificationOrgReq();
            getVerificationJobReq();
            getVerificationShopsReq();
        }
        return () => {
            isSubscribed = false;
        };
    }, []);

    return (
        <>
            <Tabs value={value} onChange={handleChange} variant="fullWidth">
                <Tab
                    sx={{ fontWeight: "bold", fontSize: 13 }}
                    label={
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    opacity: 0.9,
                                }}
                            >
                                Pending Org's
                            </Typography>
                            <Chip
                                size="small"
                                color="warning"
                                label={
                                    !isEmpty(orgVerReq) ? orgVerReq.length : "0"
                                }
                                sx={{
                                    position: "absolute",
                                    top: 10,
                                    right: 0,
                                    height: 15,
                                    textAlign: "center",
                                    fontSize: 12,
                                }}
                            />
                        </Box>
                    }
                    {...a11yProps(0)}
                />
                <Tab
                    sx={{ fontWeight: "bold", fontSize: 13 }}
                    label={
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    opacity: 0.9,
                                }}
                            >
                                Pending ben's
                            </Typography>
                            <Chip
                                size="small"
                                color="warning"
                                label={
                                    !isEmpty(benVerReq) ? benVerReq.length : "0"
                                }
                                sx={{
                                    position: "absolute",
                                    top: 10,
                                    right: 0,
                                    height: 15,
                                    textAlign: "center",
                                    fontSize: 12,
                                }}
                            />
                        </Box>
                    }
                    {...a11yProps(1)}
                />
                <Tab
                    sx={{ fontWeight: "bold", fontSize: 13 }}
                    label={
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    opacity: 0.9,
                                }}
                            >
                                Pending jobs
                            </Typography>
                            <Chip
                                size="small"
                                color="warning"
                                label={!isEmpty(jobs) ? jobs.length : "0"}
                                sx={{
                                    position: "absolute",
                                    top: 10,
                                    right: 0,
                                    height: 15,
                                    textAlign: "center",
                                    fontSize: 12,
                                }}
                            />
                        </Box>
                    }
                    {...a11yProps(2)}
                />
                <Tab
                    sx={{ fontWeight: "bold", fontSize: 13 }}
                    label={
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    opacity: 0.9,
                                }}
                            >
                                Pending shops
                            </Typography>
                            <Chip
                                size="small"
                                color="warning"
                                label={!isEmpty(shops) ? shops.length : "0"}
                                sx={{
                                    position: "absolute",
                                    top: 10,
                                    right: 0,
                                    height: 15,
                                    textAlign: "center",
                                    fontSize: 12,
                                }}
                            />
                        </Box>
                    }
                    {...a11yProps(3)}
                />
            </Tabs>
            <TabPanel value={value} index={0}>
                <DashORGTable organizations={orgVerReq} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DashBenTable beneficiaries={benVerReq} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <DashJobsTable jobs={jobs} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <DashShopsTable shops={shops} />
            </TabPanel>
        </>
    );
}
