import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    qualificationAwards: [],
};

// !########## SLICE #############
const qualificationAwardsSlice = createSlice({
    name: 'qualificationAwardsSlice',
    initialState,
    reducers: {
        setQualificationAwards: (state, actions) => {
            state.qualificationAwards = actions.payload;
        },
        addQualificationAward: (state, actions) => {
            const qualification_award = actions.payload;
            isEmpty(state.qualificationAwards)
                ? (state.qualificationAwards = [qualification_award, ...[]])
                : (state.qualificationAwards = [
                      qualification_award,
                      ...state.qualificationAwards,
                  ]);
        },
    },
});

export const {
    setQualificationAwards,
    addQualificationAward,
} = qualificationAwardsSlice.actions;
export default qualificationAwardsSlice.reducer;
