import React, { useState } from 'react';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import { Box, Grid } from '@mui/material';
import EmployerSidebar from '../../components/sidebars/EmployerSidebar';
import InnerDataFetchingError from '../../components/InnerDataFetchingError';
import ContentLoader from '../../components/ContentLoader';
import EmployerWidget2 from '../../components/widgets/EmployerWidget2';
import { useGetSingleEmployerQuery } from '../../api/employers/employersAPI';
import { setEmployerDetails } from '../../states/features/employers/employersSlice';
import useRTK from '../../hooks/useRTK';
import { useDispatch, useSelector } from 'react-redux';
import { employersSelector } from '../../states/features/selectors';
import { useParams } from 'react-router-dom';
import JobPagination from '../../components/paginations/JobPagination';

const EmployerDetails = () => {
    // ################# CONST #####################
    const RTK = useRTK();
    const { employer_id } = useParams();
    const dispatch = useDispatch();

    // ################ Comp state ####################
    const [jobs, setJobs] = useState([]);

    // ################# Redux state #####################
    const { employerDetails: employer } = useSelector(employersSelector);

    // ############# RTK ##################
    // => Employer details
    const {
        isLoading,
        isSuccess,
        isError,
        refetch,
        data: responseData,
    } = useGetSingleEmployerQuery(employer_id);
    const rtkAction = (data) => {
        dispatch(setEmployerDetails(data));
        if (data?.jobs) {
            setJobs(data?.jobs);
        }
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkAction);

    return (
        <>
            <PageLayout
                head="Employers managements"
                sidebar={<EmployerSidebar />}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {isLoading ? (
                            <>
                                <CustomCard2
                                    height={isLoading ? '80vh' : '100%'}
                                >
                                    <ContentLoader />
                                </CustomCard2>
                            </>
                        ) : isError ? (
                            <>
                                <CustomCard2
                                    height={isLoading ? '80vh' : '100%'}
                                >
                                    <InnerDataFetchingError
                                        handleDataRefetch={refetch}
                                    />
                                </CustomCard2>
                            </>
                        ) : (
                            <>
                                <EmployerWidget2 employer={employer} />
                            </>
                        )}
                        {!isLoading && (
                            <Box sx={{ mt: 2 }}>
                                <>
                                    <JobPagination
                                        height={'43vh'}
                                        head={'Employer jobs'}
                                        jobs={jobs}
                                        type={'employer'}
                                    />
                                </>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default EmployerDetails;
