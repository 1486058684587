import React from 'react';
import ShopWidget from '../../../components/widgets/ShopWidget';
import { Grid } from '@mui/material';

const BenShops = ({ shops }) => {
    return (
        <div>
            <Grid container padding={2} spacing={3}>
                {shops.map((shop) => (
                    <Grid item sm={6} key={shop.id}>
                        <ShopWidget shop={shop} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default BenShops;
