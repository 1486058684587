import { Box, Grid } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import PageHeader from '../../components/PageHeader';

const DashboardLayout = ({ sidebar, children, head }) => {
    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{
                    pt: 2,
                    px: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                }}
            >
                {head && (
                    <Grid item sm={12}>
                        <PageHeader head={head} />
                    </Grid>
                )}
                <Grid item sm={12}>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <Box>{children}</Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default DashboardLayout;
