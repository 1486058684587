import React from 'react';
import PageLayout from '../layouts/PageLayout';
import { Box, Grid } from '@mui/material';
import ShopSidebar from '../../components/sidebars/ShopSidebar';
import CustomPagination from '../../components/paginations/CustomPagination';
import { useSelector } from 'react-redux';
import { shopsSelector } from '../../states/features/selectors';
import { paginator } from '../../helpers/paginationHelper';
import { filter, isEmpty, size } from 'lodash';
import NoContent from '../../components/NoContent';
import CountBadge from '../../components/CountBadge';
import ShopWidget from '../../components/widgets/ShopWidget';
import { useParams } from 'react-router-dom';

const ShopByStatus = () => {
    // ############## CONST ###################
    const { status } = useParams();

    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10);

    // ############# Redux state #############
    const { shops } = useSelector(shopsSelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Beneficiary by status
    const getShopsByStatus = () => {
        let filteredShops = [];
        // => drafted
        if (status === 'drafted') {
            filteredShops = filter(shops, ({ status }) => status === 'drafted');
        }
        // => verified
        if (status === 'verified') {
            filteredShops = filter(shops, ({ isVerified }) => isVerified === 1);
        }
        // => requested
        if (status === 'requested') {
            filteredShops = filter(shops, ({ status }) => status === 'pending');
        }
        // => suspended
        if (status === 'suspended') {
            filteredShops = filter(
                shops,
                ({ status }) => status === 'suspended',
            );
        }
        // => denied
        if (status === 'denied') {
            filteredShops = filter(shops, ({ status }) => status === 'denied');
        }
        return filteredShops;
    };
    const shopsByStatus = getShopsByStatus();

    // => Pagination data
    const paginatedShops = paginator(shopsByStatus, page, perPage);

    return (
        <>
            <PageLayout head="Shops managements" sidebar={<ShopSidebar />}>
                <Grid container spacing={1.5}>
                    <Grid item sm={12}>
                        <CustomPagination
                            head={`${status} Wazawa Max shops`}
                            height={'63.5vh'}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedShops}
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(shopsByStatus)}
                                            item={'Shops'}
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(shopsByStatus) ? (
                                <>
                                    <NoContent
                                        height={300}
                                        message={`No ${status} shops`}
                                    />
                                </>
                            ) : (
                                <Grid container spacing={2}>
                                    {paginatedShops.data.map((shop) => (
                                        <Grid item sm={6} key={shop.id}>
                                            <ShopWidget shop={shop} />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default ShopByStatus;
