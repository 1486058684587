import React from "react";
import PageLayout from "../layouts/PageLayout";
import { Box, Grid } from "@mui/material";
import ShopSidebar from "../../components/sidebars/ShopSidebar";
import CustomPagination from "../../components/paginations/CustomPagination";
import { useSelector } from "react-redux";
import { shopsSelector } from "../../states/features/selectors";
import { paginator } from "../../helpers/paginationHelper";
import { filter, isEmpty, size } from "lodash";
import NoContent from "../../components/NoContent";
import CountBadge from "../../components/CountBadge";
import ShopWidget from "../../components/widgets/ShopWidget";
import ShopStatusesInfoboxes from "./components/ShopStatusesInfoboxes";

const Shops = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10);

    // ############# Redux state #############
    const { shops } = useSelector(shopsSelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Shops by status
    const getShopsByStatus = () => {
        // => Drafted
        let draftedShops = filter(shops, ({ status }) => status === "drafted");

        // => Verified
        let verifiedShops = filter(shops, ({ isVerified }) => isVerified === 1);
        // => On-subscription
        let onSubscriptionShops = filter(
            shops,
            ({ isSubscribed }) => isSubscribed === 1
        );
        // => Requested
        let requestedShops = filter(
            shops,
            ({ status }) => status === "pending"
        );
        // => Suspended
        let suspendedShops = filter(
            shops,
            ({ status }) => status === "suspended"
        );
        // => Denied
        let deniedShops = filter(shops, ({ status }) => status === "denied");
        return {
            draftedShops,
            verifiedShops,
            onSubscriptionShops,
            requestedShops,
            suspendedShops,
            deniedShops,
        };
    };
    const shopsByStatus = getShopsByStatus();

    // => Pagination data
    const paginatedShops = paginator(
        shopsByStatus.verifiedShops,
        page,
        perPage
    );

    return (
        <>
            <PageLayout head="Shops managements" sidebar={<ShopSidebar />}>
                <Grid container spacing={1.5}>
                    <Grid item sm={12}>
                        <ShopStatusesInfoboxes />
                    </Grid>
                    <Grid item sm={12}>
                        <CustomPagination
                            head={"Verified Wazawa Max shops"}
                            height={"51vh"}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedShops}
                            action={
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(
                                                shopsByStatus.verifiedShops
                                            )}
                                            item={"Verified shops"}
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(shopsByStatus.verifiedShops) ? (
                                <>
                                    <NoContent
                                        height={300}
                                        message={"No verified shops"}
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {paginatedShops.data.map((shop) => (
                                        <Grid item sm={6} key={shop.id}>
                                            <ShopWidget shop={shop} />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Shops;
