import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
} from "@react-pdf/renderer";
import { colors } from "../../assets/utils/constants";
import moment from "moment";
import logo from "../../assets/media/images/logo.png";

// Create styles
const styles = StyleSheet.create({
    page: {
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "50px",
        paddingBottom: "85px",
        backgroundColor: colors.bgColor2,
    },
    section: {},
    image: {
        height: "120px",
        width: "180px",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
});

// Create Document Component
const ReportLayout = ({ head, orientation, children }) => (
    <Document>
        <Page
            size="A4"
            style={styles.page}
            dpi={70}
            orientation={orientation ? orientation : "portrait"}
        >
            <View
                style={{
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    flexDirection: "row",
                    left: 40,
                    right: 30,
                    paddingTop: 10,
                }}
            >
                <View
                    style={{
                        width: 100,
                        height: "100%",
                        padding: 10,
                        backgroundColor: colors.primary,
                        borderRadius: 5,
                    }}
                >
                    <Image src={logo} />
                </View>
                <View
                    style={{
                        backgroundColor: colors.secondary,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginLeft: 5,
                        position: "relative",
                        borderRadius: 5,
                    }}
                >
                    <Text style={{ color: colors.primary, fontSize: "25px" }}>
                        Wazawa Max
                    </Text>
                    <View
                        style={{
                            height: 3,
                            backgroundColor: colors.primary,
                            width: 700,
                            position: "absolute",
                            bottom: -5,
                            left: 0,
                        }}
                    ></View>
                </View>
                <View
                    style={{
                        position: "absolute",
                        bottom: -25,
                        left: 0,
                        right: 0,
                    }}
                >
                    <Text
                        style={{
                            fontSize: "16px",
                            fontStyle: "italic",
                            textAlign: "right",
                            color: colors.primary,
                        }}
                    >
                        Connecting dreams to reality
                    </Text>
                </View>
            </View>
            <View
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 40,
                    marginTop: 80,
                }}
            >
                <Text
                    style={{
                        textAlign: "center",
                        fontSize: "12px",
                        color: colors.primary,
                        fontWeight: "700px",
                        textTransform: "uppercase",
                        textDecoration: "underline",
                    }}
                >
                    {head}
                </Text>
            </View>
            <View
                style={{
                    position: "absolute",
                    left: "0 !important",
                    right: "0 !important",
                    bottom: "0 !important",
                    height: 80,
                }}
                fixed
            >
                <View
                    style={{
                        height: 150,
                        width: 150,
                        borderTop: `70px solid ${colors.secondary}`,
                        borderRight: `70px solid ${colors.secondary}`,
                        borderRadius: "100%",
                        position: "absolute",
                        bottom: -45,
                        left: -45,
                    }}
                ></View>
                <View
                    style={{
                        height: 3,
                        width: "100%",
                        backgroundColor: colors.primary,
                        borderRadius: "100%",
                        position: "absolute",
                        top: 0,
                        left: "18%",
                    }}
                ></View>
                <View
                    style={{
                        position: "relative",
                        bottom: -10,
                        left: 0,
                        right: 0,
                        paddingRight: 20,
                    }}
                >
                    <Text
                        style={{
                            fontSize: "12px",
                            fontStyle: "italic",
                            textAlign: "right",
                            color: colors.primary,
                        }}
                    >
                        Mwalimu House , 3rd Floor,
                    </Text>
                    <Text
                        style={{
                            fontSize: "12px",
                            fontStyle: "italic",
                            textAlign: "right",
                            color: colors.primary,
                        }}
                    >
                        Ilala - Amana Street, Uhuru Road,
                    </Text>
                    <Text
                        style={{
                            fontSize: "12px",
                            fontStyle: "italic",
                            textAlign: "right",
                            color: colors.primary,
                        }}
                    >
                        P.O. Box 42638-– Dar es salaam
                    </Text>
                    <Text
                        style={{
                            fontSize: "12px",
                            fontStyle: "italic",
                            textAlign: "right",
                            color: colors.primary,
                        }}
                    >
                        info@wazawamax.co.tz, 0675228289.
                    </Text>
                </View>
            </View>
            <View style={styles.section}>
                <Text
                    style={{
                        fontSize: "12px",
                        color: colors.primary,
                        fontWeight: "700px",
                        marginBottom: "10px",
                        textAlign: "right",
                    }}
                >
                    {moment().format("LL")}
                </Text>
            </View>
            {/*################### content ############## */}
            <View>{children}</View>

            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                    `${pageNumber} out of ${totalPages}`
                }
                fixed
            />
        </Page>
    </Document>
);

export default ReportLayout;
