import {
    Avatar,
    Box,
    Chip,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/constants";
import {
    LocationOn,
    Lock,
    Pending,
    Phone,
    Unpublished,
    Verified,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const ShopWidget = ({ shop }) => {
    return (
        <>
            <Box
                sx={{
                    backgroundImage: `${`url(${shop.banner})`}`,
                    borderRadius: 2,
                    overflow: "hidden",
                    border: `1px solid ${colors.secondary}`,
                    boxShadow: `2px 2px 20px ${colors.bgColor5}`,
                }}
            >
                <Box
                    sx={{
                        background: `linear-gradient(360deg, rgba(23, 35, 60, .6), rgba(23, 35, 60, .5))`,
                    }}
                >
                    <Box
                        sx={{
                            height: 120,
                            display: "flex",
                            alignItems: "center",
                            p: 2,
                            position: "relative",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box>
                            <Typography
                                noWrap
                                sx={{
                                    width: 190,
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    color: colors.white,
                                }}
                            >
                                {shop.shop_name}
                            </Typography>
                            <Typography
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    py: 1,
                                    fontSize: 14,
                                    color: colors.white,
                                }}
                            >
                                <LocationOn
                                    color="warning"
                                    sx={{
                                        mr: 0.5,
                                        fontSize: 16,
                                        color: colors.white,
                                    }}
                                />{" "}
                                {`${shop?.region?.name}, ${shop?.district?.name}`}
                            </Typography>
                            <Box sx={{}}>
                                <Chip
                                    icon={
                                        shop.status === "drafted" ? (
                                            <Pending />
                                        ) : shop.status === "pending" ? (
                                            <Pending />
                                        ) : shop.status === "verified" ? (
                                            <Verified />
                                        ) : shop.status === "suspended" ? (
                                            <Lock />
                                        ) : shop.status === "denied" ? (
                                            <Unpublished />
                                        ) : (
                                            ""
                                        )
                                    }
                                    size="small"
                                    color={
                                        shop.status === "drafted"
                                            ? "warning"
                                            : shop.status === "pending"
                                            ? "info"
                                            : shop.status === "verified"
                                            ? "success"
                                            : shop.status === "suspended"
                                            ? "warning"
                                            : shop.status === "denied"
                                            ? "error"
                                            : "default"
                                    }
                                    label={shop.status}
                                    sx={{
                                        fontSize: 11,
                                        fontWeight: "bold",
                                        opacity: 0.9,
                                        height: 22,
                                    }}
                                />
                            </Box>
                        </Box>
                        <Link
                            to={`/${
                                shop.owner_type === "beneficiary"
                                    ? "beneficiaries"
                                    : "employers"
                            }/${shop.owner_id}`}
                        >
                            <Tooltip
                                title={`${shop.owner.first_name} ${shop.owner.last_name}`}
                                arrow
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Avatar
                                        src={shop.owner.profile_picture}
                                        className="dp"
                                        sx={{
                                            height: 60,
                                            width: 60,
                                            border: `3px solid ${colors.secondary}`,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                            img: {
                                                borderRadius: 50,
                                            },
                                        }}
                                    />
                                    <Typography
                                        noWrap
                                        sx={{
                                            fontSize: 14,
                                            color: colors.white,
                                            textAlign: "center",
                                        }}
                                    >
                                        {`${shop.owner.first_name} ${shop.owner.last_name}`}
                                    </Typography>
                                </Box>
                            </Tooltip>
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            height: 60,
                            background: `rgb(179, 219, 201, .95)`,
                            boxShadow: `2px 2px 10px ${colors.bgColor5}`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                        }}
                    >
                        <Box sx={{ textAlign: "center" }}>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    color: colors.success,
                                }}
                            >
                                {shop.shop_reg_id}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    color: colors.warning,
                                }}
                            >
                                ( {shop.owner_type} )
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                position: "absolute",
                                left: 15,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                opacity: 0.8,
                            }}
                        >
                            <Box
                                sx={{
                                    height: 15,
                                    width: 15,
                                    borderRadius: 50,
                                    bgcolor: shop.isOpened
                                        ? colors.success
                                        : "red",
                                }}
                            ></Box>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    color: shop.isOpened
                                        ? colors.success
                                        : "red",
                                }}
                            >
                                {shop.isOpened ? "Opened" : "Closed"}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                position: "absolute",
                                right: 15,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                opacity: 0.8,
                            }}
                        >
                            <Tooltip title={shop.phone_number} arrow>
                                <a href={`tel:+${shop.phone_number}`}>
                                    <IconButton
                                        color="info"
                                        sx={{
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                                        }}
                                    >
                                        <Phone />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ShopWidget;
