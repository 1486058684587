import React from 'react';
import { CalendarMonth, Person, Wc } from '@mui/icons-material';
import {
    Avatar,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    styled,
} from '@mui/material';
import moment from 'moment';
import { colors } from '../../assets/utils/constants';

const InfoListCard = styled(List)({
    'padding': 0,
    'overflow': 'hidden',
    '& .MuiListItemButton-root': {
        'paddingLeft': 24,
        'paddingRight': 24,
        'background': colors.primary,
        '& span': {
            color: colors.gray,
        },
    },
    '& .list-item': {
        'background': colors.primary,
        'color': colors.gray,
        ' p': {
            color: colors.gray,
        },
    },
});

const GeneralDetails = ({ user }) => {
    return (
        <>
            <Grid container justifyContent={'center'}>
                <Grid item sm={6} sx={{ overflow: 'hidden' }}>
                    <InfoListCard
                        sx={{
                            width: '100%',
                            bgcolor: 'background.paper',
                        }}
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Person />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${user.first_name}`}
                                secondary="First name"
                                secondaryTypographyProps={{
                                    component: 'span',
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Person />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${user.middle_name}`}
                                secondary="Middle name"
                                secondaryTypographyProps={{
                                    component: 'span',
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Person />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${user.last_name}`}
                                secondary="Last name"
                                secondaryTypographyProps={{
                                    component: 'span',
                                }}
                            />
                        </ListItem>
                    </InfoListCard>
                </Grid>
                <Grid
                    item
                    sm={6}
                    sx={{
                        overflow: 'hidden',
                        background: '#fff',
                    }}
                >
                    <InfoListCard
                        sx={{
                            width: '100%',
                            bgcolor: 'background.paper',
                        }}
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <CalendarMonth />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={moment(user.birth_date).format(
                                    'MMM Do YYYY',
                                )}
                                secondary="Date of birth"
                                secondaryTypographyProps={{
                                    component: 'span',
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Wc />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${user.gender}`}
                                secondary="Gender"
                                secondaryTypographyProps={{
                                    component: 'span',
                                }}
                            />
                        </ListItem>
                    </InfoListCard>
                </Grid>
            </Grid>
        </>
    );
};

export default GeneralDetails;
