import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import { colors } from "../../../../assets/utils/constants";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DoneAll } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import useRTK from "../../../../hooks/useRTK";
import { useApproveEmployerJobMutation } from "../../../../api/job/jobAPI";
import { setEmployerJobDetails } from "../../../../states/features/jobs/jobsSlice";

const VerifyJob = ({ cancel, closeDialogy, job }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK #####################
    const [
        approveJob,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useApproveEmployerJobMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        closeDialogy();
        dispatch(setEmployerJobDetails(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);
    return (
        <>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        textAlign: "center",
                        flexDirection: "column",
                    }}
                >
                    <Grid container>
                        <Grid item sm={12}>
                            <DoneAll
                                sx={{
                                    fontSize: 33,
                                    fontWeight: "bold",
                                    color: colors.success,
                                    opacity: 0.8,
                                    m: 0,
                                    p: 0,
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: 22,
                                    fontWeight: "bold",
                                    color: colors.success,
                                    opacity: 0.8,
                                }}
                            >
                                Please confirm job approval
                            </Typography>
                        </Grid>
                        <Grid item sm={12} sx={{ mt: 2 }}>
                            <Typography
                                sx={{
                                    opacity: 0.8,
                                }}
                            >
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Commodi eos recusandae
                                architecto saepe cum suscipit, eveniet vero ipsa
                                doloribus aliquam exercitationem iste, ullam
                                necessitatibus doloremque excepturi nesciunt
                                culpa! Sequi, officia.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <Divider sx={{ mt: 2 }} />
            <DialogActions>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        textAlign: "center",
                    }}
                >
                    <Grid container>
                        <Grid
                            item
                            sm={6}
                            sx={{
                                borderRight: `2px solid ${colors.gray}`,
                                padding: 0.5,
                            }}
                        >
                            <Button
                                startIcon={<CancelIcon />}
                                variant="outlined"
                                color="error"
                                sx={{
                                    width: "60%",
                                    fontSize: 12,
                                }}
                                size="small"
                                onClick={() => cancel()}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid
                            item
                            sm={6}
                            sx={{
                                borderLeft: `2px solid ${colors.gray}`,
                                padding: 0.5,
                            }}
                        >
                            <Button
                                size="small"
                                disabled={isLoading ? true : false}
                                startIcon={<CheckCircleIcon />}
                                variant="outlined"
                                color={`success`}
                                sx={{
                                    width: "60%",
                                    fontSize: 12,
                                }}
                                onClick={() => approveJob(job?.id)}
                            >
                                Verify request
                                {isLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: colors.primary,
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                        }}
                                    />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogActions>
        </>
    );
};

export default VerifyJob;
