import React from 'react';
import {
    Edit,
    Email,
    PinDropOutlined,
    Phone,
    Remove,
} from '@mui/icons-material';
import { colors } from '../../../assets/utils/constants';
import { Box, Grid, IconButton } from '@mui/material';
import CustomCard2 from '../../../components/cards/CustomCard2';
import ContactWidget from '../../../components/widgets/ContactWidget';
import CustomDialog from '../../../components/Dialogies/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import { helpCenterSelector } from '../../../states/features/selectors';
import { useGetContactsQuery } from '../../../api/helpCenter/helpCenterAPI';
import { setContacts } from '../../../states/features/helpCenter/helpCenterSlice';
import useRTK from '../../../hooks/useRTK';
import { isEmpty } from 'lodash';
import UpdateContacts from './CRUD/UpdateContacts';
import ContentLoader from '../../../components/ContentLoader';

const Contacts = () => {
    // ############ CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# COMP State ###############
    const [open, setOpen] = React.useState(false);

    // ############ Redux state ##############
    const { contacts } = useSelector(helpCenterSelector);

    // ############# FUNC ###############
    // => Open dialog
    const handleClickOpen = () => {
        setOpen(true);
    };
    // => Close dialog
    const handleClose = () => {
        setOpen(false);
    };
    // => Convet contacts to array
    const restructureContacts = () => {
        let contactsObj = contacts[0];
        let contactsArr = [
            {
                id: 1,
                url: `tel:${contactsObj?.phone}`,
                icon: (
                    <Phone
                        sx={{ fontSize: 25, mr: 1, color: colors.warning }}
                    />
                ),
                title: 'Call us',
                contact: isEmpty(contactsObj) ? <Remove /> : contactsObj?.phone,
            },
            {
                id: 4,
                url: `mailTo:${contactsObj?.email}`,
                icon: (
                    <Email
                        sx={{ fontSize: 25, mr: 1, color: colors.warning }}
                    />
                ),
                title: 'Send us an email',
                contact: isEmpty(contactsObj) ? <Remove /> : contactsObj?.email,
            },
            {
                id: 3,
                url: `${contactsObj?.map_url}`,
                icon: (
                    <PinDropOutlined
                        sx={{ fontSize: 25, mr: 1, color: colors.warning }}
                    />
                ),
                title: 'We are located',
                contact: isEmpty(contactsObj) ? (
                    <Remove />
                ) : (
                    contactsObj?.address
                ),
            },
        ];
        return contactsArr;
    };
    const contactsArr = restructureContacts();

    // ############## RTK ##############
    const { isLoading, isSuccess, data: contacstData } = useGetContactsQuery();
    // => Action
    const rtkAction = (data) => {
        dispatch(setContacts(data));
    };
    // => Response
    RTK.useRTKFetch(isSuccess, contacstData, rtkAction);

    return (
        <>
            {/* ############## Dialogy ############## */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`Update contacts`}
            >
                <UpdateContacts
                    handleClose={handleClose}
                    contacts={contacts[0]}
                />
            </CustomDialog>

            <CustomCard2
                head={'Phone number, Email and address'}
                height={isLoading ? 100 : '100%'}
                action={
                    <Box>
                        <IconButton
                            size="small"
                            color="warning"
                            onClick={() => {
                                handleClickOpen();
                            }}
                            sx={{
                                p: 1,
                                fontSize: 13,
                                fontWeight: 'bold',
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                            }}
                        >
                            Update contacts
                            <Edit sx={{ fontSize: 16, ml: 1 }} />
                        </IconButton>
                    </Box>
                }
            >
                <Grid container spacing={4}>
                    {isLoading ? (
                        <Grid item sm={12}>
                            <ContentLoader height={100} />
                        </Grid>
                    ) : (
                        contactsArr?.map((contact) => (
                            <Grid item sm={4} key={contact.id}>
                                <ContactWidget contact={contact} />
                            </Grid>
                        ))
                    )}
                </Grid>
            </CustomCard2>
        </>
    );
};

export default Contacts;
