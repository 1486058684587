import React, { useState } from "react";
import PageLayout from "../layouts/PageLayout";
import { Box, Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionPackagesSelector } from "../../states/features/selectors";
import { isEmpty, size } from "lodash";
import { setFreeTrialPackages } from "../../states/features/subscriptionPackages/subscriptionPackagesSlice";
import ContentLoader from "../../components/ContentLoader";
import CustomCard2 from "../../components/cards/CustomCard2";
import useRTK from "../../hooks/useRTK";
import SubscriptionPackageSidebar from "../../components/sidebars/SubscriptionPackageSidebar";
import CustomPagination from "../../components/paginations/CustomPagination";
import NoContent from "../../components/NoContent";
import { paginator } from "../../helpers/paginationHelper";
import CountBadge from "../../components/CountBadge";
import CustomDialog from "../../components/Dialogies/CustomDialog";
import { Add } from "@mui/icons-material";
import { useGetAllFreeTrialPackagesQuery } from "../../api/subscriptionPackages/freeTrialPackagesAPI";
import FreeTrialPackageWidget from "../../components/widgets/FreeTrialPackageWidget";
import CreateFreeTrialPackage from "./CRUD/CreateFreeTrialPackage";

const FreeTrialPackages = () => {
    // ############## CONST ############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(3);
    const [dialogyOpen, setDialogyOpen] = useState(false);

    // ######### Redux state ############
    const { freeTrialPackages } = useSelector(subscriptionPackagesSelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Handle dialog open
    const handleDialogOpen = () => {
        setDialogyOpen(true);
    };
    // => Dialogy closer
    const handleDialogClose = () => {
        setDialogyOpen(false);
    };

    // ############## RTK ############
    const {
        isLoading,
        isSuccess,
        data: packagesData,
    } = useGetAllFreeTrialPackagesQuery();
    const rtkActions = (data) => {
        dispatch(setFreeTrialPackages(data));
    };
    RTK.useRTKFetch(isSuccess, packagesData, rtkActions);

    // => Pagination data
    const paginatedPackages = paginator(freeTrialPackages, page, perPage);

    return (
        <>
            <PageLayout
                head="Free trial subscription packages managements"
                sidebar={<SubscriptionPackageSidebar />}
                noSidebar
            >
                {/* ################# Dialogy ################## */}
                <CustomDialog
                    openDialog={dialogyOpen}
                    handleDialogClose={handleDialogClose}
                    head={"Add free trial subscription package"}
                >
                    <CreateFreeTrialPackage
                        handleDialogClose={handleDialogClose}
                    />
                </CustomDialog>

                {isLoading ? (
                    <>
                        <CustomCard2 height={"78vh"}>
                            <ContentLoader />
                        </CustomCard2>
                    </>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <CustomPagination
                                head={`Free trial subscription packages`}
                                height={"100%"}
                                borderBottom
                                handlePageChange={handlePageChange}
                                handlePerPageChage={handlePerPageChage}
                                data={paginatedPackages}
                                action={
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box sx={{ mr: 1 }}>
                                            <CountBadge
                                                total={size(freeTrialPackages)}
                                                item={"Active packages"}
                                            />
                                        </Box>
                                        <Button
                                            startIcon={<Add />}
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => {
                                                handleDialogOpen();
                                            }}
                                        >
                                            Add package
                                        </Button>
                                    </Box>
                                }
                            >
                                {isEmpty(freeTrialPackages) ? (
                                    <>
                                        <NoContent
                                            height={"60vh"}
                                            message={
                                                "No subscription package added"
                                            }
                                        />
                                    </>
                                ) : (
                                    <Grid container spacing={4}>
                                        {paginatedPackages.data.map(
                                            (subPackage) => (
                                                <Grid
                                                    item
                                                    sm={4}
                                                    key={subPackage.id}
                                                >
                                                    <FreeTrialPackageWidget
                                                        subscriptionPackage={
                                                            subPackage
                                                        }
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                )}
                            </CustomPagination>
                        </Grid>
                    </Grid>
                )}
            </PageLayout>
        </>
    );
};

export default FreeTrialPackages;
