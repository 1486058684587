import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { size } from "lodash";
import { jobsSelector } from "../../states/features/selectors";
import Infobox1 from "../../components/infoboxes/InfoBox1";
import CustomTabs from "../../components/tabs/CustomTabs";
import { WorkHistory } from "@mui/icons-material";
import JobPagination from "../../components/paginations/JobPagination";
import JobSidebar from "../../components/sidebars/JobSidebar";
import PageLayout from "../layouts/PageLayout";

const Jobs = () => {
    // ############### REDUCER STATE #################
    const { organizationJobs, allEmployerJobs } = useSelector(jobsSelector);

    const infoBoxData = [
        {
            id: 1,
            title1: "Organization",
            title2: "Jobs",
            total: size(organizationJobs),
            url: "organizations",
        },
        {
            id: 2,
            title1: "Employers",
            title2: "Jobs",
            total: size(allEmployerJobs),
            url: "employers",
        },
    ];

    const tabs = [
        {
            id: 1,
            title: "Organization Jobs",
            icon: <WorkHistory />,
            chipColor: "success",
            total: size(organizationJobs),
            tabsContent: (
                <JobPagination
                    jobs={organizationJobs}
                    height={"47vh"}
                    type={"org"}
                />
            ),
        },
        {
            id: 2,
            title: "Employers Jobs",
            icon: <WorkHistory />,
            chipColor: "info",
            total: size(allEmployerJobs),
            tabsContent: (
                <JobPagination
                    jobs={allEmployerJobs}
                    height={"47vh"}
                    type={"employer"}
                />
            ),
        },
    ];

    return (
        <>
            <PageLayout
                head={"Organizations and employer jobs"}
                // sidebar={<JobSidebar />}
            >
                <Grid container spacing={2}>
                    {infoBoxData.map((data, index) => (
                        <Grid item sm={6} key={index}>
                            <Infobox1 data={data} height={70} />
                        </Grid>
                    ))}
                    <Grid item sm={12}>
                        <CustomTabs tabs={tabs} noHeight={"55vh"} />
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Jobs;
