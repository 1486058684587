import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ManageAccounts } from '@mui/icons-material';
import * as Yup from 'yup';
import CustomTextArea from '../../../components/Forms/CustomTextArea';
import { useAddUserRoleMutation } from '../../../api/settings/userRolesAPI';
import { addUserRole } from '../../../states/features/settings/userRolesSlice';
import {
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../helpers/RTKHelpers';
import { useDispatch } from 'react-redux';
import { successNotify } from '../../../helpers/notify';
import { DialogActions } from '@mui/material';

// -> validate settings form
const validate = Yup.object({
    name: Yup.string().required(),
    description: Yup.string().required(),
});

const AddUserRoles = ({ handleClose, refetch }) => {
    // ############# CONST ##################
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        createUserRole,
        { isLoading, isSuccess: addSuccess, data: addData },
    ] = useAddUserRoleMutation();

    // ############# useEffect ##################
    if (addSuccess) {
        handleClose();
    }
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (addSuccess) {
                refetch();
                successNotify(getRTKSuccessMessage(addData));
                dispatch(addUserRole(getRTKResponseData(addData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [addSuccess, refetch, addData, dispatch]);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    name: '',
                    description: '',
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    createUserRole(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <CustomTextField label="Name" name="name" type="text" />
                        <CustomTextArea
                            label="Description"
                            name="description"
                        />
                        <DialogActions>
                            <CustomSubmitButton
                                startIcon={<ManageAccounts />}
                                onClick={formik.handleSubmit}
                                loading={isLoading}
                            >
                                Add setting
                            </CustomSubmitButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddUserRoles;
