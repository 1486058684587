import React, { useState } from 'react';
import { colors } from '../../../assets/utils/constants';
import VerifiedIcon from '@mui/icons-material/Verified';
import DoneIcon from '@mui/icons-material/Done';
import {
    Box,
    Button,
    Grid,
    IconButton,
    styled,
    Tooltip,
    Typography,
} from '@mui/material';
import CustomCard from '../../../components/cards/CustomCard';
import { Preview, DoDisturb, QuestionMark } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import PendingIcon from '@mui/icons-material/Pending';
import CachedIcon from '@mui/icons-material/Cached';
import DocumentPreviewDialog from '../../../components/Dialogies/DocumentPreviewDialog';
import DocumentPreview from '../../../components/DocumentPreview';
import NoContent from '../../../components/NoContent';
import CustomDialog from '../../../components/Dialogies/CustomDialog';
import ApproveKeyPersonDocs from './CRUD/ApproveKeyPersonDocs';
import DenyKeyPersonDocs from './CRUD/DenyKeyPersonDocs';

// -> button con
const ButtonCon = styled(Box)({});

// -> BoxTable
const BoxTable = styled(Box)({
    'border': `1px solid ${colors.bgColor5}`,
    'paddingTop': 5,
    'paddingBottom': 5,
    'paddingLeft': 15,
    'borderRadius': 2,
    '.title': {
        fontSize: 15,
        fontWeight: 'bold',
    },
    '.value': {
        fontSize: 14,
    },
});

// -> docs status
const DocsStatus = styled(Box)({
    'height': '100%',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'flexDirection': 'column',
    '.text': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '.icon': {
        fontSize: 35,
        margin: 10,
    },
});

const ORGKeyPerson = ({ orgKeyPerson, refetchProfile }) => {
    const [confimationType, setConfimationType] = useState(false);
    const [openDocsDialog, setOpenDocsDialogy] = useState(false);
    const [confirmationDialogy, setConfirmationDialogy] = useState(false);
    // => Handle docs open
    const handleConfimationDialogOpen = (type) => {
        setConfimationType(type);
        setConfirmationDialogy(true);
    };
    // => Dialogy closer
    const handleConfimationDialogClose = () => {
        setConfirmationDialogy(false);
    };

    // => Dialogy closer
    const handleDialogClose = () => {
        setOpenDocsDialogy(false);
    };

    // => Handle docs preview
    const handleDocsPreview = () => {
        setOpenDocsDialogy(true);
    };
    return (
        <>
            {/* ##################### DIALOG ################# */}
            <DocumentPreviewDialog
                head={'Organization key person document'}
                openDialog={openDocsDialog}
                handleDialogClose={handleDialogClose}
            >
                <DocumentPreview
                    document={orgKeyPerson.document?.verification_attachment}
                />
            </DocumentPreviewDialog>
            <CustomDialog
                openDialog={confirmationDialogy}
                handleDialogClose={handleConfimationDialogClose}
                head={
                    confimationType === 'approve_kp_docs'
                        ? 'Organization key person document approval'
                        : 'Organization key person document deny'
                }
            >
                {confimationType === 'approve_kp_docs' ? (
                    <>
                        <ApproveKeyPersonDocs
                            orgKeyPerson={orgKeyPerson}
                            refetchProfile={refetchProfile}
                            cancel={handleConfimationDialogClose}
                            closeDialogy={handleConfimationDialogClose}
                        />
                    </>
                ) : confimationType === 'deny_kp_docs' ? (
                    <>
                        <DenyKeyPersonDocs
                            orgKeyPerson={orgKeyPerson}
                            refetchProfile={refetchProfile}
                            cancel={handleConfimationDialogClose}
                            closeDialogy={handleConfimationDialogClose}
                        />
                    </>
                ) : (
                    <></>
                )}
            </CustomDialog>

            <CustomCard
                head={`Company key person`}
                height={isEmpty(orgKeyPerson) ? 200 : '100%'}
            >
                <Grid container justifyContent={'center'} padding={2}>
                    {!isEmpty(orgKeyPerson) ? (
                        <>
                            <Grid container>
                                <Grid item sm={8}>
                                    <Grid item sm={12}>
                                        <Box sx={{}}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: colors.info,
                                                }}
                                            >
                                                Personal details
                                            </Typography>
                                            <Grid container>
                                                <Grid item sm={8}>
                                                    <BoxTable>
                                                        <Typography className="title">
                                                            Full name
                                                        </Typography>
                                                        <Typography className="value">{`${orgKeyPerson.first_name} ${orgKeyPerson.middle_name} ${orgKeyPerson.last_name}`}</Typography>
                                                    </BoxTable>
                                                </Grid>
                                                <Grid item sm={4}>
                                                    <BoxTable>
                                                        <Typography className="title">
                                                            Gender
                                                        </Typography>
                                                        <Typography className="value">
                                                            {
                                                                orgKeyPerson.gender
                                                            }
                                                        </Typography>
                                                    </BoxTable>
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <BoxTable>
                                                        <Typography className="title">
                                                            Phone
                                                        </Typography>
                                                        <Typography className="value">
                                                            {
                                                                orgKeyPerson.phone_number
                                                            }
                                                        </Typography>
                                                    </BoxTable>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12} marginTop={2}>
                                        <Box sx={{}}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    textAlign: 'left',
                                                    color: colors.info,
                                                }}
                                            >
                                                Attachment
                                            </Typography>
                                            <Box
                                                sx={{
                                                    p: 2,
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    sx={{
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                                    }}
                                                >
                                                    <>
                                                        <Grid
                                                            item
                                                            sm={12}
                                                            marginTop={1}
                                                        >
                                                            <Grid
                                                                container
                                                                sx={{
                                                                    borderRadius: 2,
                                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                                                }}
                                                            >
                                                                <>
                                                                    <Grid
                                                                        item
                                                                        sm={12}
                                                                    >
                                                                        <BoxTable>
                                                                            <Typography className="title">
                                                                                Verification
                                                                                type
                                                                            </Typography>
                                                                            <Typography className="value">
                                                                                {
                                                                                    orgKeyPerson
                                                                                        .document
                                                                                        .verification_type
                                                                                        .name
                                                                                }
                                                                            </Typography>
                                                                        </BoxTable>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        sm={12}
                                                                    >
                                                                        <BoxTable>
                                                                            <Typography className="title">
                                                                                Verification
                                                                                number
                                                                            </Typography>
                                                                            <Typography className="value">
                                                                                {
                                                                                    orgKeyPerson
                                                                                        .document
                                                                                        .verification_number
                                                                                }
                                                                            </Typography>
                                                                        </BoxTable>
                                                                    </Grid>
                                                                </>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <ButtonCon>
                                            <center>
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<Preview />}
                                                    color="info"
                                                    size="small"
                                                    sx={{
                                                        m: 2,
                                                    }}
                                                    onClick={() =>
                                                        handleDocsPreview()
                                                    }
                                                >
                                                    Preview document
                                                </Button>
                                                {orgKeyPerson.document
                                                    .verification_status ===
                                                'on_progress' ? (
                                                    <>
                                                        <Button
                                                            variant="outlined"
                                                            startIcon={
                                                                <DoneIcon />
                                                            }
                                                            color="success"
                                                            size="small"
                                                            sx={{
                                                                m: 2,
                                                            }}
                                                            onClick={() => {
                                                                const type =
                                                                    'approve_kp_docs';
                                                                handleConfimationDialogOpen(
                                                                    type,
                                                                );
                                                            }}
                                                        >
                                                            Approve document
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            startIcon={
                                                                <DoDisturb />
                                                            }
                                                            color="warning"
                                                            size="small"
                                                            sx={{
                                                                m: 2,
                                                            }}
                                                            onClick={() => {
                                                                const type =
                                                                    'deny_kp_docs';
                                                                handleConfimationDialogOpen(
                                                                    type,
                                                                );
                                                            }}
                                                        >
                                                            Deny document
                                                        </Button>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </center>
                                        </ButtonCon>
                                    </Grid>
                                </Grid>
                                <Grid item md={4}>
                                    {orgKeyPerson.document
                                        .verification_status === 'pending' ? (
                                        <>
                                            <DocsStatus className="warning-message">
                                                <Typography className="text">
                                                    <PendingIcon className="icon" />
                                                    Wait for submission
                                                </Typography>
                                            </DocsStatus>
                                        </>
                                    ) : orgKeyPerson.document
                                          .verification_status ===
                                      'on_progress' ? (
                                        <>
                                            <DocsStatus className="info-message">
                                                <Typography className="text">
                                                    <CachedIcon className="icon" />
                                                    Verification requested
                                                </Typography>
                                            </DocsStatus>
                                        </>
                                    ) : orgKeyPerson.document
                                          .verification_status ===
                                      'verified' ? (
                                        <>
                                            <DocsStatus className="success-message">
                                                <Typography className="text">
                                                    <VerifiedIcon className="icon" />
                                                    Document verified
                                                </Typography>
                                            </DocsStatus>
                                        </>
                                    ) : (
                                        <>
                                            <DocsStatus className="error-message">
                                                <Typography className="text">
                                                    <DoDisturb className="icon" />
                                                    Document denied
                                                </Typography>
                                                <Tooltip
                                                    title={
                                                        <Typography>
                                                            {
                                                                orgKeyPerson
                                                                    .document
                                                                    ?.deny_reason
                                                            }
                                                        </Typography>
                                                    }
                                                    arrow
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        color="error"
                                                        sx={{
                                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                        }}
                                                    >
                                                        <QuestionMark />
                                                    </IconButton>
                                                </Tooltip>
                                            </DocsStatus>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <NoContent
                                height={180}
                                message={'Organization key person do not added'}
                            />
                        </>
                    )}
                </Grid>
            </CustomCard>
        </>
    );
};

export default ORGKeyPerson;
