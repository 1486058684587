import React, { useEffect } from 'react';
import { Box, Button, List, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';
import CustomCard2 from '../cards/CustomCard2';
import PromotionCustomerWidget from '../widgets/PromotionCustomerWidget';
import PromoCustomerFilter from '../filters/PromoCustomerFilter';
import { Add } from '@mui/icons-material';
import CustomDialog from '../Dialogies/CustomDialog';
import AddPromotionCustomer from '../../pages/promotions/CRUD/AddPromotionCustomer';
import { promotionCustomersSelector } from '../../states/features/selectors';
import { useSelector } from 'react-redux';

const PromotionCustomerSidebar = () => {
    // ############## Comp state ###################
    const [open, setOpen] = React.useState(false);
    const [filteredCustomers, setFilteredCustomers] = React.useState([]);

    // ############## Redux state ###################
    const { customers } = useSelector(promotionCustomersSelector);

    // ############## FUNC ###################
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    // => Customer filter =============
    const customerFilter = (query) => {
        let currentCustomer = customers;
        if (query) {
            currentCustomer = currentCustomer.filter((data) => {
                return data.name.toLowerCase().includes(query.toLowerCase());
            });
        }
        return setFilteredCustomers(currentCustomer);
    };

    // ############### useEffect ###############
    // => set settings
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setFilteredCustomers(customers);
        }
        return () => {
            isSubscribed = false;
        };
    }, [customers, setFilteredCustomers]);

    return (
        <>
            {/* ############## Dialogy ############## */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`Add promotion customer`}
            >
                <AddPromotionCustomer handleClose={handleClose} />
            </CustomDialog>
            <CustomCard2
                head={'Promotions customers'}
                height={420}
                borderBottom
            >
                <Box
                    sx={{
                        position: 'sticky',
                        top: -15,
                        zIndex: 50,
                        p: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        mt: -15,
                    }}
                >
                    <PromoCustomerFilter handleFilter={customerFilter} />
                </Box>
                <List component="nav" sx={{ py: 0, mt: 15 }}>
                    {filteredCustomers.map((customer) => (
                        <NavLink
                            to={`/promotions/customers/${customer.id}`}
                            key={customer.id}
                        >
                            {({ isActive }) => (
                                <>
                                    <PromotionCustomerWidget
                                        isActive={isActive}
                                        customer={customer}
                                    />
                                </>
                            )}
                        </NavLink>
                    ))}
                </List>
            </CustomCard2>
            <Box sx={{ mt: 2 }}>
                <Button
                    fullWidth
                    sx={{
                        height: 80,
                        borderRadius: `50px !important`,
                        border: `2px dotted ${colors.bgColor5}`,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                    }}
                    onClick={() => handleClickOpen()}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Add
                            color="info"
                            sx={{
                                p: 0.2,
                                fontSize: 30,
                                borderRadius: 50,
                                border: `2px dotted ${colors.info}`,
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                opacity: 0.8,
                            }}
                        >
                            Add new customer
                        </Typography>
                    </Box>
                </Button>
            </Box>
        </>
    );
};

export default PromotionCustomerSidebar;
