import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { sectorsEndpoints } from './settingEndpoints';
import { settingAPI } from './settingAPI';

export const sectorsAPI = createApi({
    reducerPath: 'sectorsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getSectors: builder.query({
            query: () => `${sectorsEndpoints.GET_SECTORS}`,
        }),
        // => ADD
        addSector: builder.mutation({
            query: (payload) => ({
                url: `${sectorsEndpoints.ADD_SECTOR}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
        // => UPDATE
        updateSector: builder.mutation({
            query: (payload) => ({
                url: `${sectorsEndpoints.UPDATE_SECTOR}/` + payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateSector: builder.mutation({
            query: (payload) => ({
                url: `${sectorsEndpoints.ACTIVATE_SECTOR}/` + payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateSector: builder.mutation({
            query: (payload) => ({
                url: `${sectorsEndpoints.DEACTIVATE_SECTOR}/` + payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteSector: builder.mutation({
            query: (payload) => ({
                url: `${sectorsEndpoints.DELETE_SECTOR}/` + payload,
                method: `DELETE`,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
    }),
});

export const {
    useGetSectorsQuery,
    useAddSectorMutation,
    useUpdateSectorMutation,
    useActivateSectorMutation,
    useDeactivateSectorMutation,
    useDeleteSectorMutation,
} = sectorsAPI;
