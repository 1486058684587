import { useSelector } from "react-redux";
import { jobsSelector } from "../../../states/features/selectors";
import { filter, isEmpty } from "lodash";
import moment from "moment";

export const useORGJobReportFilter = () => {
    const { organizationJobs: allJobs } = useSelector(jobsSelector);
    const filterJob = (payload) => {
        let filteredJobs = allJobs;
        let job_status = payload?.job_status;
        let dateFrom = payload?.from;
        let dateTo = payload?.to;

        // => Filter by date
        var startDate = moment(dateFrom).format("YYYY-MM-DD");
        var endDate = moment(dateTo).format("YYYY-MM-DD");
        if (!isEmpty(dateFrom) || !isEmpty(dateTo)) {
            filteredJobs = filter(
                filteredJobs,
                ({ created_at }) =>
                    moment(created_at).format("YYYY-MM-DD") >= startDate &&
                    moment(created_at).format("YYYY-MM-DD") <= endDate
            );
        }

        // => Filter by status
        if (!isEmpty(job_status)) {
            filteredJobs = filter(
                filteredJobs,
                ({ status }) => status === job_status
            );
        }

        // => Return result
        if (!isEmpty(dateFrom) || !isEmpty(dateTo) || !isEmpty(job_status)) {
            return filteredJobs;
        } else {
            return allJobs;
        }
    };

    return {
        filterJob,
    };
};
