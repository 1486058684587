import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { ErrorMessage, useField } from "formik";
import React from "react";

const TextArea = styled(TextField)({});

const CustomTextArea = ({ label, type, maxChar, rows, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <>
            <Box
                sx={{ marginBottom: 2, borderRadius: 1.5, overflow: "hidden" }}
            >
                <TextArea
                    error={meta.touched && meta.error ? true : false}
                    label={label}
                    variant="filled"
                    fullWidth
                    type={type}
                    placeholder={field.label}
                    {...field}
                    {...props}
                    size={"small"}
                    multiline
                    rows={rows ? rows : 4}
                    focused={type === "date" || type === "time" ? true : false}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
                {maxChar && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                position: "relative",
                                fontSize: 12,
                                fontWeight: "bold",
                            }}
                        >
                            Maxmum {maxChar} characters
                        </Typography>
                        <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                            {field.value.length}/{maxChar} characters
                        </Typography>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default CustomTextArea;
