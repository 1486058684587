import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
// -> Payment method logo import
import mpesa from '../../assets/media/images/mpesa.png';
import tigopesa from '../../assets/media/images/tigopesa.png';
import halopesa from '../../assets/media/images/halopesa.png';
import azampesa from '../../assets/media/images/azampesa.png';
import airtel from '../../assets/media/images/airtel.png';
import { Payments } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import Loader from 'react-js-loader';
import useRTK from '../../hooks/useRTK';
import { useParams } from 'react-router-dom';
import { usePayPromotionSubscriptionMutation } from '../../api/promotions/promotionsAPI';
import CustomCard2 from '../cards/CustomCard2';
import PromotionSubPackageWidget from '../widgets/PromotionSubPackageWidget';
import { setPromotionDetails } from '../../states/features/promotions/promotionsSlice';

const CheckOut = ({
    checkoutDetails,
    promotion,
    handleDialogClose,
    handleDialogClose2,
}) => {
    // ############### CONST #######################
    const RTK = useRTK();
    const { promotion_id } = useParams();
    const dispatch = useDispatch();

    // ############ Redux state #################
    const currentSubscription = promotion?.currSubscription;

    // #################### RTK #####################
    const [
        paySubscriptionFee,
        {
            isLoading: payIsLoading,
            isSuccess: payIsSuccess,
            isError: payIsError,
            error: payError,
            data: payData,
        },
    ] = usePayPromotionSubscriptionMutation();

    // => RTK action
    const rtkActions = (data) => {
        handleDialogClose();
        handleDialogClose2();
        dispatch(setPromotionDetails(data));
    };

    // => RTK Response
    RTK.useRTKResponse(payIsSuccess, payIsError, payError, payData, rtkActions);

    // ############### FUNC ##################
    // => Save and pay
    const handleSubscriptionPayment = () => {
        let payload = {
            promotion_id: promotion.id,
            phone_number: checkoutDetails.phone_number,
            provider: checkoutDetails.selectedProvider,
        };
        paySubscriptionFee(payload);
    };

    return (
        <>
            <Box sx={{}}>
                <Grid container justifyContent={'center'} spacing={4}>
                    <Grid item sm={10}>
                        <Box>
                            <CustomCard2 head={'Subscribed package'}>
                                <PromotionSubPackageWidget
                                    subPackage={currentSubscription}
                                />
                            </CustomCard2>
                        </Box>
                    </Grid>
                    <Grid item sm={10}>
                        <Box
                            sx={{
                                height: 50,
                                border: `1px solid ${colors.bgColor5}`,
                                p: 2,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderRadius: 1,
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                    }}
                                >
                                    Payment method
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                        color: colors.info,
                                    }}
                                >
                                    Mobile payment
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                border: `1px solid ${colors.bgColor5}`,
                                p: 2,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderRadius: 1,
                                my: 1,
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                    }}
                                >
                                    Mobile type
                                </Typography>
                            </Box>
                            <Box>
                                <Box>
                                    <img
                                        src={`${
                                            checkoutDetails.selectedMethod ===
                                            'Mpesa'
                                                ? mpesa
                                                : checkoutDetails.selectedMethod ===
                                                  'tigo'
                                                ? tigopesa
                                                : checkoutDetails.selectedMethod ===
                                                  'airtel'
                                                ? airtel
                                                : checkoutDetails.selectedMethod ===
                                                  'halotel'
                                                ? halopesa
                                                : checkoutDetails.selectedMethod ===
                                                  'azam'
                                                ? azampesa
                                                : ''
                                        }?w=164&h=164&fit=crop&auto=format`}
                                        srcSet={`${
                                            checkoutDetails.selectedMethod ===
                                            'Mpesa'
                                                ? mpesa
                                                : checkoutDetails.selectedMethod ===
                                                  'tigo'
                                                ? tigopesa
                                                : checkoutDetails.selectedMethod ===
                                                  'airtel'
                                                ? airtel
                                                : checkoutDetails.selectedMethod ===
                                                  'halotel'
                                                ? halopesa
                                                : checkoutDetails.selectedMethod ===
                                                  'azam'
                                                ? azampesa
                                                : ''
                                        }?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        alt={checkoutDetails.selectedMethod}
                                        loading="lazy"
                                        style={{
                                            objectFit: 'contain',
                                            width: 120,
                                            borderRadius: 10,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                height: 50,
                                border: `1px solid ${colors.bgColor5}`,
                                p: 2,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderRadius: 1,
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                    }}
                                >
                                    Phone number
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                        color: colors.info,
                                    }}
                                >
                                    {checkoutDetails.phone_number}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={10}>
                        <Box
                            sx={{
                                position: 'relative',
                                textAlign: 'center',
                                mt: 0,
                            }}
                        >
                            <Button
                                variant="contained"
                                fullWidth
                                color="secondary"
                                sx={{
                                    py: 1.5,
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    textTransform: 'uppercase !important',
                                }}
                                startIcon={<Payments />}
                                onClick={() => {
                                    handleSubscriptionPayment();
                                }}
                                disabled={payIsLoading ? true : false}
                            >
                                Make payment
                                {payIsLoading && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            left: 0,
                                            right: 0,
                                        }}
                                    >
                                        <Loader
                                            type="bubble-loop"
                                            bgColor={colors.info}
                                            color={colors.info}
                                            size={50}
                                        />
                                    </Box>
                                )}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default CheckOut;
