import React from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { WorkHistoryOutlined } from '@mui/icons-material';
import { practicalTrainingsSelector } from '../../states/features/selectors';
import { useSelector } from 'react-redux';
import { filter, size } from 'lodash';

const PTStatsWidget = () => {
    // ############### Redux state ##################
    const { practicalTrainings } = useSelector(practicalTrainingsSelector);

    // ############### FUNC #####################
    const getPTByORG = () => {
        let institutionPTs = filter(
            practicalTrainings,
            ({ organization }) =>
                organization.organization_type.name === 'Institution',
        );
        let companyPTs = filter(
            practicalTrainings,
            ({ organization }) => organization.organization_type.name === 'Company',
        );
        return {
            institutionPTs,
            companyPTs,
        };
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'space-around',
                    px: 1.5,
                    borderRadius: 2,
                    border: `1px solid ${colors.bgColor5}`,
                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
                }}
            >
                <Box
                    sx={{
                        height: 150,
                        width: 150,
                        borderRadius: 50,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        border: `5px solid ${colors.bgColor4}`,
                        boxShadow: `0px 10px 15px ${colors.bgColor5}`,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    }}
                >
                    <WorkHistoryOutlined
                        sx={{ opacity: 0.8 }}
                        color="warning"
                    />
                    <Typography
                        sx={{
                            fontSize: 30,
                            fontWeight: 'bold',
                            opacity: 0.7,
                            color: colors.info,
                        }}
                    >
                        {size(practicalTrainings)}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 15,
                            fontWeight: 'bold',
                            opacity: 0.7,
                            fontStyle: 'italic',
                        }}
                    >
                        Posted PT's
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 23,
                            color: colors.info,
                            borderBottom: `3px solid ${colors.bgColor5}`,
                            borderRadius: 3,
                            px: 2,
                        }}
                    >
                        {size(getPTByORG().institutionPTs)}
                        <sup
                            style={{
                                color: colors.warning,
                                fontSize: 14,
                                fontWeight: 'bold',
                            }}
                        >
                            Institution
                        </sup>
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 23,
                            color: colors.info,
                            borderBottom: `3px solid ${colors.bgColor5}`,
                            borderRadius: 3,
                            px: 2,
                        }}
                    >
                        {size(getPTByORG().companyPTs)}
                        <sup
                            style={{
                                color: colors.warning,
                                fontSize: 14,
                                fontWeight: 'bold',
                            }}
                        >
                            Company
                        </sup>
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default PTStatsWidget;
