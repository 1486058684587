import React from "react";
import { Badge, Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { filter } from "lodash";
import { size } from "lodash";
import { shopsSelector } from "../../../states/features/selectors";
import { colors } from "../../../assets/utils/constants";
import { Link } from "react-router-dom";

const ShopStatusesInfoboxes = () => {
    // ############### REDUCER STATE #################
    const { shops } = useSelector(shopsSelector);
    // => Shops by status
    const getShopsByStatus = () => {
        // => Drafted
        let draftedShops = filter(shops, ({ status }) => status === "drafted");

        // => Verified
        let verifiedShops = filter(shops, ({ isVerified }) => isVerified === 1);
        // => On-subscription
        let onSubscriptionShops = filter(
            shops,
            ({ isSubscribed }) => isSubscribed === 1
        );
        // => Requested
        let requestedShops = filter(
            shops,
            ({ status }) => status === "pending"
        );
        // => Suspended
        let suspendedShops = filter(
            shops,
            ({ status }) => status === "suspended"
        );
        // => Denied
        let deniedShops = filter(shops, ({ status }) => status === "denied");
        return {
            draftedShops,
            verifiedShops,
            onSubscriptionShops,
            requestedShops,
            suspendedShops,
            deniedShops,
        };
    };
    const shopsByStatus = getShopsByStatus();
    const regShopStats = () => {
        const stats = [
            {
                id: 1,
                label: "Drafted",
                title2: "",
                total: size(shopsByStatus.draftedShops),
                url: "status/drafted",
                color: colors.secondary,
            },
            {
                id: 1,
                label: "On-subscription",
                title2: "",
                total: size(shopsByStatus.onSubscriptionShops),
                url: "status/on-subscription",
                color: colors.primary,
            },
            {
                id: 2,
                label: "Ver-request",
                title2: "",
                total: size(shopsByStatus.requestedShops),
                color: colors.info,
                url: "status/requested",
            },
            {
                id: 3,
                label: "Suspended",
                title2: "",
                total: size(shopsByStatus.suspendedShops),
                color: colors.warning,
                url: "status/suspended",
            },
            {
                id: 4,
                label: "Denied request",
                title2: "",
                total: size(shopsByStatus.deniedShops),
                color: "red",
                url: "status/denied",
            },
        ];

        return stats;
    };
    const stats = regShopStats(shops);

    return (
        <>
            <Box
                sx={{
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    padding: 2,
                    borderBottom: `15px solid ${colors.secondary}`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                }}
            >
                <Grid container spacing={2}>
                    {stats?.map((stat, index) => (
                        <Grid item xs key={index}>
                            <Link to={stat.url}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        border: `1px solid ${colors.bgColor5}`,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        paddingLeft: 1,
                                        paddingRight: 3,
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                        borderRadius: 2,
                                        color: colors.primary,
                                        alignItems: "center",
                                        transition: `.3s all ease-in-out`,
                                        borderLeft: `5px solid ${stat.color}`,
                                        ":hover": {
                                            transition: `.3s all ease-in-out`,
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            color: colors.primary,
                                            opacity: 0.8,
                                            width: "90%",
                                        }}
                                        noWrap
                                    >
                                        {stat.label}
                                    </Typography>
                                    <Typography>
                                        <Badge
                                            badgeContent={stat.total}
                                            showZero
                                            color="primary"
                                            sx={{ opacity: 0.8 }}
                                        />
                                    </Typography>
                                </Box>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default ShopStatusesInfoboxes;
