import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    beneficiaries: [],
    beneficiaryDetails: {},
};
const beneficiarySlice = createSlice({
    name: 'beneficiarySlice',
    initialState,
    reducers: {
        // => Set beneficiaries
        setBeneficiaries: (state, action) => {
            state.beneficiaries = action.payload;
        },

        // => Set beneficiar details
        setBeneficiaryDetails: (state, action) => {
            state.beneficiaryDetails = action.payload;
        },
    },
});

export const {
    setBeneficiaries,
    setBeneficiaryDetails,
} = beneficiarySlice.actions;
export default beneficiarySlice.reducer;
