// GET all organization
const GET_ALL_ORG = '/organizations';

// GET organization verification req
const GET_ORG_VER_REQ = '/organizations/requests';

// GET organization profile
const GET_ORG_PROFILE = '/organizations';

// GET organization jobs
const GET_ORG_JOBS = '/jobs/organizations';

// Approve org docs
const APPROVE_ORG_DOCS = '/approvals/approve-org-document';

// Deny org docs
const DENY_ORG_DOCS = '/approvals/deny-org-document';

// Approve key person docs
const APPROVE_KEY_PERSON_DOCS = '/approvals/approve-key-person';

// Deny key person docs
const DENY_KEY_PERSON_DOCS = '/approvals/deny-key-person';

// Approve organization
const APPROVE_ORGANIZATION = '/approvals/approve-org';

export const organizationEndpoints = {
    GET_ALL_ORG,
    GET_ORG_VER_REQ,
    GET_ORG_PROFILE,
    GET_ORG_JOBS,
    APPROVE_ORG_DOCS,
    DENY_ORG_DOCS,
    APPROVE_KEY_PERSON_DOCS,
    DENY_KEY_PERSON_DOCS,
    APPROVE_ORGANIZATION,
};
