import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ErrorMessage, useField } from 'formik';
import { colors } from '../../assets/utils/constants';

const CustomInput = styled(TextField)({
    padding: 0,
});

const CustomFileInput = ({
    formik,
    setFilePreview,
    setFile,
    label,
    type,
    accept,
    size,
    optional,
    ...props
}) => {
    const handleFileInputChange = (e) => {
        setFilePreview(URL.createObjectURL(e.target.files[0]));
        formik.setFieldValue('file', e.currentTarget.files[0]);
    };
    const [field, meta] = useField(props);
    return (
        <>
            <Box
                sx={{
                    marginTop: 2,
                    borderRadius: 1.5,
                    overflow: 'hidden',
                    border: `2px solid ${colors.bgColor4}`,
                    position: 'relative',
                }}
            >
                {optional && (
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            opacity: 0.8,
                            position: 'absolute',
                            textAlign: 'right',
                            left: 0,
                            top: 0,
                            right: 10,
                        }}
                    >
                        <span className="error-text">**optional**</span>
                    </Typography>
                )}
                <CustomInput
                    error={meta.touched && meta.error ? true : false}
                    label={label}
                    size={size ? size : 'medium'}
                    variant="outlined"
                    fullWidth
                    type={type}
                    placeholder={field.label}
                    onChange={handleFileInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{ accept: accept }}
                    sx={{
                        '& .MuiFormLabel-root': {
                            color: colors.secondary,
                        },
                    }}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomFileInput;
