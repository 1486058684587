import { useSelector } from "react-redux";
import { studentsSelector } from "../../../states/features/selectors";
import { filter, isEmpty } from "lodash";
import moment from "moment";

export const useStudentReportFilter = () => {
    const { students } = useSelector(studentsSelector);
    const filterStudent = (payload) => {
        let filteredStudents = students;
        let student_status = payload?.student_status;
        let dateFrom = payload?.from;
        let dateTo = payload?.to;

        // => Filter by date
        var startDate = moment(dateFrom).format("YYYY-MM-DD");
        var endDate = moment(dateTo).format("YYYY-MM-DD");
        if (!isEmpty(dateFrom) || !isEmpty(dateTo)) {
            filteredStudents = filter(
                students,
                ({ created_at }) =>
                    moment(created_at).format("YYYY-MM-DD") >= startDate &&
                    moment(created_at).format("YYYY-MM-DD") <= endDate
            );
        }

        // => Filter by status
        if (!isEmpty(student_status)) {
            filteredStudents = filter(
                filteredStudents,
                ({ status }) => status === student_status
            );
        }

        // => Return result
        if (
            !isEmpty(dateFrom) ||
            !isEmpty(dateTo) ||
            !isEmpty(student_status)
        ) {
            return filteredStudents;
        } else {
            return students;
        }
    };

    return {
        filterStudent,
    };
};
