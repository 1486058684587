import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { practicalTrainingEndpoints } from './practicalTrainingEndpoints';

export const practicalTrainingsAPI = createApi({
    reducerPath: 'practicalTrainingsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Trainings', 'Training', 'Applicants'],
    endpoints: (builder) => ({
        // => GET all
        getAllTrainings: builder.query({
            query: () => `${practicalTrainingEndpoints.GET_ALL_TRAININGS}`,
            providesTags: ['Trainings'],
        }),

        // => GET sigle
        getSingleTraining: builder.query({
            query: (payload) =>
                `${practicalTrainingEndpoints.GET_SINGLE_TRAINING}/${payload}`,
            providesTags: ['Training'],
        }),

        // => GET applicants
        getTrainingApplicants: builder.query({
            query: (payload) =>
                `${practicalTrainingEndpoints.GET_TRAINING_APPLICANTS}/${payload}`,
            providesTags: ['Applicants'],
        }),

        // => SUSPEND training
        suspendTraining: builder.mutation({
            query: (payload) => ({
                url: `${practicalTrainingEndpoints.SUSPEND_TRAINING}/${payload}`,
                method: 'PUT',
            }),
            invalidatesTags: (args, error) =>
                error ? [] : ['Trainings', 'Training'],
        }),

        // => RESUME training
        resumeTraining: builder.mutation({
            query: (payload) => ({
                url: `${practicalTrainingEndpoints.RESUME_TRAINING}/${payload}`,
                method: 'PUT',
            }),
            invalidatesTags: (args, error) =>
                error ? [] : ['Trainings', 'Training'],
        }),
    }),
});

export const {
    useGetAllTrainingsQuery,
    useGetSingleTrainingQuery,
    useGetTrainingApplicantsQuery,
    useSuspendTrainingMutation,
    useResumeTrainingMutation,

    // => Lazy
    useLazyGetAllTrainingsQuery,
    useLazyGetTrainingApplicantsQuery,
} = practicalTrainingsAPI;
