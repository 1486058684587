import { createSlice } from "@reduxjs/toolkit";
import { removeArrObj } from "../../../helpers/arrayHelpers";
import { isEmpty } from "lodash";

const initialState = {
    admins: [],
    adminDetails: {},
};
const adminsSlice = createSlice({
    name: "adminsSlice",
    initialState,
    reducers: {
        // => Set Admins
        setAdmins: (state, action) => {
            state.admins = action.payload;
        },

        // => Set Admin details
        setAdminDetails: (state, action) => {
            state.adminDetails = action.payload;
        },
        addAdmin: (state, actions) => {
            let admin = actions.payload;
            isEmpty(state.admins)
                ? (state.admins = [admin, ...[]])
                : (state.admins = [admin, ...state.admins]);
        },
        updateAdminDetails: (state, actions) => {
            let newadmin = actions.payload;
            state.adminDetails = newadmin;
        },
        removeAdmin: (state, actions) => {
            let payload = actions.payload;
            let admins = removeArrObj("id", payload, state.admins);
            state.admins = admins;
        },
    },
});

export const {
    setAdmins,
    setAdminDetails,
    addAdmin,
    updateAdminDetails,
    removeAdmin,
} = adminsSlice.actions;
export default adminsSlice.reducer;
