// => GET all
const GET_ALL_ADMINS = "/admins";

// => GET single
const GET_SINGLE_ADMIN = "/admins";

// => CREATE admin
const CREATE_ADMIN = "/admins";

// => UPDATE admin
const UPDATE_ADMIN = "/admins";

// => ACTIVATE admin
const ACTIVATE_ADMIN = "/admins/activate";

// => DEACTIVATE admin
const DEACTIVATE_ADMIN = "/admins/deactivate";

// => SUSPEND admin
const SUSPEND_ADMIN = "/admins/suspend";

// => DELETE admin
const DELETE_ADMIN = "/admins";

export const adminsEndpoints = {
    GET_ALL_ADMINS,
    GET_SINGLE_ADMIN,
    CREATE_ADMIN,
    UPDATE_ADMIN,
    ACTIVATE_ADMIN,
    DEACTIVATE_ADMIN,
    SUSPEND_ADMIN,
    DELETE_ADMIN,
};
