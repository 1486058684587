import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { userStatusEndpoints } from './settingEndpoints';
import { settingAPI } from './settingAPI';

export const userStatusAPI = createApi({
    reducerPath: 'userStatusAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getUserStatus: builder.query({
            query: () => `${userStatusEndpoints.GET_USER_STATUS}`,
        }),
        // => ADD
        addUserStatus: builder.mutation({
            query: (payload) => ({
                url: `${userStatusEndpoints.ADD_USER_STATUS}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
        // => UPDATE
        updateUserStatus: builder.mutation({
            query: (payload) => ({
                url: `${userStatusEndpoints.UPDATE_USER_STATUS}/` + payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateUserStatus: builder.mutation({
            query: (payload) => ({
                url: `${userStatusEndpoints.ACTIVATE_USER_STATUS}/` + payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateUserStatus: builder.mutation({
            query: (payload) => ({
                url: `${userStatusEndpoints.DEACTIVATE_USER_STATUS}/` + payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteUserStatus: builder.mutation({
            query: (payload) => ({
                url: `${userStatusEndpoints.DELETE_USER_STATUS}/` + payload,
                method: `DELETE`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
    }),
});

export const {
    useGetUserStatusQuery,
    useAddUserStatusMutation,
    useUpdateUserStatusMutation,
    useActivateUserStatusMutation,
    useDeactivateUserStatusMutation,
    useDeleteUserStatusMutation,
} = userStatusAPI;
