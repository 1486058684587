import React from "react";
import { DriveFolderUpload, LocalPhone, Mail } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";
import { Link } from "react-router-dom";
import { colors } from "../../assets/utils/constants";
import dp from "../../assets/media/images/user.jpg";
import CustomDialog from "../../components/Dialogies/CustomDialog";
import UpdateProfilePicture from "./CRUD/UpdateProfilePicture";

const InfoListCard = styled(List)({
    padding: 0,
    overflow: "hidden",
    "& .MuiListItemButton-root": {
        paddingLeft: 24,
        paddingRight: 24,
        background: colors.primary,
        "& span": {
            color: colors.gray,
        },
    },
    "& .list-item": {
        background: colors.primary,
        color: colors.gray,
        " p": {
            color: colors.gray,
        },
    },
});

const LeftbarDetails = ({ user }) => {
    // ################# Comp state ###################
    const [open, setOpen] = React.useState(false);

    // => handle dialogy open
    const handleClickOpen = () => {
        setOpen(true);
    };
    // => handle dialogy close
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {/* ################## Update profile Dialogy ################ */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={"Update profile picture"}
            >
                <UpdateProfilePicture
                    user={user}
                    handleDialogClose={handleClose}
                />
            </CustomDialog>

            <Grid container justifyContent={"center"}>
                <Grid
                    item
                    sm={7}
                    style={{
                        position: "absolute",
                        top: 200,
                        background: colors.secondary,
                        borderRadius: 10,
                    }}
                >
                    <img
                        src={user.profile_picture ? user.profile_picture : dp}
                        alt="dp"
                        style={{
                            height: "14em",
                            width: "12em",
                            borderRadius: 10,
                            objectFit: "cover",
                        }}
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            right: 5,
                            bottom: 5,
                        }}
                    >
                        <IconButton onClick={() => handleClickOpen()}>
                            {/* <Link href={""}> */}
                            <Tooltip title="Update">
                                <DriveFolderUpload
                                    sx={{
                                        color: colors.secondary,
                                        border: `2px solid ${colors.secondary}`,
                                        fontSize: 30,
                                        borderRadius: 50,
                                        padding: 0.5,
                                    }}
                                />
                            </Tooltip>
                            {/* </Link> */}
                        </IconButton>
                    </Box>
                </Grid>
                <Grid
                    item
                    sm={12}
                    style={{
                        position: "relative",
                        top: 100,
                        textAlign: "center",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: "bold",
                            fontSize: 20,
                            color: colors.gray,
                        }}
                    >
                        {user.registration_id}
                    </Typography>
                    <Typography
                        style={{
                            fontSize: 16,
                            color: colors.gray,
                        }}
                    >
                        {`${user.first_name} ${user.middle_name} ${user.last_name}`}
                    </Typography>
                    <Typography>
                        <span
                            style={{
                                fontSize: 10,
                                color: colors.primary,
                                background: colors.secondary,
                                borderRadius: 10,
                                paddingLeft: 10,
                                paddingRight: 10,
                                fontWeight: "bold",
                            }}
                        >
                            {user.role}
                        </span>
                    </Typography>
                    <Grid item sm={12}>
                        <InfoListCard
                            sx={{
                                marginTop: 4,
                                width: "100%",
                                bgcolor: "background.paper",
                            }}
                        >
                            <ListItemButton
                                component="a"
                                href="#customized-list"
                            >
                                <ListItemText
                                    sx={{ my: 0 }}
                                    primary="Contact infos"
                                    primaryTypographyProps={{
                                        fontSize: 20,
                                        fontWeight: "medium",
                                        letterSpacing: 0,
                                    }}
                                />
                            </ListItemButton>
                            <Divider />
                            <ListItem className="list-item">
                                <ListItemAvatar>
                                    <Avatar>
                                        <Mail />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${user.email}`}
                                    secondary="email"
                                />
                            </ListItem>
                            <ListItem className="list-item">
                                <ListItemAvatar>
                                    <Avatar>
                                        <LocalPhone />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${user.phone_number}`}
                                    secondary="Phone"
                                />
                            </ListItem>
                        </InfoListCard>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default LeftbarDetails;
