import React, { useEffect } from 'react';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { Edit } from '@mui/icons-material';
import CustomDialog from '../../components/Dialogies/CustomDialog';
import ContentLoader from '../../components/ContentLoader';
import InnerDataFetchingError from '../../components/InnerDataFetchingError';
import ActivateDeactivatePromotion from './CRUD/ActivateDeactivatePromotion';
import DeletePromotion from './CRUD/DeletePromotion';
import UpdatePromotion from './CRUD/UpdatePromotion';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useRTK from '../../hooks/useRTK';
import { promotionsSelector } from '../../states/features/selectors';
import {
    addCheckPaymentStatus,
    setPromotionDetails,
} from '../../states/features/promotions/promotionsSlice';
import { useGetPromotionQuery } from '../../api/promotions/promotionsAPI';
import { isEmpty } from 'lodash';
import strHelper from '../../helpers/strHelper';
import CurrentSubscription from './components/CurrentSubscription';
import PromoDescriptions from './components/PromoDescriptions';
import PromoActionButtons from './components/PromoActionButtons';
import PromotionCustomerWidget4 from '../../components/widgets/PromotionCustomerWidget4';
import PromotionSidebar from '../../components/sidebars/PromotionSidebar';

const PromotionDetails = () => {
    // ############# Const ##############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const { promo_id } = useParams();

    // ############# Comp State ############
    const [open, setOpen] = React.useState(false);
    const [dialogType, setDialogType] = React.useState(false);

    // ############# Redux State ############
    const { promotionDetails: promotion, checkPaymentStatus } = useSelector(
        promotionsSelector,
    );

    // ############# FUNC ###############
    const handleClickOpen = (type) => {
        setOpen(true);
        setDialogType(type);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // ############# RTK ##################
    // => promotion
    const {
        isLoading,
        isSuccess,
        isError,
        refetch,
        data: responseData,
    } = useGetPromotionQuery(promo_id);
    const rtkAction = (data) => {
        dispatch(setPromotionDetails(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkAction);

    // => Check payment status
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            let paymentStatus =
                promotion?.currSubscription?.transaction?.status;
            if (paymentStatus === 'pending') {
                setTimeout(() => {
                    refetch();
                    dispatch(addCheckPaymentStatus(checkPaymentStatus + 1));
                }, 5000);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [promotion, checkPaymentStatus]);

    return (
        <>
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={
                    dialogType === 'update'
                        ? `Update promotion`
                        : 'Receive promotion payment'
                }
            >
                {dialogType === 'update' ? (
                    <UpdatePromotion
                        promotion={promotion}
                        handleClose={handleClose}
                    />
                ) : (
                    ''
                )}
            </CustomDialog>

            <PageLayout
                head={'Promotion details'}
                sidebar={<PromotionSidebar />}
            >
                <CustomCard2
                    head={'Promotion details'}
                    height={'72vh'}
                    action={
                        !isEmpty(promotion) && (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <ActivateDeactivatePromotion
                                    promotion={promotion}
                                />
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="warning"
                                    startIcon={<Edit />}
                                    sx={{ mx: 2 }}
                                    onClick={() => {
                                        let type = 'update';
                                        handleClickOpen(type);
                                    }}
                                >
                                    Edit
                                </Button>
                                <DeletePromotion promotion={promotion} />
                            </Box>
                        )
                    }
                >
                    {isLoading ? (
                        <>
                            <CustomCard2 height={'65vh'}>
                                <ContentLoader />
                            </CustomCard2>
                        </>
                    ) : isError ? (
                        <>
                            <InnerDataFetchingError
                                height={500}
                                handleDataRefetch={refetch}
                            />
                        </>
                    ) : (
                        !isEmpty(promotion) && (
                            <Grid container spacing={4}>
                                <Grid item sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={6}>
                                            <Typography
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    fontSize: 20,
                                                    fontWeight: 'bold',
                                                    opacity: 0.7,
                                                    p: 1,
                                                    borderRadius: 1,
                                                    background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                                                    mb: 1,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {strHelper().strCapitalizeFirstChar(
                                                    promotion.title,
                                                )}
                                            </Typography>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        fontStyle: 'italic',
                                                        color: colors.info,
                                                    }}
                                                >
                                                    Promotion banner
                                                </Typography>
                                                <Avatar
                                                    src={promotion.banner_image}
                                                    variant="square"
                                                    sx={{
                                                        width: '100%',
                                                        height: '28vh',
                                                        borderRadius: 2,
                                                        boxShadow: `0px 2px 30px ${colors.bgColor5}`,
                                                        img: {
                                                            objectFit: 'cover',
                                                        },
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6}>
                                            {/* ############# Promo Description ############## */}
                                            <PromoDescriptions
                                                promotion={promotion}
                                            />

                                            {/* ############# Curr Subscription ############## */}
                                            <Box
                                                sx={{
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    mt: 2,
                                                    boxShadow: `0px 0px 10px 0px ${colors.bgColor5}`,
                                                }}
                                            >
                                                <CurrentSubscription
                                                    promotion={promotion}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item sm={6}>
                                            <Box>
                                                <CustomCard2
                                                    head={'Promotion customer'}
                                                >
                                                    <PromotionCustomerWidget4
                                                        customer={
                                                            promotion.customer
                                                        }
                                                    />
                                                </CustomCard2>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Box>
                                                <PromoActionButtons
                                                    promotion={promotion}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default PromotionDetails;
