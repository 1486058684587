import React from 'react';
import Loader from 'react-js-loader';
import { Grid } from '@mui/material';
import { colors } from '../assets/utils/constants';

const ContentLoader = ({ child, height, children }) => {
    return (
        <>
            <Grid container>
                <Grid
                    item
                    sm={12}
                    xs={12}
                    sx={{
                        height: height ? height : '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                    }}
                >
                    {child ? (
                        <>{children}</>
                    ) : (
                        <>
                            <Loader
                                type="bubble-ping"
                                bgColor={colors.primary}
                                color={colors.primary}
                                size={100}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default ContentLoader;
