import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    settings: [],
    isMainFetch: true,
    jobPostFramework: {},
    smsBalance: 0,
};

// !########## SLICE #############
const settingsSlice = createSlice({
    name: 'settingsSlice',
    initialState,
    reducers: {
        setSettings: (state, actions) => {
            state.settings = actions.payload;
        },
        setJobPostFramework: (state, actions) => {
            state.jobPostFramework = actions.payload;
        },
        // => Deactivate main fetch
        disableMainFetch: (state, actions) => {
            state.isMainFetch = false;
        },

        // => Set SMS balance
        setSmsBalance: (state, actions) => {
            state.smsBalance = actions.payload;
        },
    },
});

export const {
    setSettings,
    setJobPostFramework,
    disableMainFetch,
    setSmsBalance,
} = settingsSlice.actions;
export default settingsSlice.reducer;
