import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { promotionsEndpoints } from './promotionsEndpoints';
import { promotionsAPI } from './promotionsAPI';

export const promotionsCustomersAPI = createApi({
    reducerPath: 'promotionsCustomersAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Customers', 'Customer'],
    endpoints: (builder) => ({
        // => GET all
        getPromotionsCustomers: builder.query({
            query: () => `${promotionsEndpoints.GET_ALL_CUSTOMERS}`,
            providesTags: ['Customers'],
        }),
        // => GET one
        getPromotionsCustomer: builder.query({
            query: (payload) =>
                `${promotionsEndpoints.GET_ONE_CUSTOMER}/${payload}`,
            providesTags: ['Customer'],
        }),
        // => CREATE
        createPromotionsCustomer: builder.mutation({
            query: (payload) => ({
                url: `${promotionsEndpoints.CREATE_CUSTOMER}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ['Customers'],
        }),
        // => UPDATE
        updatePromotionsCustomer: builder.mutation({
            query: (payload) => ({
                url:
                    `${promotionsEndpoints.UPDATE_CUSTOMER}/` +
                    payload.get('customer_id'),
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ['Customers', 'Customer'],
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(promotionsAPI.util.invalidateTags(['Promotions']));
            },
        }),
        // => ACTIVATE
        activatePromotionsCustomer: builder.mutation({
            query: (payload) => ({
                url: `${promotionsEndpoints.ACTIVATE_CUSTOMER}/` + payload,
                method: `PUT`,
            }),
            invalidatesTags: ['Customers', 'Customer'],
        }),
        // => DEACTIVATE
        deactivatePromotionsCustomer: builder.mutation({
            query: (payload) => ({
                url: `${promotionsEndpoints.DEACTIVATE_CUSTOMER}/` + payload,
                method: `PUT`,
            }),
            invalidatesTags: ['Customers', 'Customer'],
        }),
        // => DELETE
        deletePromotionsCustomer: builder.mutation({
            query: (payload) => ({
                url: `${promotionsEndpoints.DELETE_CUSTOMER}/` + payload,
                method: `DELETE`,
            }),
            invalidatesTags: ['Customers', 'Customer'],
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(promotionsAPI.util.invalidateTags(['Promotions']));
            },
        }),
    }),
});

export const {
    useGetPromotionsCustomersQuery,
    useGetPromotionsCustomerQuery,
    useCreatePromotionsCustomerMutation,
    useUpdatePromotionsCustomerMutation,
    useActivatePromotionsCustomerMutation,
    useDeactivatePromotionsCustomerMutation,
    useDeletePromotionsCustomerMutation,

    // => Lazy
    useLazyGetPromotionsCustomersQuery
} = promotionsCustomersAPI;
