import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import CustomCard2 from "../../components/cards/CustomCard2";
import PageLayout from "../layouts/PageLayout";
import ReportButtonWidget from "../../components/widgets/ReportButtonWidget";
import ReportDialog from "../../components/Dialogies/ReportDialog";
import RevenueCard2 from "../../components/dash/RevenueCard2";
import { PDFViewer } from "@react-pdf/renderer";
import JobReportCRUD from "./CRUD/JobReportCRUD";
import ApplicantReport from "./reports/ApplicantReport";
import StudentReport from "./reports/StudentReport";
import TrainingReport from "./reports/TrainingReport";
import TransactionReport from "./reports/TransactionReport";
import { useORGJobReportFilter } from "./filters/ORGjobFilter";
import { useEmployerJobReportFilter } from "./filters/employerJobFilter";
import { useTransactionReportFilter } from "./filters/transactionFilter";
import { useApplicantReportFilter } from "./filters/applicantFilter";
import { useStudentReportFilter } from "./filters/studentFilter";
import { useTrainingReportFilter } from "./filters/trainingFilter";
import TransactionReportCRUD from "./CRUD/TransactionReportCRUD";
import TrainingReportCRUD from "./CRUD/TrainingReportCRUD";
import StudentReportCRUD from "./CRUD/StudentReportCRUD";
import ApplicantReportCRUD from "./CRUD/ApplicantReportCRUD";
import ORGJobReport from "./reports/ORGJobReport";
import EmployerJobReport from "./reports/EmployerJobReport";

const ReportAndAnalytics = () => {
    // ############### Comp State #################
    const [openDialog, setOpenDialog] = useState(false);
    const [actionHead, setActionHead] = useState("");
    const [actionType, setActionType] = useState("");
    const [jobsORGReportData, setJobsORGReportData] = useState([]);
    const [jobsEmployerReportData, setJobsEmployerReportData] = useState([]);
    const [trainingReportData, setTrainingReportData] = useState([]);
    const [studentReportData, setStudentReportData] = useState([]);
    const [applicantReportData, setApplicantReportData] = useState([]);
    const [transactionReportData, setTransactionReportData] = useState([]);

    // ############## FUNC #################
    // => Handle dialogy open
    const handleDialogOpen = (action, head) => {
        setActionType(action);
        setActionHead(head);
        setOpenDialog(true);
    };
    // => Handle dialogy close
    const handleDialogClose = () => {
        setActionHead("");
        setOpenDialog(false);
    };

    // ################### Report Data Filters ###################
    const filterORGJobs = useORGJobReportFilter();
    const filterEmployerJobs = useEmployerJobReportFilter();
    const filterTrainings = useTrainingReportFilter();
    const filterStudents = useStudentReportFilter();
    const filterApplicants = useApplicantReportFilter();
    const filterTransactions = useTransactionReportFilter();

    const handleORGJobReportDataFilter = (payload) => {
        const filteredJobs = filterORGJobs.filterJob(payload);
        setJobsORGReportData(filteredJobs);
    };
    const handleEmployerJobReportDataFilter = (payload) => {
        const filteredJobs = filterEmployerJobs.filterJob(payload);
        setJobsEmployerReportData(filteredJobs);
    };
    const handleTrainingReportDataFilter = (payload) => {
        const filteredTrainings = filterTrainings.filterTraining(payload);
        setTrainingReportData(filteredTrainings);
    };
    const handleStudentReportDataFilter = (payload) => {
        const filteredStudents = filterStudents.filterStudent(payload);
        setStudentReportData(filteredStudents);
    };
    const handleApplicantReportDataFilter = (payload) => {
        const filteredApplicants = filterApplicants.filterApplicant(payload);
        setApplicantReportData(filteredApplicants);
    };
    const handleTransactionReportDataFilter = (payload) => {
        const filteredTransactions =
            filterTransactions.filterTransaction(payload);
        setTransactionReportData(filteredTransactions);
    };

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            handleORGJobReportDataFilter([]);
            handleEmployerJobReportDataFilter([]);
            handleTrainingReportDataFilter([]);
            handleStudentReportDataFilter([]);
            handleApplicantReportDataFilter([]);
            handleTransactionReportDataFilter([]);
        }
        return () => {
            isSubscribed = false;
        };
    }, []);

    const reports = [
        {
            id: 1,
            title: "ORG Jobs",
            action: handleDialogOpen,
            actionType: "jobsORG",
        },
        {
            id: 2,
            title: "Employers Jobs",
            action: handleDialogOpen,
            actionType: "jobsEmployer",
        },
        {
            id: 3,
            title: "Trainings",
            action: handleDialogOpen,
            actionType: "trainings",
        },
        {
            id: 4,
            title: "Students",
            action: handleDialogOpen,
            actionType: "students",
        },
        {
            id: 5,
            title: "Applicants",
            action: handleDialogOpen,
            actionType: "applicants",
        },
        {
            id: 6,
            title: "Transactions",
            action: handleDialogOpen,
            actionType: "transactions",
        },
    ];

    return (
        <>
            {/* ############# DIALOG ############### */}
            <ReportDialog
                isConfirm
                noDropClose
                openDialog={openDialog}
                customWidth={"md"}
                handleDialogClose={handleDialogClose}
                head={actionHead}
            >
                <Box
                    style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <PDFViewer style={{ height: "100%", width: "60%" }}>
                        {actionType === "jobsORG" ? (
                            <>
                                <ORGJobReport jobs={jobsORGReportData} />
                            </>
                        ) : actionType === "jobsEmployer" ? (
                            <>
                                <EmployerJobReport jobs={jobsEmployerReportData} />
                            </>
                        ) : actionType === "applicants" ? (
                            <>
                                <ApplicantReport
                                    applicants={applicantReportData}
                                />
                            </>
                        ) : actionType === "students" ? (
                            <>
                                <StudentReport students={studentReportData} />
                            </>
                        ) : actionType === "trainings" ? (
                            <>
                                <TrainingReport
                                    trainings={trainingReportData}
                                />
                            </>
                        ) : actionType === "transactions" ? (
                            <>
                                <TransactionReport
                                    transactions={transactionReportData}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </PDFViewer>
                    <Box
                        style={{
                            height: "100%",
                            width: "40%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Grid
                            container
                            justifyContent={"center"}
                            sx={{ mt: 2 }}
                        >
                            <Grid item sm={11} md={11}>
                                <CustomCard2 head={actionHead}>
                                    {actionType === "jobsORG" ? (
                                        <>
                                            <JobReportCRUD
                                                handler={
                                                    handleORGJobReportDataFilter
                                                }
                                            />
                                        </>
                                    ) : actionType === "jobsEmployer" ? (
                                        <>
                                            <JobReportCRUD
                                                handler={
                                                    handleEmployerJobReportDataFilter
                                                }
                                            />
                                        </>
                                    ) : actionType === "applicants" ? (
                                        <>
                                            <ApplicantReportCRUD
                                                handler={
                                                    handleApplicantReportDataFilter
                                                }
                                            />
                                        </>
                                    ) : actionType === "students" ? (
                                        <>
                                            <StudentReportCRUD
                                                handler={
                                                    handleStudentReportDataFilter
                                                }
                                            />
                                        </>
                                    ) : actionType === "trainings" ? (
                                        <>
                                            <TrainingReportCRUD
                                                handler={
                                                    handleTrainingReportDataFilter
                                                }
                                            />
                                        </>
                                    ) : actionType === "transactions" ? (
                                        <>
                                            <TransactionReportCRUD
                                                handler={
                                                    handleTransactionReportDataFilter
                                                }
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </CustomCard2>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </ReportDialog>

            <PageLayout head={"Analysis and reports"} noSidebar>
                <Grid container spacing={2}>
                    <Grid item sm={8}>
                        <CustomCard2
                            head={"Finance revenue overview"}
                            borderBottom
                            action={
                                <Box sx={{ display: "flex" }}>
                                    {/* <RevenueFilter width={180} /> */}
                                </Box>
                            }
                        >
                            <RevenueCard2 />
                        </CustomCard2>
                    </Grid>
                    <Grid item sm={4}>
                        <CustomCard2 head={"Reports"}>
                            <Grid container spacing={2.5}>
                                {reports.map((report, index) => (
                                    <Grid item sm={6} key={index}>
                                        <ReportButtonWidget report={report} />
                                    </Grid>
                                ))}
                            </Grid>
                        </CustomCard2>
                    </Grid>
                </Grid>
                <Grid container mt={2}></Grid>
            </PageLayout>
        </>
    );
};

export default ReportAndAnalytics;
