import React from "react";
import { Routes, Route } from "react-router-dom";

// custom components imports
import AppLayout from "../pages/layouts/AppLayout";
import Companies from "../pages/organizations/companies/Companies";
import Institutions from "../pages/organizations/institutions/Institutions";
import Employers from "../pages/employers/Employers";
import Beneficiaries from "../pages/beneficiaries/Beneficiaries";
import Login from "../pages/auth/Login";
import ForgetPassword from "../pages/auth/ForgetPassword";
import Profile from "../pages/profile/Profile";
import GuestRoutes from "./GuestRoutes";
import PrivateRoutes from "./PrivateRoutes";
import ErrorPage404 from "../pages/errors/ErrorPage404";
import Dashboard from "../pages/Dashboard";
import OrgProfile from "../pages/organizations/profile/OrgProfile";
import {
    DepertmentsElement,
    DistrictsElement,
    EducationLevelElement,
    ExperienceLevelElement,
    GroupStatusElement,
    IdentificationTypesElement,
    JobCategoriesElement,
    JobPostPricingElement,
    OrganizationCategoryElement,
    OrganizationTypeElement,
    PaymentMethodsElement,
    QualificationAwardElement,
    RegionsElement,
    RelationTypesElement,
    SectionsElement,
    SectorsElement,
    SettingsElement,
    UserRolesElement,
    UserStatusElement,
    VerificationTypesElement,
} from "./SettingsElements";
import AuthLayout from "../pages/layouts/AuthLayout";
import CompaniesByStatus from "../pages/organizations/companies/CompaniesByStatus";
import InstitutionsByStatus from "../pages/organizations/institutions/InstitutionsByStatus";
import Promotions from "../pages/promotions/Promotions";
import Shops from "../pages/shops/Shops";
import Organizations from "../pages/organizations/Organizations";
import PromotionCustomerDetails from "../pages/promotions/PromotionCustomerDetails";
import PromotionCustomers from "../pages/promotions/PromotionCustomers";
import PromotionByStatus from "../pages/promotions/PromotionByStatus";
import PromotionDetails from "../pages/promotions/PromotionDetails";
import Blogs from "../pages/blogs/Blogs";
import BlogDetails from "../pages/blogs/BlogDetails";
import Faqs from "../pages/faqs/Faqs";
import NotificationDetails from "../pages/notifications/NotificationDetails";
import Notifications from "../pages/notifications/Notifications";
import Students from "../pages/students/Students";
import Jobs from "../pages/jobs/Jobs";
import PracticalTrainings from "../pages/practicalTrainings/PracticalTrainings";
import PracticalTrainingDetails from "../pages/practicalTrainings/PracticalTrainingDetails";
import StudentDetails from "../pages/students/StudentDetails";
import EmployerJobDetails from "../pages/jobs/employers/EmployerJobDetails";
import EmployerJobs from "../pages/jobs/employers/EmployerJobs";
import ORGJobDetails from "../pages/jobs/organizations/ORGJobDetails";
import OrganizationJobs from "../pages/jobs/organizations/OrganizationJobs";
import EmployerDetails from "../pages/employers/EmployerDetails";
import BeneficiaryDetails from "../pages/beneficiaries/BeneficiaryDetails";
import EmployerByStatus from "../pages/employers/EmployerByStatus";
import HelpCenter from "../pages/settings/helpCenter/HelpCenter";
import BeneficiaryByStatus from "../pages/beneficiaries/BeneficiaryByStatus";
import ShopDetails from "../pages/shops/ShopDetails";
import ShopByStatus from "../pages/shops/ShopByStatus";
import ReportAndAnalytics from "../pages/reportAndAnalytics/ReportAndAnalytics";
import SubscriptionPackages from "../pages/subscriptionPackages/SubscriptionPackages";
import Finance from "../pages/finance/Finance";
import LogsViwer from "../pages/logsViewer/LogsViwer";
import PracticalTrainingByStatus from "../pages/practicalTrainings/PracticalTrainingByStatus";
import Admins from "../pages/admins/Admins";
import AdminDetails from "../pages/admins/AdminDetails";
import AdminByStatus from "../pages/admins/AdminByStatus";
import EmployerJobsByStatus from "../pages/jobs/employers/EmployerJobsByStatus";
import OrganizationJobsByStstus from "../pages/jobs/organizations/OrganizationJobsByStstus";
import SubscriptionPackagesByStatus from "../pages/subscriptionPackages/SubscriptionPackagesByStatus";
import SubscriptionPackagesByType from "../pages/subscriptionPackages/SubscriptionPackagesByType";
import FreeTrialPackages from "../pages/subscriptionPackages/FreeTrialPackages";

// -> login
const LoginElement = () => (
    <AuthLayout>
        <Login />
    </AuthLayout>
);

// -> forget password
const ForgetElement = () => (
    <AuthLayout>
        <ForgetPassword />
    </AuthLayout>
);

// -> profile
const ProfileElement = () => (
    <AppLayout>
        <Profile />
    </AppLayout>
);

// -> dashboard
const DashboardElement = () => (
    <AppLayout>
        <Dashboard />
    </AppLayout>
);

// -> Organizations
const OrganizationsElement = () => (
    <AppLayout>
        <Organizations />
    </AppLayout>
);
// -> companies
const CompaniesElement = () => (
    <AppLayout>
        <Companies />
    </AppLayout>
);
const CompaniesByStatusElement = () => (
    <AppLayout>
        <CompaniesByStatus />
    </AppLayout>
);

// -> institutions
const InstitutionsElement = () => (
    <AppLayout>
        <Institutions />
    </AppLayout>
);
const InstitutionsByStatusElement = () => (
    <AppLayout>
        <InstitutionsByStatus />
    </AppLayout>
);

/**#############################################
 * Employers
 ##############################################*/
// -> employers
const EmployersElement = () => (
    <AppLayout>
        <Employers />
    </AppLayout>
);
// -> employers by status
const EmployersByStatusElement = () => (
    <AppLayout>
        <EmployerByStatus />
    </AppLayout>
);
// -> employer details
const EmployerDetailsElement = () => (
    <AppLayout>
        <EmployerDetails />
    </AppLayout>
);

/**#############################################
 * Beneficiaries
 ##############################################*/
// -> beneficiaries
const BeneficiariesElement = () => (
    <AppLayout>
        <Beneficiaries />
    </AppLayout>
);
// -> beneficiaries by status
const BeneficiaryByStatusElement = () => (
    <AppLayout>
        <BeneficiaryByStatus />
    </AppLayout>
);
// -> Beneficiaries details
const BeneficiaryDetailsElement = () => (
    <AppLayout>
        <BeneficiaryDetails />
    </AppLayout>
);

// -> Organization profile
const OrganizationDetailsElement = () => (
    <AppLayout>
        <OrgProfile />
    </AppLayout>
);

/**#############################################
 * Promotions
 ##############################################*/
const PromotionsElement = () => (
    <AppLayout>
        <Promotions />
    </AppLayout>
);
const PromotionDetailsElement = () => (
    <AppLayout>
        <PromotionDetails />
    </AppLayout>
);
const PromotionByStatusElement = () => (
    <AppLayout>
        <PromotionByStatus />
    </AppLayout>
);
const PromotionCustomersElement = () => (
    <AppLayout>
        <PromotionCustomers />
    </AppLayout>
);
const PromotionCustomerDetailsElement = () => (
    <AppLayout>
        <PromotionCustomerDetails />
    </AppLayout>
);

/**#############################################
 * Shops
 ##############################################*/
const ShopsElement = () => (
    <AppLayout>
        <Shops />
    </AppLayout>
);
const ShopsByStatusElement = () => (
    <AppLayout>
        <ShopByStatus />
    </AppLayout>
);
const ShopDetailsElement = () => (
    <AppLayout>
        <ShopDetails />
    </AppLayout>
);

/**#############################################
 * Students
 ##############################################*/
const StudentsElement = () => (
    <AppLayout>
        <Students />
    </AppLayout>
);
const StudentDetailsElement = () => (
    <AppLayout>
        <StudentDetails />
    </AppLayout>
);

/**#############################################
 * Admins
 ##############################################*/
const AdminsElement = () => (
    <AppLayout>
        <Admins />
    </AppLayout>
);
const AdminDetailsElement = () => (
    <AppLayout>
        <AdminDetails />
    </AppLayout>
);
const AdminByStatusElement = () => (
    <AppLayout>
        <AdminByStatus />
    </AppLayout>
);

/**#############################################
 * Jobs
 ##############################################*/
const JobsElement = () => (
    <AppLayout>
        <Jobs />
    </AppLayout>
);
const ORGJobsElement = () => (
    <AppLayout>
        <OrganizationJobs />
    </AppLayout>
);
const OrganizationJobsByStstusElement = () => (
    <AppLayout>
        <OrganizationJobsByStstus />
    </AppLayout>
);
const ORGJobDetailsElement = () => (
    <AppLayout>
        <ORGJobDetails />
    </AppLayout>
);
const EmployerJobsElement = () => (
    <AppLayout>
        <EmployerJobs />
    </AppLayout>
);
const EmployerJobsByStatuslement = () => (
    <AppLayout>
        <EmployerJobsByStatus />
    </AppLayout>
);
const EmployerJobDetailsElement = () => (
    <AppLayout>
        <EmployerJobDetails />
    </AppLayout>
);
/**#############################################
 * Practical training
 ##############################################*/
const PracticalTrainingsElement = () => (
    <AppLayout>
        <PracticalTrainings />
    </AppLayout>
);
const PracticalTrainingsByStatusElement = () => (
    <AppLayout>
        <PracticalTrainingByStatus />
    </AppLayout>
);
const PracticalTrainingDetailsElement = () => (
    <AppLayout>
        <PracticalTrainingDetails />
    </AppLayout>
);

/**#############################################
 * Notifications
 ##############################################*/
const NotificationsElement = () => (
    <AppLayout>
        <Notifications />
    </AppLayout>
);
const NotificationDetailsElement = () => (
    <AppLayout>
        <NotificationDetails />
    </AppLayout>
);

/**#############################################
 * Subscription package
 ##############################################*/
const SubscriptionPackagesElement = () => (
    <AppLayout>
        <SubscriptionPackages />
    </AppLayout>
);
const SubscriptionPackagesByStatusElement = () => (
    <AppLayout>
        <SubscriptionPackagesByStatus />
    </AppLayout>
);
const SubscriptionPackagesByTypesElement = () => (
    <AppLayout>
        <SubscriptionPackagesByType />
    </AppLayout>
);
const FreeTrialPackagesElement = () => (
    <AppLayout>
        <FreeTrialPackages />
    </AppLayout>
);

/**#############################################
 * Finance
 ##############################################*/
const FinanceElement = () => (
    <AppLayout>
        <Finance />
    </AppLayout>
);

/**#############################################
 * Report and analytics
 ##############################################*/
const ReportAndAnalyticsElement = () => (
    <AppLayout>
        <ReportAndAnalytics />
    </AppLayout>
);

/**#############################################
 * LogsViwer
 ##############################################*/
const LogsViwerElement = () => (
    <AppLayout>
        <LogsViwer />
    </AppLayout>
);

/**#############################################
 * Configuration
 ##############################################*/
const BlogsElement = () => (
    <AppLayout>
        <Blogs />
    </AppLayout>
);
const BlogDetailsElement = () => (
    <AppLayout>
        <BlogDetails />
    </AppLayout>
);
const FaqsElement = () => (
    <AppLayout>
        <Faqs />
    </AppLayout>
);
const ContactsElement = () => (
    <AppLayout>
        <HelpCenter />
    </AppLayout>
);

// ? ################ ERROR PAGES ###############
const AuthErrorPage404Element = () => (
    <AppLayout>
        <ErrorPage404 />
    </AppLayout>
);
// const GuestErrorPage404Element = () => (
//     <AuthLayout>
//         <ErrorPage404 />
//     </AuthLayout>
// );

const AppRoutes = () => {
    return (
        <React.Fragment>
            <Routes>
                {/* ##################### GUEST ROUTES ################## */}
                <Route path="/" element={<GuestRoutes />}>
                    <Route path="/" element={<LoginElement />} />
                    <Route
                        path="/forget-password"
                        element={<ForgetElement />}
                    />
                    {/* ##################### 404 ROUTES ################## */}
                    {/* <Route path="*" element={<GuestErrorPage404Element />} /> */}
                </Route>
                {/* ##################### PROTECTED ROUTES ################## */}
                <Route path="/" element={<PrivateRoutes />}>
                    <Route path="dashboard" element={<DashboardElement />} />
                    <Route path="/profile" element={<ProfileElement />} />
                    {/* ############## ORG ROUTES ############## */}
                    <Route
                        path="/organizations"
                        element={<OrganizationsElement />}
                    />
                    <Route
                        path="/organizations/companies"
                        element={<CompaniesElement />}
                    />
                    <Route
                        path="/organizations/companies/:status"
                        element={<CompaniesByStatusElement />}
                    />
                    <Route
                        path="/organizations/institutions"
                        element={<InstitutionsElement />}
                    />
                    <Route
                        path="/organizations/institutions/:status"
                        element={<InstitutionsByStatusElement />}
                    />
                    <Route
                        path="/organizations/:org_type/details/:org_id"
                        element={<OrganizationDetailsElement />}
                    />

                    {/* ############## EMPLOYER ROUTES ############## */}
                    <Route path="/employers" element={<EmployersElement />} />
                    <Route
                        path="/employers/status/:status"
                        element={<EmployersByStatusElement />}
                    />
                    <Route
                        path="/employers/:employer_id"
                        element={<EmployerDetailsElement />}
                    />

                    {/* ############## BENEFICIARY ROUTES ############## */}
                    <Route
                        path="/beneficiaries"
                        element={<BeneficiariesElement />}
                    />
                    <Route
                        path="/beneficiaries/status/:status"
                        element={<BeneficiaryByStatusElement />}
                    />
                    <Route
                        path="/beneficiaries/:beneficiary_id"
                        element={<BeneficiaryDetailsElement />}
                    />

                    {/* ############## STUDENTS ROUTES ############## */}
                    <Route path="/students" element={<StudentsElement />} />
                    <Route
                        path="/students/:student_id"
                        element={<StudentDetailsElement />}
                    />

                    {/* ############## STUDENTS ROUTES ############## */}
                    <Route path="/admins" element={<AdminsElement />} />
                    <Route
                        path="/admins/:admin_id"
                        element={<AdminDetailsElement />}
                    />
                    <Route
                        path="/admins/status/:status"
                        element={<AdminByStatusElement />}
                    />

                    {/* ############## JOBS ROUTES ############## */}
                    <Route path="/jobs" element={<JobsElement />} />
                    <Route
                        path="/jobs/organizations"
                        element={<ORGJobsElement />}
                    />
                    <Route
                        path="/jobs/organizations"
                        element={<ORGJobsElement />}
                    />
                    <Route
                        path="/jobs/organizations/status/:status"
                        element={<OrganizationJobsByStstusElement />}
                    />
                    <Route
                        path="/jobs/organizations/:job_id"
                        element={<ORGJobDetailsElement />}
                    />
                    <Route
                        path="/jobs/employers"
                        element={<EmployerJobsElement />}
                    />
                    <Route
                        path="/jobs/employers/status/:status"
                        element={<EmployerJobsByStatuslement />}
                    />
                    <Route
                        path="/jobs/employers/:job_id"
                        element={<EmployerJobDetailsElement />}
                    />

                    {/* ############## PTS ROUTES ############## */}
                    <Route
                        path="/practical-trainings"
                        element={<PracticalTrainingsElement />}
                    />
                    <Route
                        path="/practical-trainings/status/:status"
                        element={<PracticalTrainingsByStatusElement />}
                    />
                    <Route
                        path="/practical-trainings/:training_id"
                        element={<PracticalTrainingDetailsElement />}
                    />

                    {/* ############## CONFIGURATION ROUTES ############## */}
                    <Route path="/blogs" element={<BlogsElement />} />
                    <Route
                        path="/blogs/:blog_id"
                        element={<BlogDetailsElement />}
                    />
                    <Route path="/faqs" element={<FaqsElement />} />
                    <Route path="/contacts" element={<ContactsElement />} />

                    {/* ############## SETTINGS ROUTES ############## */}
                    <Route
                        path="/site-settings"
                        element={<SettingsElement />}
                    />
                    <Route
                        path="/user-status"
                        element={<UserStatusElement />}
                    />
                    <Route path="/user-roles" element={<UserRolesElement />} />
                    <Route
                        path="/job-categories"
                        element={<JobCategoriesElement />}
                    />
                    <Route
                        path="/organization-categories"
                        element={<OrganizationCategoryElement />}
                    />
                    <Route
                        path="/organization-types"
                        element={<OrganizationTypeElement />}
                    />
                    <Route path="/sectors" element={<SectorsElement />} />
                    <Route
                        path="/depertments"
                        element={<DepertmentsElement />}
                    />
                    <Route path="/sections" element={<SectionsElement />} />
                    <Route
                        path="/identification-types"
                        element={<IdentificationTypesElement />}
                    />
                    <Route
                        path="/payment-methods"
                        element={<PaymentMethodsElement />}
                    />
                    <Route
                        path="/verification-types"
                        element={<VerificationTypesElement />}
                    />
                    <Route
                        path="/group-status"
                        element={<GroupStatusElement />}
                    />
                    <Route
                        path="/qualification-awards"
                        element={<QualificationAwardElement />}
                    />
                    <Route
                        path="/education-levels"
                        element={<EducationLevelElement />}
                    />
                    <Route
                        path="/experience-levels"
                        element={<ExperienceLevelElement />}
                    />
                    <Route
                        path="/job-post-pricing"
                        element={<JobPostPricingElement />}
                    />
                    <Route path="/regions" element={<RegionsElement />} />
                    <Route path="/districts" element={<DistrictsElement />} />
                    <Route
                        path="/relation-types"
                        element={<RelationTypesElement />}
                    />

                    {/* ############## ACTIONS ROUTES ############## */}
                    <Route path="/promotions" element={<PromotionsElement />} />
                    <Route
                        path="/promotions/:promo_id"
                        element={<PromotionDetailsElement />}
                    />
                    <Route
                        path="/promotions/status/:status"
                        element={<PromotionByStatusElement />}
                    />
                    <Route
                        path="/promotions/customers"
                        element={<PromotionCustomersElement />}
                    />
                    <Route
                        path="/promotions/customers/:customer_id"
                        element={<PromotionCustomerDetailsElement />}
                    />
                    <Route path="/shops" element={<ShopsElement />} />
                    <Route
                        path="/shops/status/:status"
                        element={<ShopsByStatusElement />}
                    />
                    <Route
                        path="/shops/:shop_id"
                        element={<ShopDetailsElement />}
                    />

                    {/* ##################### Notifications ROUTES ########### */}
                    <Route
                        path="/notifications"
                        element={<NotificationsElement />}
                    />
                    <Route
                        path="/notifications/:notification_id"
                        element={<NotificationDetailsElement />}
                    />

                    {/* ##################### Subscription packages ROUTES ########### */}
                    <Route
                        path="/subscription-packages"
                        element={<SubscriptionPackagesElement />}
                    />
                    <Route
                        path="/subscription-packages/status/:status"
                        element={<SubscriptionPackagesByStatusElement />}
                    />
                    <Route
                        path="/subscription-packages/types/:type"
                        element={<SubscriptionPackagesByTypesElement />}
                    />
                    <Route
                        path="/subscription-packages/types/free-trial"
                        element={<FreeTrialPackagesElement />}
                    />

                    {/* ##################### Report and analytics ROUTES ########### */}
                    <Route
                        path="/report-and-analytics"
                        element={<ReportAndAnalyticsElement />}
                    />

                    {/* ##################### System logs ROUTES ########### */}
                    <Route path="/system-logs" element={<LogsViwerElement />} />

                    {/* ##################### Finance and analytics ROUTES ########### */}
                    <Route path="/finance" element={<FinanceElement />} />
                </Route>
                {/* ##################### 404 ROUTES ################## */}
                <Route path="*" element={<AuthErrorPage404Element />} />
            </Routes>
        </React.Fragment>
    );
};

export default AppRoutes;
