import React from 'react';
import { LocationOn, MoreVert } from '@mui/icons-material';
import {
    Avatar,
    Badge,
    Box,
    Chip,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';
import strHelper from '../../helpers/strHelper';
import moment from 'moment/moment';
import { filter, size } from 'lodash';

const FieldWidgetOne = ({ training }) => {
    // ############## FUNC #####################
    const getApprovalCount = () => {
        let approvals = filter(
            training?.selectedInstitutions,
            ({ status }) => status === 'approved',
        );
        return size(approvals);
    };

    return (
        <>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    borderTop: `5px solid ${colors.bgColor5}`,
                    borderRadius: 2,
                    overflow: 'hidden',
                    boxShadow: `1px 1px 10px 0px ${colors.bgColor5}`,
                }}
            >
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        p: 1,
                        textAlign: 'center',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Chip
                            label={training.status}
                            size="small"
                            color={
                                training.status === 'draft' ||
                                training.status === 'closed'
                                    ? 'warning'
                                    : training.status === 'published'
                                    ? 'success'
                                    : training.status === 'suspended'
                                    ? 'error'
                                    : 'primary'
                            }
                            sx={{
                                fontSize: 10,
                                fontWeight: 'bold',
                                textTransform: 'capitalize',
                                height: 20,
                                pt: 0.2,
                            }}
                        />
                        <Box sx={{ mt: 1 }}>
                            <Typography
                                component={'div'}
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                }}
                            >
                                Approval
                                <Chip
                                    label={getApprovalCount()}
                                    size="small"
                                    color="secondary"
                                    sx={{ fontSize: 10, ml: 0.5 }}
                                />
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                color: colors.warning,
                            }}
                        >
                            {strHelper().strCapitalizeFirstChar(training.title)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: colors.info,
                            }}
                        >
                            {strHelper().strCapitalizeFirstChar(
                                training.department.name,
                            )}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                opacity: 0.8,
                                color: colors.warning,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: colors.primary,
                                fontWeight: 'bold',
                            }}
                        >
                            <LocationOn
                                sx={{
                                    fontSize: 12,
                                    mr: 0.5,
                                }}
                            />
                            {training.region}
                        </Typography>
                    </Box>
                    <Box sx={{}}>
                        <Tooltip title="Training details" arrow>
                            <Link to={`/practical-trainings/${training.id}`}>
                                <IconButton
                                    size="small"
                                    color="info"
                                    sx={{
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                                    }}
                                >
                                    <MoreVert />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </Box>
                </Box>
                <Box
                    sx={{
                        p: 1.5,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                            <ListItemAvatar>
                                <Avatar
                                    alt="Business Op"
                                    src={training.organization.org_logo}
                                    variant="square"
                                    sx={{
                                        borderRadius: 2,
                                        border: `1px dotted ${colors.warning}`,
                                        p: 0.5,
                                        img: {
                                            objectFit: 'contain',
                                        },
                                    }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                disableTypography
                                primary={
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            opacity: 0.8,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {
                                            training.organization
                                                .organization_name
                                        }
                                    </Typography>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            <LocationOn
                                                sx={{
                                                    fontSize: 14,
                                                    color: colors.secondary,
                                                }}
                                            />
                                            {training.organization.location}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Typography
                            sx={{ fontSize: 14, width: 300, mt: 0.5 }}
                            noWrap
                        >
                            {training.description}
                        </Typography>
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                p: 0.5,
                                px: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 2,
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                            }}
                        >
                            <Badge
                                showZero
                                color="info"
                                badgeContent={training.totalApplied}
                            >
                                <Typography sx={{ fontSize: 12, pr: 2 }}>
                                    Applicants
                                </Typography>
                            </Badge>
                        </Box>
                        <Box
                            sx={{
                                p: 0.5,
                                px: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 2,
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                                mt: 1,
                            }}
                        >
                            <Typography
                                sx={{ fontSize: 12, fontWeight: 'bold' }}
                            >
                                {moment(training.created_at)
                                    .startOf('hour')
                                    .fromNow()}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center',
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            p: 1,
                            width: '100%',
                            borderBottom: `5px solid ${colors.success}`,
                        }}
                    >
                        <Typography
                            noWrap
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: 'green',
                            }}
                        >
                            Application start
                        </Typography>
                        <Typography noWrap sx={{ fontSize: 12 }}>
                            {moment(training.open_date).format('LL')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            textAlign: 'center',
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            p: 1,
                            width: '100%',
                            borderLeft: `1px solid ${colors.bgColor5}`,
                            borderRight: `1px solid ${colors.bgColor5}`,
                            borderBottom: `5px solid ${colors.danger}`,
                        }}
                    >
                        <Typography
                            noWrap
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: 'red',
                            }}
                        >
                            Application deadline
                        </Typography>
                        <Typography noWrap sx={{ fontSize: 12 }}>
                            {moment(training.end_date).format('LL')}
                        </Typography>
                    </Box>
                    {/* <Box
                        sx={{
                            textAlign: 'center',
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            pt: 1,
                            width: '100%',
                            borderBottom: `5px solid ${colors.primary}`,
                        }}
                    >
                        <Typography
                            noWrap
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                            }}
                        >
                            Positions
                        </Typography>
                        <Typography noWrap sx={{ fontSize: 12 }}>
                            {training.positions ? (
                                `${
                                    training.positions - training.totalApproved
                                } out of ${training.positions}`
                            ) : (
                                <AllInclusive color="warning" />
                            )}
                        </Typography>
                    </Box> */}
                </Box>
            </Box>
        </>
    );
};

export default FieldWidgetOne;
