import React from "react";
import PageLayout from "../layouts/PageLayout";
import InfoboxWidget2 from "../../components/widgets/InfoboxWidget2";
import { Box, Grid } from "@mui/material";
import EmployerWidget from "../../components/widgets/EmployerWidget";
import EmployerSidebar from "../../components/sidebars/EmployerSidebar";
import { colors } from "../../assets/utils/constants";
import { paginator } from "../../helpers/paginationHelper";
import { useSelector } from "react-redux";
import { employersSelector } from "../../states/features/selectors";
import { filter, isEmpty, size } from "lodash";
import CountBadge from "../../components/CountBadge";
import NoContent from "../../components/NoContent";
import CustomPagination from "../../components/paginations/CustomPagination";

const Employers = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10);

    // ############# Redux state #############
    const { employers } = useSelector(employersSelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Employer by status
    const getEmployerByStatus = () => {
        // => Active
        let activeEmployer = filter(
            employers,
            ({ is_active }) => is_active === 1
        );
        // => Inactive
        let inactiveEmployer = filter(
            employers,
            ({ is_active }) => is_active === 0
        );
        // => Suspended
        let suspendedEmployer = filter(
            employers,
            ({ status }) => status === "suspended"
        );
        // => Banned
        let bannedEmployer = filter(
            employers,
            ({ status }) => status === "banned"
        );
        return {
            activeEmployer,
            inactiveEmployer,
            suspendedEmployer,
            bannedEmployer,
        };
    };
    const employerByStatus = getEmployerByStatus();

    const infoBoxData = [
        {
            id: 1,
            title1: "Inactive employers",
            title2: "",
            total: size(employerByStatus.inactiveEmployer),
            url: "status/inactive",
            color: colors.primary,
        },
        {
            id: 2,
            title1: "Suspended employers",
            title2: "",
            total: size(employerByStatus.suspendedEmployer),
            color: colors.warning,
            url: "status/suspended",
        },
        {
            id: 3,
            title1: "Banned employers",
            title2: "",
            total: size(employerByStatus.bannedEmployer),
            color: "red",
            url: "status/banned",
        },
    ];

    // => Pagination data
    const paginatedEmployers = paginator(
        employerByStatus.activeEmployer,
        page,
        perPage
    );

    return (
        <>
            <PageLayout
                head="Employers managements"
                sidebar={<EmployerSidebar />}
            >
                <Grid container spacing={1.5}>
                    {infoBoxData.map((data) => (
                        <Grid item sm={4} key={data.id}>
                            <InfoboxWidget2 data={data} height={70} />
                        </Grid>
                    ))}
                    <Grid item sm={12}>
                        <CustomPagination
                            head={"Active Wazawa Max employers"}
                            height={"51vh"}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedEmployers}
                            action={
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(
                                                employerByStatus.activeEmployer
                                            )}
                                            item={"Active employers"}
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(employerByStatus.activeEmployer) ? (
                                <>
                                    <NoContent
                                        height={300}
                                        message={"No active employers"}
                                    />
                                </>
                            ) : (
                                <Grid container spacing={2}>
                                    {paginatedEmployers.data.map((employer) => (
                                        <Grid item sm={6} key={employer.id}>
                                            <EmployerWidget
                                                employer={employer}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Employers;
