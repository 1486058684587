import React from 'react';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ManageAccounts } from '@mui/icons-material';
import * as Yup from 'yup';
import CustomTextArea from '../../../components/Forms/CustomTextArea';
import { useDispatch } from 'react-redux';
import { DialogActions } from '@mui/material';
import useRTK from '../../../hooks/useRTK';
import { useAddSectorMutation } from '../../../api/settings/sectorsAPI';
import { addSector } from '../../../states/features/settings/sectorsSlice';

// -> validate settings form
const validate = Yup.object({
    name: Yup.string().required(),
    description: Yup.string().nullable(),
});

const AddSector = ({ handleClose, refetch }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        createSector,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useAddSectorMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        refetch();
        handleClose();
        dispatch(addSector(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    name: '',
                    description: '',
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    createSector(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <CustomTextField label="Name" name="name" type="text" />
                        <CustomTextArea
                            label="Description"
                            name="description"
                        />
                        <DialogActions>
                            <CustomSubmitButton
                                startIcon={<ManageAccounts />}
                                onClick={formik.handleSubmit}
                                loading={isLoading}
                            >
                                Add setting
                            </CustomSubmitButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddSector;
