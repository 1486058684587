import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    educationLevels: [],
};

// !########## SLICE #############
const educationLevelsSlice = createSlice({
    name: 'educationLevelsSlice',
    initialState,
    reducers: {
        setEducationLevels: (state, actions) => {
            state.educationLevels = actions.payload;
        },
        addEducationLevel: (state, actions) => {
            const education_level = actions.payload;
            isEmpty(state.educationLevels)
                ? (state.educationLevels = [education_level, ...[]])
                : (state.educationLevels = [
                      education_level,
                      ...state.educationLevels,
                  ]);
        },
    },
});

export const {
    setEducationLevels,
    addEducationLevel,
} = educationLevelsSlice.actions;
export default educationLevelsSlice.reducer;
