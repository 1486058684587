import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import { ArrowBackIosNewTwoTone } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { colors } from '../assets/utils/constants';
import strHelper from '../helpers/strHelper';

const PageHead = styled(Grid)({
    'display': 'flex',
    'alignItems': 'center',
    'height': 70,
    'background': `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3}) !important`,
    'paddingLeft': 23,
    'paddingRight': 23,
    'justifyContent': 'space-between',
    'boxShadow': `2px 2px 4px 0px ${colors.bgColor5}`,

    '& .head': {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'colors.primary',
        opacity: 0.8,
    },
});

const PageHeader = ({ head }) => {
    const navigate = useNavigate();
    const STR = strHelper();
    return (
        <>
            <Grid container sx={{ position: 'sticky', top: 80 }}>
                <PageHead item xs={12} sx={{ position: 'sticky', top: 80 }}>
                    <Typography className="head">
                        {STR.strCapitalizeFirstChar(head)}
                    </Typography>
                    <Box>
                        <Button
                            startIcon={<ArrowBackIosNewTwoTone />}
                            // variant="outlined"
                            size="small"
                            sx={{ pl: 2, pr: 2 }}
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Go Back
                        </Button>
                    </Box>
                </PageHead>
            </Grid>
        </>
    );
};

export default PageHeader;
