import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    experienceLevels: [],
};

// !########## SLICE #############
const experienceLevelsSlice = createSlice({
    name: 'experienceLevelsSlice',
    initialState,
    reducers: {
        setExperienceLevels: (state, actions) => {
            state.experienceLevels = actions.payload;
        },
        addExperienceLevel: (state, actions) => {
            const experience_level = actions.payload;
            isEmpty(state.experienceLevels)
                ? (state.experienceLevels = [experience_level, ...[]])
                : (state.experienceLevels = [
                      experience_level,
                      ...state.experienceLevels,
                  ]);
        },
    },
});

export const {
    setExperienceLevels,
    addExperienceLevel,
} = experienceLevelsSlice.actions;
export default experienceLevelsSlice.reducer;
