import React from 'react';
import CustomCard2 from '../../../components/cards/CustomCard2';
import OrganizationFieldWidget from '../../../components/widgets/OrganizationFieldWidget';

const Institution = ({ student }) => {
    return (
        <>
            <CustomCard2 head={'Institution details (Attended Institution)'}>
                <OrganizationFieldWidget organization={student.institution} />
            </CustomCard2>
        </>
    );
};

export default Institution;
