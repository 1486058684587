import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/constants";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { studentsSelector } from "../../states/features/selectors";
import { filter, isEmpty, size, take } from "lodash";
import NoContent from "../NoContent";
import CustomPagination from "../paginations/CustomPagination";
import { paginator } from "../../helpers/paginationHelper";
import OnFieldStudentWidget from "../widgets/OnFieldStudentWidget";

const StudentSidebar = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10);

    // ############## Redux state ###############
    const { students } = useSelector(studentsSelector);

    // ############## FUNC ####################
    // => Get on-training students
    const getOnTrainingStudents = () => {
        let onTrainingStudents = filter(
            students,
            ({ isOnTraining }) => isOnTraining === true
        );
        return onTrainingStudents;
    };
    const onTrainingStudents = getOnTrainingStudents();

    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Pagination data
    const paginatedOnTrainingStudents = paginator(
        take(onTrainingStudents, 10),
        page,
        perPage
    );

    return (
        <>
            <Grid container spacing={1.5}>
                <Grid item sm={12}>
                    <CustomPagination
                        head={"On training students"}
                        height={{ md: "64.5vh", xs: "100%" }}
                        borderBottom
                        handlePageChange={handlePageChange}
                        handlePerPageChage={handlePerPageChage}
                        data={paginatedOnTrainingStudents}
                        action={
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    sx={{
                                        py: 0.5,
                                        px: 2,
                                        borderRadius: 2,
                                        fontWeight: "bold",
                                        fontSize: 12,
                                        mr: 1,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                                    }}
                                >
                                    {size(onTrainingStudents)}{" "}
                                    <sup>Students</sup>
                                </Typography>
                                <Box>
                                    <Link
                                        to={"/students/trainings/on-training"}
                                    >
                                        <Button
                                            variant="outlined"
                                            endIcon={
                                                <ArrowCircleRightOutlined />
                                            }
                                            size="small"
                                            className="app-btn"
                                        >
                                            View
                                        </Button>
                                    </Link>
                                </Box>
                            </Box>
                        }
                    >
                        {isEmpty(onTrainingStudents) ? (
                            <>
                                <NoContent
                                    height={"35vh"}
                                    message={"No on training student"}
                                />
                            </>
                        ) : (
                            <Grid container spacing={1}>
                                {paginatedOnTrainingStudents.data.map(
                                    (student) => (
                                        <Grid
                                            item
                                            sm={12}
                                            xs={12}
                                            key={student.id}
                                        >
                                            <OnFieldStudentWidget
                                                student={student}
                                            />
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        )}
                    </CustomPagination>
                </Grid>
            </Grid>
        </>
    );
};

export default StudentSidebar;
