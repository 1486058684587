import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { colors } from '../../assets/utils/constants';
import { isEmpty } from 'lodash';
import ORGJobWidget from '../widgets/ORGJobWidget';

const columns = [{ id: 's_n', label: 'S/N', minWidth: 0 }];

function createData(s_n) {
    return {
        s_n,
    };
}

// !############### MAIN FUNC #####################
const JobTable = ({ height, jobs }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = jobs?.map((job) => {
        return createData(<ORGJobWidget job={job} />);
    });

    return (
        <React.Fragment>
            {!isEmpty(jobs) && (
                <Paper
                    sx={{
                        width: '100%',
                        overflow: 'hidden',
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2}) !important`,
                    }}
                >
                    <TableContainer
                        sx={{
                            maxHeight: height ? height : 350,
                            minHeight: height ? height : 350,
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableBody>
                                {rows
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage,
                                    )
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                {columns.map((column) => {
                                                    const value =
                                                        row[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            sx={{ pb: 0 }}
                                                        >
                                                            {column.format &&
                                                            typeof value ===
                                                                'number'
                                                                ? column.format(
                                                                      value,
                                                                  )
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[1, 2, 5, 10]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            bgcolor: colors.bgColor,
                            borderTop: `1px solid ${colors.secondary}`,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3}) !important`
                        }}
                    />
                </Paper>
            )}
        </React.Fragment>
    );
};

export default JobTable;
