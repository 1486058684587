import React from 'react';
import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import PromotionCustomerSidebar from '../../components/sidebars/PromotionCustomerSidebar';
import PromotionCustomerWidget3 from '../../components/widgets/PromotionCustomerWidget3';
import PromotionWidget from '../../components/widgets/PromotionWidget';
import CountBadge from '../../components/CountBadge';
import { useGetPromotionsCustomerQuery } from '../../api/promotions/promotionsCustomersAPI';
import { setCustomerDetails } from '../../states/features/promotions/promotionCustomersSlice';
import InnerDataFetchingError from '../../components/InnerDataFetchingError';
import ContentLoader from '../../components/ContentLoader';
import useRTK from '../../hooks/useRTK';
import { useDispatch, useSelector } from 'react-redux';
import { promotionCustomersSelector } from '../../states/features/selectors';
import { isEmpty, size } from 'lodash';
import NoContent from '../../components/NoContent';

const PromotionCustomerDetails = () => {
    // ################# CONST #####################
    const RTK = useRTK();
    const { customer_id } = useParams();
    const dispatch = useDispatch();

    // ################# Redux state #####################
    const { customerDetails: customer } = useSelector(
        promotionCustomersSelector,
    );
    const promotions = customer.promotions;

    // ############# RTK ##################
    const {
        isLoading,
        isSuccess,
        isError,
        refetch,
        data: responseData,
    } = useGetPromotionsCustomerQuery(customer_id);
    const rtkAction = (data) => {
        dispatch(setCustomerDetails(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkAction);

    return (
        <>
            <PageLayout
                head={`Promotion customer details`}
                sidebar={<PromotionCustomerSidebar />}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard2 height={'79vh'}>
                            {isLoading ? (
                                <>
                                    <ContentLoader />
                                </>
                            ) : isError ? (
                                <>
                                    <InnerDataFetchingError
                                        handleDataRefetch={refetch}
                                    />
                                </>
                            ) : (
                                <>
                                    <PromotionCustomerWidget3
                                        customer={customer}
                                    />
                                    <Box sx={{ mt: 3 }}>
                                        <CustomCard2
                                            head={`Customer promotions`}
                                            height={'44vh'}
                                            action={
                                                <CountBadge
                                                    total={size(promotions)}
                                                    item={'Promotions'}
                                                />
                                            }
                                        >
                                            {isEmpty(promotions) ? (
                                                <>
                                                    <NoContent
                                                        message={
                                                            'No promotion added'
                                                        }
                                                                height={250}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Grid container spacing={2}>
                                                        {promotions.map(
                                                            (promotion) => (
                                                                <Grid
                                                                    item
                                                                    sm={4}
                                                                    key={
                                                                        promotion.id
                                                                    }
                                                                >
                                                                    <PromotionWidget
                                                                        promotion={
                                                                            promotion
                                                                        }
                                                                    />
                                                                </Grid>
                                                            ),
                                                        )}
                                                    </Grid>
                                                </>
                                            )}
                                        </CustomCard2>
                                    </Box>
                                </>
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default PromotionCustomerDetails;
