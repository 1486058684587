import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    userStatus: [],
};

// !########## SLICE #############
const userStatusSlice = createSlice({
    name: 'userStatusSlice',
    initialState,
    reducers: {
        setUserStatus: (state, actions) => {
            state.userStatus = actions.payload;
        },
        addUserStatus: (state, actions) => {
            const user_status = actions.payload;
            isEmpty(state.userStatus)
                ? (state.userStatus = [user_status, ...[]])
                : (state.userStatus = [user_status, ...state.userStatus]);
        },
    },
});

export const { setUserStatus, addUserStatus } = userStatusSlice.actions;
export default userStatusSlice.reducer;
