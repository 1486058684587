import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { verificationTypeEndpoints } from './settingEndpoints';
import { settingAPI } from './settingAPI';

export const verificationTypesAPI = createApi({
    reducerPath: 'verificationTypesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getVerificationTypes: builder.query({
            query: () => `${verificationTypeEndpoints.GET_VERIFICATION_TYPES}`,
        }),
        // => ADD
        addVerificationType: builder.mutation({
            query: (payload) => ({
                url: `${verificationTypeEndpoints.ADD_VERIFICATION_TYPE}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
        // => UPDATE
        updateVerificationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${verificationTypeEndpoints.UPDATE_VERIFICATION_TYPE}/` +
                    payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateVerificationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${verificationTypeEndpoints.ACTIVATE_VERIFICATION_TYPE}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateVerificationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${verificationTypeEndpoints.DEACTIVATE_VERIFICATION_TYPE}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteVerificationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${verificationTypeEndpoints.DELETE_VERIFICATION_TYPE}/` +
                    payload,
                method: `DELETE`,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
    }),
});

export const {
    useGetVerificationTypesQuery,
    useAddVerificationTypeMutation,
    useUpdateVerificationTypeMutation,
    useActivateVerificationTypeMutation,
    useDeactivateVerificationTypeMutation,
    useDeleteVerificationTypeMutation,
} = verificationTypesAPI;
