import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CreditCard, SendToMobile, LocalAtm } from '@mui/icons-material';
import { colors } from '../../assets/utils/constants';
import MoblilePaymentOptions from './MoblilePaymentOptions';
import { useDispatch } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import ContentLoader from '../ContentLoader';
import InnerDataFetchingError from '../InnerDataFetchingError';
import CustomCard2 from '../cards/CustomCard2';
import { useGetPaymentMethodsQuery } from '../../api/settings/paymentMethodsAPI';
import { setPaymentMethods } from '../../states/features/settings/paymentMethodsSlice';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Box
                        sx={{
                            p: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        'id': `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const PaymentOptions = ({ promotion, handleDialogClose }) => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // ############ CONST ################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK #################
    const {
        isLoading,
        isSuccess,
        isError,
        data,
        refetch,
    } = useGetPaymentMethodsQuery();
    // ############# Dispatch settings ##########
    const rtkActions = (data) => {
        dispatch(setPaymentMethods(data));
    };
    RTK.useRTKFetch(isSuccess, data, rtkActions);

    return (
        <Box sx={{ bgcolor: colors.bgColor2 }}>
            {isLoading ? (
                <CustomCard2 height={400}>
                    <ContentLoader />
                </CustomCard2>
            ) : isError ? (
                <>
                    <InnerDataFetchingError
                        handleDataRefetch={refetch}
                        height={350}
                    />
                </>
            ) : (
                <>
                    <AppBar
                        position="static"
                        sx={{
                            boxShadow: 'none',
                            background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                            pt: 1,
                        }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="none"
                            textColor="inherit"
                            variant="fullWidth"
                            sx={{
                                '.Mui-selected': {
                                    color: colors.warning,
                                },
                            }}
                        >
                            <Tab
                                label={
                                    <Typography
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        <SendToMobile sx={{ mr: 1 }} /> Mobile
                                        payments
                                    </Typography>
                                }
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={
                                    <Typography
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {' '}
                                        <CreditCard sx={{ mr: 1 }} /> Card
                                        payments
                                    </Typography>
                                }
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <MoblilePaymentOptions
                            promotion={promotion}
                            handleDialogClose={handleDialogClose}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <Box
                            sx={{
                                height: 313,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <LocalAtm
                                sx={{
                                    fontSize: 150,
                                    color: colors.info,
                                    opacity: 0.6,
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    color: 'red',
                                    opacity: 0.8,
                                    width: '60%',
                                    textAlign: 'center',
                                }}
                            >
                                Ops sorry! We are current working on card
                                payment gatway. We will let you know when it is
                                ready.
                            </Typography>
                        </Box>
                    </TabPanel>
                </>
            )}
        </Box>
    );
};

export default PaymentOptions;
