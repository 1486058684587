import React from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, CircularProgress, Switch } from '@mui/material';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import {
    useActivateEducationLevelMutation,
    useDeactivateEducationLevelMutation,
    useDeleteEducationLevelMutation,
} from '../../../api/settings/educationLevelAPI';
import { setEducationLevels } from '../../../states/features/settings/educationLevelsSlice';

const ActionButtons = ({ row, refetch, handleEdit }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        activateEducationLevel,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivateEducationLevelMutation();
    const [
        deactivateEducationLevel,
        {
            isLoading: deactivateLoading,
            isSuccess: deactivateSuccess,
            isError: deactivateIsError,
            error: deactivateError,
            data: deactivateData,
        },
    ] = useDeactivateEducationLevelMutation();
    const [
        deleteEducationLevel,
        {
            isLoading: deleteLoading,
            isSuccess: deleteSuccess,
            isError: deleteIsError,
            error: deleteError,
            data: deleteData,
        },
    ] = useDeleteEducationLevelMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        refetch();
        dispatch(setEducationLevels(data));
    };
    RTK.useRTKResponse(
        activateSuccess,
        activateIsError,
        activateError,
        activateData,
        rtkActions,
    );
    RTK.useRTKResponse(
        deactivateSuccess,
        deactivateIsError,
        deactivateError,
        deactivateData,
        rtkActions,
    );
    // => Delete res
    RTK.useRTKResponse(
        deleteSuccess,
        deleteIsError,
        deleteError,
        deleteData,
        rtkActions,
    );

    return (
        <>
            <Box>
                <Button
                    variant="outlined"
                    color="secondary"
                    disabled={
                        activateLoading || deactivateLoading ? true : false
                    }
                    sx={{
                        padding: 0.2,
                    }}
                    onClick={() =>
                        parseInt(row.status) === 1
                            ? deactivateEducationLevel(row.id)
                            : activateEducationLevel(row.id)
                    }
                >
                    {activateLoading || deactivateLoading ? (
                        <>
                            <CircularProgress
                                size={20}
                                sx={{
                                    my: 0.25,
                                }}
                            />
                        </>
                    ) : (
                        <Switch
                            size="small"
                            checked={parseInt(row.status) === 1 ? true : false}
                        />
                    )}
                </Button>
                <Button
                    variant="outlined"
                    color="warning"
                    onClick={() => {
                        let payload = {
                            id: row.id,
                            award: row.award,
                            qualification: row.qualification,
                            percentage: row.percentage,
                            description: row.description,
                        };
                        handleEdit(payload);
                    }}
                >
                    <Edit
                        style={{
                            fontSize: 16,
                        }}
                    />
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        deleteEducationLevel(row.id);
                    }}
                >
                    {deleteLoading ? (
                        <>
                            <CircularProgress
                                size={15}
                                sx={{
                                    my: 0,
                                }}
                            />
                        </>
                    ) : (
                        <Delete
                            style={{
                                fontSize: 16,
                            }}
                        />
                    )}
                </Button>
            </Box>
        </>
    );
};

export default ActionButtons;
