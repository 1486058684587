import React, { useEffect } from 'react';
import LeftBar from './LeftBar';
import Banner from './Banner';
import MainContent from './MainContent';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageLoader from '../../../components/PageLoader';
import { useGetORGProfileQuery } from '../../../api/organizations/organizationAPI';
import useRTK from '../../../hooks/useRTK';
import { setOrgProfile } from '../../../states/features/organizations/organizationsSlice';
import { organizationsSelector } from '../../../states/features/selectors';
import { isEmpty } from 'lodash';
import { setSingleOrganizationJobs } from '../../../states/features/jobs/jobsSlice';
import { setORGStudents } from '../../../states/features/students/studentsSlice';

// !################ MAIN FUNC ##################
const OrgProfile = () => {
    // ############### CONST #################
    const RTK = useRTK();
    let { org_id } = useParams();
    const dispatch = useDispatch();

    // ############## Comp state #############
    const { orgProfile } = useSelector(organizationsSelector);

    // ############### RTK #################
    const {
        isLoading: profileLoading,
        isSuccess: profileSuccess,
        data: profileData,
        refetch: refetchProfile,
    } = useGetORGProfileQuery(org_id);

    // ############## USEEFFECT ######################
    const rtkAction = (data) => {
        dispatch(setOrgProfile(data));
    };
    RTK.useRTKFetch(profileSuccess, profileData, rtkAction);
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            dispatch(setSingleOrganizationJobs([]));
            dispatch(setORGStudents([]));
        }
        return () => {
            isSubscribed = false;
        };
    }, [dispatch]);

    return (
        <>
            {profileLoading ? (
                <>
                    <PageLoader />
                </>
            ) : (
                !isEmpty(orgProfile) && (
                    <>
                        {/* ################## bannner ############################## */}
                        <Grid container>
                            <Grid item sm={12} xs={12}>
                                <Banner organization={orgProfile} />
                            </Grid>
                        </Grid>

                        {/* ################### main content ######################## */}
                        <Grid container justifyContent={'center'}>
                            <Grid item md={3} sm={12}>
                                <LeftBar organization={orgProfile} />
                            </Grid>
                            <Grid item md={9} sm={12}>
                                <MainContent
                                    organization={orgProfile}
                                    orgDocs={orgProfile.document}
                                    refetchProfile={refetchProfile}
                                    orgKeyPerson={orgProfile.key_person}
                                />
                            </Grid>
                        </Grid>
                    </>
                )
            )}
        </>
    );
};
export default OrgProfile;
