import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    sectors: [],
};

// !########## SLICE #############
const sectorsSlice = createSlice({
    name: 'sectorsSlice',
    initialState,
    reducers: {
        setSectors: (state, actions) => {
            state.sectors = actions.payload;
        },
        addSector: (state, actions) => {
            const sector = actions.payload;
            isEmpty(state.sectors)
                ? (state.sectors = [sector, ...[]])
                : (state.sectors = [sector, ...state.sectors]);
        },
    },
});

export const { setSectors, addSector } = sectorsSlice.actions;
export default sectorsSlice.reducer;
