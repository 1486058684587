import React from "react";
import ReportLayout from "../../layouts/ReportLayout";
import {
    CuTableHeader,
    CuTableRow,
    CuTableSN,
    CuTableColumn,
    CuTableHead,
    CuTableBody,
    CuReportTable,
} from "../../../components/Tables/ReportTableComp";
import { isEmpty } from "lodash";
import ReportNoData from "../../../components/ReportNoData";

const StudentReport = ({ students }) => {
    return (
        <ReportLayout
            head={"Wazawa Max organization report"}
            orientation={"landscape"}
        >
            {isEmpty(students) ? (
                <>
                    <ReportNoData />
                </>
            ) : (
                <CuReportTable>
                    <CuTableHeader>
                        <CuTableRow>
                            <CuTableSN value={"S/N"} />
                            <CuTableHead value={"student"} />
                            <CuTableHead value={"Section"} />
                            <CuTableHead
                                value={"Start"}
                                style={{
                                    width: "220px",
                                    textAlign: "center",
                                }}
                            />
                            <CuTableHead
                                value={"Graduate"}
                                style={{
                                    width: "220px",
                                    textAlign: "center",
                                }}
                            />
                            <CuTableHead
                                value={"status"}
                                style={{
                                    width: "220px",
                                    textAlign: "center",
                                }}
                            />
                        </CuTableRow>
                    </CuTableHeader>
                    {/* Table body */}
                    <CuTableBody>
                        {students.map((student, index) => (
                            <CuTableRow key={index}>
                                <CuTableSN value={++index} />
                                <CuTableColumn
                                    value={`${student.first_name} ${student.last_name}`}
                                    subValue={student.reg_id}
                                />
                                <CuTableColumn
                                    value={
                                        student?.section ? (
                                            student?.section?.section?.name
                                        ) : (
                                            <>-</>
                                        )
                                    }
                                />
                                <CuTableColumn
                                    value={student?.start_year}
                                    style={{
                                        width: "220px",
                                        whiteSpace: "nowrap",
                                        textAlign: "center",
                                    }}
                                />
                                <CuTableColumn
                                    value={student?.finish_year}
                                    style={{
                                        width: "220px",
                                        whiteSpace: "nowrap",
                                        textAlign: "center",
                                    }}
                                />
                                <CuTableColumn
                                    value={student.status}
                                    style={{
                                        width: "220px",
                                        whiteSpace: "nowrap",
                                        textAlign: "center",
                                        color:
                                            student.status === "inactive"
                                                ? "brown"
                                                : student.status === "active"
                                                ? "green"
                                                : student.status === "suspended"
                                                ? "red"
                                                : student.status === "graduated"
                                                ? "blue"
                                                : "black",
                                    }}
                                />
                            </CuTableRow>
                        ))}
                    </CuTableBody>
                </CuReportTable>
            )}
        </ReportLayout>
    );
};

export default StudentReport;
