import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../assets/utils/constants";
import { ArrowCircleRightOutlined } from "@mui/icons-material";

const ReportButtonWidget = ({ report }) => {
    return (
        <div>
            <Link>
                <Box
                    sx={{
                        height: 60,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        borderRadius: 2,
                        boxShadow: `2px 2px 5px 0px ${colors.bgColor5}`,
                        transition: `.3s all ease-in-out`,
                        ".arrow": {
                            position: "absolute",
                            right: 10,
                            opacity: 0.8,
                            fontSize: 16,
                            transition: `.3s all ease-in-out`,
                        },
                        "::before": {
                            content: "''",
                            position: "absolute",
                            width: 0,
                            top: 0,
                            bottom: 0,
                            left: 0,
                            borderLeft: `5px solid ${colors.bgColor5}`,
                            borderTop: `5px solid ${colors.bgColor5}`,
                            borderTopLeftRadius: 10,
                            transition: `.3s all ease-in-out`,
                            transition: `.3s all ease-in-out`,
                        },
                        ":hover": {
                            transition: `.3s all ease-in-out`,
                            transform: `scale(1.01)`,
                            ".arrow": {
                                right: 5,
                                transition: `.3s all ease-in-out`,
                                color: colors.info,
                            },
                            "::before": {
                                width: 50,
                                bottom: 50,
                                transition: `.3s all ease-in-out`,
                                borderLeft: `5px solid ${colors.info}`,
                                borderTop: `5px solid ${colors.info}`,
                            },
                        },
                    }}
                    onClick={() => {
                        let actionHead = report.title + " Report";
                        let actionType = report.actionType;
                        report.action(actionType, actionHead);
                    }}
                >
                    <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                        {report.title}{" "}
                    </Typography>
                    <ArrowCircleRightOutlined className="arrow" />
                </Box>
            </Link>
        </div>
    );
};

export default ReportButtonWidget;
