import React from "react";
import PageLayout from "../layouts/PageLayout";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionPackagesSelector } from "../../states/features/selectors";
import { filter, isEmpty, size } from "lodash";
import { useGetAllPackagesQuery } from "../../api/subscriptionPackages/subscriptionPackagesAPI";
import { setSubscriptionPackages } from "../../states/features/subscriptionPackages/subscriptionPackagesSlice";
import ContentLoader from "../../components/ContentLoader";
import CustomCard2 from "../../components/cards/CustomCard2";
import useRTK from "../../hooks/useRTK";
import SubscriptionPackageSidebar from "../../components/sidebars/SubscriptionPackageSidebar";
import Infobox1 from "../../components/infoboxes/InfoBox1";
import CustomPagination from "../../components/paginations/CustomPagination";
import NoContent from "../../components/NoContent";
import { paginator } from "../../helpers/paginationHelper";
import CountBadge from "../../components/CountBadge";
import SubscriptionPackageWidget from "../../components/widgets/SubscriptionPackageWidget";
import { colors } from "../../assets/utils/constants";

const SubscriptionPackages = () => {
    // ############## CONST ############
    const dispatch = useDispatch();
    const RTK = useRTK();

    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(3);

    // ######### Redux state ############
    const { subscriptionPackages, freeTrialPackages } = useSelector(
        subscriptionPackagesSelector
    );

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    const infoBoxData = [
        {
            id: 2,
            title1: "Active",
            title2: "packages",
            total: size(
                filter(subscriptionPackages, ({ status }) => status === 1)
            ),
            url: "/subscription-packages/status/active",
        },
        {
            id: 3,
            title1: "In-active",
            title2: "packages",
            total: size(
                filter(subscriptionPackages, ({ status }) => status === 0)
            ),
            url: "/subscription-packages/status/in-active",
        },
        {
            id: 1,
            title1: "Discounted",
            title2: "packages",
            total: size(
                filter(
                    subscriptionPackages,
                    ({ discount_per }) => parseInt(discount_per) !== 0
                )
            ),
            url: "/subscription-packages/status/discounted",
        },
        {
            id: 4,
            title1: "Frial Trial",
            title2: "packages",
            colorSign: colors.info,
            total: size(freeTrialPackages),
            url: "/subscription-packages/types/free-trial",
        },
    ];

    // ############## RTK ############
    const {
        isLoading,
        isSuccess,
        data: packagesData,
    } = useGetAllPackagesQuery();
    const rtkActions = (data) => {
        dispatch(setSubscriptionPackages(data));
    };
    RTK.useRTKFetch(isSuccess, packagesData, rtkActions);

    // => Pagination data
    const paginatedPackages = paginator(
        filter(subscriptionPackages, ({ status }) => status === 1),
        page,
        perPage
    );

    return (
        <>
            <PageLayout
                head="Subscription packages managements"
                sidebar={<SubscriptionPackageSidebar />}
            >
                {isLoading ? (
                    <>
                        <CustomCard2 height={"78vh"}>
                            <ContentLoader />
                        </CustomCard2>
                    </>
                ) : (
                    <Grid container spacing={2} sx={{ mb: 4 }}>
                        {infoBoxData.map((data, index) => (
                            <Grid item xs key={index}>
                                <Infobox1
                                    data={data}
                                    height={80}
                                    colorSign={data?.colorSign}
                                />
                            </Grid>
                        ))}
                        <Grid item sm={12}>
                            <CustomPagination
                                head={"Active subscription packages"}
                                height={"53vh"}
                                borderBottom
                                handlePageChange={handlePageChange}
                                handlePerPageChage={handlePerPageChage}
                                data={paginatedPackages}
                                action={
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box sx={{ ml: 1 }}>
                                            <CountBadge
                                                total={size(
                                                    filter(
                                                        subscriptionPackages,
                                                        ({ status }) =>
                                                            status === 1
                                                    )
                                                )}
                                                item={"Active packages"}
                                            />
                                        </Box>
                                    </Box>
                                }
                            >
                                {isEmpty(subscriptionPackages) ? (
                                    <>
                                        <NoContent
                                            height={300}
                                            message={
                                                "No subscription package added"
                                            }
                                        />
                                    </>
                                ) : (
                                    <Grid container spacing={4}>
                                        {paginatedPackages.data.map(
                                            (subPackage) => (
                                                <Grid
                                                    item
                                                    sm={4}
                                                    key={subPackage.id}
                                                >
                                                    <SubscriptionPackageWidget
                                                        subscriptionPackage={
                                                            subPackage
                                                        }
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                )}
                            </CustomPagination>
                        </Grid>
                    </Grid>
                )}
            </PageLayout>
        </>
    );
};

export default SubscriptionPackages;
