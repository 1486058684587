import React from 'react';
import { useGetSingleORGJobsQuery } from '../../../api/organizations/organizationAPI';
import { useDispatch, useSelector } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { jobsSelector } from '../../../states/features/selectors';
import { setSingleOrganizationJobs } from '../../../states/features/jobs/jobsSlice';
import JobTable from '../../../components/Tables/JobTable';
import CustomCard from '../../../components/cards/CustomCard';
import ContentLoader from '../../../components/ContentLoader';
import NoContent from '../../../components/NoContent';
import { isEmpty } from 'lodash';

const ORGJobs = ({ organization }) => {
    // ############## RTK ############
    const dispatch = useDispatch();
    const RTK = useRTK();

    // ############## Redux state ############
    const { singleORGJobs } = useSelector(jobsSelector);

    // ############## RTK ############
    const { isLoading, isSuccess, data: jobsData } = useGetSingleORGJobsQuery(
        organization.id,
    );
    const rtkActions = (data) => {
        dispatch(setSingleOrganizationJobs(data));
    };
    RTK.useRTKFetch(isSuccess, jobsData, rtkActions);

    return (
        <>
            <CustomCard head={'All organization jobs'} height={450}>
                {isLoading ? (
                    <>
                        <ContentLoader />
                    </>
                ) : isEmpty(singleORGJobs) ? (
                    <>
                        <NoContent message={'No job created'} />
                    </>
                ) : (
                    <JobTable jobs={singleORGJobs} height={397} />
                )}
            </CustomCard>
        </>
    );
};

export default ORGJobs;
