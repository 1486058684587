import { Close } from '@mui/icons-material';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../assets/utils/constants';

const AppBarMenu = ({
    menuToggle,
    title,
    subTitle,
    action,
    actionBtn,
    onClose,
    children,
}) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    'position': 'absolute',
                    'right': 0,
                    'top': 52,
                    'bottom': 0,
                    'background': 'red',
                    'width': 400,
                    'borderRadius': 2.5,
                    'transition': '.3s all ease-in-out',
                    'display': menuToggle,
                    '::after': {
                        content: "''",
                        position: 'absolute',
                        right: 8,
                        top: -10,
                        opacity: 1,
                        borderBottom: '15px solid #fff',
                        borderLeft: '12px solid transparent',
                        borderRight: '12px solid transparent',
                        transition: '.3s all ease-in-out',
                    },
                }}
            >
                <Card
                    sx={{
                        boxShadow: `0px 0px 28px 0 rgb(23, 35, 60, .2),
                        -0px -0px 9px 0 rgba(255, 255, 255, 0.55) !important`,
                    }}
                >
                    <CardHeader
                        component={'div'}
                        title={title}
                        subheader={subTitle}
                        action={
                            <IconButton onClick={() => onClose()}>
                                <Close sx={{ fontSize: 18 }} />
                            </IconButton>
                        }
                        sx={{
                            'bgcolor': colors.bgColor,
                            'borderBottom': `1px solid ${colors.secondary}`,
                            '.MuiCardHeader-title': {
                                fontSize: 18,
                                fontWeight: 'bold',
                            },
                            '.MuiCardHeader-subheader': {
                                fontSize: 12,
                            },
                            '.MuiCardHeader-action': {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 0.5,
                                borderRadius: '50%',
                                boxShadow: `3px 3px 14px 0 rgb(23, 35, 60, .2),
                                                            -8px -8px 9px 0 rgba(255, 255, 255, 0.55) !important`,
                            },
                        }}
                    />
                    <CardContent
                        sx={{
                            height: 350,
                            bgcolor: '#fff',
                            overflowY: 'auto',
                            position: 'relative',
                        }}
                    >
                        {children}
                    </CardContent>
                    <CardActions
                        sx={{
                            height: 50,
                            bgcolor: colors.bgColor,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Link to={action}>
                            <Button size="small" sx={{ width: '100%' }}>
                                {actionBtn}
                            </Button>
                        </Link>
                    </CardActions>
                </Card>
            </Box>
        </React.Fragment>
    );
};

export default AppBarMenu;
