import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { filter, size } from 'lodash';

const OrganizationSidebar = ({ organizations, orgType }) => {
    const orgType2 = orgType === 'Company' ? 'Companies' : 'Institutions';
    const stats = {
        total: size(organizations),
        on_progress: size(filter(organizations, ['is_verified', 'pending'])),
        requested: size(
            filter(
                organizations,
                ({ is_verified }) =>
                    is_verified === 'on_progress' ||
                    is_verified === 'requested',
            ),
        ),
        approved: size(filter(organizations, ['is_verified', 'verified'])),
        denied: size(filter(organizations, ['is_verified', 'denied'])),
    };

    return (
        <>
            <Box
                sx={{
                    p: 1,
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        opacity: 0.8,
                        fontWeight: 'bold',
                    }}
                >
                    {orgType2} stats
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    px: 1.5,
                    py: 2,
                    borderRadius: 2,
                    mt: 1,
                    boxShadow: `1px -1px 10px 0px ${colors.bgColor4}`,
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                }}
            >
                <Box
                    sx={{
                        height: 150,
                        width: 150,
                        borderRadius: 50,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        border: `5px solid ${colors.bgColor3}`,
                        boxShadow: `0px 10px 15px ${colors.bgColor4}`,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 35,
                            fontWeight: 'bold',
                            opacity: 0.8,
                            color: colors.info,
                        }}
                    >
                        {stats.total}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            opacity: 0.7,
                            fontStyle: 'italic',
                        }}
                    >
                        {orgType2}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.info,
                                    borderBottom: `3px solid ${colors.bgColor5}`,
                                    px: 1,
                                    borderRadius: 2,
                                }}
                            >
                                {stats.on_progress}
                                <sup
                                    style={{
                                        color: colors.warning,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    On-Progress
                                </sup>
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.info,
                                    borderBottom: `3px solid ${colors.bgColor5}`,
                                    px: 1,
                                    borderRadius: 2,
                                }}
                            >
                                {stats.requested}
                                <sup
                                    style={{
                                        color: colors.info,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    Requested
                                </sup>
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.info,
                                    borderBottom: `3px solid ${colors.bgColor5}`,
                                    px: 1,
                                    borderRadius: 2,
                                }}
                            >
                                {stats.approved}
                                <sup
                                    style={{
                                        color: colors.success,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    Approved
                                </sup>
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.info,
                                    borderBottom: `3px solid ${colors.bgColor5}`,
                                    px: 1,
                                    borderRadius: 2,
                                }}
                            >
                                {stats.denied}
                                <sup
                                    style={{
                                        color: colors.warning,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    Denied
                                </sup>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default OrganizationSidebar;
