import React from 'react';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ManageAccounts } from '@mui/icons-material';
import * as Yup from 'yup';
import CustomTextArea from '../../../components/Forms/CustomTextArea';
import { useDispatch } from 'react-redux';
import { DialogActions, Grid, MenuItem } from '@mui/material';
import useRTK from '../../../hooks/useRTK';
import { useAddDepartmentMutation } from '../../../api/settings/departmentsAPI';
import CustomSelectInput from '../../../components/Forms/CustomSelectInput';
import { addDepartment } from '../../../states/features/settings/departmentsSlice';

// -> validate settings form
const validate = Yup.object({
    name: Yup.string().required(),
    description: Yup.string().nullable(),
    sector_id: Yup.string().required('Sector is required field'),
});

const AddDepartment = ({ handleClose, refetch, sectors }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        createDepartment,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useAddDepartmentMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        refetch();
        handleClose();
        dispatch(addDepartment(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    sector_id: '',
                    name: '',
                    description: '',
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    createDepartment(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container columnSpacing={4}>
                            <Grid item sm={6}>
                                <CustomSelectInput
                                    type="text"
                                    name="sector_id"
                                    label="Select sector"
                                    formik={formik}
                                    color="primary"
                                >
                                    {sectors?.map((sector) => (
                                        <MenuItem value={sector.id}>
                                            {sector.name}
                                        </MenuItem>
                                    ))}
                                </CustomSelectInput>
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    label="Name"
                                    name="name"
                                    type="text"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomTextArea
                                    label="Description"
                                    name="description"
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <CustomSubmitButton
                                startIcon={<ManageAccounts />}
                                onClick={formik.handleSubmit}
                                loading={isLoading}
                            >
                                Add setting
                            </CustomSubmitButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddDepartment;
