// => GET packages
const GET_ALL_PACKAGEs = 'subscription-packages';

// => CREATE package
const CREATE_PACKAGE = 'subscription-packages';

// => ACTIVATE package
const ACTIVATE_PACKAGE = 'subscription-packages/activate';

// => DEACTIVATE package
const DEACTIVATE_PACKAGE = 'subscription-packages/deactivate';

// => UPDATE package
const UPDATE_PACKAGE = 'subscription-packages';

// => DELETE package
const DELETE_PACKAGE = 'subscription-packages';

export const subscriptionPackageEndpoints = {
    GET_ALL_PACKAGEs,
    CREATE_PACKAGE,
    ACTIVATE_PACKAGE,
    DEACTIVATE_PACKAGE,
    UPDATE_PACKAGE,
    DELETE_PACKAGE,
};
