import React from "react";
import { Form, Formik } from "formik";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { Print } from "@mui/icons-material";
import { Grid, MenuItem } from "@mui/material";
import SmallCustomSelectInput from "../../../components/Forms/SmallCustomSelectInput";
import { colors } from "../../../assets/utils/constants";
import SmallCustomTextField from "../../../components/Forms/SmallCustomTextField";


const ApplicantReportCRUD = ({ handler }) => {
    const jobStatus = [
        {
            status: "draft",
        },
        {
            status: "published",
        },
        {
            status: "expired",
        },
        {
            status: "unpublished",
        },
        {
            status: "banned",
        },
        {
            status: "closed",
        },
        {
            status: "pending",
        },
    ];
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{ job_status: "", from: "", to: "" }}
                onSubmit={(payload) => {
                    handler(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <SmallCustomSelectInput
                                    label="Job status"
                                    type="text"
                                    name="job_status"
                                    color={colors.primary}
                                    formik={formik}
                                >
                                    {jobStatus?.map((status) => (
                                        <MenuItem
                                            key={status.status}
                                            value={status.status}
                                        >
                                            {status.status}
                                        </MenuItem>
                                    ))}
                                </SmallCustomSelectInput>
                            </Grid>
                            <Grid item sm={6}>
                                <SmallCustomTextField
                                    label="From (Posted date)"
                                    name="from"
                                    type="date"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <SmallCustomTextField
                                    label="To (Posted date)"
                                    name="to"
                                    type="date"
                                />
                            </Grid>

                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    startIcon={<Print />}
                                    onClick={formik.handleSubmit}
                                    sx={{ py: 2 }}
                                >
                                    Generate report
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ApplicantReportCRUD;
