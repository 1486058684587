import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { organizationTypeEndpoints } from './settingEndpoints';
import { settingAPI } from './settingAPI';

export const organizationTypesAPI = createApi({
    reducerPath: 'organizationTypesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getOrganizationTypes: builder.query({
            query: () => `${organizationTypeEndpoints.GET_ORGANIZATION_TYPES}`,
        }),
        // => ADD
        addOrganizationType: builder.mutation({
            query: (payload) => ({
                url: `${organizationTypeEndpoints.ADD_ORGANIZATION_TYPE}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
        // => UPDATE
        updateOrganizationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${organizationTypeEndpoints.UPDATE_ORGANIZATION_TYPE}/` +
                    payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateOrganizationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${organizationTypeEndpoints.ACTIVATE_ORGANIZATION_TYPE}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateOrganizationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${organizationTypeEndpoints.DEACTIVATE_ORGANIZATION_TYPE}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteOrganizationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${organizationTypeEndpoints.DELETE_ORGANIZATION_TYPE}/` +
                    payload,
                method: `DELETE`,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
    }),
});

export const {
    useGetOrganizationTypesQuery,
    useAddOrganizationTypeMutation,
    useUpdateOrganizationTypeMutation,
    useActivateOrganizationTypeMutation,
    useDeactivateOrganizationTypeMutation,
    useDeleteOrganizationTypeMutation,
} = organizationTypesAPI;
