import React, { useState } from "react";
import {
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { Add, DeleteForever, Edit, TaskAltTwoTone } from "@mui/icons-material";
import { colors } from "../../assets/utils/constants";
import CustomDialog from "../Dialogies/CustomDialog";
import ActivateDeactivateFreeTrialPackage from "../../pages/subscriptionPackages/CRUD/ActivateDeactivateFreeTrialPackage";
import DeleteFreeTrialPackage from "../../pages/subscriptionPackages/CRUD/DeleteFreeTrialPackage";
import UpdateFreeTrialPackage from "../../pages/subscriptionPackages/CRUD/UpdateFreeTrialPackage";
import CreateFreeTrialBenefit from "../../pages/subscriptionPackages/CRUD/CreateFreeTrialBenefit";
import DeleteFreeTrialBenefit from "../../pages/subscriptionPackages/CRUD/DeleteFreeTrialBenefit";
import UpdateFreeTrialBenefit from "../../pages/subscriptionPackages/CRUD/UpdateFreeTrialBenefit";
import { isEmpty } from "lodash";
import NoContent from "../NoContent";

const FreeTrialPackageWidget = ({ subscriptionPackage }) => {
    // ############## Comp State ##################
    const [dialogyOpen, setDialogyOpen] = useState(false);
    const [dialogyOpenBenefit, setDialogyOpenBenefit] = useState(false);
    const [actionType, setActionType] = useState("");
    const [actionTypeBenefit, setActionTypeBenefit] = useState("");
    const [packageBenefit, setPackageBenefit] = useState({});
    const [freeTrialPackage, setFreeTrialPackageBenefit] = useState({});

    // ############## FUNC #####################
    // => Handle docs open
    const handleDialogOpen = (type) => {
        setActionType(type);
        setDialogyOpen(true);
    };
    // => Dialogy closer
    const handleDialogClose = () => {
        setDialogyOpen(false);
    };
    // => Handle docs open
    const handleDialogOpenBenefit = (type, trialPackage, trialBenefit) => {
        setActionTypeBenefit(type);
        setDialogyOpenBenefit(true);
        setPackageBenefit(trialBenefit);
        setFreeTrialPackageBenefit(trialPackage);
    };
    // => Dialogy closer
    const handleDialogCloseBenefit = () => {
        setDialogyOpenBenefit(false);
    };

    return (
        <>
            {/* ################# Dialogy ################## */}
            <CustomDialog
                openDialog={dialogyOpen}
                handleDialogClose={handleDialogClose}
                head={
                    actionType === "update"
                        ? "Update free trial package"
                        : actionType === "delete"
                        ? "Confirm free trial package deletion"
                        : ""
                }
            >
                {actionType === "update" ? (
                    <UpdateFreeTrialPackage
                        handleDialogClose={handleDialogClose}
                        subscriptionPackage={subscriptionPackage}
                    />
                ) : actionType === "delete" ? (
                    <DeleteFreeTrialPackage
                        handleDialogClose={handleDialogClose}
                        subscriptionPackage={subscriptionPackage}
                    />
                ) : (
                    ""
                )}
            </CustomDialog>
            <CustomDialog
                openDialog={dialogyOpenBenefit}
                handleDialogClose={handleDialogCloseBenefit}
                head={
                    actionTypeBenefit === "update"
                        ? "Update free trial package benefit"
                        : actionTypeBenefit === "delete"
                        ? "Confirm free trial package benefit deletion"
                        : "Add free trial benefit"
                }
            >
                {actionTypeBenefit === "update" ? (
                    <UpdateFreeTrialBenefit
                        handleDialogClose={handleDialogCloseBenefit}
                        packageBenefit={packageBenefit}
                    />
                ) : actionTypeBenefit === "delete" ? (
                    <DeleteFreeTrialBenefit
                        handleDialogClose={handleDialogCloseBenefit}
                        packageBenefit={packageBenefit}
                    />
                ) : (
                    <CreateFreeTrialBenefit
                        handleDialogClose={handleDialogCloseBenefit}
                        freeTrialPackage={freeTrialPackage}
                    />
                )}
            </CustomDialog>

            <Box
                sx={{
                    display: "flex",
                    position: "relative",
                    justifyContent: "center",
                    border: `5px solid ${colors.secondary}`,
                    borderRadius: 3,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        "& .action-btn": {
                            transition: `.3s ease-in-out all`,
                        },
                        "& .price-con": {
                            transition: `.3s ease-in-out all`,
                        },
                        ":hover": {
                            "& .action-btn": {
                                bottom: 48,
                                transition: `.3s ease-in-out all`,
                            },
                            "& .price-con": {
                                transition: `.3s ease-in-out all`,
                                boxShadow: `1px 1px 5px 2px ${colors.bgColor5}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                            },
                        },
                    }}
                >
                    <Box
                        sx={{
                            background: `linear-gradient(180deg, ${colors.secondary}, ${colors.bgColor3})`,
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            pb: 5,
                        }}
                    >
                        <Box
                            sx={{
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                                borderBottomRightRadius: 10,
                                borderBottomLeftRadius: 10,
                                color: colors.info,
                                boxShadow: `15px 8px 10px ${colors.bgColor5}`,
                                position: "relative",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    opacity: 0.8,
                                    pt: 1,
                                }}
                            >
                                {subscriptionPackage.name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                    pb: 1,
                                    color: colors.warning,
                                }}
                            >
                                ( {subscriptionPackage.user} )
                            </Typography>
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: -30,
                                    left: 3,
                                }}
                            >
                                <Chip
                                    label={
                                        subscriptionPackage.status === 1
                                            ? "Active"
                                            : "In-active"
                                    }
                                    color={
                                        subscriptionPackage.status === 1
                                            ? "success"
                                            : "warning"
                                    }
                                    size="small"
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: 12,
                                        opacity: 0.8,
                                        height: 18,
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ px: 2, mt: 5 }}>
                            <Box>
                                <Typography
                                    sx={{
                                        display: "flex",
                                        // alignItems: "center",
                                        fontSize: 15,
                                    }}
                                >
                                    <TaskAltTwoTone
                                        sx={{
                                            fontSize: 18,
                                            mr: 0.5,
                                            color: colors.success,
                                        }}
                                    />
                                    {subscriptionPackage.description}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ px: 2 }}>
                            <Box
                                sx={{
                                    borderBottom: `2px dotted ${colors.info}`,
                                    my: 1,
                                    position: "relative",
                                    width: "100%",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        opacity: 0.8,
                                        textAlign: "center",
                                    }}
                                >
                                    Benefit
                                </Typography>
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: -5,
                                        right: 0,
                                    }}
                                >
                                    <Tooltip title="Add">
                                        <IconButton
                                            size="small"
                                            color="info"
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => {
                                                let type = "add";
                                                handleDialogOpenBenefit(
                                                    type,
                                                    subscriptionPackage,
                                                    {}
                                                );
                                            }}
                                        >
                                            <Add
                                                sx={{
                                                    fontSize: 14,
                                                }}
                                            />
                                            add benefit
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box sx={{ height: 200, overflowY: "auto" }}>
                                <Grid container spacing={2}>
                                    {isEmpty(subscriptionPackage.benefits) ? (
                                        <>
                                            <Grid item sm={12}>
                                                <NoContent
                                                    height={200}
                                                    message={"No benefit added"}
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            {subscriptionPackage.benefits.map(
                                                (benefit, index) => (
                                                    <Grid
                                                        item
                                                        sm={12}
                                                        sx={{
                                                            borderBottom: `1px dotted ${colors.primary}`,
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 17,
                                                                        fontWeight:
                                                                            "bold",
                                                                        opacity: 0.8,
                                                                        mr: 1,
                                                                    }}
                                                                >
                                                                    {++index}
                                                                    &nbsp;:
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    {
                                                                        benefit.benefit
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "center",
                                                                    flexDirection:
                                                                        "column",
                                                                }}
                                                            >
                                                                <Tooltip title="Edit">
                                                                    <IconButton
                                                                        size="small"
                                                                        color="warning"
                                                                        sx={{
                                                                            mb: 1,
                                                                        }}
                                                                        onClick={() => {
                                                                            let type =
                                                                                "update";
                                                                            handleDialogOpenBenefit(
                                                                                type,
                                                                                subscriptionPackage,
                                                                                benefit
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Edit
                                                                            sx={{
                                                                                fontSize: 14,
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete">
                                                                    <IconButton
                                                                        size="small"
                                                                        color="error"
                                                                        onClick={() => {
                                                                            let type =
                                                                                "delete";
                                                                            handleDialogOpenBenefit(
                                                                                type,
                                                                                "",
                                                                                benefit
                                                                            );
                                                                        }}
                                                                    >
                                                                        <DeleteForever
                                                                            sx={{
                                                                                fontSize: 14,
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                )
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        className="price-con"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            // justifyContent: "center",
                            width: "100%",
                            // position: "absolute",
                            bottom: 0,
                            borderTopRightRadius: 55,
                            border: `2px solid ${colors.secondary}`,
                            boxShadow: `1px 1px 3px 2px ${colors.bgColor4}`,
                        }}
                    >
                        <Box
                            sx={{
                                height: 75,
                                width: 65,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                position: "absolute",
                                right: -10,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                border: `2px dotted ${colors.warning}`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    color: "red",
                                }}
                            >
                                {subscriptionPackage.duration}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 11,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                }}
                            >
                                Days
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                py: 0.5,
                                display: "flex",
                                alignItems: "flex-start",
                                bottom: 0,
                                right: 0,
                                left: 0,
                                px: 2,
                            }}
                        >
                            <ActivateDeactivateFreeTrialPackage
                                subscriptionPackage={subscriptionPackage}
                            />
                            <Tooltip title="Update plan" arrow placement="top">
                                <Button
                                    size="small"
                                    fullWidth
                                    variant="contained"
                                    sx={{ width: 10, opacity: 0.8, mx: 2 }}
                                    color="warning"
                                    onClick={() => {
                                        let type = "update";
                                        handleDialogOpen(type);
                                    }}
                                >
                                    <Edit sx={{ fontSize: 18 }} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Delete plan" arrow placement="top">
                                <Button
                                    size="small"
                                    fullWidth
                                    variant="contained"
                                    sx={{ width: 10, opacity: 0.8 }}
                                    color="error"
                                    onClick={() => {
                                        let type = "delete";
                                        handleDialogOpen(type);
                                    }}
                                >
                                    <DeleteForever sx={{ fontSize: 18 }} />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default FreeTrialPackageWidget;
