import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { adminBaseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { adminsEndpoints } from "./adminsEndpoints";

export const adminsAPI = createApi({
    reducerPath: "adminsAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ["Admins", "Admin"],
    endpoints: (builder) => ({
        // => GET all
        getAllAdmins: builder.query({
            query: () => `${adminsEndpoints.GET_ALL_ADMINS}`,
            providesTags: ["Admins"],
        }),

        // => GET single
        getSingleAdmin: builder.query({
            query: (payload) =>
                `${adminsEndpoints.GET_SINGLE_ADMIN}/${payload}`,
            providesTags: ["Admin"],
        }),
        // => CREATE
        createAdmin: builder.mutation({
            query: (payload) => ({
                url: `${adminsEndpoints.CREATE_ADMIN}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ["Admins", "Admin"]),
        }),
        // => UPDATE
        updateAdmin: builder.mutation({
            query: (payload) => ({
                url: `${adminsEndpoints.UPDATE_ADMIN}/` + payload.admin_id,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ["Admins", "Admin"]),
        }),
        // => ACTIVATE
        activateAdmin: builder.mutation({
            query: (payload) => ({
                url: `${adminsEndpoints.ACTIVATE_ADMIN}/` + payload,
                method: `PUT`,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ["Admins", "Admin"]),
        }),
        // => DEACTIVATE
        deactivateAdmin: builder.mutation({
            query: (payload) => ({
                url: `${adminsEndpoints.DEACTIVATE_ADMIN}/` + payload,
                method: `PUT`,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ["Admins", "Admin"]),
        }),
        // => SUSPEND
        suspendAdmin: builder.mutation({
            query: (payload) => ({
                url: `${adminsEndpoints.SUSPEND_ADMIN}/` + payload,
                method: `PUT`,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ["Admins", "Admin"]),
        }),
        // => DELETE
        deleteAdmin: builder.mutation({
            query: (payload) => ({
                url: `${adminsEndpoints.DELETE_ADMIN}/` + payload,
                method: `DELETE`,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ["Admins", "Admin"]),
        }),
    }),
});

export const {
    useGetSingleAdminQuery,
    useCreateAdminMutation,
    useUpdateAdminMutation,
    useActivateAdminMutation,
    useDeactivateAdminMutation,
    useSuspendAdminMutation,
    useDeleteAdminMutation,

    // => Lazy
    useLazyGetAllAdminsQuery,
} = adminsAPI;
