import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { beneficiaryEndpoints } from './beneficiaryEndpoints';

export const beneficiaryAPI = createApi({
    reducerPath: 'beneficiaryAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Beneficiaries', 'Beneficiary'],
    endpoints: (builder) => ({
        // => GET all
        getAllBeneficiaries: builder.query({
            query: () => `${beneficiaryEndpoints.GET_ALL_BENEFICIARIES}`,
            providesTags: ['Beneficiaries'],
        }),

        // => GET single
        getSingleBeneficiary: builder.query({
            query: (payload) =>
                `${beneficiaryEndpoints.GET_SINGLE_BENEFICIARY}/${payload}`,
            providesTags: ['Beneficiary'],
        }),

        // => VERIFY
        verifyBeneficiary: builder.mutation({
            query: (payload) => ({
                url: `${beneficiaryEndpoints.VERIFY_BENEFICIARY}/${payload}`,
                method: 'PUT',
            }),
            invalidatesTags: (args, error) =>
                error ? [] : ['Beneficiaries', 'Beneficiary'],
        }),

        // => DENY
        denyBeneficiary: builder.mutation({
            query: (payload) => ({
                url: `${beneficiaryEndpoints.DENY_BENEFICIARY}/${payload.beneficiary_id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (args, error) =>
                error ? [] : ['Beneficiaries', 'Beneficiary'],
        }),
    }),
});

export const {
    useGetSingleBeneficiaryQuery,
    useVerifyBeneficiaryMutation,
    useDenyBeneficiaryMutation,

    // => Lazy
    useLazyGetAllBeneficiariesQuery,
} = beneficiaryAPI;
