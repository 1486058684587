import React from 'react';
import PageLayout from '../layouts/PageLayout';
import { Box, Grid } from '@mui/material';
import EmployerWidget from '../../components/widgets/EmployerWidget';
import EmployerSidebar from '../../components/sidebars/EmployerSidebar';
import { paginator } from '../../helpers/paginationHelper';
import { useSelector } from 'react-redux';
import { employersSelector } from '../../states/features/selectors';
import { filter, isEmpty, size } from 'lodash';
import CountBadge from '../../components/CountBadge';
import NoContent from '../../components/NoContent';
import CustomPagination from '../../components/paginations/CustomPagination';
import { useParams } from 'react-router-dom';

const EmployerByStatus = () => {
    // ############## CONST ###################
    const { status } = useParams();

    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10);

    // ############# Redux state #############
    const { employers } = useSelector(employersSelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Employer by status
    const getEmployerByStatus = () => {
        let filteredEmployers = [];
        // => Inactive
        if (status === 'inactive') {
            filteredEmployers = filter(
                employers,
                ({ is_active }) => is_active === 0,
            );
        }
        // => Suspended
        if (status === 'suspended') {
            filteredEmployers = filter(
                employers,
                ({ status }) => status === 'suspended',
            );
        }
        // => Banned
        if (status === 'banned') {
            filteredEmployers = filter(
                employers,
                ({ status }) => status === 'banned',
            );
        }
        return filteredEmployers;
    };

    const employerByStatus = getEmployerByStatus();

    // => Pagination data
    const paginatedEmployers = paginator(employerByStatus, page, perPage);

    return (
        <>
            <PageLayout
                head={`${status} Wazawa max employers`}
                sidebar={<EmployerSidebar />}
            >
                <Grid container spacing={1.5}>
                    <Grid item sm={12}>
                        <CustomPagination
                            head={`${status} employers`}
                            height={'63.5vh'}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedEmployers}
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(employerByStatus)}
                                            item={'Employers'}
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(employerByStatus) ? (
                                <>
                                    <NoContent
                                        height={300}
                                        message={`No ${status} employers`}
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {paginatedEmployers.data.map((employer) => (
                                        <Grid item sm={4} key={employer.id}>
                                            <EmployerWidget
                                                employer={employer}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default EmployerByStatus;
