import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import { Box, DialogActions, Divider, Grid, Typography } from "@mui/material";
import { colors } from "../../../assets/utils/constants";
import { Cancel, DeleteForever } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import { setFreeTrialPackages } from "../../../states/features/subscriptionPackages/subscriptionPackagesSlice";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { useDeleteFreeTrialPackageBenefitMutation } from "../../../api/subscriptionPackages/freeTrialPackagesAPI";

const DeleteFreeTrialBenefit = ({ handleDialogClose, packageBenefit }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK #####################
    const [
        deleteSubscriptionPackage,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useDeleteFreeTrialPackageBenefitMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setFreeTrialPackages(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);
    return (
        <>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        textAlign: "center",
                        flexDirection: "column",
                    }}
                >
                    <Grid container>
                        <Grid item sm={12}>
                            <Typography
                                sx={{
                                    fontSize: 22,
                                    fontWeight: "bold",
                                    color: "red",
                                    opacity: 0.7,
                                }}
                            >
                                Please confirm free trial benefit deletion
                            </Typography>
                        </Grid>
                        <Grid item sm={12} sx={{ mt: 2 }}>
                            <Typography
                                sx={{
                                    opacity: 0.8,
                                }}
                            >
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Commodi eos recusandae
                                architecto saepe cum suscipit, eveniet vero ipsa
                                doloribus aliquam exercitationem iste, ullam
                                necessitatibus doloremque excepturi nesciunt
                                culpa! Sequi, officia.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <Divider sx={{ mt: 2 }} />
            <DialogActions>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        textAlign: "center",
                    }}
                >
                    <Grid container>
                        <Grid
                            item
                            sm={6}
                            sx={{
                                borderRight: `2px solid ${colors.gray}`,
                                padding: 0.5,
                            }}
                        >
                            <CustomSubmitButton
                                startIcon={<Cancel />}
                                btnColor={"primary"}
                                variant={"outlined"}
                                onClick={() => handleDialogClose()}
                                sx={{
                                    py: 2,
                                }}
                            >
                                Cancel
                            </CustomSubmitButton>
                        </Grid>
                        <Grid
                            item
                            sm={6}
                            sx={{
                                borderLeft: `2px solid ${colors.gray}`,
                                padding: 0.5,
                            }}
                        >
                            <CustomSubmitButton
                                loading={isLoading}
                                startIcon={<DeleteForever />}
                                btnColor={"error"}
                                onClick={() =>
                                    deleteSubscriptionPackage(packageBenefit?.id)
                                }
                                sx={{
                                    py: 2,
                                }}
                            >
                                Delete benefit
                            </CustomSubmitButton>
                        </Grid>
                    </Grid>
                </Box>
            </DialogActions>
        </>
    );
};

export default DeleteFreeTrialBenefit;
