import React from 'react';
import { Grid } from '@mui/material';
import StudentsTable from '../../components/Tables/StudentsTable';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import InfoboxWidget2 from '../../components/widgets/InfoboxWidget2';
import { useSelector } from 'react-redux';
import { studentsSelector } from '../../states/features/selectors';
import { isEmpty, size } from 'lodash';
import NoContent from '../../components/NoContent';
import StudentSidebar from '../../components/sidebars/StudentSidebar';
import CountBadge from '../../components/CountBadge';

const StudentsManagement = () => {
    // ############### Redux state ##############
    const { students } = useSelector(studentsSelector);

    const infoBoxData = [
        {
            id: 1,
            title1: 'Beneficiary students',
            title2: 'Talents',
            total: size(3),
            url: 'training-managements',
        },
        {
            id: 2,
            title1: 'On-training students',
            title2: 'On field',
            total: size(3),
            url: 'training-managements',
        },
        {
            id: 3,
            title1: 'Graduated students',
            title2: 'Graduator',
            total: size(3),
            url: '/students/trainings',
        },
    ];

    return (
        <>
            <PageLayout head={"Students managements"} noSidebar>
                <Grid container spacing={2}>
                    <Grid item sm={8}>
                        <Grid container spacing={1.5}>
                            {infoBoxData.map((data) => (
                                <Grid item sm={4} key={data.id}>
                                    <InfoboxWidget2 data={data} height={70} />
                                </Grid>
                            ))}
                            <Grid item sm={12}>
                                <CustomCard2
                                    head={"Registered students"}
                                    padding={0}
                                    action={
                                        <CountBadge
                                            item={"Students"}
                                            total={size(students)}
                                        />
                                    }
                                >
                                    {!isEmpty(students) ? (
                                        <StudentsTable
                                            height={"48vh"}
                                            students={students}
                                        />
                                    ) : (
                                        <NoContent
                                            height={"57vh"}
                                            message={"No student added"}
                                        />
                                    )}
                                </CustomCard2>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={4}>
                        <StudentSidebar />
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default StudentsManagement;
