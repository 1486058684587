import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { qualificationAwardEndpoints } from './settingEndpoints';
import { settingAPI } from './settingAPI';

export const qualificationAwardsAPI = createApi({
    reducerPath: 'qualificationAwardsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getQualificationAwards: builder.query({
            query: () =>
                `${qualificationAwardEndpoints.GET_QUALIFICATION_AWARDS}`,
        }),
        // => ADD
        addQualificationAward: builder.mutation({
            query: (payload) => ({
                url: `${qualificationAwardEndpoints.ADD_QUALIFICATION_AWARD}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
        // => UPDATE
        updateQualificationAward: builder.mutation({
            query: (payload) => ({
                url:
                    `${qualificationAwardEndpoints.UPDATE_QUALIFICATION_AWARD}/` +
                    payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateQualificationAward: builder.mutation({
            query: (payload) => ({
                url:
                    `${qualificationAwardEndpoints.ACTIVATE_QUALIFICATION_AWARD}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateQualificationAward: builder.mutation({
            query: (payload) => ({
                url:
                    `${qualificationAwardEndpoints.DEACTIVATE_QUALIFICATION_AWARD}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteQualificationAward: builder.mutation({
            query: (payload) => ({
                url:
                    `${qualificationAwardEndpoints.DELETE_QUALIFICATION_AWARD}/` +
                    payload,
                method: `DELETE`,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
    }),
});

export const {
    useGetQualificationAwardsQuery,
    useAddQualificationAwardMutation,
    useUpdateQualificationAwardMutation,
    useActivateQualificationAwardMutation,
    useDeactivateQualificationAwardMutation,
    useDeleteQualificationAwardMutation,
} = qualificationAwardsAPI;
