import React from 'react';
import { DeleteForever } from '@mui/icons-material';
import useRTK from '../../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress } from '@mui/material';
import { removeCustomer } from '../../../states/features/promotions/promotionCustomersSlice';
import { useDeletePromotionsCustomerMutation } from '../../../api/promotions/promotionsCustomersAPI';

const DeleteCustomer = ({ customer }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        deleteCustomer,
        {
            isLoading: deleteLoading,
            isSuccess: deleteSuccess,
            isError: deleteIsError,
            error: deleteError,
            data: deleteData,
        },
    ] = useDeletePromotionsCustomerMutation();
    // ############# useEffect ##################
    const rtkActions = (data) => {
        navigate(`/promotions/customers`);
        dispatch(removeCustomer(data));
    };
    RTK.useRTKResponse(
        deleteSuccess,
        deleteIsError,
        deleteError,
        deleteData,
        rtkActions,
    );
    return (
        <Button
            size="small"
            onClick={() => deleteCustomer(customer.id)}
            disabled={deleteLoading ? true : false}
            variant="outlined"
            color="error"
            startIcon={<DeleteForever />}
            sx={{ ml: 1 }}
        >
            Delete
            {deleteLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                    }}
                >
                    <CircularProgress
                        size={23}
                        color="secondary"
                        sx={{
                            my: 0.25,
                            mt: 1.3,
                        }}
                    />
                </Box>
            )}
        </Button>
    );
};

export default DeleteCustomer;
