import React from 'react';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ManageAccounts } from '@mui/icons-material';
import * as Yup from 'yup';
import CustomTextArea from '../../../components/Forms/CustomTextArea';
import { useDispatch } from 'react-redux';
import { DialogActions, Grid } from '@mui/material';
import useRTK from '../../../hooks/useRTK';
import { setExperienceLevels } from '../../../states/features/settings/experienceLevelsSlice';
import { useUpdateExperienceLevelMutation } from '../../../api/settings/experienceLevelAPI';

// -> validate settings form
const validate = Yup.object({
    experience: Yup.string().required(),
    percentage: Yup.number().required().min(0).max(100),
    description: Yup.string().nullable(),
});

const UpdateExperienceLevel = ({ handleClose, refetch, editedValues }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        updateExperienceLevel,
        { isLoading, isSuccess, isError, error, data: updateData },
    ] = useUpdateExperienceLevelMutation();

    // ############# RTK response ##################
    const rtkActions = (data) => {
        refetch();
        handleClose(data);
        dispatch(setExperienceLevels(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updateData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                 initialValues={{
                    id: editedValues.id,
                    experience: editedValues.experience,
                    percentage: editedValues.percentage,
                    description: editedValues.description,
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    updateExperienceLevel(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container columnSpacing={2}>
                            <Grid item sm={6}>
                                <CustomTextField
                                    label="Experience level"
                                    name="experience"
                                    type="text"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    label="Percentage (For job posting)"
                                    name="percentage"
                                    type="number"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomTextArea
                                    label="Description"
                                    name="description"
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <CustomSubmitButton
                                startIcon={<ManageAccounts />}
                                onClick={formik.handleSubmit}
                                loading={isLoading}
                            >
                                Update setting
                            </CustomSubmitButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateExperienceLevel;
