// ############### ORG job endpoints #################
// => Get all org jobs
const GET_ORG_JOBS = "/jobs";

// => Get single org job
const GET_SINGLE_JOBS = "/jobs";

// => Get single org jobs
const GET_SINGLE_ORG_JOBS = "/jobs/organizations";

// => Suspend org job
const SUSPEND_JOB = "/jobs/suspend";

// => Unsuspend org job
const UNSUSPEND_JOB = "/jobs/unsuspend";

// ############### EMPLOYER job endpoints #################
// => Get all employer jobs
const GET_ALL_EMPLOYER_JOBS = "/employers/jobs/all";

// => Get single employer job
const GET_EMPLOYER_SINGLE_JOB = "/employers/jobs/single";

// => Get single employer jobs
const GET_SINGLE_EMPLOYER_JOBS = "/employers/jobs";

// => Suspend employer job
const SUSPEND_EMPLOYER_JOB = "/employers/jobs/suspend";

// => Unsuspend employer job
const UNSUSPEND_EMPLOYER_JOB = "/employers/jobs/unsuspend";

// => Approve employer job
const APPROVE_EMPLOYER_JOB = "/employers/jobs/approve";

// => Deny employer job
const DENY_EMPLOYER_JOB = "/employers/jobs/deny";

export const jobEndpoints = {
    GET_ORG_JOBS,
    GET_SINGLE_JOBS,
    GET_SINGLE_ORG_JOBS,
    SUSPEND_JOB,
    UNSUSPEND_JOB,
    GET_ALL_EMPLOYER_JOBS,
    GET_EMPLOYER_SINGLE_JOB,
    GET_SINGLE_EMPLOYER_JOBS,
    SUSPEND_EMPLOYER_JOB,
    UNSUSPEND_EMPLOYER_JOB,
    APPROVE_EMPLOYER_JOB,
    DENY_EMPLOYER_JOB,
};
