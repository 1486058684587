import React from 'react';
import { Form, Formik } from 'formik';
import { colors } from '../../assets/utils/constants';
import CustomTextField from '../../components/Forms/CustomTextField';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

// ======= MUI styled comp ===============
import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';

// ======== form box ==============
const FormBox = styled(Box)(({ theme }) => ({
    'backgroundColor': colors.primary,
    'zIndex': 999,
    'borderTopLeftRadius': 50,
    'borderBottomRightRadius': 50,
    'padding': '50px 25px',
    '& .head': {
        fontSize: 50,
        textAlign: 'center',
        marginBottom: 0,
        color: colors.secondary,
    },
    '& .sub-head': {
        fontSize: 20,
        textAlign: 'center',
        marginTop: -10,
        color: colors.gray,
    },
    '& .form-input': {
        '& label': {
            color: `${colors.secondary}!important`,
        },
        '& input': {
            color: `${colors.gray}!important`,
            paddingLeft: 16,
        },
    },
    '& .sigin-btn': {
        'backgroundColor': colors.secondary,
        'color': colors.primary,
        'paddingLeft': 120,
        'paddingRight': 120,
        'paddingTop': 10,
        'paddingBottom': 10,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            backgroundColor: colors.secondary,
            opacity: 0.8,
            transition: 'all ease-in-out .2s',
        },
    },
    '& .forget': {
        'color': colors.gray,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            color: colors.secondary,
            transition: 'all ease-in-out .2s',
        },
    },
    [theme.breakpoints.down('md')]: {
        'padding': '50px 5px',
        '& .head': {
            fontSize: 35,
        },
        '& .sub-head': {
            fontSize: 15,
        },
        '& .sigin-btn': {
            paddingLeft: 30,
            paddingRight: 30,
        },
    },
}));

// ========= validation ================
const validation = Yup.object({
    username: Yup.string().required(),
    password: Yup.string().required(),
});

const ForgetPassword = () => {
    return (
        <React.Fragment>
            <Grid item sm={10}>
                <FormBox>
                    <Typography className="head">Password reset</Typography>
                    <Typography className="sub-head">
                        Provide a registered phone number
                    </Typography>
                    {/* ######### Form ######## */}
                    <Formik
                        initialValues={{
                            phone: '',
                        }}
                        validationSchema={validation}
                        onSubmit={(values) => {}}
                    >
                        {(formik) => (
                            <Form style={{ marginTop: 25 }}>
                                <Grid container justifyContent={'center'}>
                                    {/* ######### phone ######### */}
                                    <Grid item sm={10} xs={11}>
                                        <CustomTextField
                                            className="form-input"
                                            label="Phone number"
                                            type="text"
                                            name="Phone"
                                        />
                                    </Grid>

                                    {/* ######### sign btn ######### */}
                                    <Grid
                                        item
                                        sm={10}
                                        md={10}
                                        xs={10}
                                        sx={{
                                            marginTop: 2.5,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            className="sigin-btn"
                                            onClick={formik.handleSubmit}
                                        >
                                            Request new password
                                        </Button>
                                    </Grid>

                                    {/* ######### forget ######### */}
                                    <Grid
                                        item
                                        sm={10}
                                        md={10}
                                        xs={10}
                                        sx={{
                                            marginTop: 2.5,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Link to={'/'}>
                                            <Typography className="forget">
                                                Sign in here ...
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </FormBox>
            </Grid>
        </React.Fragment>
    );
};

export default ForgetPassword;
