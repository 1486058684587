import { createSlice } from '@reduxjs/toolkit';
import { updateArrObj } from '../../../helpers/arrayHelpers';

const initialState = {
    notifications: {},
    notificationDetails: [],
    notificationPage: 'home',
};

const notificationsSlice = createSlice({
    name: 'notificationsSlice',
    initialState,
    reducers: {
        // => Set not's
        setNotifications: (state, action) => {
            state.notifications = action.payload;
        },

        // => Set not details
        setNotificationDetails: (state, action) => {
            state.notificationDetails = action.payload;
        },

        // => Set not details
        setNotificationPage: (state, action) => {
            state.notificationPage = action.payload;
        },

        // => Update not
        updateNotification: (state, action) => {
            let newNot = action.payload;
            let updatedNotifications = updateArrObj(
                'id',
                newNot,
                state.notifications,
            );
            state.notifications = updatedNotifications;
        },
        resetNotificationStates: (state) => {
            state.notifications = {};
            state.notificationDetails = [];
            state.notificationPage = 'home';
        },
    },
});

export const {
    setNotifications,
    setNotificationDetails,
    updateNotification,
    setNotificationPage,
    resetNotificationStates,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
