import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    identificationTypes: [],
};

// !########## SLICE #############
const identificationTypesSlice = createSlice({
    name: 'identificationTypesSlice',
    initialState,
    reducers: {
        setIdentificationTypes: (state, actions) => {
            state.identificationTypes = actions.payload;
        },
        addIdentificationType: (state, actions) => {
            const identification_type = actions.payload;
            isEmpty(state.identificationTypes)
                ? (state.identificationTypes = [identification_type, ...[]])
                : (state.identificationTypes = [
                      identification_type,
                      ...state.identificationTypes,
                  ]);
        },
    },
});

export const {
    setIdentificationTypes,
    addIdentificationType,
} = identificationTypesSlice.actions;
export default identificationTypesSlice.reducer;
