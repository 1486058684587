import AppLayout from '../pages/layouts/AppLayout';
import Settings from '../pages/settings/Settings';
import UserStatus from '../pages/settings/userStatus/UserStatus';
import UserRoles from '../pages/settings/userRoles/UserRoles';
import VerificationTypes from '../pages/settings/verificationTypes/VerificationTypes';
import GroupStatuses from '../pages/settings/groupStatus/GroupStatuses';
import PaymentMethods from '../pages/settings/paymentMethods/PaymentMethods';
import Sectors from '../pages/settings/sectors/Sectors';
import IdentificationTypes from '../pages/settings/identificationTypes/IdentificationTypes';
import JobCategories from '../pages/settings/jobCategories/JobCategories';
import OrganizationCategories from '../pages/settings/organizationCategories/OrganizationCategories';
import OrganizationTypes from '../pages/settings/organizationTypes/OrganizationTypes';
import Departments from '../pages/settings/departments/Departments';
import Sections from '../pages/settings/sections/Sections';
import QualificationAwards from '../pages/settings/qualificationAward/QualificationAwards';
import EducationLevel from '../pages/settings/educationLevels/EducationLevel';
import ExperienceLevels from '../pages/settings/experienceLevels/ExperienceLevels';
import JobPostPricing from '../pages/settings/JobPostPricing';
import Regions from '../pages/settings/regions/Regions';
import Districts from '../pages/settings/districts/Districts';
import RelationTypes from '../pages/settings/relationTypes/RelationTypes';

// -> settings
export const SettingsElement = () => (
    <AppLayout>
        <Settings />
    </AppLayout>
);

// -> user status settings
export const UserStatusElement = () => (
    <AppLayout>
        <UserStatus />
    </AppLayout>
);

// -> user roles settings
export const UserRolesElement = () => (
    <AppLayout>
        <UserRoles />
    </AppLayout>
);

// -> job category settings
export const JobCategoriesElement = () => (
    <AppLayout>
        <JobCategories />
    </AppLayout>
);

// -> organization categories settings
export const OrganizationCategoryElement = () => (
    <AppLayout>
        <OrganizationCategories />
    </AppLayout>
);

// -> organizations types settings
export const OrganizationTypeElement = () => (
    <AppLayout>
        <OrganizationTypes />
    </AppLayout>
);

// -> sectors settings
export const SectorsElement = () => (
    <AppLayout>
        <Sectors />
    </AppLayout>
);

// -> depertments settings
export const DepertmentsElement = () => (
    <AppLayout>
        <Departments />
    </AppLayout>
);

// -> sections settings
export const SectionsElement = () => (
    <AppLayout>
        <Sections />
    </AppLayout>
);

// -> identification types settings
export const IdentificationTypesElement = () => (
    <AppLayout>
        <IdentificationTypes />
    </AppLayout>
);

// -> payment methods settings
export const PaymentMethodsElement = () => (
    <AppLayout>
        <PaymentMethods />
    </AppLayout>
);

// -> verification types settings
export const VerificationTypesElement = () => (
    <AppLayout>
        <VerificationTypes />
    </AppLayout>
);

// -> group status settings
export const GroupStatusElement = () => (
    <AppLayout>
        <GroupStatuses />
    </AppLayout>
);

// -> qualification award settings
export const QualificationAwardElement = () => (
    <AppLayout>
        <QualificationAwards />
    </AppLayout>
);

// -> education level settings
export const EducationLevelElement = () => (
    <AppLayout>
        <EducationLevel />
    </AppLayout>
);

// -> experience level settings
export const ExperienceLevelElement = () => (
    <AppLayout>
        <ExperienceLevels />
    </AppLayout>
);

// -> Jopb post pricing settings
export const JobPostPricingElement = () => (
    <AppLayout>
        <JobPostPricing />
    </AppLayout>
);

// -> Regions settings
export const RegionsElement = () => (
    <AppLayout>
        <Regions />
    </AppLayout>
);

// -> Districts settings
export const DistrictsElement = () => (
    <AppLayout>
        <Districts />
    </AppLayout>
);

// -> Relation type settings
export const RelationTypesElement = () => (
    <AppLayout>
        <RelationTypes />
    </AppLayout>
);
