import {
    Box,
    Card,
    CardHeader,
    Typography,
    styled,
} from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';

// -> styled MUI
const CustomCardStyled = styled(Card)(({ theme }) => ({
    'color': 'gray',
    'borderRadius': 10,
    'boxShadow': `1px 1px 5px 0px ${colors.bgColor5}`,
    '& .card-header': {
        'padding': 12,
        'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
        'textTransform': 'capitalize',
        '.MuiCardHeader-avatar': {
            position: 'relative',
            right: 20,
        },
        '.MuiCardHeader-subheader': {
            color: colors.primary,
            fontWeight: 'bold',
            fontSize: 13,
            marginLeft: 1.5,
        },
    },
}));

function CustomCard({ head, children, action, height }) {
    return (
        <React.Fragment>
            <CustomCardStyled>
                <CardHeader
                    className="card-header"
                    subheader={
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                color: colors.primary,
                                opacity: 0.8,
                            }}
                            component={'div'}
                            noWrap
                        >
                            {head}
                        </Typography>
                    }
                    action={action ? action : ''}
                ></CardHeader>
                <Box sx={{ p: 0, position: 'relative', height: height }}>
                    {children}
                </Box>
            </CustomCardStyled>
        </React.Fragment>
    );
}

export default CustomCard;
