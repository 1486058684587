import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { promotionsEndpoints } from './promotionsEndpoints';
import { promotionsCustomersAPI } from './promotionsCustomersAPI';

export const promotionsAPI = createApi({
    reducerPath: 'promotionsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Promotions', 'Promotion'],
    endpoints: (builder) => ({
        // => GET all
        getPromotions: builder.query({
            query: () => `${promotionsEndpoints.GET_ALL_PROMOTIONS}`,
            providesTags: ['Promotions'],
        }),
        // => GET one
        getPromotion: builder.query({
            query: (payload) =>
                `${promotionsEndpoints.GET_ONE_PROMOTION}/${payload}`,
            providesTags: ['Promotion'],
            invalidatesTags: (arg, error) => (error ? [] : ['Promotions']),
        }),
        // => CREATE
        createPromotion: builder.mutation({
            query: (payload) => ({
                url: `${promotionsEndpoints.CREATE_PROMOTION}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ['Promotions']),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(
                    promotionsCustomersAPI.util.invalidateTags([
                        'Customers',
                        'Customers',
                    ]),
                );
            },
        }),
        // => UPDATE
        updatePromotion: builder.mutation({
            query: (payload) => ({
                url:
                    `${promotionsEndpoints.UPDATE_PROMOTION}/` +
                    payload.get('promotion_id'),
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: (arg, error) =>
                error ? [] : ['Promotions', 'Promotion'],
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(
                    promotionsCustomersAPI.util.invalidateTags([
                        'Customers',
                        'Customer',
                    ]),
                );
            },
        }),
        // => ACTIVATE
        activatePromotion: builder.mutation({
            query: (payload) => ({
                url: `${promotionsEndpoints.ACTIVATE_PROMOTION}/` + payload,
                method: `PUT`,
            }),
            invalidatesTags: (arg, error) =>
                error ? [] : ['Promotions', 'Promotion'],
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(
                    promotionsCustomersAPI.util.invalidateTags([
                        'Customers',
                        'Customer',
                    ]),
                );
            },
        }),
        // => DEACTIVATE
        deactivatePromotion: builder.mutation({
            query: (payload) => ({
                url: `${promotionsEndpoints.DEACTIVATE_PROMOTION}/` + payload,
                method: `PUT`,
            }),
            invalidatesTags: (arg, error) =>
                error ? [] : ['Promotions', 'Promotion'],
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(
                    promotionsCustomersAPI.util.invalidateTags([
                        'Customers',
                        'Customer',
                    ]),
                );
            },
        }),

        // => PROMOTION SUBSCRIPTION
        promotionSubscription: builder.mutation({
            query: (payload) => ({
                url:
                    `${promotionsEndpoints.PROMOTION_SUBSCRIPTION}/` +
                    payload.promotion_id,
                method: `post`,
                body: payload,
            }),
            invalidatesTags: (arg, error) =>
                error ? [] : ['Promotions', 'Promotion'],
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(
                    promotionsCustomersAPI.util.invalidateTags([
                        'Customers',
                        'Customer',
                    ]),
                );
            },
        }),

        // => PAY SUBSCRIPTION PAYMENT
        payPromotionSubscription: builder.mutation({
            query: (payload) => ({
                url:
                    `${promotionsEndpoints.PAY_PROMOTION_SUBSCRIPTION}/` +
                    payload.promotion_id,
                method: `post`,
                body: payload,
            }),
            invalidatesTags: (arg, error) =>
                error ? [] : ['Promotions', 'Promotion'],
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(
                    promotionsCustomersAPI.util.invalidateTags([
                        'Customers',
                        'Customer',
                    ]),
                );
            },
        }),

        // => PUBLISH PROMOTION
        publishPromotion: builder.mutation({
            query: (payload) => ({
                url: `${promotionsEndpoints.PUBLISH_PROMOTION}/` + payload,
                method: `PUT`,
            }),
            invalidatesTags: (arg, error) =>
                error ? [] : ['Promotions', 'Promotion'],
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(
                    promotionsCustomersAPI.util.invalidateTags([
                        'Customers',
                        'Customer',
                    ]),
                );
            },
        }),

        // => DELETE
        deletePromotion: builder.mutation({
            query: (payload) => ({
                url: `${promotionsEndpoints.DELETE_PROMOTION}/` + payload,
                method: `DELETE`,
            }),
            invalidatesTags: (arg, error) =>
                error ? [] : ['Promotions', 'Promotion'],
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(
                    promotionsCustomersAPI.util.invalidateTags([
                        'Customers',
                        'Customer',
                    ]),
                );
            },
        }),
    }),
});

export const {
    useGetPromotionsQuery,
    useGetPromotionQuery,
    useCreatePromotionMutation,
    useUpdatePromotionMutation,
    useActivatePromotionMutation,
    useDeactivatePromotionMutation,
    usePublishPromotionMutation,
    useDeletePromotionMutation,
    usePromotionSubscriptionMutation,
    usePayPromotionSubscriptionMutation,

    // => Lazy
    useLazyGetPromotionsQuery,
} = promotionsAPI;
