import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { size } from 'lodash';
import { promotionCustomersSelector } from '../../states/features/selectors';
import CountBadge from '../../components/CountBadge';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import PromotionCustomerWidget2 from '../../components/widgets/PromotionCustomerWidget2';
import PromotionCustomerSidebar from '../../components/sidebars/PromotionCustomerSidebar';

const PromotionCustomers = () => {
    // ############## Redux state ###################
    const { customers } = useSelector(promotionCustomersSelector);

    return (
        <>
            <PageLayout
                head={`Promotion customers`}
                sidebar={<PromotionCustomerSidebar />}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard2
                            head={`All customers`}
                            height={550}
                            borderBottom
                            action={
                                <CountBadge
                                    total={size(customers)}
                                    item={'customers'}
                                />
                            }
                        >
                            <Grid container spacing={2}>
                                {customers.map((customer) => (
                                    <Grid item sm={4} key={customer.id}>
                                        <PromotionCustomerWidget2
                                            customer={customer}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default PromotionCustomers;
