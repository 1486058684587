import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { organizationCategoryEndpoints } from './settingEndpoints';
import { settingAPI } from './settingAPI';

export const organizationCategoriesAPI = createApi({
    reducerPath: 'organizationCategoriesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getOrganizationCatgories: builder.query({
            query: () =>
                `${organizationCategoryEndpoints.GET_ORGANIZATION_CATEGORIES}`,
        }),
        // => ADD
        addOrganizationCatgory: builder.mutation({
            query: (payload) => ({
                url: `${organizationCategoryEndpoints.ADD_ORGANIZATION_CATEGORY}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
        // => UPDATE
        updateOrganizationCatgory: builder.mutation({
            query: (payload) => ({
                url:
                    `${organizationCategoryEndpoints.UPDATE_ORGANIZATION_CATEGORY}/` +
                    payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateOrganizationCatgory: builder.mutation({
            query: (payload) => ({
                url:
                    `${organizationCategoryEndpoints.ACTIVATE_ORGANIZATION_CATEGORY}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateOrganizationCatgory: builder.mutation({
            query: (payload) => ({
                url:
                    `${organizationCategoryEndpoints.DEACTIVATE_ORGANIZATION_CATEGORY}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteOrganizationCatgory: builder.mutation({
            query: (payload) => ({
                url:
                    `${organizationCategoryEndpoints.DELETE_ORGANIZATION_CATEGORY}/` +
                    payload,
                method: `DELETE`,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
    }),
});

export const {
    useGetOrganizationCatgoriesQuery,
    useAddOrganizationCatgoryMutation,
    useUpdateOrganizationCatgoryMutation,
    useActivateOrganizationCatgoryMutation,
    useDeactivateOrganizationCatgoryMutation,
    useDeleteOrganizationCatgoryMutation,
} = organizationCategoriesAPI;
