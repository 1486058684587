import React from 'react';
import { DeleteForever } from '@mui/icons-material';
import useRTK from '../../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import { useDeleteBlogMutation } from '../../../api/blogs/blogsAPI';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress } from '@mui/material';
import { removeBlog } from '../../../states/features/blogs/blogsSlice';

const DeleteBlog = ({ blog }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        deleteBlog,
        {
            isLoading: deleteLoading,
            isSuccess: deleteSuccess,
            isError: deleteIsError,
            error: deleteError,
            data: deleteData,
        },
    ] = useDeleteBlogMutation();
    // ############# useEffect ##################
    const rtkActions = (data) => {
        navigate(`/blogs`);
        dispatch(removeBlog(data));
    };
    RTK.useRTKResponse(
        deleteSuccess,
        deleteIsError,
        deleteError,
        deleteData,
        rtkActions,
    );
    return (
        <Button
            size="small"
            onClick={() => deleteBlog(blog.id)}
            disabled={deleteLoading ? true : false}
            variant="outlined"
            color="error"
            startIcon={<DeleteForever />}
            sx={{ ml: 1 }}
        >
            Delete
            {deleteLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                    }}
                >
                    <CircularProgress
                        size={23}
                        color="secondary"
                        sx={{
                            my: 0.25,
                            mt: 1.3,
                        }}
                    />
                </Box>
            )}
        </Button>
    );
};

export default DeleteBlog;
