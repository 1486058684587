import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Button, IconButton } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import PageLayout from '../layouts/PageLayout';
import CountBadge from '../../components/CountBadge';
import { colors } from '../../assets/utils/constants';
import { Add, Edit } from '@mui/icons-material';
import CustomDialog from '../../components/Dialogies/CustomDialog';
import AddFaq from './CRUD/AddFaq';
import UpdateFaq from './CRUD/UpdateFaq';
import { useDispatch, useSelector } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import { faqsSelector } from '../../states/features/selectors';
import { useGetFaqsQuery } from '../../api/faqs/faqsAPI';
import { setFaqs } from '../../states/features/faqs/faqsSlice';
import ActivateDeactivate from './CRUD/ActivateDeactivate';
import DeleteFaq from './CRUD/DeleteFaq';
import { isEmpty, size } from 'lodash';
import NoContent from '../../components/NoContent';
import ContentLoader from '../../components/ContentLoader';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
    'border': `1px solid ${colors.bgColor5}`,
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
    'flexDirection': 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Faqs = () => {
    // ############## CONST ############
    const dispatch = useDispatch();
    const RTK = useRTK();

    // ############## Comp state ############
    const [expanded, setExpanded] = React.useState('panel-1');
    const [open, setOpen] = React.useState(false);
    const [dialogType, setDialogType] = React.useState('');
    const [faqDetails, setFaqDetails] = React.useState({});

    // ############## Redux state ############
    const { faqs } = useSelector(faqsSelector);

    // ############## RTK ############
    const { isLoading, isSuccess, data: faqData } = useGetFaqsQuery();
    const rtkActions = (data) => {
        dispatch(setFaqs(data));
    };
    RTK.useRTKFetch(isSuccess, faqData, rtkActions);

    // ############## FUNC ############
    const handleClickOpen = (type) => {
        setOpen(true);
        setDialogType(type);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            {/* ############## Dialogy ############## */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`Add new question`}
            >
                {dialogType === 'add' ? (
                    <AddFaq handleClose={handleClose} />
                ) : dialogType === 'update' ? (
                    <UpdateFaq handleClose={handleClose} faq={faqDetails} />
                ) : (
                    <></>
                )}
            </CustomDialog>

            <PageLayout head={'Frequency asked questions'} noSidebar>
                <CustomCard2
                    head={'All faqs'}
                    height={535}
                    borderBottom
                    action={
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: 300,
                            }}
                        >
                            <CountBadge total={size(faqs)} item={'Faqs'} />
                            <Button
                                sx={{
                                    height: 50,
                                    borderRadius: `15px !important`,
                                    border: `2px double ${colors.bgColor5}`,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                }}
                                onClick={() => {
                                    let type = 'add';
                                    handleClickOpen(type);
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Add
                                        color="info"
                                        sx={{
                                            p: 1,
                                            fontSize: 40,
                                            borderRadius: 50,
                                            mr: 1,
                                            border: `2px dotted ${colors.info}`,
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 'bold',
                                            opacity: 0.8,
                                        }}
                                    >
                                        Add new faq
                                    </Typography>
                                </Box>
                            </Button>
                        </Box>
                    }
                >
                    {isLoading ? (
                        <>
                            <ContentLoader />
                        </>
                    ) : isEmpty(faqs) ? (
                        <>
                            <NoContent message={'No Faq added'} />
                        </>
                    ) : (
                        faqs?.map((question, index) => (
                            <Accordion
                                key={index}
                                expanded={expanded === `panel-${++index}`}
                                onChange={handleChange(`panel-${index}`)}
                            >
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                opacity: 0.8,
                                            }}
                                        >
                                            {question.question}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <ActivateDeactivate
                                                faq={question}
                                            />
                                            <IconButton
                                                color="warning"
                                                onClick={() => {
                                                    let type = 'update';
                                                    setFaqDetails(question);
                                                    handleClickOpen(type);
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                            <DeleteFaq faq={question} />
                                            <Typography
                                                sx={{
                                                    height: 30,
                                                    width: 30,
                                                    ml: 2,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: 50,
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                                    boxShadow: `1px 1px 10px 0px ${colors.bgColor5}`,
                                                }}
                                            >
                                                {index}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{question.answer}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default Faqs;
