import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import useRTK from "../../hooks/useRTK";
import { isEmpty } from "lodash";
import { adminsSelector } from "../../states/features/selectors";
import { useGetSingleAdminQuery } from "../../api/admins/adminsAPI";
import { setAdminDetails } from "../../states/features/admins/adminsSlice";
import {
    Box,
    Button,
    Chip,
    Grid,
    Tab,
    Tabs,
    Typography,
    styled,
} from "@mui/material";
import {
    BlockOutlined,
    DeleteForever,
    ManageAccounts,
} from "@mui/icons-material";
import LeftbarDetails from "./LeftbarDetails";
import PropTypes from "prop-types";
import { colors } from "../../assets/utils/constants";
import banner from "../../assets/media/images/banner.jpg";
import GeneralDetails from "./GeneralDetails";
import PageLayout from "../layouts/PageLayout";
import UpdateAdmin from "./CRUD/UpdateAdmin";
import CustomDialog from "../../components/Dialogies/CustomDialog";
import CustomSubmitButton from "../../components/Forms/CustomSubmitButton";
import ActivateSuspendAdmin from "./CRUD/ActivateSuspendAdmin";
import DeleteAccount from "./CRUD/DeleteAccount";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const CustomTabPanel = styled(TabPanel)({});

// !################ MAIN FUNC ##################
const AdminDetails = () => {
    // ############### CONST #################
    const RTK = useRTK();
    let { admin_id } = useParams();
    const dispatch = useDispatch();

    // ################# Comp state ###################
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [actionType, setActionType] = React.useState("");

    // ############## Redux state #############
    const { adminDetails } = useSelector(adminsSelector);

    // ################# FUNC ###################
    // => handle tabs change
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // => handle dialogy open
    const handleClickOpen = (action) => {
        setOpen(true);
        setActionType(action);
    };
    // => handle dialogy close
    const handleClose = () => {
        setOpen(false);
    };

    // ############### RTK #################
    const {
        isLoading: profileLoading,
        isSuccess: profileSuccess,
        data: profileData,
    } = useGetSingleAdminQuery(admin_id);

    // ############## USEEFFECT ######################
    const rtkAction = (data) => {
        dispatch(setAdminDetails(data));
    };
    RTK.useRTKFetch(profileSuccess, profileData, rtkAction);

    return (
        <>
            {/* ################## Update profile Dialogy ################ */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={
                    actionType === "update"
                        ? "Update system admin account"
                        : actionType === "delete"
                        ? "Delete system admin account"
                        : ""
                }
            >
                {actionType === "update" ? (
                    <UpdateAdmin
                        handleClose={handleClose}
                        user={adminDetails}
                    />
                ) : actionType === "delete" ? (
                    <>
                        <DeleteAccount
                            handleClose={handleClose}
                            user={adminDetails}
                        />
                    </>
                ) : (
                    ""
                )}
            </CustomDialog>

            <PageLayout head={"System admin details"} noSidebar>
                {profileLoading ? (
                    <>
                        <PageLoader />
                    </>
                ) : (
                    !isEmpty(adminDetails) && (
                        <>
                            <Grid
                                container
                                sx={{
                                    position: "relative",
                                    height: 200,
                                    left: 0,
                                    right: 0,

                                    justifyContent: "space-between",
                                    backgroundImage: `url(${banner})`,
                                    borderRadius: 4,
                                }}
                            >
                                <Grid item sm={12}>
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            p: 10,
                                            background: `rgba(178, 218, 200, .6)`,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                textAlign: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 35,
                                                    fontWeight: "bold",
                                                    color: colors.primary,
                                                }}
                                            >
                                                {`${adminDetails.first_name} ${
                                                    !isEmpty(
                                                        adminDetails.middle_name
                                                    )
                                                        ? adminDetails.middle_name
                                                        : ""
                                                } ${adminDetails.last_name}`}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    fontWeight: "bold",
                                                    color: colors.primary,
                                                    bgcolor:
                                                        adminDetails.role ===
                                                        "Super admin"
                                                            ? colors.info
                                                            : colors.secondary,
                                                    borderRadius: 5,
                                                    width: "70%",
                                                    mb: 1,
                                                    color:
                                                        adminDetails.role ===
                                                        "Super admin"
                                                            ? colors.secondary
                                                            : colors.primary,
                                                }}
                                            >
                                                {adminDetails.role}
                                            </Typography>
                                            <Chip
                                                label={adminDetails.status}
                                                color={
                                                    adminDetails.status ===
                                                    "in-active"
                                                        ? "warning"
                                                        : adminDetails.status ===
                                                          "active"
                                                        ? "success"
                                                        : "error"
                                                }
                                                size="small"
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                textAlign: "end",
                                                position: "absolute",
                                                right: 20,
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <CustomSubmitButton
                                                startIcon={<ManageAccounts />}
                                                btnColor={"info"}
                                                onClick={() => {
                                                    let action = "update";
                                                    handleClickOpen(action);
                                                }}
                                                sx={{ py: 0.5 }}
                                            >
                                                Update account
                                            </CustomSubmitButton>
                                            <ActivateSuspendAdmin
                                                admin={adminDetails}
                                            />
                                            <Button
                                                variant="contained"
                                                startIcon={<DeleteForever />}
                                                onClick={() => {
                                                    let action = "delete";
                                                    handleClickOpen(action);
                                                }}
                                                color="error"
                                                size="small"
                                                sx={{ mb: 1.5 }}
                                            >
                                                Delete account
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent={"center"}
                                sx={{ mb: 5 }}
                            >
                                {/* ################## leftbar ############################## */}
                                <Grid
                                    item
                                    sm={3}
                                    style={{
                                        background: colors.primary,
                                        height: "70vh",
                                        borderBottomLeftRadius: 20,
                                        overflow: "hidden",
                                    }}
                                >
                                    <LeftbarDetails user={adminDetails} />
                                </Grid>
                                {/* ################### main content ######################## */}
                                <Grid
                                    item
                                    sm={8}
                                    style={{
                                        background: colors.bgColor1,
                                        height: "70vh",
                                        borderBottomRightRadius: 20,
                                    }}
                                >
                                    <Box sx={{ width: "100%" }}>
                                        <Box
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                            }}
                                        >
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                            >
                                                <Tab
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                fontWeight:
                                                                    "bold",
                                                                textTransform:
                                                                    "uppercase",
                                                                opacity: 0.9,
                                                                fontSize: 13,
                                                            }}
                                                        >
                                                            Personal information
                                                        </Typography>
                                                    }
                                                    {...a11yProps(0)}
                                                />
                                                {/* <Tab
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                fontWeight:
                                                                    "bold",
                                                                textTransform:
                                                                    "uppercase",
                                                                opacity: 0.9,
                                                                fontSize: 13,
                                                            }}
                                                        ></Typography>
                                                    }
                                                    {...a11yProps(1)}
                                                /> */}
                                            </Tabs>
                                        </Box>
                                        {/* ################ General details ################# */}
                                        <CustomTabPanel value={value} index={0}>
                                            <GeneralDetails
                                                user={adminDetails}
                                            />
                                        </CustomTabPanel>

                                        {/* ################ Change password ################# */}
                                        <CustomTabPanel value={value} index={1}>
                                            {/* <ChangePassword /> */}
                                        </CustomTabPanel>
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    )
                )}
            </PageLayout>
        </>
    );
};
export default AdminDetails;
