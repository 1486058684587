// => GET all
const GET_ALL_STUDENTS = '/students';

// => GET single
const GET_SINGLE_STUDENT = '/students';

// => GET org students
const GET_ORG_STUDENTS = '/students/institutions';

export const studentEndpoints = {
    GET_ALL_STUDENTS,
    GET_SINGLE_STUDENT,
    GET_ORG_STUDENTS,
};
