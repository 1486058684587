import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, CircularProgress, Switch } from '@mui/material';
import React, { useEffect } from 'react';
import {
    useActivateUserRoleMutation,
    useDeactivateUserRoleMutation,
    useDeleteUserRoleMutation,
} from '../../../api/settings/userRolesAPI';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../helpers/RTKHelpers';
import { errorNotify, successNotify } from '../../../helpers/notify';
import { useDispatch } from 'react-redux';
import { setUserRoles } from '../../../states/features/settings/userRolesSlice';

const ActionButtons = ({ row, refetch, handleEdit }) => {
    // ############# CONST ##################
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        activateUserRole,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivateUserRoleMutation();
    const [
        deactivateUserRole,
        {
            isLoading: deactivateLoading,
            isSuccess: deactivateSuccess,
            isError: deactivateIsError,
            error: deactivateError,
            data: deactivateData,
        },
    ] = useDeactivateUserRoleMutation();
    const [
        deleteUserRole,
        {
            isLoading: deleteLoading,
            isSuccess: deleteSuccess,
            isError: deleteIsError,
            error: deleteError,
            data: deleteData,
        },
    ] = useDeleteUserRoleMutation();

    // ############# useEffect ##################
    // => Activate res
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (activateSuccess) {
                refetch();
                successNotify(getRTKSuccessMessage(activateData));
                dispatch(setUserRoles(getRTKResponseData(activateData)));
            }
            if (activateIsError) {
                successNotify(getRTKErrorMessage(activateError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        activateSuccess,
        activateIsError,
        activateError,
        activateData,
        refetch,
        dispatch,
    ]);
    // => Deactivate res
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (deactivateSuccess) {
                refetch();
                successNotify(getRTKSuccessMessage(deactivateData));
                dispatch(setUserRoles(getRTKResponseData(deactivateData)));
            }
            if (deactivateIsError) {
                successNotify(getRTKErrorMessage(deactivateError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        deactivateSuccess,
        deactivateIsError,
        deactivateError,
        deactivateData,
        refetch,
        dispatch,
    ]);
    // => Delete res
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (deleteSuccess) {
                refetch();
                successNotify(getRTKSuccessMessage(deleteData));
                dispatch(setUserRoles(getRTKResponseData(deleteData)));
            }
            if (deleteIsError) {
                errorNotify(getRTKErrorMessage(deleteError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        deleteSuccess,
        deleteIsError,
        deleteError,
        deleteData,
        refetch,
        dispatch,
    ]);

    return (
        <>
            <Box>
                <Button
                    variant="outlined"
                    color="secondary"
                    disabled={
                        activateLoading || deactivateLoading ? true : false
                    }
                    sx={{
                        padding: 0.2,
                    }}
                    onClick={() =>
                        parseInt(row.status) === 1
                            ? deactivateUserRole(row.id)
                            : activateUserRole(row.id)
                    }
                >
                    {activateLoading || deactivateLoading ? (
                        <>
                            <CircularProgress
                                size={20}
                                sx={{
                                    my: 0.25,
                                }}
                            />
                        </>
                    ) : (
                        <Switch
                            size="small"
                            checked={parseInt(row.status) === 1 ? true : false}
                        />
                    )}
                </Button>
                <Button
                    variant="outlined"
                    color="warning"
                    onClick={() => {
                        let payload = {
                            id: row.id,
                            name: row.name,
                            description: row.description,
                        };
                        handleEdit(payload);
                    }}
                >
                    <Edit
                        style={{
                            fontSize: 16,
                        }}
                    />
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        deleteUserRole(row.id);
                    }}
                >
                    {deleteLoading ? (
                        <>
                            <CircularProgress
                                size={15}
                                sx={{
                                    my: 0,
                                }}
                            />
                        </>
                    ) : (
                        <Delete
                            style={{
                                fontSize: 16,
                            }}
                        />
                    )}
                </Button>
            </Box>
        </>
    );
};

export default ActionButtons;
