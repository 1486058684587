import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { Image, Save } from '@mui/icons-material';
import CustomFileInput from '../../../components/Forms/CustomFileInput';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { colors } from '../../../assets/utils/constants';
import { useNavigate } from 'react-router-dom';
import CustomSelectInput from '../../../components/Forms/CustomSelectInput';
import { useCreatePromotionsCustomerMutation } from '../../../api/promotions/promotionsCustomersAPI';
import { addCustomer } from '../../../states/features/promotions/promotionCustomersSlice';

// ######### validation schema #################
const createPromotionCustomerValidationSchema = Yup.object({
    file: Yup.string().nullable(),
    name: Yup.string().required(),
    location: Yup.string().required(),
    email: Yup.string().nullable(),
    phone_number: Yup.string()
        .required('Phone number is required field')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'Positions must be a number',
        )
        .max(10, 'Phone number must be at most 10 characters')
        .min(10, 'Phone number must be at least 10 characters'),
    customer_type: Yup.string().required('Customer type is a required field'),
});

const AddPromotionCustomer = ({ handleClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // ############# Comp state ################
    const [filePreview, setFilePreview] = useState('');

    // ############# RTK ##################
    const [
        createPromotionCustomer,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useCreatePromotionsCustomerMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleClose();
        navigate(`/promotions/customers/${data.id}`);
        dispatch(addCustomer(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Box>
                <Formik
                    initialValues={{
                        file: '',
                        name: '',
                        location: '',
                        email: '',
                        phone_number: '',
                        customer_type: '',
                    }}
                    validationSchema={createPromotionCustomerValidationSchema}
                    onSubmit={(values) => {
                        let payload = new FormData();
                        payload.append('logo', values.file);
                        payload.append('name', values.name);
                        payload.append('location', values.location);
                        payload.append('email', values.email);
                        payload.append('phone_number', values.phone_number);
                        payload.append('customer_type', values.customer_type);
                        createPromotionCustomer(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid
                                container
                                justifyContent={'center'}
                                columnSpacing={2}
                            >
                                <Grid item sm={12}>
                                    <Grid
                                        container
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Grid item sm={7} sx={{}}>
                                            {filePreview ? (
                                                <>
                                                    <img
                                                        src={filePreview}
                                                        alt="img"
                                                        style={{
                                                            width: '100%',
                                                            height: '10em',
                                                            objectFit:
                                                                'contain',
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Image
                                                        sx={{
                                                            width: '100%',
                                                            height: '7em',
                                                            objectFit:
                                                                'contain',
                                                            opacity: 0.7,
                                                            color:
                                                                colors.secondary,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            sm={5}
                                            sx={{
                                                height: '100%',
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                    opacity: 0.8,
                                                }}
                                            >
                                                Customer logo
                                            </Typography>
                                            <CustomFileInput
                                                name="file"
                                                type="file"
                                                formik={formik}
                                                accept="image/*"
                                                optional={true}
                                                setFilePreview={setFilePreview}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <CustomTextField
                                        name="name"
                                        type={'text'}
                                        label={'Customer name'}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomSelectInput
                                        type="text"
                                        name="customer_type"
                                        label="Customer type"
                                        formik={formik}
                                        color="primary"
                                    >
                                        <MenuItem value={'individual'}>
                                            Individual
                                        </MenuItem>
                                        <MenuItem value={'company'}>
                                            Company
                                        </MenuItem>
                                    </CustomSelectInput>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        name="location"
                                        type={'text'}
                                        label={'Location'}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        name="phone_number"
                                        type={'text'}
                                        label={'Phone number'}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        name="Email"
                                        type={'text'}
                                        label={'Email'}
                                        optional={true}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        startIcon={<Save />}
                                        onClick={formik.handleSubmit}
                                        btnColor={'primary'}
                                        sx={{ py: 2 }}
                                    >
                                        Save customer
                                    </CustomSubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default AddPromotionCustomer;
