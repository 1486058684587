import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import PageLayout from '../layouts/PageLayout';
import InfoboxWidget2 from '../../components/widgets/InfoboxWidget2';
import PracticalTrainingSidebar from '../../components/sidebars/PracticalTrainingSidebar';
import { practicalTrainingsSelector } from '../../states/features/selectors';
import { useSelector } from 'react-redux';
import { colors } from '../../assets/utils/constants';
import { filter, isEmpty, size } from 'lodash';
import NoContent from '../../components/NoContent';
import CountBadge from '../../components/CountBadge';
import FieldWidgetOne from '../../components/widgets/FieldWidgetOne';
import CustomPagination from '../../components/paginations/CustomPagination';
import { paginator } from '../../helpers/paginationHelper';
import TrainingFilters from '../../components/filters/TrainingFilters';

const PracticalTrainings = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);
    const [trainings, setTraining] = useState([]);

    // ############### Redux state #################
    const { practicalTrainings } = useSelector(practicalTrainingsSelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // ################### FUNCT ######################
    const getPTByStatus = () => {
        let activePTs = filter(
            practicalTrainings,
            ({ status }) => status === 'published',
        );
        let draftedPTs = filter(
            practicalTrainings,
            ({ status }) => status === 'draft',
        );
        let closedPTs = filter(
            practicalTrainings,
            ({ status }) => status === 'closed',
        );
        let expiredPTs = filter(
            practicalTrainings,
            ({ status }) => status === 'expired',
        );
        let suspendedPTs = filter(
            practicalTrainings,
            ({ status }) => status === 'suspended',
        );

        return {
            activePTs,
            draftedPTs,
            closedPTs,
            expiredPTs,
            suspendedPTs,
        };
    };

    const activePTs = getPTByStatus().activePTs;
    const draftedPTs = getPTByStatus().draftedPTs;
    const closedPTs = getPTByStatus().closedPTs;
    const expiredPTs = getPTByStatus().expiredPTs;
    const suspendedPTs = getPTByStatus().suspendedPTs;

    const infoBoxData = [
        {
            id: 1,
            title1: 'Drafted',
            title2: 'Practical training',
            total: size(draftedPTs),
            colorSign: colors.primary,
            url: 'status/drafted',
        },
        {
            id: 1,
            title1: 'Closed',
            title2: 'Practical training',
            total: size(closedPTs),
            colorSign: colors.warning,
            url: 'status/closed',
        },
        {
            id: 2,
            title1: 'Expired',
            title2: 'Practical training',
            total: size(expiredPTs),
            colorSign: colors.danger,
            url: 'status/expired',
        },
        {
            id: 3,
            title1: 'Suspended',
            title2: 'Practical training',
            total: size(suspendedPTs),
            colorSign: 'red',
            url: 'status/suspended',
        },
    ];

    // => Filter by training name
    const filterByPTName = (search) => {
        let filteredTraining = [];
        if (search) {
            filteredTraining = activePTs.filter((training) => {
                return training.title
                    .toLowerCase()
                    .includes(search.toLowerCase());
            });
            return setTraining(filteredTraining);
        }
        return setTraining(activePTs);
    };

    // => Pagination data
    const paginatedActivePTs = paginator(trainings, page, perPage);

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setTraining(activePTs);
        }

        return () => {
            isSubscribed = false;
        };
    }, []);

    return (
        <>
            <PageLayout
                head={'Practical trainings'}
                sidebar={<PracticalTrainingSidebar />}
            >
                <Grid container spacing={2}>
                    {infoBoxData.map((data) => (
                        <Grid item sm={3} key={data.id}>
                            <InfoboxWidget2
                                data={data}
                                colorSign={data.colorSign}
                            />
                        </Grid>
                    ))}
                    <Grid item sm={12}>
                        <CustomPagination
                            head={
                                'Published (Active) practical training vacancy'
                            }
                            height={'49.5vh'}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedActivePTs}
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <TrainingFilters
                                        filterByJobName={filterByPTName}
                                        // filterByIsFor={filterByIsFor}
                                    />
                                    <CountBadge
                                        total={size(trainings)}
                                        item={'Active PTs'}
                                    />
                                </Box>
                            }
                        >
                            {isEmpty(trainings) ? (
                                <>
                                    <NoContent
                                        height={300}
                                        message={
                                            'No active practical trainings'
                                        }
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {paginatedActivePTs.data.map((training) => (
                                        <Grid item sm={6} key={training.id}>
                                            <FieldWidgetOne
                                                training={training}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default PracticalTrainings;
