import React, { useEffect } from 'react';
import PageLayout from '../layouts/PageLayout';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { ArrowCircleLeft } from '@mui/icons-material';
import { colors } from '../../assets/utils/constants';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsSelector } from '../../states/features/selectors';
import useRTK from '../../hooks/useRTK';
import { useGetSingleNotificationQuery } from '../../api/notifications/notificationsAPI';
import {
    setNotificationDetails,
    setNotificationPage,
    updateNotification,
} from '../../states/features/notifications/notificationsSlice';
import CustomCard2 from '../../components/cards/CustomCard2';
import InnerDataFetchingError from '../../components/InnerDataFetchingError';
import ContentLoader from '../../components/ContentLoader';
import NotifcationLayout from '../layouts/NotifcationLayout';
import DeleteNotification from './CRUD/DeleteNotification';

const NotificationDetails = () => {
    // ################## CONST #####################
    const RTK = useRTK();
    const { notification_id } = useParams();
    const dispatch = useDispatch();

    // ############## Redux state ################
    const { notificationDetails } = useSelector(notificationsSelector);

    // ############### RTK ####################
    const {
        isLoading,
        isSuccess,
        isError,
        refetch,
        data: jobData,
    } = useGetSingleNotificationQuery(notification_id);
    // => Response
    const rtkAction = (data) => {
        dispatch(setNotificationDetails(data));
        dispatch(updateNotification(data));
    };
    RTK.useRTKFetch(isSuccess, jobData, rtkAction);

    // ############# useEffect #################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            dispatch(setNotificationPage('details'));
        }

        return () => {
            isSubscribed = false;
        };
    }, [setNotificationPage]);

    return (
        <>
            <PageLayout noSidebar head={'Notifications'}>
                <NotifcationLayout>
                    {isLoading ? (
                        <Box sx={{ width: '100%' }}>
                            <CustomCard2 height={620}>
                                <ContentLoader />
                            </CustomCard2>
                        </Box>
                    ) : isError ? (
                        <Box sx={{ width: '100%' }}>
                            <CustomCard2 height={620}>
                                <InnerDataFetchingError
                                    height={550}
                                    handleDataRefetch={refetch}
                                />
                            </CustomCard2>
                        </Box>
                    ) : (
                        <Box sx={{ width: '100%' }}>
                            {/* ############ Title ################## */}
                            <Box
                                sx={{
                                    p: { md: 2.3, xs: 1.5 },
                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor4})`,
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: { md: 20, xs: 16 },
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                        color: colors.info,
                                    }}
                                >
                                    {notificationDetails.title}
                                </Typography>
                                <Box>
                                    <DeleteNotification
                                        notification={notificationDetails}
                                    />
                                    <Link to={'/notifications'}>
                                        <Tooltip title="Back" arrow>
                                            <IconButton
                                                size="small"
                                                color="info"
                                                sx={{
                                                    ml: 1,
                                                    boxShadow: `1px 2px 5px 0px ${colors.bgColor3} !important`,
                                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor4})`,
                                                }}
                                            >
                                                <ArrowCircleLeft className="icon" />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                </Box>
                            </Box>

                            {/* ############ Body ################# */}
                            <Box sx={{ p: 3, opacity: 0.8 }}>
                                <Typography sx={{ fontSize: 16 }}>
                                    {notificationDetails.body}
                                </Typography>
                            </Box>

                            {/* ############# Action link ################ */}
                            {notificationDetails.action_link !== null && (
                                <Box
                                    sx={{
                                        p: 3,
                                        opacity: 0.8,
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Link to={notificationDetails.action_link}>
                                        <Button variant="outlined">
                                            Action link
                                        </Button>
                                    </Link>
                                </Box>
                            )}
                        </Box>
                    )}
                </NotifcationLayout>
            </PageLayout>
        </>
    );
};

export default NotificationDetails;
