import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userRoles: [],
};

// !########## SLICE #############
const userRolesSlice = createSlice({
    name: 'userRolesSlice',
    initialState,
    reducers: {
        setUserRoles: (state, actions) => {
            state.userRoles = actions.payload;
        },
        addUserRole: (state, actions) => {
            const user_role = actions.payload;
            state.userRoles = [user_role, ...state.userRoles];
        },
    },
});

export const { setUserRoles, addUserRole } = userRolesSlice.actions;
export default userRolesSlice.reducer;
