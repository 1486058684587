import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import CustomCard from "../cards/CustomCard";
import EmployerJobInfoBoxWidget from "../widgets/EmployerJobInfoBoxWidget";
import SuspendUnsuspendJob from "../../pages/jobs/employers/CRUD/SuspendUnsuspendJob";
import EmployerMiniWidget from "../widgets/EmployerMiniWidget";
import { Unpublished, Verified } from "@mui/icons-material";
import DenyJob from "../../pages/jobs/employers/CRUD/DenyJob";
import VerifyJob from "../../pages/jobs/employers/CRUD/VerifyJob";
import CustomDialog from "../Dialogies/CustomDialog";

const EmployerJobDetailsSidebar = ({ jobDetails }) => {
    // ############## Comp State ##################
    const [confimationType, setConfimationType] = useState(false);
    const [dialogyOpen, setDialogyOpen] = useState(false);

    // ############## FUNC #####################
    // => Handle docs open
    const handleDialogOpen = (type) => {
        setConfimationType(type);
        setDialogyOpen(true);
    };
    // => Dialogy closer
    const handleDialogClose = () => {
        setDialogyOpen(false);
    };

    return (
        <>
            {/* ################# Dialogy ################## */}
            <CustomDialog
                openDialog={dialogyOpen}
                handleDialogClose={handleDialogClose}
                head={
                    confimationType === "verify"
                        ? "Verify shop verification request"
                        : confimationType === "suspend"
                        ? "Verify shop suspend"
                        : confimationType === "resume"
                        ? "Verify shop un-suspend"
                        : "Deny shop verification request"
                }
            >
                {confimationType === "verify" ? (
                    <>
                        <VerifyJob
                            closeDialogy={handleDialogClose}
                            cancel={handleDialogClose}
                            job={jobDetails}
                        />
                    </>
                ) : confimationType === "denied" ? (
                    <>
                        <DenyJob
                            closeDialogy={handleDialogClose}
                            cancel={handleDialogClose}
                            job={jobDetails}
                        />
                    </>
                ) : confimationType === "suspend" ? (
                    <>
                        {/* <SuspendShop
                            closeDialogy={handleConfimationDialogClose}
                            cancel={handleConfimationDialogClose}
                            shop={shop}
                        /> */}
                    </>
                ) : confimationType === "resume" ? (
                    <>
                        {/* <ResumeShop
                            closeDialogy={handleConfimationDialogClose}
                            cancel={handleConfimationDialogClose}
                            shop={shop}
                        /> */}
                    </>
                ) : (
                    <></>
                )}
            </CustomDialog>

            <Box>
                <EmployerJobInfoBoxWidget jobDetails={jobDetails} />
            </Box>
            <Box sx={{ mt: 1 }}>
                <CustomCard head={"Posted by"}>
                    <EmployerMiniWidget employer={jobDetails.employer} />
                </CustomCard>
            </Box>
            {jobDetails.status !== "denied" ? (
                <Box sx={{ mt: 1 }}>
                    <CustomCard head={"Actions links"}>
                        {jobDetails.status === "pending" && (
                            <Grid container px={1}>
                                <Grid item sm={12}>
                                    <Button
                                        startIcon={<Verified />}
                                        color="success"
                                        variant="contained"
                                        fullWidth
                                        onClick={() => {
                                            let type = "verify";
                                            handleDialogOpen(type);
                                        }}
                                        sx={{ mb: 1 }}
                                    >
                                        Verify job
                                    </Button>
                                </Grid>
                                <Grid item sm={12}>
                                    <Button
                                        startIcon={<Unpublished />}
                                        color="error"
                                        variant="contained"
                                        fullWidth
                                        onClick={() => {
                                            let type = "denied";
                                            handleDialogOpen(type);
                                        }}
                                    >
                                        Deny job
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                        {parseInt(jobDetails.is_approved) === 1 && (
                            <SuspendUnsuspendJob job={jobDetails} />
                        )}
                    </CustomCard>
                </Box>
            ) : (
                ""
            )}
        </>
    );
};

export default EmployerJobDetailsSidebar;
