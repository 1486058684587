import React from 'react';
import { Leaderboard, WorkHistoryOutlined } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
// import CustomCard2 from '../cards/CustomCard2';
// import OrgWithFieldVacancyTable from '../Tables/OrgWithFieldVacancyTable';
import { practicalTrainingsSelector } from '../../states/features/selectors';
import { filter, size } from 'lodash';
import { useSelector } from 'react-redux';
import StatsInfoBox from '../infoboxes/StatsInfoBox';

const PracticalTrainingSidebar = () => {
    // ############### Redux state #################
    const { practicalTrainings } = useSelector(practicalTrainingsSelector);

    // => Drafted
    const draftedPTs = filter(
        practicalTrainings,
        ({ status }) => status === 'draft',
    );
    // => Published
    const publishedPTs = filter(
        practicalTrainings,
        ({ status }) => status === 'published',
    );
    // => Closed
    const closedPTs = filter(
        practicalTrainings,
        ({ status }) => status === 'closed',
    );
    // => Suspended
    const suspendedPTs = filter(
        practicalTrainings,
        ({ status }) => status === 'suspended',
    );

    const ptStats = [
        {
            id: 1,
            title: 'Drafted',
            stats: `${size(draftedPTs)} Training`,
            borderColor: colors.danger,
        },
        {
            id: 2,
            title: 'Published',
            stats: `${size(publishedPTs)} Training`,
            borderColor: colors.success,
        },
        {
            id: 3,
            title: 'Closed',
            stats: `${size(closedPTs)} Training`,
            borderColor: colors.warning,
        },
        {
            id: 4,
            title: 'Suspended',
            stats: `${size(suspendedPTs)} Training`,
            borderColor: 'red',
        },
    ];
    return (
        <>
            <Grid container rowSpacing={2}>
                <Grid item sm={12}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                opacity: 0.7,
                            }}
                        >
                            <Leaderboard color="info" sx={{ mr: 1 }} />
                            <Typography
                                sx={{ fontSize: 15, fontWeight: 'bold' }}
                            >
                                Overviews
                            </Typography>
                        </Box>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid
                                item
                                sm={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        height: 142,
                                        width: 142,
                                        borderRadius: 50,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        border: `5px solid ${colors.bgColor3}`,
                                        boxShadow: `0px 10px 15px ${colors.bgColor4}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                                    }}
                                >
                                    <WorkHistoryOutlined
                                        sx={{ opacity: 0.8 }}
                                        color="warning"
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: 30,
                                            fontWeight: 'bold',
                                            opacity: 0.8,
                                            color: colors.info,
                                        }}
                                    >
                                        {size(practicalTrainings)}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            opacity: 0.7,
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        Posted PT's
                                    </Typography>
                                </Box>
                            </Grid>
                            {ptStats.map((stats) => (
                                <Grid item sm={6} key={stats.id}>
                                    <StatsInfoBox stats={stats} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
                {/* <Grid item sm={12}>
                    <Box>
                        <CustomCard2 head={'Top organizations'} height={200}>
                            <OrgWithFieldVacancyTable />
                        </CustomCard2>
                    </Box>
                </Grid> */}
            </Grid>
        </>
    );
};

export default PracticalTrainingSidebar;
