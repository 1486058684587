import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/constants";
import { Link } from "react-router-dom";

const SubPackageInfoBox = ({ data }) => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    borderRadius: 3,
                    overflow: "hidden",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        background: `linear-gradient(90deg, ${colors.primary}, ${colors.primary})`,
                        width: "60%",
                        height: 100,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: " center",
                        boxShadow: `1px 1px 15px 2px ${colors.warning}`,
                    }}
                >
                    <Box sx={{ textAlign: "center" }}>
                        <Typography
                            sx={{
                                fontSize: 35,
                                fontWeight: "bold",
                                fontStyle: "italic",
                                color: colors.secondary,
                            }}
                        >
                            {data.total}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: colors.secondary,
                                fontWeight: "bold",
                            }}
                        >
                            packages
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        bgcolor: colors.secondary,
                        width: "100%",
                        height: 100,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: " center",
                    }}
                >
                    <Box sx={{ textAlign: "center" }}>
                        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                            {data.title}
                        </Typography>
                        <Typography sx={{ fontSize: 11, fontWeight: "bold" }}>
                            Subscription Packages
                        </Typography>
                        <Link to={data.url}>
                            <Button
                                size="small"
                                variant="contained"
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                Manage
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default SubPackageInfoBox;
