import { useSelector } from "react-redux";
import { filter, isEmpty } from "lodash";
import moment from "moment";
import { practicalTrainingsSelector } from "../../../states/features/selectors";

export const useTrainingReportFilter = () => {
    const { practicalTrainings } = useSelector(practicalTrainingsSelector);

    const filterTraining = (payload) => {
        let filteredTrainings = practicalTrainings;
        let training_status = payload?.training_status;
        let dateFrom = payload?.from;
        let dateTo = payload?.to;

        // => Filter by date
        var startDate = moment(dateFrom).format("YYYY-MM-DD");
        var endDate = moment(dateTo).format("YYYY-MM-DD");
        if (!isEmpty(dateFrom) || !isEmpty(dateTo)) {
            filteredTrainings = filter(
                filteredTrainings,
                ({ created_at }) =>
                    moment(created_at).format("YYYY-MM-DD") >= startDate &&
                    moment(created_at).format("YYYY-MM-DD") <= endDate
            );
        }

        // => Filter by status
        if (!isEmpty(training_status)) {
            filteredTrainings = filter(
                filteredTrainings,
                ({ status }) => status === training_status
            );
        }

        // => Return result
        if (
            !isEmpty(dateFrom) ||
            !isEmpty(dateTo) ||
            !isEmpty(training_status)
        ) {
            return filteredTrainings;
        } else {
            return practicalTrainings;
        }
    };

    return {
        filterTraining,
    };
};
