import { Button, CircularProgress, Switch, Tooltip } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { colors } from '../../../assets/utils/constants';
import {
    useActivatePromotionsCustomerMutation,
    useDeactivatePromotionsCustomerMutation,
} from '../../../api/promotions/promotionsCustomersAPI';
import { updateCustomer } from '../../../states/features/promotions/promotionCustomersSlice';

const ActivateDeactivateCustomer = ({ customer }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        activateAction,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivatePromotionsCustomerMutation();
    const [
        deactivateAction,
        {
            isLoading: deactivateLoading,
            isSuccess: deactivateSuccess,
            isError: deactivateIsError,
            error: deactivateError,
            data: deactivateData,
        },
    ] = useDeactivatePromotionsCustomerMutation();

    // ############# useEffect ##################
    // => Activate res
    // ############# useEffect ##################
    const rtkActions = (data) => {
        dispatch(updateCustomer(data));
    };
    RTK.useRTKResponse(
        activateSuccess,
        activateIsError,
        activateError,
        activateData,
        rtkActions,
    );
    RTK.useRTKResponse(
        deactivateSuccess,
        deactivateIsError,
        deactivateError,
        deactivateData,
        rtkActions,
    );
    return (
        <>
            <Tooltip title={customer.status ? 'Deactivate' : 'Activate'} arrow>
                <Button
                    variant="outlined"
                    color="secondary"
                    disabled={
                        activateLoading || deactivateLoading ? true : false
                    }
                    sx={{
                        padding: 0.2,
                        mr: 1,
                        border: `1px dotted ${colors.info} !important`,
                    }}
                    onClick={() =>
                        customer.status
                            ? deactivateAction(customer.id)
                            : activateAction(customer.id)
                    }
                >
                    {activateLoading || deactivateLoading ? (
                        <>
                            <CircularProgress
                                size={20}
                                color="primary"
                                sx={{
                                    my: 0.25,
                                }}
                            />
                        </>
                    ) : (
                        <Switch
                            size="small"
                            color={customer.status ? 'success' : 'error'}
                            checked={customer.status ? true : false}
                        />
                    )}
                </Button>
            </Tooltip>
        </>
    );
};

export default ActivateDeactivateCustomer;
