import React from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';

const CustomSettingsCard = ({ children, height, head, action }) => {
    return (
        <>
            <Box sx={{ borderRadius: 3, overflow: 'hidden' }}>
                <Box
                    sx={{
                        backgroundColor: colors.primary,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: 1,
                        py: 0.5,
                    }}
                >
                    <Typography
                        padding={1}
                        sx={{
                            fontSize: 20,
                            color: colors.gray,
                        }}
                    >
                        {head}
                    </Typography>
                    {action}
                </Box>
                <Box
                    sx={{
                        height: height ? height : '72vh',
                        position: 'relative',
                        overflow: 'auto',
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </>
    );
};

export default CustomSettingsCard;
