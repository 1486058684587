import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { removeArrObj, updateArrObj } from '../../../helpers/arrayHelpers';

const initialState = {
    blogs: [],
    blogDetails: {},
    blogPagnations: [],
};

// !########## SLICE #############
const blogsSlice = createSlice({
    name: 'blogsSlice',
    initialState,
    reducers: {
        setBlogs: (state, actions) => {
            state.blogs = actions.payload.data;
            state.blogPagnations = actions.payload;
        },
        setBlogDetails: (state, actions) => {
            state.blogDetails = actions.payload;
        },
        addBlog: (state, actions) => {
            let blog = actions.payload;
            isEmpty(state.blogs)
                ? (state.blogs = [blog, ...[]])
                : (state.blogs = [blog, ...state.blogs]);
        },
        updateBlog: (state, actions) => {
            let newBlog = actions.payload;
            let blogs = updateArrObj('id', newBlog, state.blogs);
            state.blogDetails = actions.payload;
            state.blogs = blogs;
        },
        removeBlog: (state, actions) => {
            let payload = actions.payload;
            let blogs = removeArrObj('id', payload, state.blogs);
            state.blogs = blogs;
        },
    },
});

export const {
    setBlogs,
    setBlogDetails,
    addBlog,
    updateBlog,
    removeBlog,
} = blogsSlice.actions;
export default blogsSlice.reducer;
