import React from "react";
import { Form, Formik } from "formik";
import CustomTextField from "../../../components/Forms/CustomTextField";
import CustomSelectInput from "../../../components/Forms/CustomSelectInput";
import { Grid, MenuItem } from "@mui/material";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { ArrowRightAltSharp, ManageAccounts } from "@mui/icons-material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
    addAdmin,
    setAdmins,
} from "../../../states/features/admins/adminsSlice";
import useRTK from "../../../hooks/useRTK";
import { useCreateAdminMutation } from "../../../api/admins/adminsAPI";

// ============= update profile validation =====================
const createAdminValidation = Yup.object({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
    role: Yup.string().required("Role is required field"),
    gender: Yup.string().required("This field is required"),
    email: Yup.string().email().required("This field is required"),
    phone_number: Yup.string()
        .required("This field is required")
        .max(10, "Phone number should contain 10 integers")
        .min(10, "Phone number should contain 10 integers"),
});

const AddAdmin = ({ handleClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        createAdmin,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useCreateAdminMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleClose();
        dispatch(addAdmin(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Formik
                initialValues={{
                    first_name: "",
                    last_name: "",
                    phone_number: "",
                    email: "",
                    gender: "",
                    role: "",
                }}
                validationSchema={createAdminValidation}
                onSubmit={(payload) => {
                    createAdmin(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container columnSpacing={2}>
                            <Grid item sm={6}>
                                <CustomTextField
                                    className="form-input"
                                    label="First name"
                                    type="text"
                                    name="first_name"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    className="form-input"
                                    label="Last name"
                                    type="text"
                                    name="last_name"
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2}>
                            <Grid item sm={6}>
                                <CustomTextField
                                    className="form-input"
                                    label="Phone number"
                                    type="text"
                                    name="phone_number"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    className="form-input"
                                    label="Email"
                                    type="emai"
                                    name="email"
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2}>
                            <Grid item sm={6}>
                                <CustomSelectInput
                                    label="Gender"
                                    type="text"
                                    name="gender"
                                    formik={formik}
                                    color={"primary"}
                                >
                                    <MenuItem value={"male"}>Male</MenuItem>
                                    <MenuItem value={"female"}>Female</MenuItem>
                                </CustomSelectInput>
                            </Grid>
                            <Grid item sm={6}>
                                <CustomSelectInput
                                    label="Role"
                                    type="text"
                                    name="role"
                                    formik={formik}
                                    color={"primary"}
                                >
                                    <MenuItem value={"Super admin"}>
                                        Super admin
                                    </MenuItem>
                                </CustomSelectInput>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    loading={isLoading}
                                    startIcon={<ManageAccounts />}
                                    endIcon={<ArrowRightAltSharp />}
                                    onClick={formik.handleSubmit}
                                    btnColor={"primary"}
                                    sx={{ py: 2 }}
                                >
                                    Add admin
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddAdmin;
