import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
    Avatar,
    Chip,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from "@mui/material";
import { colors } from "../../assets/utils/constants";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import moment from "moment";
import {
    ArrowCircleRightOutlined,
    LocationOn,
    OpenInFull,
    RemoveRedEye,
} from "@mui/icons-material";
import NoContent from "../NoContent";
import { isEmpty } from "lodash";

const columns = [
    { id: "s_n", label: "S/N", align: "center" },
    { id: "organization", label: "Organization" },
    {
        id: "location",
        label: "Location",
        align: "left",
    },
    {
        id: "status",
        label: "Status",
        align: "center",
    },
    {
        id: "action",
        label: "Action",
        align: "center",
    },
];

function createData(s_n, organization, location, status, action) {
    return {
        s_n,
        organization,
        location,
        status,
        action,
    };
}

const OrganizationTable = ({ organizations, height }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = organizations?.map((org, index) => {
        return createData(
            ++index,
            <Box>
                <List sx={{ p: 0 }}>
                    <ListItem sx={{ p: 0 }}>
                        <ListItemAvatar>
                            <Avatar
                                src={org.org_logo}
                                variant="square"
                                sx={{
                                    width: 90,
                                    height: 70,
                                    borderRadius: 2,
                                    border: `2px solid ${colors.bgColor3}`,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                    img: {
                                        objectFit: "contain",
                                    },
                                }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            disableTypography
                            primary={
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        color: colors.primary,
                                        opacity: 0.8,
                                    }}
                                >
                                    {org.organization_name}
                                </Typography>
                            }
                            secondary={
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            color: colors.info,
                                        }}
                                    >
                                        {org.login_id}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }} noWrap>
                                        {org.organization_type.name}
                                    </Typography>
                                </Box>
                            }
                            primaryTypographyProps={{
                                whiteSpace: "nowrap",
                                color: "gray",
                            }}
                            secondaryTypographyProps={{ whiteSpace: "nowrap" }}
                            sx={{ ml: 1 }}
                        />
                    </ListItem>
                </List>
            </Box>,
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <LocationOn sx={{ fontSize: 14, color: colors.info }} />
                <Typography
                    noWrap
                    sx={{
                        fontSize: 14,
                        fontWeight: "bold",
                        opacity: 0.8,
                        width: 100,
                    }}
                >
                    {org.location}
                </Typography>
            </Box>,
            <Chip
                size="small"
                label={
                    org.is_verified === "pending"
                        ? "Pending"
                        : org.is_verified === "requested"
                        ? "Requested"
                        : org.is_verified === "on_progress"
                        ? "On-progress"
                        : org.is_verified === "denied"
                        ? "Denied"
                        : "Verified"
                }
                color={
                    org.is_verified === "pending"
                        ? "warning"
                        : org.is_verified === "requested"
                        ? "info"
                        : org.is_verified === "on_progress"
                        ? "info"
                        : org.is_verified === "denied"
                        ? "error"
                        : "success"
                }
            />,
            <Link
                to={`/organizations/${
                    org.organization_type.name === "Company"
                        ? "companies"
                        : "institutions"
                }/details/${org.id}`}
            >
                <Tooltip title="Organization details" arrow>
                    <IconButton
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                        }}
                    >
                        <RemoveRedEye color="info" />
                    </IconButton>
                </Tooltip>
            </Link>
        );
    });

    return (
        <Paper
            sx={{
                width: "100%",
                overflow: "hidden",
                boxShadow: "none !important",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    bottom: 10,
                    left: 15,
                    zIndex: 999,
                }}
            >
                <IconButton
                    size="small"
                    color="info"
                    sx={{
                        background: `linear-gradient(90deg, ${colors.bgColor4}, ${colors.bgColor4})`,
                    }}
                >
                    <OpenInFull sx={{ fontSize: 20 }} />
                </IconButton>
            </Box>
            <TableContainer
                sx={{
                    height: height ? height : 415,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                }}
            >
                {isEmpty(organizations) ? (
                    <>
                        <NoContent message={"No organization available"} />
                    </>
                ) : (
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                            textTransform: "uppercase",
                                            fontWeight: "bold",
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                            fontSize: 14,
                                            color: colors.primary,
                                            whiteSpace: "nowrap",
                                            opacity: 0.8,
                                        }}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.s_n}
                                            sx={{
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                            }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                    >
                                                        {column.format &&
                                                        typeof value ===
                                                            "number"
                                                            ? column.format(
                                                                  value
                                                              )
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[1, 2, 5, 10]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                }}
            />
        </Paper>
    );
};

export default OrganizationTable;
