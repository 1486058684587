import React, { useState } from 'react';
import { Clear, Done, DoneAll } from '@mui/icons-material';
import { Box, Button, Chip, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { colors } from '../../../assets/utils/constants';
import CustomDialog from '../../../components/Dialogies/CustomDialog';
import VerifyOrganization from './CRUD/VerifyOrganization';

// !############## MAIN FUNC ################
const RequredDetails = ({ organization, refetchProfile }) => {
    const [openDialog, setOpenDialogy] = useState(false);
    // -> Dialogy open
    const handleDialogyOpen = () => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };
    const orgDocs = organization.document;
    const orgKeyPerson = organization.key_person;

    const profileReqDetails = [
        {
            label: `${organization.organization_type.name} logo`,
            status: organization.org_logo ? true : false,
        },
        {
            label: `${organization.organization_type.name} description`,
            status: organization.description ? true : false,
        },
        {
            label: `${organization.organization_type.name} phone number verification`,
            status: organization.phone_number_verified_at ? true : false,
        },
        {
            label: `${organization.organization_type.name} email verification`,
            status: organization.email_verified_at ? true : false,
        },
        {
            label: `${organization.organization_type.name} verification document`,
            status:
                !isEmpty(orgDocs) && orgDocs.verification_status !== 'denied'
                    ? true
                    : false,
        },
        {
            label: `${organization.organization_type.name} key person and verification document`,
            status:
                !isEmpty(orgKeyPerson) &&
                orgKeyPerson.document &&
                orgKeyPerson.document?.verification_status !== 'denied'
                    ? true
                    : false,
        },
    ];

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={'Approve organization'}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            >
                <VerifyOrganization
                    user={organization}
                    closeDialogy={handleDialogClose}
                    refetchProfile={refetchProfile}
                    orgDocs={orgDocs}
                    orgKeyPerson={orgKeyPerson}
                />
            </CustomDialog>
            <Box sx={{ textAlign: 'left', pb: 2 }}>
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        mb: 1,
                        fontSize: 16,
                        color: colors.success,
                    }}
                >
                    Important required details
                </Typography>
                {profileReqDetails?.map((detail, index) => (
                    <Box component={'span'} key={index} sx={{ mr: 2 }}>
                        <Chip
                            label={detail.label}
                            sx={{
                                mb: 2,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                border: `1px solid ${colors.bgColor5}`,
                            }}
                            icon={
                                detail.status ? (
                                    <Done
                                        sx={{
                                            color: 'green !important',
                                            fontSize: 18,
                                        }}
                                    />
                                ) : (
                                    <Clear
                                        sx={{
                                            color: 'red !important',
                                            fontSize: 18,
                                        }}
                                    />
                                )
                            }
                        />
                    </Box>
                ))}
            </Box>
            {organization.is_verified !== 'verified' && (
                <Box sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        sx={{ mt: 2 }}
                        color="success"
                        startIcon={<DoneAll />}
                        onClick={() => handleDialogyOpen()}
                    >
                        Approve {organization.organization_name}
                    </Button>
                </Box>
            )}
        </>
    );
};

export default RequredDetails;
