import React from 'react';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import { Grid } from '@mui/material';
import BlogPostWidget from '../../components/widgets/BlogPostWidget';
import CountBadge from '../../components/CountBadge';
import BlogsSidebar from '../../components/sidebars/BlogsSidebar';
import { blogsSelector } from '../../states/features/selectors';
import { useDispatch, useSelector } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import { useLazyGetPaginatedBlogsQuery } from '../../api/blogs/blogsAPI';
import { setBlogs } from '../../states/features/blogs/blogsSlice';
import { isEmpty, size } from 'lodash';
import ContentLoader from '../../components/ContentLoader';
import DataPagnation from '../../components/DataPagnation';

const Blogs = () => {
    // ############## RTK ############
    const dispatch = useDispatch();
    const RTK = useRTK();

    // ############## Redux state ############
    const { blogs, blogPagnations } = useSelector(blogsSelector);

    // ############## RTK ############
    const [
        getPaginatedBloags,
        { isLoading, isSuccess, data: blogsData },
    ] = useLazyGetPaginatedBlogsQuery();
    const rtkActions = (data) => {
        dispatch(setBlogs(data));
    };
    RTK.useRTKFetch(isSuccess, blogsData, rtkActions);

    return (
        <>
            <PageLayout head={'Posted blogs'} sidebar={<BlogsSidebar />}>
                <CustomCard2
                    head={'All posted blogs'}
                    height={515}
                    action={<CountBadge total={size(blogs)} item={'Blogs'} />}
                    footer={
                        !isEmpty(blogPagnations) && (
                            <DataPagnation
                                paginations={blogPagnations}
                                handlePagination={getPaginatedBloags}
                            />
                        )
                    }
                >
                    {isLoading ? (
                        <>
                            <ContentLoader />
                        </>
                    ) : (
                        <Grid container spacing={3}>
                            {blogs.map((blog) => (
                                <Grid item sm={6} key={blog.id}>
                                    <BlogPostWidget blog={blog} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default Blogs;
