import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { adminBaseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { relationTypesEndpoints } from "./settingEndpoints";
import { settingAPI } from "./settingAPI";

export const relationTypeAPI = createApi({
    reducerPath: "relationTypeAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getRelationTypes: builder.query({
            query: () => `${relationTypesEndpoints.GET_RELATION_TYPES}`,
        }),
        // => ADD
        addRelationType: builder.mutation({
            query: (payload) => ({
                url: `${relationTypesEndpoints.ADD_RELATION_TYPE}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(["Settings"]));
            },
        }),
        // => UPDATE
        updateRelationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${relationTypesEndpoints.UPDATE_RELATION_TYPE}/` +
                    payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateRelationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${relationTypesEndpoints.ACTIVATE_RELATION_TYPE}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateRelationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${relationTypesEndpoints.DEACTIVATE_RELATION_TYPE}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteRelationType: builder.mutation({
            query: (payload) => ({
                url:
                    `${relationTypesEndpoints.DELETE_RELATION_TYPE}/` + payload,
                method: `DELETE`,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(["Settings"]));
            },
        }),
    }),
});

export const {
    useGetRelationTypesQuery,
    useAddRelationTypeMutation,
    useUpdateRelationTypeMutation,
    useActivateRelationTypeMutation,
    useDeactivateRelationTypeMutation,
    useDeleteRelationTypeMutation,
} = relationTypeAPI;
