import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { educationLevelEndpoints } from './settingEndpoints';
import { settingAPI } from './settingAPI';

export const educationLevelAPI = createApi({
    reducerPath: 'educationLevelAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getEducationLevels: builder.query({
            query: () => `${educationLevelEndpoints.GET_EDUCATION_LEVELS}`,
        }),
        // => ADD
        addEducationLevel: builder.mutation({
            query: (payload) => ({
                url: `${educationLevelEndpoints.ADD_EDUCATION_LEVEL}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
        // => UPDATE
        updateEducationLevel: builder.mutation({
            query: (payload) => ({
                url:
                    `${educationLevelEndpoints.UPDATE_EDUCATION_LEVEL}/` +
                    payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateEducationLevel: builder.mutation({
            query: (payload) => ({
                url:
                    `${educationLevelEndpoints.ACTIVATE_EDUCATION_LEVEL}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateEducationLevel: builder.mutation({
            query: (payload) => ({
                url:
                    `${educationLevelEndpoints.DEACTIVATE_EDUCATION_LEVEL}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteEducationLevel: builder.mutation({
            query: (payload) => ({
                url:
                    `${educationLevelEndpoints.DELETE_EDUCATION_LEVEL}/` +
                    payload,
                method: `DELETE`,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
    }),
});

export const {
    useGetEducationLevelsQuery,
    useAddEducationLevelMutation,
    useUpdateEducationLevelMutation,
    useActivateEducationLevelMutation,
    useDeactivateEducationLevelMutation,
    useDeleteEducationLevelMutation,
} = educationLevelAPI;
