import React, { useEffect } from 'react';
import PageLayout from '../layouts/PageLayout';
import { Box, Grid, Typography } from '@mui/material';
import { MarkChatRead } from '@mui/icons-material';
import NotifcationLayout from '../layouts/NotifcationLayout';
import { colors } from '../../assets/utils/constants';
import { useDispatch } from 'react-redux';
import { setNotificationPage } from '../../states/features/notifications/notificationsSlice';

const Notifications = () => {
    // ############# CONST #####################
    const dispatch = useDispatch();

    // ############# useEffect #################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            dispatch(setNotificationPage('home'));
        }

        return () => {
            isSubscribed = false;
        };
    }, [setNotificationPage]);

    return (
        <>
            <PageLayout noSidebar head={'Notifications'}>
                <NotifcationLayout>
                    <Box
                        sx={{
                            height: '75vh',
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={12} sx={{ textAlign: 'center' }}>
                                <MarkChatRead
                                    sx={{ fontSize: 100, opacity: 0.8 }}
                                />
                            </Grid>
                            <Grid item sm={8}>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                        textAlign: 'center',
                                    }}
                                >
                                    Select notification from notification list
                                    to read.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </NotifcationLayout>
            </PageLayout>
        </>
    );
};

export default Notifications;
