// => GET packages
const GET_ALL_FREE_TRIALS = "free-trials";

// => CREATE package
const CREATE_FREE_TRIAL = "free-trials";

// => ACTIVATE package
const ACTIVATE_FREE_TRIAL = "free-trials/activate";

// => DEACTIVATE package
const DEACTIVATE_FREE_TRIAL = "free-trials/deactivate";

// => UPDATE package
const UPDATE_FREE_TRIAL = "free-trials";

// => DELETE package
const DELETE_FREE_TRIAL = "free-trials";

// ############## BENEFIT ####################
// => CREATE benefit
const CREATE_BENEFIT = "free-trials/benefits";

// => UPDATE benefit
const UPDATE_BENEFIT = "free-trials/benefits";

// => DELETE benefit
const DELETE_BENEFIT = "free-trials/benefits";

export const freeTrialPackageEndpoints = {
    GET_ALL_FREE_TRIALS,
    CREATE_FREE_TRIAL,
    ACTIVATE_FREE_TRIAL,
    DEACTIVATE_FREE_TRIAL,
    UPDATE_FREE_TRIAL,
    DELETE_FREE_TRIAL,
    CREATE_BENEFIT,
    UPDATE_BENEFIT,
    DELETE_BENEFIT,
};
