import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Box, Stack, Badge, Typography, Button } from "@mui/material";
import { colors } from "../assets/utils/constants";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useState } from "react";
import AppBarMenu from "./AppBarMenu";
import ProfileCard from "./cards/ProfileCard";
import { size } from "lodash";
import { useDate } from "../hooks/useDate";
import NotificationsBar from "./NotificationsBar";
import logo from "../assets/media/images/logo.png";
import { SettingsSuggest, Terminal } from "@mui/icons-material";
import { useNavigate, NavLink } from "react-router-dom";
import { filter } from "lodash";
import { notificationsSelector } from "../states/features/selectors";
import BeemSMSBalanceWidget from "./widgets/BeemSMSBalanceWidget";

const drawerWidth = 240;
// ->
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    backgroundColor: colors.primary,
}));

const UserAvatar = styled(Avatar)({
    border: `2px solid ${colors.bgColor5}`,
    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.primary})`,
    "& img": {
        padding: 1,
        borderRadius: 50,
    },
});

const TopbarRight = styled(Stack)({
    "& .icon": {
        color: colors.gray,
    },
});

// !###################### MAIN FUNC ############################
const Topbar = ({ mobileOpen, handleDrawerToggle }) => {
    const [openNotBar, setOpenNotBar] = React.useState(false);
    const [profileMenu, setProfileMenu] = useState("none");
    const navigate = useNavigate();

    const handleProfileMenuOpen = () => {
        setProfileMenu("block");
    };

    const handleProfileMenuClose = () => {
        setProfileMenu("none");
    };

    const toggleNotDrawer = () => {
        setOpenNotBar(true);
    };

    const closeNotDrawer = () => {
        setOpenNotBar(false);
    };

    // -> date
    const date = useDate();

    // -> store
    const { user } = useSelector((state) => state.authReducer);
    const { notifications } = useSelector(notificationsSelector);

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: "none",
                    width: {
                        xs: "100%",
                        sm: `100%`,
                    },
                }}
            >
                {/* ############### Notificationbar ###############*/}
                <NotificationsBar
                    openNotBar={openNotBar}
                    closeNotDrawer={closeNotDrawer}
                />
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                    }}
                >
                    {/* ############### Left ################## */}
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {mobileOpen ? (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerToggle}
                                edge="start"
                                sx={{
                                    display: { xs: "block", sm: "none" },
                                    marginTop: 1.3,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerToggle}
                                edge="start"
                                sx={{
                                    display: { xs: "block", sm: "none" },
                                    marginTop: 1.3,
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    color: colors.bgColor4,
                                }}
                            >
                                Welcome back, {user.first_name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    color: colors.bgColor4,
                                }}
                            >
                                Explore different function from your dashboard
                            </Typography>
                        </Box>
                    </Box>

                    {/* ############### Center ################## */}
                    <Stack variant="div" style={{ display: "flex" }}>
                        <Box
                            sx={{
                                background: `linear-gradient(180deg, rgb(255, 255, 255, .1), ${colors.primary})`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 2,
                                p: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    color: colors.bgColor5,
                                    width: 70,
                                    textAlign: "center",
                                }}
                            >
                                {date.time}
                            </Typography>
                            <Typography
                                component={"div"}
                                sx={{
                                    ml: 4,
                                    mr: 3,
                                    width: 90,
                                    bgcolor: colors.primary,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 2,
                                }}
                            >
                                <Avatar
                                    src={logo}
                                    variant="square"
                                    sx={{
                                        height: 40,
                                        width: 40,
                                        img: {
                                            objectFit: "contain",
                                        },
                                    }}
                                />
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    color: colors.bgColor5,
                                    width: 90,
                                    textAlign: "center",
                                }}
                            >
                                {date.date}
                            </Typography>
                        </Box>
                    </Stack>

                    {/* ############### Right ################## */}
                    <TopbarRight
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Box>
                            <NavLink to="/site-settings">
                                <Button
                                    startIcon={<SettingsSuggest />}
                                    variant="contained"
                                    fullWidth
                                    color="secondary"
                                    sx={{
                                        height: 45,
                                        borderRadius: `25px !important`,
                                        fontWeight: "bold !important",
                                        border: `2px dotted ${colors.bgColor5}`,
                                        ":hover": {
                                            bgcolor: colors.secondary,
                                            opacity: 0.9,
                                            border: `2px solid ${colors.warning}`,
                                        },
                                    }}
                                >
                                    System configurations
                                </Button>
                            </NavLink>
                        </Box>
                        <Stack
                            spacing={4}
                            direction="row"
                            sx={{
                                position: "relative",
                                p: 1,
                                borderRadius: 2,
                                overflow: "hidden",
                                background: `linear-gradient(180deg, rgb(255, 255, 255, .2), ${colors.primary})`,
                            }}
                        >
                            <Badge
                                color="secondary"
                                showZero
                                sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    "& .MuiBadge-badge": {
                                        background: `linear-gradient(180deg, ${colors.info}, ${colors.info})`,
                                        fontWeight: "bold",
                                        height: 15,
                                        color: colors.white,
                                    },
                                }}
                                onClick={() => {
                                    navigate("/system-logs");
                                }}
                            >
                                <Terminal className="icon" />
                                <Typography
                                    sx={{
                                        fontSize: 10,
                                        fontWeight: "bold",
                                        ml: 0.5,
                                    }}
                                >
                                    Logs
                                </Typography>
                            </Badge>
                        </Stack>
                        <Stack
                            spacing={4}
                            direction="row"
                            sx={{
                                position: "relative",
                                p: 1,
                                borderRadius: 2,
                                // overflow: 'hidden',
                                background: `linear-gradient(180deg, rgb(255, 255, 255, .2), ${colors.primary})`,
                            }}
                        >
                            <a
                                href="https://login.beem.africa/#!/?utm_source=direct&utm_medium=web&utm_campaign=none"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <BeemSMSBalanceWidget />
                            </a>
                        </Stack>
                        <Stack
                            spacing={4}
                            direction="row"
                            sx={{
                                position: "relative",
                                p: 1,
                                borderRadius: 2,
                                // overflow: 'hidden',
                                background: `linear-gradient(180deg, rgb(255, 255, 255, .2), ${colors.primary})`,
                            }}
                        >
                            <Badge
                                color="error"
                                badgeContent={size(
                                    filter(
                                        notifications,
                                        ({ isSeen }) => isSeen === 0
                                    )
                                )}
                                showZero
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    "& .MuiBadge-badge": {
                                        bgcolor: "red",
                                        fontWeight: "bold",
                                        height: 15,
                                    },
                                }}
                                onClick={() => toggleNotDrawer()}
                            >
                                <NotificationsActiveIcon className="icon" />
                                <Typography
                                    sx={{
                                        fontSize: 10,
                                        fontWeight: "bold",
                                        ml: 0.5,
                                    }}
                                >
                                    Notification
                                </Typography>
                            </Badge>
                        </Stack>
                        <Stack sx={{ position: "relative" }}>
                            <UserAvatar
                                className="dp"
                                sx={{
                                    cursor: "pointer",
                                    height: 45,
                                    width: 45,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                                }}
                                alt={`${user.first_name} ${user.middle_name} ${user.last_name}`}
                                src={
                                    user.profile_picture
                                        ? user.profile_picture
                                        : "dp"
                                }
                                onClick={() => {
                                    handleProfileMenuOpen();
                                }}
                            />
                            <AppBarMenu
                                menuToggle={profileMenu}
                                title="Manage Profile"
                                subTitle="Manage your profile"
                                actionBtn="Manage profile"
                                onClose={handleProfileMenuClose}
                                action="/profile"
                            >
                                <ProfileCard user={user} />
                            </AppBarMenu>
                        </Stack>
                    </TopbarRight>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Topbar;
