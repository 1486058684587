import React from "react";
import ReportLayout from "../../layouts/ReportLayout";
import {
    CuTableHeader,
    CuTableRow,
    CuTableSN,
    CuTableColumn,
    CuTableHead,
    CuTableBody,
    CuReportTable,
} from "../../../components/Tables/ReportTableComp";
import { isEmpty } from "lodash";
import moment from "moment";
import ReportNoData from "../../../components/ReportNoData";
import { numFormater } from "../../../helpers/numFormater";

const TransactionReport = ({ transactions }) => {
    return (
        <ReportLayout
            head={"Wazawa Max organization report"}
            orientation={"landscape"}
        >
            {isEmpty(transactions) ? (
                <>
                    <ReportNoData />
                </>
            ) : (
                <CuReportTable>
                    <CuTableHeader>
                        <CuTableRow>
                            <CuTableSN value={"S/N"} />
                            <CuTableHead
                                value={"Payment Ref"}
                                style={{
                                    width: "420px",
                                    textAlign: "center",
                                }}
                            />
                            <CuTableHead
                                value={"Service"}
                                style={{
                                    width: "420px",
                                    textAlign: "center",
                                }}
                            />
                            <CuTableHead
                                value={"Payment method"}
                                style={{
                                    width: "420px",
                                    textAlign: "center",
                                }}
                            />
                            <CuTableHead
                                value={"Amount"}
                                style={{
                                    width: "300px",
                                    whiteSpace: "nowrap",
                                    textAlign: "center",
                                }}
                            />
                            <CuTableHead
                                value={"Date payed"}
                                style={{
                                    width: "420px",
                                    textAlign: "center",
                                }}
                            />
                            <CuTableHead
                                value={"status"}
                                style={{
                                    textAlign: "center",
                                    width: "300px",
                                }}
                            />
                        </CuTableRow>
                    </CuTableHeader>
                    {/* Table body */}
                    <CuTableBody>
                        {Object.values(transactions).map(
                            (transaction, index) => (
                                <CuTableRow key={index}>
                                    <CuTableSN value={++index} />
                                    <CuTableColumn
                                        value={transaction.reference_number}
                                        style={{
                                            width: "420px",
                                            textAlign: "center",
                                        }}
                                    />
                                    <CuTableColumn
                                        value={transaction.service}
                                        style={{
                                            width: "420px",
                                            textAlign: "center",
                                        }}
                                    />
                                    <CuTableColumn
                                        value={transaction.payment_method}
                                        style={{
                                            width: "420px",
                                            textAlign: "center",
                                        }}
                                    />
                                    <CuTableColumn
                                        value={numFormater(transaction.amount)}
                                        style={{
                                            width: "300px",
                                            whiteSpace: "nowrap",
                                            textAlign: "center",
                                        }}
                                    />
                                    <CuTableColumn
                                        value={moment(
                                            transaction.updated_at
                                        ).format("LL")}
                                        style={{
                                            width: "420px",
                                            textAlign: "center",
                                        }}
                                    />
                                    <CuTableColumn
                                        value={transaction.status}
                                        style={{
                                            width: "300px",
                                            whiteSpace: "nowrap",
                                            textAlign: "center",
                                            color:
                                                transaction.status ===
                                                "not_paid"
                                                    ? "brown"
                                                    : transaction.status ===
                                                      "pending"
                                                    ? "brown"
                                                    : transaction.status ===
                                                          "complete" ||
                                                      transaction.status ===
                                                          "success"
                                                    ? "green"
                                                    : transaction.status ===
                                                          "failed" ||
                                                      transaction.status ===
                                                          "failure"
                                                    ? "red"
                                                    : "black",
                                        }}
                                    />
                                </CuTableRow>
                            )
                        )}
                    </CuTableBody>
                </CuReportTable>
            )}
        </ReportLayout>
    );
};

export default TransactionReport;
