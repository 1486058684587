import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
    departments: [],
};

// !########## SLICE #############
const districtsSlice = createSlice({
    name: "districtsSlice",
    initialState,
    reducers: {
        setDistricts: (state, actions) => {
            state.districts = actions.payload;
        },
        addDistrict: (state, actions) => {
            const department = actions.payload;
            isEmpty(state.districts)
                ? (state.districts = [department, ...[]])
                : (state.districts = [department, ...state.districts]);
        },
    },
});

export const { setDistricts, addDistrict } = districtsSlice.actions;
export default districtsSlice.reducer;
