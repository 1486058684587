import React from 'react';
import {
    AttachEmailOutlined,
    LocationOn,
    PhoneForwardedOutlined,
    RemoveRedEye,
} from '@mui/icons-material';
import {
    Avatar,
    Badge,
    Box,
    Button,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Tooltip,
    Typography,
    Chip,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { Link } from 'react-router-dom';

const PromotionCustomerWidget4 = ({ customer }) => {
    return (
        <>
            <Box
                sx={{
                    'width': '100%',
                    'borderRadius': 4,
                    'overflow': 'hidden',
                    'border': `4px solid ${colors.bgColor4}`,
                    'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    'transition': '.3s all ease-in-out',
                    ':hover': {
                        transition: '.3s all ease-in-out',
                        border: `4px solid ${colors.secondary}`,
                    },
                }}
            >
                <ListItemButton
                    sx={{
                        'transition': '.3s all ease-in-out',
                        '& .dp': {
                            mr: 2,
                            transition: '.3s all ease-in-out',
                        },
                        'bgcolor': colors.primary,
                        ':hover': {
                            cursor: 'default',
                            bgcolor: colors.primary,
                        },
                    }}
                >
                    <ListItem
                        sx={{
                            p: 0,
                            position: 'relative',
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                src={customer.logo_url}
                                className="dp"
                                sx={{
                                    height: 70,
                                    width: 70,
                                    border: `2px solid ${colors.secondary}`,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            disableTypography
                            primary={
                                <Typography
                                    noWrap
                                    sx={{
                                        fontWeight: 500,
                                        color: colors.secondary,
                                        opacity: 0.8,
                                    }}
                                >
                                    {customer.name}
                                </Typography>
                            }
                            secondary={
                                <Box sx={{}}>
                                    <Typography
                                        noWrap
                                        sx={{
                                            fontSize: 12,
                                            display: 'flex',
                                            alignItems: 'center',
                                            opacity: 0.8,
                                            color: colors.secondary,
                                        }}
                                    >
                                        <LocationOn
                                            color="secondary"
                                            sx={{ fontSize: 14 }}
                                        />{' '}
                                        {customer.location}
                                    </Typography>
                                    <Chip
                                        label={customer.customer_type}
                                        color={
                                            customer.customer_type === 'company'
                                                ? 'info'
                                                : 'secondary'
                                        }
                                        size="small"
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 'bold',
                                            height: 14,
                                            mt: 0.5,
                                            ml: 0.2,
                                        }}
                                    />
                                </Box>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                                height: '100%',
                                color: colors.white,
                                borderRadius: 1,
                                opacity: 0.9,
                            }}
                        >
                            <Link to={`/promotions/customers/${customer.id}`}>
                                <Tooltip title={'Customer details'} arrow>
                                    <IconButton
                                        sx={{
                                            borderRadius: `50px !important`,
                                            transform: `scale(1.2)`,
                                            border: `1px dotted ${colors.primary}`,
                                            background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                                        }}
                                    >
                                        <RemoveRedEye
                                            sx={{
                                                fontSize: 15,
                                                color: colors.primary,
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </Box>
                    </ListItem>
                </ListItemButton>
            </Box>
        </>
    );
};

export default PromotionCustomerWidget4;
