import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { filter, size } from 'lodash';
import StatsInfoBox from '../infoboxes/StatsInfoBox';
import {
    employersSelector,
    jobsSelector,
} from '../../states/features/selectors';
import { useSelector } from 'react-redux';

const EmployerSidebar = () => {
    // ############# Redux state #############
    const { employers } = useSelector(employersSelector);
    const { allEmployerJobs } = useSelector(jobsSelector);

    // ############# FUNC ####################
    // => Employer by status
    const getEmployerByStatus = () => {
        // => Active
        let activeEmployer = filter(
            employers,
            ({ is_active }) => is_active === 1,
        );
        // => Inactive
        let inactiveEmployer = filter(
            employers,
            ({ is_active }) => is_active === 0,
        );
        // => Suspended
        let suspendedEmployer = filter(
            employers,
            ({ status }) => status === 'suspended',
        );
        // => Banned
        let bannedEmployer = filter(
            employers,
            ({ status }) => status === 'banned',
        );
        return {
            activeEmployer,
            inactiveEmployer,
            suspendedEmployer,
            bannedEmployer,
        };
    };
    const employerByStatus = getEmployerByStatus();

    const jobStats = [
        {
            id: 1,
            title: 'Pending jobs',
            stats: `${size(
                filter(allEmployerJobs, ['status', 'pending']),
            )} Jobs`,
            borderColor: colors.warning,
        },
        {
            id: 2,
            title: 'Published jobs',
            stats: `${size(
                filter(allEmployerJobs, ['status', 'published']),
            )} Jobs`,
            borderColor: colors.success,
        },
        {
            id: 3,
            title: 'Denied jobs',
            stats: `${size(
                filter(allEmployerJobs, ['status', 'denied']),
            )} Jobs`,
            borderColor: colors.display,
        },
        {
            id: 4,
            title: 'Banned jobs',
            stats: `${size(
                filter(allEmployerJobs, ['status', 'banned']),
            )} Jobs`,
            borderColor: 'red',
        },
    ];

    return (
        <>
            <Box
                sx={{
                    p: 1,
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        opacity: 0.8,
                        fontWeight: 'bold',
                    }}
                >
                    Employers stats
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    px: 1.5,
                    py: 2,
                    borderRadius: 2,
                    mt: 1,
                    boxShadow: `1px -1px 10px 0px ${colors.bgColor4}`,
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                }}
            >
                <Box
                    sx={{
                        height: 150,
                        width: 150,
                        borderRadius: 50,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        border: `5px solid ${colors.bgColor4}`,
                        boxShadow: `0px 10px 15px ${colors.bgColor5}`,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 35,
                            fontWeight: 'bold',
                            opacity: 0.8,
                            color: colors.info,
                        }}
                    >
                        {size(employers)}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            opacity: 0.8,
                            fontStyle: 'italic',
                        }}
                    >
                        {'Employers'}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.info,
                                    borderBottom: `3px solid ${colors.bgColor5}`,
                                }}
                            >
                                {size(employerByStatus.activeEmployer)}
                                <sup
                                    style={{
                                        color: colors.warning,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    Active
                                </sup>
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.danger,
                                    borderBottom: `3px solid ${colors.bgColor5}`,
                                }}
                            >
                                {size(employerByStatus.inactiveEmployer)}
                                <sup
                                    style={{
                                        color: colors.danger,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    Inactive
                                </sup>
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.info,
                                    borderBottom: `3px solid ${colors.bgColor5}`,
                                }}
                            >
                                {size(employerByStatus.suspendedEmployer)}
                                <sup
                                    style={{
                                        color: colors.success,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    Suspended
                                </sup>
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.info,
                                    borderBottom: `3px solid ${colors.bgColor5}`,
                                }}
                            >
                                {size(employerByStatus.bannedEmployer)}
                                <sup
                                    style={{
                                        color: colors.warning,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    Banned
                                </sup>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box
                sx={{
                    p: 1,
                    mt: 5,
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        opacity: 0.8,
                        fontWeight: 'bold',
                    }}
                >
                    Employers job stats
                </Typography>
            </Box>
            <Grid container spacing={2} sx={{ mt: 0 }}>
                {jobStats.map((stats) => (
                    <Grid item sm={6} key={stats.id}>
                        <StatsInfoBox stats={stats} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default EmployerSidebar;
