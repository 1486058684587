import React from 'react';
import {
    AttachEmailOutlined,
    LocationOn,
    PhoneForwardedOutlined,
} from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
    IconButton,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { isEmpty, size } from 'lodash';
import ActivateDeactivateEmployer from '../../pages/employers/CRUD/ActivateDeactivateEmployer';

const EmployerWidget2 = ({ employer }) => {
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    borderRadius: 2,
                    overflow: 'hidden',
                    background: `linear-gradient(180deg, ${colors.secondary}, ${colors.bgColor3})`,
                    transition: '.3s all ease-in-out',
                }}
            >
                <ListItemButton
                    sx={{
                        'mb': 1,
                        'transition': '.3s all ease-in-out',
                        '& .dp': {
                            mr: 2,
                            transition: '.3s all ease-in-out',
                        },
                        'bgcolor': colors.primary,
                        ':hover': {
                            cursor: 'default',
                            bgcolor: colors.primary,
                        },
                    }}
                >
                    <ListItem
                        sx={{
                            p: 0,
                            position: 'relative',
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                src={''}
                                className="dp"
                                sx={{
                                    height: 60,
                                    width: 60,
                                    border: `2px solid ${colors.secondary}`,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        color: colors.secondary,
                                        opacity: 0.8,
                                    }}
                                >
                                    {`${employer.first_name} ${employer.last_name}`}
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    noWrap
                                    sx={{
                                        fontSize: 13,
                                        display: 'flex',
                                        alignItems: 'center',
                                        opacity: 0.8,
                                        color: colors.secondary,
                                        width: 150,
                                    }}
                                >
                                    <LocationOn
                                        color="secondary"
                                        sx={{ fontSize: 14 }}
                                    />{' '}
                                    {`${employer.region} - ${employer.district}`}
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            noWrap
                            sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: colors.secondary,
                            }}
                        >
                            {`${employer.registration_number}`}
                        </Typography>
                        {/* <ActivateDeactivateEmployer employer={employer} /> */}
                    </Box>
                </ListItemButton>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        pb: 1,
                        borderBottom: `2px dotted ${colors.bgColor5}`,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: 50,
                                mx: 0.5,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                opacity: 0.9,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        mt: 0.5,
                                    }}
                                >
                                    Jobs
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        color: colors.info,
                                    }}
                                >
                                    {size(employer.jobs)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                'width': '100%',
                                'height': 50,
                                'mx': 0.5,
                                'display': 'flex',
                                'alignItems': 'center',
                                'justifyContent': 'center',
                                'borderRadius': 1,
                                'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                'opacity': 0.9,
                                '& .icon': {
                                    transition: `.3s ease-in-out all`,
                                },
                                ':hover': {
                                    '& .icon': {
                                        color: colors.warning,
                                        transition: `.3s ease-in-out all`,
                                    },
                                },
                            }}
                        >
                            <a
                                href={`tel:${employer.phone_number}`}
                                style={{ width: '100%' }}
                            >
                                <Button
                                    fullWidth
                                    size="small"
                                    color="info"
                                    sx={{ py: 1.5 }}
                                >
                                    <PhoneForwardedOutlined
                                        sx={{ fontSize: 20 }}
                                        className="icon"
                                    />
                                </Button>
                            </a>
                        </Box>
                        <Box
                            sx={{
                                'width': '100%',
                                'height': 50,
                                'mx': 0.5,
                                'display': 'flex',
                                'alignItems': 'center',
                                'justifyContent': 'center',
                                'color': colors.white,
                                'borderRadius': 1,
                                'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                'opacity': 0.9,
                                ':hover': {
                                    '& .icon': {
                                        color: colors.warning,
                                    },
                                },
                            }}
                        >
                            {isEmpty(employer.email) ? (
                                <Button
                                    fullWidth
                                    size="small"
                                    color="info"
                                    disabled
                                >
                                    <AttachEmailOutlined
                                        sx={{ fontSize: 20 }}
                                        className="icon"
                                    />
                                </Button>
                            ) : (
                                <a
                                    href={`mailTo:${employer.email}`}
                                    style={{ width: '100%' }}
                                >
                                    <Button
                                        fullWidth
                                        size="small"
                                        color="info"
                                        sx={{ py: 1.5 }}
                                    >
                                        <AttachEmailOutlined
                                            sx={{ fontSize: 20 }}
                                            className="icon"
                                        />
                                    </Button>
                                </a>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default EmployerWidget2;
