import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, PublishOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { updatePracticalTraining } from '../../../states/features/practicalTraining/practicalTrainingsSlice';
import useRTK from '../../../hooks/useRTK';
import { useResumeTrainingMutation } from '../../../api/practicalTrainings/practicalTrainingsAPI';

const ResumeTraining = ({ training, handleClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        resumePT,
        {
            isLoading: resumeLoading,
            isSuccess: resumeSuccess,
            isError: resumeIsError,
            error: resumeError,
            data: resumeData,
        },
    ] = useResumeTrainingMutation();
    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleClose();
        dispatch(updatePracticalTraining(data));
    };
    RTK.useRTKResponse(
        resumeSuccess,
        resumeIsError,
        resumeError,
        resumeData,
        rtkActions,
    );

    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Box sx={{ mb: 2 }} className="warning-message">
                        <Typography>
                            <strong>NOTE : </strong>
                            You'r about to resume this practical training. By
                            resuming practical training the organization can
                            then publish it to students for applications.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <CustomSubmitButton
                        startIcon={<PublishOutlined />}
                        endIcon={<ArrowRightAltOutlined />}
                        btnColor={'info'}
                        variant={'contained'}
                        loading={resumeLoading}
                        sx={{ py: 2, fontSize: 16 }}
                        onClick={() => {
                            resumePT(training.id);
                        }}
                    >
                        Resume practical training
                    </CustomSubmitButton>
                </Grid>
            </Grid>
        </>
    );
};

export default ResumeTraining;
