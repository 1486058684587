import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { userRolesEndpoints } from './settingEndpoints';
import { settingAPI } from './settingAPI';

export const userRolesAPI = createApi({
    reducerPath: 'userRolesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getUserRoles: builder.query({
            query: () => `${userRolesEndpoints.GET_USER_ROLES}`,
        }),
        // => ADD
        addUserRole: builder.mutation({
            query: (payload) => ({
                url: `${userRolesEndpoints.ADD_USER_ROLE}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
        // => UPDATE
        updateUserRole: builder.mutation({
            query: (payload) => ({
                url: `${userRolesEndpoints.UPDATE_USER_ROLE}/` + payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateUserRole: builder.mutation({
            query: (payload) => ({
                url: `${userRolesEndpoints.ACTIVATE_USER_ROLE}/` + payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateUserRole: builder.mutation({
            query: (payload) => ({
                url: `${userRolesEndpoints.DEACTIVATE_USER_ROLE}/` + payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteUserRole: builder.mutation({
            query: (payload) => ({
                url: `${userRolesEndpoints.DELETE_USER_ROLE}/` + payload,
                method: `DELETE`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
    }),
});

export const {
    useGetUserRolesQuery,
    useAddUserRoleMutation,
    useUpdateUserRoleMutation,
    useActivateUserRoleMutation,
    useDeactivateUserRoleMutation,
    useDeleteUserRoleMutation,
} = userRolesAPI;
