import React from 'react';
import PageLayout from '../../layouts/PageLayout';
import CustomCard2 from '../../../components/cards/CustomCard2';
import { Grid } from '@mui/material';
import Contacts from './Contacts';
import MapLocation from './MapLocation';
import SocialNetworks from './SocialNetworks';

const HelpCenter = () => {
    return (
        <>
            <PageLayout head={'Wazawa Max contacts'} noSidebar>
                <CustomCard2
                    head={'How people can reach Wazawa Max'}
                    borderBottom
                >
                    <Grid container spacing={4}>
                        {/* ############## Contacts ############## */}
                        <Grid item sm={12}>
                            <Contacts />
                        </Grid>

                        {/* ############## Social Nets ############## */}
                        <Grid item sm={6}>
                            <SocialNetworks />
                        </Grid>

                        {/* ############## Location ############## */}
                        <Grid item sm={6}>
                            <MapLocation />
                        </Grid>
                    </Grid>
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default HelpCenter;
