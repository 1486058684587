// -> get all
const GET_ALL = '/faqs';

// -> add
const CREATE_FAQ = '/faqs';

// -> update
const UPDATE_FAQ = '/faqs';

// -> activate
const ACTIVATE_FAQ = '/faqs/publish';

// -> deactivate
const DEACTIVATE_FAQ = '/faqs/unpublish';

// -> delete
const DELETE_FAQ = '/faqs';

export const faqsEndpoints = {
    GET_ALL,
    CREATE_FAQ,
    UPDATE_FAQ,
    ACTIVATE_FAQ,
    DEACTIVATE_FAQ,
    DELETE_FAQ,
};
