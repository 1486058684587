import { MarkChatRead } from '@mui/icons-material';
import React from 'react';
import { setNotifications } from '../../../states/features/notifications/notificationsSlice';
import { useMarkAllReadyMutation } from '../../../api/notifications/notificationsAPI';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import useRTK from '../../../hooks/useRTK';
import { useDispatch } from 'react-redux';

const MarkAllRead = () => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        markReadAction,
        {
            isLoading: markReadLoading,
            isSuccess: markReadSuccess,
            isError: markReadIsError,
            error: markReadError,
            data: markReadData,
        },
    ] = useMarkAllReadyMutation();

    // => Res
    // ############# useEffect ##################
    const rtkActions = (data) => {
        dispatch(setNotifications(data));
    };
    RTK.useRTKResponse(
        markReadSuccess,
        markReadIsError,
        markReadError,
        markReadData,
        rtkActions,
    );

    return (
        <div>
            <CustomSubmitButton
                variant="outlined"
                btnColor="success"
                loading={markReadLoading}
                startIcon={<MarkChatRead />}
                mt={true}
                sx={{ fontSize: 11 }}
                onClick={markReadAction}
            >
                Mark all ready
            </CustomSubmitButton>
        </div>
    );
};

export default MarkAllRead;
