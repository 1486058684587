import React from "react";
import { Form, Formik } from "formik";
import CustomTextField from "../../../components/Forms/CustomTextField";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { ManageAccounts } from "@mui/icons-material";
import * as Yup from "yup";
import CustomTextArea from "../../../components/Forms/CustomTextArea";
import { useDispatch } from "react-redux";
import { Grid, MenuItem } from "@mui/material";
import useRTK from "../../../hooks/useRTK";
import CustomSelectInput from "../../../components/Forms/CustomSelectInput";
import { useUpdateDistrictMutation } from "../../../api/settings/districtsAPI";
import { setDistricts } from "../../../states/features/settings/districtsSlice";

// -> validate settings form
const validate = Yup.object({
    name: Yup.string().required(),
    description: Yup.string().nullable(),
    region_id: Yup.string().required("Region is required field"),
});

const UpdateDistrict = ({ handleClose, refetch, editedValues, regions }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        updateDistrict,
        { isLoading, isSuccess, isError, error, data: updateData },
    ] = useUpdateDistrictMutation();

    // ############# RTK response ##################
    const rtkActions = (data) => {
        refetch();
        handleClose(data);
        dispatch(setDistricts(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updateData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    id: editedValues.id,
                    region_id: editedValues.region.id,
                    name: editedValues.name,
                    description: editedValues.description,
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    updateDistrict(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container columnSpacing={4}>
                            <Grid item sm={6}>
                                <CustomSelectInput
                                    type="text"
                                    name="region_id"
                                    label="Select region"
                                    formik={formik}
                                    color="primary"
                                >
                                    {regions?.map((region) => (
                                        <MenuItem
                                            key={region.id}
                                            value={region.id}
                                        >
                                            {region.name}
                                        </MenuItem>
                                    ))}
                                </CustomSelectInput>
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    label="Name"
                                    name="name"
                                    type="text"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomTextArea
                                    label="Description"
                                    name="description"
                                />
                            </Grid>
                        </Grid>
                        <CustomSubmitButton
                            startIcon={<ManageAccounts />}
                            onClick={formik.handleSubmit}
                            loading={isLoading}
                            btnColor={"info"}
                            sx={{ py: 1.5 }}
                        >
                            Update setting
                        </CustomSubmitButton>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateDistrict;
