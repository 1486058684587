import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { shopEndpoints } from './shopEndpoints';

export const shopAPI = createApi({
    reducerPath: 'shopAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Shops', 'Shop'],
    endpoints: (builder) => ({
        // => GET all
        getAllShops: builder.query({
            query: () => `${shopEndpoints.GET_ALL_SHOPS}`,
            providesTags: ['Shops'],
        }),

        // => GET single
        getSingleShop: builder.query({
            query: (payload) => `${shopEndpoints.GET_SINGLE_SHOP}/${payload}`,
            providesTags: ['Shop'],
        }),

        // => SUSPEND
        suspendShop: builder.mutation({
            query: (payload) => ({
                url: `${shopEndpoints.SUSPEND_SHOP}/${payload}`,
                method: 'PUT',
            }),
            invalidatesTags: (args, error) => (error ? [] : ['Shops', 'Shop']),
        }),

        // => RESUME
        resumeShop: builder.mutation({
            query: (payload) => ({
                url: `${shopEndpoints.RESUME_SHOP}/${payload}`,
                method: 'PUT',
            }),
            invalidatesTags: (args, error) => (error ? [] : ['Shops', 'Shop']),
        }),

        // => VERIFY
        verifyShop: builder.mutation({
            query: (payload) => ({
                url: `${shopEndpoints.VERIFY_SHOP}/${payload}`,
                method: 'PUT',
            }),
            invalidatesTags: (args, error) => (error ? [] : ['Shops', 'Shop']),
        }),

        // => DENY
        denyShop: builder.mutation({
            query: (payload) => ({
                url: `${shopEndpoints.DENY_SHOP}/${payload.shop_id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (args, error) => (error ? [] : ['Shops', 'Shop']),
        }),
    }),
});

export const {
    useGetSingleShopQuery,
    useSuspendShopMutation,
    useResumeShopMutation,
    useVerifyShopMutation,
    useDenyShopMutation,

    // => Lazy
    useLazyGetAllShopsQuery,
} = shopAPI;
