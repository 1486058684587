// ############################ CUSTOMERS ##########################
// -> get all
const GET_ALL_CUSTOMERS = '/customers';

// -> get one
const GET_ONE_CUSTOMER = '/customers';

// -> add customer
const CREATE_CUSTOMER = '/customers';

// -> update customer
const UPDATE_CUSTOMER = '/customers';

// -> activate customer
const ACTIVATE_CUSTOMER = '/customers/activate';

// -> deactivate customer
const DEACTIVATE_CUSTOMER = '/customers/deactivate';

// -> delete customer
const DELETE_CUSTOMER = '/customers';

// ############################ PROMOTIONS ##########################
// -> get all
const GET_ALL_PROMOTIONS = '/promotions';

// -> get one
const GET_ONE_PROMOTION = '/promotions';

// -> add promotion
const CREATE_PROMOTION = '/promotions';

// -> update promotion
const UPDATE_PROMOTION = '/promotions';

// -> activate promotion
const ACTIVATE_PROMOTION = '/promotions/activate';

// -> deactivate promotion
const DEACTIVATE_PROMOTION = '/promotions/deactivate';

// -> Publish promotion
const PUBLISH_PROMOTION = '/promotions/publish';

// -> Promotion subscription
const PROMOTION_SUBSCRIPTION = '/promotions/subscriptions';

// -> Pay promotion
const PAY_PROMOTION_SUBSCRIPTION = '/promotions/subscriptions/pay';

// -> delete promotion
const DELETE_PROMOTION = '/promotions';

export const promotionsEndpoints = {
    GET_ALL_CUSTOMERS,
    GET_ONE_CUSTOMER,
    CREATE_CUSTOMER,
    UPDATE_CUSTOMER,
    ACTIVATE_CUSTOMER,
    DEACTIVATE_CUSTOMER,
    DELETE_CUSTOMER,
    GET_ALL_PROMOTIONS,
    GET_ONE_PROMOTION,
    CREATE_PROMOTION,
    UPDATE_PROMOTION,
    ACTIVATE_PROMOTION,
    DEACTIVATE_PROMOTION,
    PUBLISH_PROMOTION,
    DELETE_PROMOTION,
    PROMOTION_SUBSCRIPTION,
    PAY_PROMOTION_SUBSCRIPTION,
};
