import React from 'react';
import { Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import CustomCard from '../../../components/cards/CustomCard';
import NoContent from '../../../components/NoContent';

const ORGDescription = ({ organization }) => {
    return (
        <>
            <CustomCard
                head={'Organization description'}
                height={isEmpty(organization.description) ? 200 : '100%'}
            >
                <Grid container justifyContent={'center'} padding={2}>
                    <Grid
                        item
                        sm={12}
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        {isEmpty(organization.description) ? (
                            <NoContent
                                height={180}
                                message={
                                    'Organization description not provided'
                                }
                            />
                        ) : (
                            <Typography
                                sx={{
                                    textAlign: 'left',
                                }}
                            >
                                {organization.description}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </CustomCard>
        </>
    );
};

export default ORGDescription;
