import React from "react";
import { styled } from "@mui/material/styles";
import {
    Card,
    CardContent as MuiCardContent,
    CardActions,
    Typography,
    Grid,
    Stack,
} from "@mui/material";
import { Link } from "react-router-dom";

// material icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// helpers imports
import { colors } from "../../assets/utils/constants";

// custom styled component
const CardLink = styled(Stack)({
    textDecoration: "none",
    "& .icon": {
        transition: "all ease-in-out 0.3s",
    },
    "&:hover .icon": {
        marginRight: -5,
        transition: "all ease-in-out 0.3s",
    },
});

const CardContent = styled(MuiCardContent)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "space-between",
    backgroundColor: colors.primary,
}));

const CardActionsLink = styled(CardActions)(({ theme }) => ({
    justifyContent: "space-between",
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    backgroundColor: colors.secondary,
}));

const InfoBox = ({ name, total, url }) => {
    return (
        <>
            <Grid item xs={4} md={4} sm={4}>
                <CardLink>
                    <Card
                        sx={{
                            borderRadius: 2,
                            boxShadow: `1px 1px 5px 0px ${colors.bgColor5}`,
                        }}
                    >
                        <CardContent>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item xs={8}>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        sx={{
                                            color: colors.gray,
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            letterSpacing: 1,
                                            width: "100%",
                                        }}
                                        noWrap
                                    >
                                        {name}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    md={3}
                                    sx={{
                                        textAlign: "center",
                                        borderRadius: 5,
                                        border: `2px solid ${colors.secondary}`,
                                    }}
                                >
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                        sx={{
                                            color: colors.gray,
                                            fontSize: { md: 16, xs: 16 },
                                            margin: 0,
                                        }}
                                    >
                                        {total}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Link to={url}>
                            <CardActionsLink>
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    View all
                                </Typography>
                                <ChevronRightIcon className="icon" />
                            </CardActionsLink>
                        </Link>
                    </Card>
                </CardLink>
            </Grid>
        </>
    );
};

export default InfoBox;
