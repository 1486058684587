import React from 'react';
import ConfigurationLayout from '../layouts/ConfigurationLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import { Box, Button, DialogActions, Grid, Typography } from '@mui/material';
import { ManageAccounts, Update } from '@mui/icons-material';
import { colors } from '../../assets/utils/constants';
import { numFormater } from '../../helpers/numFormater';
import CustomDialog from '../../components/Dialogies/CustomDialog';
import { Form, Formik } from 'formik';
import CustomTextField from '../../components/Forms/CustomTextField';
import { useDispatch, useSelector } from 'react-redux';
import {
    useGetJobPostPricingQuery,
    useUpdateJobPostPricingMutation,
} from '../../api/settings/settingAPI';
import useRTK from '../../hooks/useRTK';
import { setJobPostFramework } from '../../states/features/settings/settingsSlice';
import CustomSubmitButton from '../../components/Forms/CustomSubmitButton';
import { settingsSelector } from '../../states/features/selectors';
import * as Yup from 'yup';
import ContentLoader from '../../components/ContentLoader';

// ########### Validation ##################
const validateUpdateSchema = Yup.object({
    base_amount: Yup.string().required(),
    position_base_discount: Yup.string().required(),
    base_position_cost: Yup.string().required(),
    individual: Yup.string().required(),
    group: Yup.string().required(),
    company: Yup.string().required(),
});

const JobPostPricing = () => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp state #############
    const [open, setOpen] = React.useState(false);

    // ############# Redux state #############
    const { jobPostFramework } = useSelector(settingsSelector);

    // ############# RTK ##################
    const {
        isLoading,
        isSuccess,
        data: jobPostFramworkData,
    } = useGetJobPostPricingQuery();
    // => Action
    const rtkActions = (data) => {
        dispatch(setJobPostFramework(data));
    };
    // => Response
    RTK.useRTKFetch(isSuccess, jobPostFramworkData, rtkActions);

    // ############## FUNC #####################
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // ############# RTK ##################
    const [
        updateJobPostPricing,
        {
            isLoading: updateLoading,
            isSuccess: updateIsSuccess,
            isError: updateIsError,
            error: updateError,
            data: updateData,
        },
    ] = useUpdateJobPostPricingMutation();

    // ############# useEffect ##################
    const updateRtkActions = (data) => {
        handleClose();
        dispatch(setJobPostFramework(data));
    };
    RTK.useRTKResponse(
        updateIsSuccess,
        updateIsError,
        updateError,
        updateData,
        updateRtkActions,
    );

    const jobPricing = [
        {
            id: 1,
            name: 'Base amount',
            value: jobPostFramework.base_amount,
            type: 'amount',
        },
        {
            id: 2,
            name: 'Position base discount',
            value: jobPostFramework.position_base_discount,
            type: 'amount',
        },
        {
            id: 3,
            name: 'Base position cost',
            value: jobPostFramework.base_position_cost,
            type: 'amount',
        },
        {
            id: 4,
            name: 'Individual cost (%)',
            value: jobPostFramework.individual,
            type: 'per',
        },
        {
            id: 5,
            name: 'Group cost (%)',
            value: jobPostFramework.group,
            type: 'per',
        },
        {
            id: 6,
            name: 'Company cost (%)',
            value: jobPostFramework.company,
            type: 'per',
        },
    ];

    return (
        <>
            {/* =============== Dialogy ================== */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`Update job post pricing`}
            >
                <Formik
                    initialValues={{
                        base_amount: jobPostFramework.base_amount,
                        position_base_discount:
                            jobPostFramework.position_base_discount,
                        base_position_cost: jobPostFramework.base_position_cost,
                        individual: jobPostFramework.individual,
                        group: jobPostFramework.group,
                        company: jobPostFramework.company,
                    }}
                    validationSchema={validateUpdateSchema}
                    onSubmit={(payload) => {
                        updateJobPostPricing(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid container columnSpacing={2}>
                                <Grid item sm={6}>
                                    <CustomTextField
                                        label="Base amount"
                                        name="base_amount"
                                        type="number"
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <CustomTextField
                                        label="Position base discount"
                                        name="position_base_discount"
                                        type="number"
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <CustomTextField
                                        label="Base position cost"
                                        name="base_position_cost"
                                        type="number"
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <CustomTextField
                                        label="Individual cost (%)"
                                        name="individual"
                                        type="number"
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <CustomTextField
                                        label="Group cost (%)"
                                        name="group"
                                        type="number"
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <CustomTextField
                                        label="Company cost (%)"
                                        name="company"
                                        type="number"
                                    />
                                </Grid>
                            </Grid>
                            <DialogActions>
                                <CustomSubmitButton
                                    startIcon={<ManageAccounts />}
                                    onClick={formik.handleSubmit}
                                    loading={updateLoading}
                                >
                                    Update setting
                                </CustomSubmitButton>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </CustomDialog>

            <ConfigurationLayout head={'Job post pricing'}>
                <CustomCard2
                    height={558}
                    head={'Job posting fee framework controller'}
                    action={
                        <Button
                            variant="contained"
                            startIcon={<Update />}
                            onClick={() => handleClickOpen()}
                        >
                            Update framework
                        </Button>
                    }
                >
                    {isLoading ? (
                        <>
                            <ContentLoader />
                        </>
                    ) : (
                        <>
                            <Grid container spacing={4}>
                                {jobPricing.map((pricing) => (
                                    <Grid item sm={4}>
                                        <Box
                                            sx={{
                                                height: 100,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: 5,
                                                borderBottomRightRadius: 20,
                                                borderBottomLeftRadius: 20,
                                                borderTop: `5px solid ${colors.bgColor5}`,
                                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                                            }}
                                        >
                                            <Box sx={{}}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        fontSize: 22,
                                                        textAlign: 'center',
                                                        opacity: 0.8,
                                                        color: colors.info,
                                                    }}
                                                >
                                                    {pricing.type === 'amount'
                                                        ? numFormater(
                                                              pricing.value,
                                                          )
                                                        : pricing.value}
                                                    {pricing.type ===
                                                    'amount' ? (
                                                        <>
                                                            <sup
                                                                style={{
                                                                    color:
                                                                        colors.success,
                                                                }}
                                                            >
                                                                TZS
                                                            </sup>
                                                        </>
                                                    ) : (
                                                        <> %</>
                                                    )}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        textAlign: 'center',
                                                        opacity: 0.8,
                                                    }}
                                                >
                                                    {pricing.name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </CustomCard2>
            </ConfigurationLayout>
        </>
    );
};

export default JobPostPricing;
