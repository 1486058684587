import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Topbar from '../../components/Topbar';
import Sidebar from '../../components/Sidebar';
import { colors } from '../../assets/utils/constants';
import HotToastfy from '../../components/HotToastfy';
import FullAppLoader from '../../components/FullAppLoader';
import useRTK from '../../hooks/useRTK';
import { useLocation } from 'react-router-dom';

const DrawerHeader = styled('div')(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const AppLayout = ({ children }) => {
    // => Scroll to top
    const { pathname } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // ################## CONST ###################
    const RTK = useRTK();

    // ################## Comp state ###################
    const [mobileOpen, setMobileOpen] = React.useState(false);

    // ################## FUNC ########################
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                position: 'absolute',
                top: 0,
                bottom: '100%',
                right: 0,
                left: 0,
            }}
        >
            {RTK.useRTKMainData() ? (
                <FullAppLoader />
            ) : (
                <>
                    {/* ############## Tostfy ############## */}
                    <HotToastfy />

                    {/* ============= topbar ================== */}
                    <Topbar
                        handleDrawerToggle={handleDrawerToggle}
                        mobileOpen={mobileOpen}
                    />

                    {/* ============= sidebar ================= */}
                    <Sidebar mobileOpen={mobileOpen} />

                    {/* ============= content ================= */}
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, bgcolor: colors.bgColor }}
                    >
                        <DrawerHeader />
                        {children}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default AppLayout;
