import { colors } from '../assets/utils/constants';

export const paymentStatusColor = (status, type = 'MUI') => {
    let color = 'default';
    if (status === 'not_paid') {
        color = 'warning' || colors.warning;
    } else if (status === 'complete') {
        color = 'success' || colors.success;
    } else if (status === 'failed') {
        color = 'error' || colors.danger;
    } else if (status === 'pending') {
        color = 'info' || colors.info;
    }

    if (status === 'not_paid' && type !== 'MUI') {
        color = colors.warning;
    } else if (status === 'complete' && type !== 'MUI') {
        color = colors.success;
    } else if (status === 'failed' && type !== 'MUI') {
        color = colors.danger;
    } else if (status === 'pending' && type !== 'MUI') {
        color = colors.info;
    }

    return color;
};
