import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    organizationJobs: [],
    singleORGJobs: [],
    allEmployerJobs: [],
    jobDetails: {},
    employerJobDetails: {},
};

// !########## SLICE #############
const jobsSlice = createSlice({
    name: "jobsSlice",
    initialState,
    reducers: {
        setOrganizationJobs: (state, actions) => {
            state.organizationJobs = actions.payload;
        },
        setSingleOrganizationJobs: (state, actions) => {
            state.singleORGJobs = actions.payload;
        },
        setAllEmployerJobs: (state, actions) => {
            state.allEmployerJobs = actions.payload;
        },
        updateJob: (state, actions) => {
            let job = actions.payload;
            state.jobDetails = job;
        },
        updateEmployerJob: (state, actions) => {
            let job = actions.payload;
            state.employerJobDetails = job;
        },
        setJobDetails: (state, actions) => {
            state.jobDetails = actions.payload;
        },
        setEmployerJobDetails: (state, actions) => {
            state.employerJobDetails = actions.payload;
        },
    },
});

export const {
    setOrganizationJobs,
    setSingleOrganizationJobs,
    setAllEmployerJobs,
    updateJob,
    updateEmployerJob,
    setJobDetails,
    setEmployerJobDetails,
} = jobsSlice.actions;
export default jobsSlice.reducer;
