import React, { useState } from 'react';
import CustomCard2 from '../../../components/cards/CustomCard2';
import { Box, Typography, Chip, Button } from '@mui/material';
import { colors } from '../../../assets/utils/constants';
import { numFormater } from '../../../helpers/numFormater';
import { isEmpty } from 'lodash';
import NoContent from '../../../components/NoContent';
import { SubscriptOutlined } from '@mui/icons-material';
import CustomDialog from '../../../components/Dialogies/CustomDialog';
import PromotionSubscription from '../CRUD/PromotionSubscription';
import moment from 'moment';

const CurrentSubscription = ({ promotion }) => {
    // ############# Redux state ##################
    const currentSubscription = promotion?.currSubscription;

    // ############ Comp states ###################
    const [openDialog, setOpenDialogy] = useState(false);

    // -> Dialogy closer
    const handleDialogOpen = () => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <div>
            <CustomDialog
                head={'Promotion subscription'}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            >
                <PromotionSubscription
                    promotion={promotion}
                    handleDialogClose={handleDialogClose}
                />
            </CustomDialog>

            <CustomCard2
                head={'Current subscribed package'}
                action={
                    <Box>
                        {!isEmpty(currentSubscription) ? (
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            pl: 1,
                                            borderRadius: 50,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                            mr: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 10,
                                                mr: 1,
                                                opacity: 0.8,
                                                color: colors.info,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Payment
                                        </Typography>
                                        <Chip
                                            color={
                                                currentSubscription.transaction ===
                                                false
                                                    ? 'error'
                                                    : currentSubscription
                                                          .transaction
                                                          .status === 'pending'
                                                    ? 'info'
                                                    : currentSubscription
                                                          .transaction
                                                          .status === 'success'
                                                    ? 'success'
                                                    : currentSubscription
                                                          .transaction
                                                          .status === 'failure'
                                                    ? 'error'
                                                    : 'error'
                                            }
                                            label={
                                                currentSubscription.transaction ===
                                                false
                                                    ? 'Not paid'
                                                    : currentSubscription
                                                          .transaction.status
                                            }
                                            size="small"
                                            sx={{
                                                px: 1,
                                                opacity: 0.8,
                                                fontSize: 11,
                                                height: 18,
                                                fontWeight: 'bold',
                                                textTransform: 'capitalize',
                                            }}
                                        />
                                    </Box>
                                    <Chip
                                        color={
                                            currentSubscription.status ===
                                            'active'
                                                ? 'success'
                                                : currentSubscription.status ===
                                                  'inactive'
                                                ? 'warning'
                                                : 'error'
                                        }
                                        label={currentSubscription.status}
                                        size="small"
                                        sx={{
                                            textTransform: 'capitalize',
                                            fontWeight: 'bold',
                                            opacity: 0.8,
                                            fontSize: 11,
                                            height: 18,
                                            px: 1,
                                        }}
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="small"
                                startIcon={<SubscriptOutlined />}
                                onClick={() => {
                                    handleDialogOpen();
                                }}
                            >
                                Subscribe now !
                            </Button>
                        )}
                    </Box>
                }
            >
                {!isEmpty(currentSubscription) ? (
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                color: colors.info,
                                opacity: 0.8,
                                py: 1,
                            }}
                        >
                            {currentSubscription.package.name}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                justifyContent: 'center',
                                borderRadius: 2,
                                border: `2px dotted ${colors.secondary}`,
                                boxShadow: `1px 1px 3px 2px ${colors.bgColor4}`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    textAlign: 'center',
                                    p: 0.5,
                                    fontWeight: 'bold',
                                    color: colors.info,
                                }}
                            >
                                {numFormater(currentSubscription.package.price)}
                                <sup
                                    style={{
                                        fontSize: 11,
                                        color: colors.success,
                                    }}
                                >
                                    TZS
                                </sup>
                            </Typography>
                            <Typography>{'/'}</Typography>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    textAlign: 'center',
                                    p: 0.5,
                                    fontWeight: 'bold',
                                    color: colors.info,
                                }}
                            >
                                {currentSubscription.package.duration} Days
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                px: 1,
                                mt: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    color: colors.success,
                                }}
                            >
                                <strong>From:</strong>{' '}
                                {currentSubscription.start_date
                                    ? moment(
                                          currentSubscription.start_date,
                                      ).format('MMM Do YYYY')
                                    : '-'}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    color: 'red',
                                }}
                            >
                                <strong>To:</strong>{' '}
                                {currentSubscription.end_date
                                    ? moment(
                                          currentSubscription.end_date,
                                      ).format('MMM Do YYYY')
                                    : '-'}
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <>
                        <NoContent
                            height={100}
                            message={'No subscribed package currently'}
                        />
                    </>
                )}
            </CustomCard2>
        </div>
    );
};

export default CurrentSubscription;
