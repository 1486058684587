import React from 'react';
import { Grid } from '@mui/material';
import OrganizationTable from '../../../components/Tables/OrganizationTable';
import CustomCard from '../../../components/cards/CustomCard';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import { size } from 'lodash';
import PageLayout from '../../layouts/PageLayout';
import { organizationsSelector } from '../../../states/features/selectors';
import CountBadge from '../../../components/CountBadge';
import { useParams } from 'react-router-dom';

const InstitutionsByStatus = () => {
    // ################# CONST #####################
    const { status } = useParams();

    // ############### REDUCER STATE #################
    const { organizations } = useSelector(organizationsSelector);
    const institutions = filter(organizations, [
        'organization_type.name',
        'Institution',
    ]);

    // ################ FUNC ###################
    const getOrgByStatus = (institutions) => {
        let filteredInstitutions = [];
        if (status === 'on-progress') {
            filteredInstitutions = filter(institutions, [
                'is_verified',
                'pending',
            ]);
        }
        if (status === 'request') {
            filteredInstitutions = filter(
                institutions,
                ({ is_verified }) =>
                    is_verified === 'on_progress' ||
                    is_verified === 'requested',
            );
        }
        if (status === 'verified') {
            filteredInstitutions = filter(institutions, [
                'is_verified',
                'verified',
            ]);
        }
        if (status === 'denied') {
            filteredInstitutions = filter(institutions, [
                'is_verified',
                'denied',
            ]);
        }
        return filteredInstitutions;
    };
    const companyByStatus = getOrgByStatus(institutions);

    return (
        <>
            <PageLayout head={`${status} institutions`}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard
                            head={`All ${status} institutions`}
                            action={
                                <CountBadge
                                    total={size(companyByStatus)}
                                    item={'institutions'}
                                />
                            }
                        >
                            <Grid container>
                                <Grid item sm={12}>
                                    <OrganizationTable
                                        height={522}
                                        organizations={companyByStatus}
                                    />
                                </Grid>
                            </Grid>
                        </CustomCard>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default InstitutionsByStatus;
