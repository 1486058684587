import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import { DoDisturb } from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CustomTextArea from "../../../../components/Forms/CustomTextArea";
import { useDispatch } from "react-redux";
import useRTK from "../../../../hooks/useRTK";
import { colors } from "../../../../assets/utils/constants";
import { useDenyEmployerJobMutation } from "../../../../api/job/jobAPI";
import { setEmployerJobDetails } from "../../../../states/features/jobs/jobsSlice";

// ################## VALIDATION #################
const denyValidationSchema = Yup.object({
    deny_reason: Yup.string()
        .required("Please provide reason for job denied")
        .max(500, "Reason must be at most 500 characters"),
});

const DenyJob = ({ cancel, closeDialogy, job }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK #####################
    const [
        denyEmployerJob,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useDenyEmployerJobMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        closeDialogy();
        dispatch(setEmployerJobDetails(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);
    return (
        <>
            <Formik
                initialValues={{
                    job_id: job.id,
                    deny_reason: "",
                }}
                validationSchema={denyValidationSchema}
                onSubmit={(payload) => {
                    denyEmployerJob(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <DialogContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    alignItems: "center",
                                    textAlign: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Grid container>
                                    <Grid item sm={12}>
                                        <WarningIcon
                                            sx={{
                                                fontSize: 33,
                                                fontWeight: "bold",
                                                color: colors.warning,
                                                opacity: 0.8,
                                                m: 0,
                                                p: 0,
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: 22,
                                                fontWeight: "bold",
                                                color: colors.warning,
                                                opacity: 0.8,
                                            }}
                                        >
                                            Please confirm job deny
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12} sx={{ mt: 2 }}>
                                        <>
                                            <Typography
                                                sx={{
                                                    textAlign: "left",
                                                    mb: 2,
                                                    opacity: 0.8,
                                                }}
                                            >
                                                Lorem ipsum dolor, sit amet
                                                consectetur adipisicing elit.
                                                Lorem ipsum dolor, sit amet
                                                consectetur adipisicing elit.
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    textAlign: "left",
                                                    opacity: 0.8,
                                                }}
                                            >
                                                Provide deny reason below
                                            </Typography>
                                            <CustomTextArea
                                                maxChar={500}
                                                rows={5}
                                                name={"deny_reason"}
                                                label={"Deny reason"}
                                            />
                                        </>
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <Divider sx={{ mt: 2 }} />
                        <DialogActions>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "center",
                                }}
                            >
                                <Grid container>
                                    <Grid
                                        item
                                        sm={6}
                                        sx={{
                                            borderRight: `2px solid ${colors.gray}`,
                                            padding: 0.5,
                                        }}
                                    >
                                        <Button
                                            startIcon={<CancelIcon />}
                                            variant="outlined"
                                            color="error"
                                            sx={{
                                                width: "60%",
                                                fontSize: 12,
                                            }}
                                            size="small"
                                            onClick={() => cancel()}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        sm={6}
                                        sx={{
                                            borderLeft: `2px solid ${colors.gray}`,
                                            padding: 0.5,
                                        }}
                                    >
                                        <Button
                                            disabled={isLoading ? true : false}
                                            startIcon={<DoDisturb />}
                                            variant="outlined"
                                            color={`warning`}
                                            sx={{
                                                width: "60%",
                                                fontSize: 12,
                                            }}
                                            size="small"
                                            onClick={() => {
                                                formik.handleSubmit();
                                            }}
                                        >
                                            Deny request
                                            {isLoading && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: colors.primary,
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        marginTop: "-12px",
                                                        marginLeft: "-12px",
                                                    }}
                                                />
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default DenyJob;
