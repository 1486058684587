import React, { useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import {
    Box,
    Button,
    Grid,
    IconButton,
    styled,
    Tooltip,
    Typography,
} from '@mui/material';
import CustomCard from '../../../components/cards/CustomCard';
import {
    Preview,
    DoDisturb,
    Cached,
    Pending,
    Verified,
    QuestionMark,
} from '@mui/icons-material';
import { isEmpty } from 'lodash';
import NoContent from '../../../components/NoContent';
import { colors } from '../../../assets/utils/constants';
import DocumentPreviewDialog from '../../../components/Dialogies/DocumentPreviewDialog';
import DocumentPreview from '../../../components/DocumentPreview';
import CustomDialog from '../../../components/Dialogies/CustomDialog';
import ApproveORGDocs from './CRUD/ApproveORGDocs';
import DenyORGDocs from './CRUD/DenyORGDocs';

// -> button con
const ButtonCon = styled(Box)({});

// -> BoxTable
const BoxTable = styled(Box)({
    'border': `1px solid ${colors.bgColor5}`,
    'paddingTop': 5,
    'paddingBottom': 5,
    'paddingLeft': 15,
    'borderRadius': 2,
    '.title': {
        fontSize: 15,
        fontWeight: 'bold',
    },
    '.value': {
        fontSize: 14,
    },
});

// -> docs status
const DocsStatus = styled(Box)({
    'height': '100%',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'flexDirection': 'column',
    '.text': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '.icon': {
        fontSize: 35,
        margin: 10,
    },
});

const ORGDocument = ({ orgDocs, refetchProfile }) => {
    const [confimationType, setConfimationType] = useState(false);
    const [confirmationDialogy, setConfirmationDialogy] = useState(false);
    const [openDocsDialog, setOpenDocsDialogy] = useState(false);

    // => Handle docs open
    const handleConfimationDialogOpen = (type) => {
        setConfimationType(type);
        setConfirmationDialogy(true);
    };
    // => Dialogy closer
    const handleConfimationDialogClose = () => {
        setConfirmationDialogy(false);
    };

    // => Dialogy closer
    const handleDialogClose = () => {
        setOpenDocsDialogy(false);
    };

    // => Handle docs preview
    const handleDocsPreview = () => {
        setOpenDocsDialogy(true);
    };
    return (
        <>
            {/* ##################### DIALOG ################# */}
            <DocumentPreviewDialog
                head={'Organization key person document'}
                openDialog={openDocsDialog}
                handleDialogClose={handleDialogClose}
            >
                <DocumentPreview document={orgDocs.attachment} />
            </DocumentPreviewDialog>
            <CustomDialog
                openDialog={confirmationDialogy}
                handleDialogClose={handleConfimationDialogClose}
                head={
                    confimationType === 'approve_org_docs'
                        ? 'Organization document approval'
                        : 'Organization document deny'
                }
            >
                {confimationType === 'approve_org_docs' ? (
                    <>
                        <ApproveORGDocs
                            orgDocs={orgDocs}
                            refetchProfile={refetchProfile}
                            cancel={handleConfimationDialogClose}
                            closeDialogy={handleConfimationDialogClose}
                        />
                    </>
                ) : confimationType === 'deny_org_docs' ? (
                    <>
                        <DenyORGDocs
                            orgDocs={orgDocs}
                            refetchProfile={refetchProfile}
                            cancel={handleConfimationDialogClose}
                            closeDialogy={handleConfimationDialogClose}
                        />
                    </>
                ) : (
                    <></>
                )}
            </CustomDialog>

            <CustomCard
                head={'Verification document'}
                height={isEmpty(orgDocs) ? 200 : '100%'}
            >
                <Grid container justifyContent={'center'} padding={2}>
                    <>
                        {!isEmpty(orgDocs) ? (
                            <>
                                <Grid item md={8} sm={12}>
                                    <Grid container>
                                        <Grid item sm={12}>
                                            <BoxTable>
                                                <Typography className="title">
                                                    Verification type
                                                </Typography>
                                                <Typography className="value">
                                                    {
                                                        orgDocs
                                                            .verification_type
                                                            .name
                                                    }
                                                </Typography>
                                            </BoxTable>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <BoxTable>
                                                <Typography className="title">
                                                    Verification number
                                                </Typography>
                                                <Typography className="value">
                                                    {
                                                        orgDocs.verification_number
                                                    }
                                                </Typography>
                                            </BoxTable>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <ButtonCon>
                                                <center>
                                                    <Button
                                                        variant="outlined"
                                                        startIcon={<Preview />}
                                                        color="info"
                                                        size="small"
                                                        sx={{
                                                            m: 2,
                                                        }}
                                                        onClick={() =>
                                                            handleDocsPreview()
                                                        }
                                                    >
                                                        Preview document
                                                    </Button>

                                                    {orgDocs.verification_status ===
                                                        'requested' ||
                                                    orgDocs.verification_status ===
                                                        'on_progress' ? (
                                                        <>
                                                            <Button
                                                                variant="outlined"
                                                                startIcon={
                                                                    <DoneIcon />
                                                                }
                                                                color="success"
                                                                size="small"
                                                                sx={{
                                                                    m: 2,
                                                                }}
                                                                onClick={() => {
                                                                    const type =
                                                                        'approve_org_docs';
                                                                    handleConfimationDialogOpen(
                                                                        type,
                                                                    );
                                                                }}
                                                            >
                                                                Approve document
                                                            </Button>
                                                            <Button
                                                                variant="outlined"
                                                                startIcon={
                                                                    <DoDisturb />
                                                                }
                                                                color="warning"
                                                                size="small"
                                                                sx={{
                                                                    m: 2,
                                                                }}
                                                                onClick={() => {
                                                                    const type =
                                                                        'deny_org_docs';
                                                                    handleConfimationDialogOpen(
                                                                        type,
                                                                    );
                                                                }}
                                                            >
                                                                Deny document
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </center>
                                            </ButtonCon>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4}>
                                    {orgDocs.verification_status ===
                                    'pending' ? (
                                        <>
                                            <DocsStatus className="warning-message">
                                                <Typography className="text">
                                                    <Pending className="icon" />
                                                    Wait for submission
                                                </Typography>
                                            </DocsStatus>
                                        </>
                                    ) : orgDocs.verification_status ===
                                      'on_progress' ? (
                                        <>
                                            <DocsStatus className="info-message">
                                                <Typography className="text">
                                                    <Cached className="icon" />
                                                    Verification requested
                                                </Typography>
                                            </DocsStatus>
                                        </>
                                    ) : orgDocs.verification_status ===
                                      'verified' ? (
                                        <>
                                            <DocsStatus className="success-message">
                                                <Typography className="text">
                                                    <Verified className="icon" />
                                                    Document verified
                                                </Typography>
                                            </DocsStatus>
                                        </>
                                    ) : (
                                        <>
                                            <DocsStatus className="error-message">
                                                <Typography className="text">
                                                    <DoDisturb className="icon" />
                                                    Document denied
                                                </Typography>
                                                <Tooltip
                                                    title={
                                                        <Typography>
                                                            {
                                                                orgDocs?.deny_reason
                                                            }
                                                        </Typography>
                                                    }
                                                    arrow
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        color="error"
                                                        sx={{
                                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                        }}
                                                    >
                                                        <QuestionMark />
                                                    </IconButton>
                                                </Tooltip>
                                            </DocsStatus>
                                        </>
                                    )}
                                </Grid>
                            </>
                        ) : (
                            <>
                                <NoContent
                                    height={180}
                                    message={
                                        'Organization document do not uploaded'
                                    }
                                />
                            </>
                        )}
                    </>
                </Grid>
            </CustomCard>
        </>
    );
};

export default ORGDocument;
