import React, { useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Grid } from "@mui/material";
import JobInfoCard from "../../../components/cards/JobInfoCard";
import NoContent from "../../../components/NoContent";
import { filter, isEmpty, size } from "lodash";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomPagination from "../../../components/paginations/CustomPagination";
import { paginator } from "../../../helpers/paginationHelper";
import JobFilters from "../../../components/filters/JobFilters";
import { jobsSelector } from "../../../states/features/selectors";
import JobSidebar from "../../../components/sidebars/JobSidebar";
import CountBadge from "../../../components/CountBadge";
import EmployerJobWidget from "../../../components/widgets/EmployerJobWidget";

const EmployerJobs = () => {
    // ############## Comp state ##################
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [jobs, setJobs] = useState([]);

    // ############# Redux state ################
    const { allEmployerJobs: allJobs } = useSelector(jobsSelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Filter by job name
    const filterByJobName = (search) => {
        let filteredJobs = [];
        if (search) {
            filteredJobs = allJobs.filter((job) => {
                return job.title.toLowerCase().includes(search.toLowerCase());
            });
            return setJobs(filteredJobs);
        }
        return setJobs(allJobs);
    };

    // => Filter by category
    const filterByIsFor = (isFor) => {
        let filteredJobs = [];
        if (isFor) {
            filteredJobs = allJobs.filter((job) => {
                return job.isFor.toLowerCase().includes(isFor.toLowerCase());
            });
            return setJobs(filteredJobs);
        }
        return setJobs(allJobs);
    };

    // -> job status
    const jobStatus = [
        {
            id: 1,
            total: size(filter(jobs, ["status", "draft"])),
            name: "Drafted ",
            url: "status/draft",
        },
        {
            id: 6,
            total: size(filter(jobs, ["status", "pending"])),
            name: "Pending ",
            url: "status/pending",
        },
        {
            id: 2,
            total: size(filter(jobs, ["status", "published"])),
            name: "Published ",
            url: "status/published",
        },
        {
            id: 4,
            total: size(filter(jobs, ["status", "closed"])),
            name: "Closed ",
            url: "status/closed",
        },
        {
            id: 5,
            total: size(filter(jobs, ["status", "expired"])),
            name: "Expired ",
            url: "status/expired",
        },
    ];

    // => Pagination data
    const paginatedJobs = paginator(jobs, page, perPage);

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setJobs(allJobs);
        }

        return () => {
            isSubscribed = false;
        };
    }, [allJobs]);

    return (
        <>
            <PageLayout head="Jobs management" sidebar={<JobSidebar />}>
                <Grid container spacing={2}>
                    {jobStatus?.map((info) => (
                        <Grid item xs key={info.id}>
                            <Link to={info.url}>
                                <JobInfoCard info={info} />
                            </Link>
                        </Grid>
                    ))}
                    <Grid item sm={12}>
                        <CustomPagination
                            head={"Latest posted jobs"}
                            height={"48vh"}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedJobs}
                            action={
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <JobFilters
                                        filterByJobName={filterByJobName}
                                        filterByIsFor={filterByIsFor}
                                    />
                                    <CountBadge
                                        total={size(jobs)}
                                        item={"Jobs"}
                                    />
                                </Box>
                            }
                        >
                            {isEmpty(jobs) ? (
                                <>
                                    <NoContent
                                        height={250}
                                        message={"No job posted"}
                                    />
                                </>
                            ) : (
                                <Grid container spacing={1}>
                                    {paginatedJobs.data.map((job) => (
                                        <Grid item sm={12} xs={12} key={job.id}>
                                            <EmployerJobWidget job={job} />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default EmployerJobs;
