import React from "react";
import { Form, Formik } from "formik";
import CustomTextField from "../../../components/Forms/CustomTextField";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { ManageAccounts } from "@mui/icons-material";
import * as Yup from "yup";
import CustomTextArea from "../../../components/Forms/CustomTextArea";
import { useDispatch } from "react-redux";
import { Grid, MenuItem } from "@mui/material";
import useRTK from "../../../hooks/useRTK";
import CustomSelectInput from "../../../components/Forms/CustomSelectInput";
import { useAddDistrictMutation } from "../../../api/settings/districtsAPI";

// -> validate settings form
const validate = Yup.object({
    name: Yup.string().required(),
    description: Yup.string().nullable(),
    region_id: Yup.string().required("Region is required field"),
});

const AddDistrict = ({ handleClose, refetch, regions }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        addDistrict,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useAddDistrictMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        refetch();
        handleClose();
        dispatch(addDistrict(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    region_id: "",
                    name: "",
                    description: "",
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    addDistrict(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container columnSpacing={4}>
                            <Grid item sm={6}>
                                <CustomSelectInput
                                    type="text"
                                    name="region_id"
                                    label="Select region"
                                    formik={formik}
                                    color="primary"
                                >
                                    {regions?.map((region) => (
                                        <MenuItem
                                            key={region.id}
                                            value={region.id}
                                        >
                                            {region.name}
                                        </MenuItem>
                                    ))}
                                </CustomSelectInput>
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    label="Name"
                                    name="name"
                                    type="text"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomTextArea
                                    label="Description"
                                    name="description"
                                />
                            </Grid>
                        </Grid>
                        <CustomSubmitButton
                            startIcon={<ManageAccounts />}
                            onClick={formik.handleSubmit}
                            loading={isLoading}
                            sx={{ py: 1.5 }}
                        >
                            Add setting
                        </CustomSubmitButton>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddDistrict;
