import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import CustomCard2 from '../cards/CustomCard2';
import { Add } from '@mui/icons-material';
import CustomDialog from '../Dialogies/CustomDialog';
import AddPromotionCustomer from '../../pages/promotions/CRUD/AddPromotionCustomer';
import IncomeWidget from '../widgets/IncomeWidget';
import { numFormater } from '../../helpers/numFormater';
import {
    promotionCustomersSelector,
    promotionsSelector,
} from '../../states/features/selectors';
import { useSelector } from 'react-redux';
import { filter, size } from 'lodash';
import Infobox1 from '../infoboxes/InfoBox1';

const PromotionSidebar = ({ settings }) => {
    // ################ Comp state ###############
    const [open, setOpen] = React.useState(false);

    // ################ Redux state ###############
    const { promotions } = useSelector(promotionsSelector);
    const { customers } = useSelector(promotionCustomersSelector);

    // ################ FUNC ###############
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const getPromotionsPaidAmount = () => {
        let paidPromo = filter(
            promotions,
            ({ currSubscription }) =>
                currSubscription?.transaction?.status === 'success',
        );
        let totalPaid = 0;
        paidPromo.forEach((promo) => {
            totalPaid += promo.currSubscription?.transaction?.amount;
        });
        return totalPaid;
    };
    const getPromotionsUnpaidAmount = () => {
        let unpaidPromo = filter(
            promotions,
            ({ currSubscription }) =>
                currSubscription?.transaction?.status !== 'success',
        );
        let totalUnpaid = 0;
        unpaidPromo.forEach((promo) => {
            totalUnpaid += promo.currSubscription?.package?.price;
        });
        return totalUnpaid;
    };

    // ################# Promo stats #################
    const promotionStats = {
        active: size(filter(promotions, ({ status }) => status === 'active')),
        inactive: size(
            filter(promotions, ({ status }) => status === 'inactive'),
        ),
        expired: size(filter(promotions, ({ status }) => status === 'expired')),
        paid: size(
            filter(
                promotions,
                ({ currSubscription }) =>
                    currSubscription?.transaction?.status === 'success',
            ),
        ),
        unpaid: size(
            filter(
                promotions,
                ({ currSubscription }) =>
                    currSubscription?.transaction?.status !== 'success',
            ),
        ),
        paidAmount: getPromotionsPaidAmount(),
        unpaidAmount: getPromotionsUnpaidAmount(),
    };

    const promoCustData = {
        id: 4,
        title1: 'Registered promotion',
        title2: 'customers',
        total: size(customers),
        url: '/promotions/customers',
    };

    return (
        <>
            {/* ############## Dialogy ############## */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`Add promotion customer`}
            >
                <AddPromotionCustomer handleClose={handleClose} />
            </CustomDialog>
            <CustomCard2 head={'Promotions stats'} height={'100%'}>
                <Box>
                    <IncomeWidget
                        total={numFormater(promotionStats.paidAmount)}
                        title={'Actual promotions revenue'}
                    />
                </Box>
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    color: colors.success,
                                    fontSize: 14,
                                    textAlign: 'center',
                                }}
                            >
                                {numFormater(promotionStats.paidAmount)}
                                <sup style={{ fontSize: 14 }}>TZS</sup>
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.info,
                                    borderBottom: `3px solid ${colors.success}`,
                                }}
                            >
                                {promotionStats.paid}
                                <sup
                                    style={{
                                        color: colors.success,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    Paid amount
                                </sup>
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    color: colors.success,
                                    fontSize: 14,
                                    textAlign: 'center',
                                }}
                            >
                                {numFormater(promotionStats.unpaidAmount)}
                                <sup style={{ fontSize: 14 }}>TZS</sup>
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: 'red',
                                    borderBottom: `3px solid ${colors.danger}`,
                                }}
                            >
                                {promotionStats.unpaid}
                                <sup
                                    style={{
                                        color: 'red',
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    Unpaid amount
                                </sup>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        px: 1.5,
                        borderRadius: 2,
                        mt: 3,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    }}
                >
                    <Box
                        sx={{
                            height: 140,
                            width: 140,
                            borderRadius: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            border: `5px solid ${colors.bgColor3}`,
                            boxShadow: `0px 10px 15px ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 30,
                                fontWeight: 'bold',
                                opacity: 0.8,
                                color: colors.info,
                            }}
                        >
                            {size(promotions)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontStyle: 'italic',
                                fontWeight: 'bold',
                                opacity: 0.8,
                            }}
                        >
                            Promotions
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography
                            sx={{
                                color: colors.info,
                                fontSize: 20,
                                borderBottom: `3px solid ${colors.bgColor5}`,
                            }}
                        >
                            {promotionStats.active}{' '}
                            <sup
                                style={{
                                    color: colors.success,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}
                            >
                                Active
                            </sup>
                        </Typography>
                        <Typography
                            sx={{
                                color: colors.info,
                                fontSize: 20,
                                borderBottom: `3px solid ${colors.bgColor5}`,
                            }}
                        >
                            {promotionStats.inactive}{' '}
                            <sup
                                style={{
                                    color: colors.warning,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}
                            >
                                In-Active
                            </sup>
                        </Typography>
                        <Typography
                            sx={{
                                color: colors.info,
                                fontSize: 20,
                                borderBottom: `3px solid ${colors.bgColor5}`,
                            }}
                        >
                            {promotionStats.expired}{' '}
                            <sup
                                style={{
                                    color: 'red',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}
                            >
                                Expired
                            </sup>
                        </Typography>
                    </Box>
                </Box>
            </CustomCard2>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Infobox1 data={promoCustData} height={80} />
                </Grid>
                {/* <Grid item xs={12}>
                    <Box>
                        <Button
                            fullWidth
                            sx={{
                                height: 75,
                                borderRadius: `50px !important`,
                                border: `2px dotted ${colors.bgColor5}`,
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                            }}
                            onClick={() => handleClickOpen()}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Add
                                    color="info"
                                    sx={{
                                        p: 0.2,
                                        fontSize: 30,
                                        borderRadius: 50,
                                        border: `2px dotted ${colors.info}`,
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                    }}
                                >
                                    Add new customer
                                </Typography>
                            </Box>
                        </Button>
                    </Box>
                </Grid> */}
            </Grid>
        </>
    );
};

export default PromotionSidebar;
