import React from "react";
import { Box, Grid, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import CustomTextField from "../../../components/Forms/CustomTextField";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { Save } from "@mui/icons-material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import CustomTextArea from "../../../components/Forms/CustomTextArea";
import CustomSelectInput from "../../../components/Forms/CustomSelectInput";
import { setFreeTrialPackages } from "../../../states/features/subscriptionPackages/subscriptionPackagesSlice";
import { useCreateFreeTrialPackageMutation } from "../../../api/subscriptionPackages/freeTrialPackagesAPI";

const createPackageValidationSchema = Yup.object({
    name: Yup.string().required(),
    user: Yup.string().required(),
    duration: Yup.string().required(),
    description: Yup.string().max(100).nullable(),
});

const CreateFreeTrialPackage = ({ handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();
    const packageUser = [
        {
            id: 1,
            type: "employer",
        },
        {
            id: 2,
            type: "organization",
        },
        {
            id: 3,
            type: "beneficiary",
        },
    ];

    // ############# RTK ##################
    const [
        createPackage,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useCreateFreeTrialPackageMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setFreeTrialPackages(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Box>
                <Formik
                    initialValues={{
                        name: "",
                        user: "",
                        duration: "",
                        description: "",
                    }}
                    validationSchema={createPackageValidationSchema}
                    onSubmit={(payload) => {
                        createPackage(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid
                                container
                                justifyContent={"center"}
                                columnSpacing={2}
                            >
                                <Grid item sm={12} xs={12}>
                                    <CustomTextField
                                        name="name"
                                        type={"text"}
                                        label={"Package name"}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <CustomSelectInput
                                        type="text"
                                        name="user"
                                        label="Package for"
                                        formik={formik}
                                        color="primary"
                                    >
                                        {packageUser.map((type) => (
                                            <MenuItem
                                                value={type.type}
                                                key={type.id}
                                            >
                                                {type.type}
                                            </MenuItem>
                                        ))}
                                    </CustomSelectInput>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <CustomTextField
                                        name="duration"
                                        type={"number"}
                                        label={"Package duration(days)"}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <CustomTextArea
                                        name="description"
                                        type={"text"}
                                        label={"Package description"}
                                        rows={3}
                                        maxChar={100}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        startIcon={<Save />}
                                        onClick={formik.handleSubmit}
                                        btnColor={"primary"}
                                        sx={{
                                            py: 2,
                                        }}
                                    >
                                        Save package
                                    </CustomSubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default CreateFreeTrialPackage;
