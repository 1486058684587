import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../assets/utils/constants';
import {
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { isEmpty } from 'lodash';

const DataPagnation = ({ paginations, handlePagination }) => {
    const links = paginations?.links.slice(1, -1);
    const previousUrl = paginations?.prev_page_url;
    const nextUrl = paginations?.next_page_url;
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: 50,
                    borderBottomRightRadius: 5,
                    borderBottomLeftRadius: 5,
                    px: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                }}
            >
                {/* PREVIOUS BTN */}
                <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    startIcon={<KeyboardDoubleArrowLeft />}
                    disabled={isEmpty(previousUrl) ? true : false}
                    onClick={() => {
                        handlePagination(previousUrl);
                    }}
                >
                    Previous
                </Button>
                {/* LINKS BTN */}
                <Box>
                    {links.map((link, index) => (
                        <Button
                            size="small"
                            key={index}
                            onClick={() => {
                                handlePagination(link.url);
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: `2px solid ${
                                        link.active
                                            ? colors.warning
                                            : colors.info
                                    }`,
                                    height: 30,
                                    width: 40,
                                    borderRadius: 2,
                                    color: colors.primary,
                                }}
                            >
                                {index + 1}
                            </Typography>
                        </Button>
                    ))}
                </Box>
                {/* NEXT BTN */}
                <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    disabled={isEmpty(nextUrl) ? true : false}
                    endIcon={<KeyboardDoubleArrowRight />}
                    onClick={() => {
                        handlePagination(nextUrl);
                    }}
                >
                    Next
                </Button>
            </Box>
        </>
    );
};

export default DataPagnation;
