import React from 'react';

import {
    Avatar,
    Box,
    Grid,
    Button,
    Chip,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { Logout } from '@mui/icons-material';

// !################ MAIN FUNC ####################
const ProfileCard = ({user}) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        alignContent: 'space-between',
                    }}
                    padding={2}
                >
                    <Grid item sm={12}>
                        <center>
                            <Avatar
                                sx={{
                                    height: 80,
                                    width: 80,
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    mt: 1,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                }}
                            >
                                {user.registration_id}
                            </Typography>
                            <Typography>
                                {`${user.first_name} ${user.middle_name} ${user.last_name}`}
                            </Typography>
                            <Chip
                                sx={{ mt: 1 }}
                                color="secondary"
                                label="Super admin"
                            />
                        </center>
                    </Grid>
                    <Grid item sm={12}>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <a href="mailto:info@wmax.co.tz">
                                    <Box
                                        className="info-message"
                                        sx={{
                                            border: `1px solid ${colors.gray}`,
                                            p: 1,
                                            textAlign: 'center',
                                            borderBottom: '2px solid #2e77ae',
                                        }}
                                    >
                                        {user.email}
                                    </Box>
                                </a>
                            </Grid>
                            <Grid item sm={6}>
                                <a href="tel:+255743967567">
                                    <Box
                                        className="info-message"
                                        sx={{
                                            border: `1px solid ${colors.gray}`,
                                            p: 1,
                                            textAlign: 'center',
                                            borderBottom: '2px solid #2e77ae',
                                        }}
                                    >
                                        {user.phone_number}
                                    </Box>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12}>
                        <Box sx={{ textAlign: 'center' }}>
                            <Button
                                startIcon={<Logout />}
                                variant="contained"
                                color="error"
                                sx={{ width: '100%' }}
                            >
                                Logout
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};

export default ProfileCard;
