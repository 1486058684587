// => GET all
const GET_ALL_EMPLOYERS = '/employers';

// => GET single
const GET_SINGLE_EMPLOYER = '/employers';

export const employerEndpoints = {
    GET_ALL_EMPLOYERS,
    GET_SINGLE_EMPLOYER,
};
