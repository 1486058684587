// => GET all
const GET_ALL = `/notifications`;

// => Get single
const GET_SINGLE = `/notifications`;

// => Mark all read
const MARK_ALL_READ = `/notifications/read-all`;

// => DELETE single
const DELETE_SINGLE = `/notifications/single`;

// => DELETE all
const DELETE_ALL = `/notifications/all`;

export const notificationsEndpoints = {
    GET_ALL,
    GET_SINGLE,
    MARK_ALL_READ,
    DELETE_SINGLE,
    DELETE_ALL,
};
