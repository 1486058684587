import React from 'react';
import { Box, MenuItem } from '@mui/material';
import CustomSelectFilter from '../Forms/CustomSelectFilter';

const RevenueFilter = ({ width }) => {
    const [status, setStatus] = React.useState('');

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    return (
        <>
            <Box sx={{ display: 'flex', width: width }}>
                <Box sx={{ width: '100%' }}>
                    <CustomSelectFilter width={160} label={'Filter monthly'}>
                        <MenuItem value={'pending'}>Pending</MenuItem>
                        <MenuItem value={'completed'}>Completed</MenuItem>
                    </CustomSelectFilter>
                </Box>
            </Box>
        </>
    );
};

export default RevenueFilter;
