// LOGIN user
const LOGIN = "/auth/login";

// UPDATE profile
const GET_PROFILE = "/auth/profile";

// UPDATE profile
const UPDATE_PROFILE = "/auth/update-profile";

// UPDATE profile picture
const UPDATE_PROFILE_PICTURE = "/auth/update-profile/picture";

// CHANGE password
const CHANGE_PASSWORD = "/auth/change-password";

// LOGOUT user
const LOGOUT = "/auth/logout";

export const authEndpoints = {
    LOGIN,
    LOGOUT,
    GET_PROFILE,
    UPDATE_PROFILE,
    UPDATE_PROFILE_PICTURE,
    CHANGE_PASSWORD,
};
