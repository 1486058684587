import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    departments: [],
};

// !########## SLICE #############
const departmentsSlice = createSlice({
    name: 'departmentsSlice',
    initialState,
    reducers: {
        setDepartments: (state, actions) => {
            state.departments = actions.payload;
        },
        addDepartment: (state, actions) => {
            const department = actions.payload;
            isEmpty(state.departments)
                ? (state.departments = [department, ...[]])
                : (state.departments = [department, ...state.departments]);
        },
    },
});

export const { setDepartments, addDepartment } = departmentsSlice.actions;
export default departmentsSlice.reducer;
