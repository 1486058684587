import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { adminBaseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { jobEndpoints } from "./jobEndpoints";

export const jobAPI = createApi({
    reducerPath: "jobAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // ###################### ORG Jobs ######################
        // => Get all jobs
        getORGJobs: builder.query({
            query: () => `${jobEndpoints.GET_ORG_JOBS}`,
        }),
        // => Get single job
        getSingleJob: builder.query({
            query: (payload) => `${jobEndpoints.GET_SINGLE_JOBS}/${payload}`,
        }),
        // => Get org jobs
        getSingleORGJobs: builder.query({
            query: (payload) =>
                `${jobEndpoints.GET_SINGLE_ORG_JOBS}/${payload}`,
        }),
        // => Suspend job
        suspendJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.SUSPEND_JOB}/${payload}`,
                method: "PUT",
                body: payload,
            }),
        }),
        // => Unsuspend job
        unSuspendJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.UNSUSPEND_JOB}/${payload}`,
                method: "PUT",
                body: payload,
            }),
        }),

        // ###################### Employer Jobs ######################
        // => Get all jobs
        getAllEmployerJobs: builder.query({
            query: () => `${jobEndpoints.GET_ALL_EMPLOYER_JOBS}`,
        }),
        // => Get single job
        getEmployerSingleJob: builder.query({
            query: (payload) =>
                `${jobEndpoints.GET_EMPLOYER_SINGLE_JOB}/${payload}`,
        }),
        // => Get org jobs
        getSingleEmployerJobs: builder.query({
            query: (payload) =>
                `${jobEndpoints.GET_SINGLE_EMPLOYER_JOBS}/${payload}`,
        }),
        // => Approve job
        approveEmployerJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.APPROVE_EMPLOYER_JOB}/${payload}`,
                method: "PUT",
                body: payload,
            }),
        }),
        // => Deny job
        denyEmployerJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.DENY_EMPLOYER_JOB}/${payload.job_id}`,
                method: "PUT",
                body: payload,
            }),
        }),
        // => Suspend job
        suspendEmployerJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.SUSPEND_EMPLOYER_JOB}/${payload}`,
                method: "PUT",
                body: payload,
            }),
        }),
        // => Unsuspend job
        unSuspendEmployerJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.UNSUSPEND_EMPLOYER_JOB}/${payload}`,
                method: "PUT",
                body: payload,
            }),
        }),
    }),
});

export const {
    useGetORGJobsQuery,
    useGetSingleJobQuery,
    useGetSingleORGJobsQuery,
    useSuspendJobMutation,
    useUnSuspendJobMutation,
    useGetAllEmployerJobsQuery,
    useGetEmployerSingleJobQuery,
    useGetSingleEmployerJobsQuery,
    useApproveEmployerJobMutation,
    useDenyEmployerJobMutation,
    useSuspendEmployerJobMutation,
    useUnSuspendEmployerJobMutation,

    // => Lazy
    useLazyGetORGJobsQuery,
    useLazyGetAllEmployerJobsQuery,
    useLazyGetSingleEmployerJobsQuery,
} = jobAPI;
