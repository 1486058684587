import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Typography,
    styled,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { colors } from '../assets/utils/constants';
import { Link } from 'react-router-dom';
import NotificationWidget from './widgets/NotificationWidget';
import { useSelector } from 'react-redux';
import { notificationsSelector } from '../states/features/selectors';
import { isEmpty, size, take } from 'lodash';
import NoContent from './NoContent';

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const MenuCon = styled(Box)({
    'height': '100vh',
    'width': 400,
    'borderRadius': 2.5,
    'transition': '.3s all ease-in-out',
    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
    'overflow': 'hidden',
    '::after': {
        content: "''",
        position: 'absolute',
        right: 8,
        top: -10,
        opacity: 1,
        borderBottom: '15px solid #fff',
        borderLeft: '12px solid transparent',
        borderRight: '12px solid transparent',
        transition: '.3s all ease-in-out',
    },
});

const NotificationsBar = ({ openNotBar, closeNotDrawer }) => {
    // ############## Redux state ################
    const { notifications } = useSelector(notificationsSelector);

    return (
        <React.Fragment key={1}>
            <Drawer
                anchor={'right'}
                open={openNotBar}
                sx={{ position: 'relative' }}
            >
                <DrawerHeader />
                <MenuCon>
                    <Card sx={{ border: 'none', boxShadow: 'none' }}>
                        <CardHeader
                            titleTypographyProps={{ color: colors.primary }}
                            component={'div'}
                            title={
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                    }}
                                >
                                    Notification{' '}
                                    <Typography
                                        component={'span'}
                                        sx={{
                                            fontWeight: 'bold',
                                            color: colors.warning,
                                        }}
                                    >
                                        ({size(notifications)})
                                    </Typography>
                                </Typography>
                            }
                            subheader={'Explore all notitfication'}
                            action={
                                <Close
                                    onClick={closeNotDrawer}
                                    sx={{ fontSize: 18, cursor: 'pointer' }}
                                />
                            }
                            sx={{
                                'bgcolor': colors.bgColor,
                                'borderBottom': `1px solid ${colors.secondary}`,
                                '.MuiCardHeader-title': {
                                    fontSize: 18,
                                    fontWeight: 'bold',
                                },
                                '.MuiCardHeader-subheader': {
                                    fontSize: 12,
                                },
                                '.MuiCardHeader-action': {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 0.5,
                                    borderRadius: '50%',
                                    boxShadow: `3px 3px 14px 0 rgb(23, 35, 60, .2),
                                -8px -8px 9px 0 rgba(255, 255, 255, 0.55) !important`,
                                },
                            }}
                        />
                        <CardContent
                            sx={{
                                height: '76vh',
                                bgcolor: '#fff',
                                overflowY: 'auto',
                                position: 'relative',
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                            }}
                        >
                            {isEmpty(notifications) ? (
                                <>
                                    <NoContent
                                        height={'50vh'}
                                        message={'No notification available'}
                                    />
                                </>
                            ) : (
                                take(
                                    notifications,
                                    20,
                                )?.map((notification, index) => (
                                    <NotificationWidget
                                        key={index}
                                        closeNotDrawer={closeNotDrawer}
                                        notification={notification}
                                    />
                                ))
                            )}
                        </CardContent>
                        <CardActions
                            sx={{
                                height: 50,
                                bgcolor: colors.bgColor,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                border: 'none',
                            }}
                        >
                            <Link to={'/notifications'}>
                                <Button
                                    size="small"
                                    fullWidth
                                    sx={{ px: 10, py: 1.5 }}
                                >
                                    {'View all notification'}
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </MenuCon>
            </Drawer>
        </React.Fragment>
    );
};
export default NotificationsBar;
