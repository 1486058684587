import React from 'react';
import { Grid } from '@mui/material';
import OrganizationTable from '../../../components/Tables/OrganizationTable';
import CustomCard from '../../../components/cards/CustomCard';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import { size } from 'lodash';
import PageLayout from '../../layouts/PageLayout';
import { organizationsSelector } from '../../../states/features/selectors';
import CountBadge from '../../../components/CountBadge';
import InstitutionStatusesInfoboxes from './InstitutionStatusesInfoboxes';
import OrganizationSidebar from '../../../components/sidebars/OrganizationSidebar';

const Institutions = () => {
    // ############### REDUCER STATE #################
    const { organizations } = useSelector(organizationsSelector);
    const institutions = filter(organizations, [
        'organization_type.name',
        'Institution',
    ]);

    return (
        <>
            <PageLayout
                head={'Registered Institutions'}
                sidebar={
                    <OrganizationSidebar
                        orgType={'Institution'}
                        organizations={institutions}
                    />
                }
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <InstitutionStatusesInfoboxes
                            institutions={institutions}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <CustomCard
                            head={'All registered Institutions'}
                            action={
                                <CountBadge
                                    total={size(institutions)}
                                    item={'Institutions'}
                                />
                            }
                        >
                            <Grid container>
                                <Grid item sm={12}>
                                    <OrganizationTable
                                        organizations={institutions}
                                    />
                                </Grid>
                            </Grid>
                        </CustomCard>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Institutions;
