import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, Image, Save } from '@mui/icons-material';
import CustomFileInput from '../../../components/Forms/CustomFileInput';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { colors } from '../../../assets/utils/constants';
import CustomTextArea from '../../../components/Forms/CustomTextArea';
import CustomSelectInput from '../../../components/Forms/CustomSelectInput';
import { useUpdatePromotionMutation } from '../../../api/promotions/promotionsAPI';
import {
    setPromotionDetails,
    updatePromotion,
} from '../../../states/features/promotions/promotionsSlice';
import {
    promotionCustomersSelector,
    subscriptionPackagesSelector,
} from '../../../states/features/selectors';
import { filter, isEmpty } from 'lodash';
import { numFormater } from '../../../helpers/numFormater';

// ######### validation schema #################
const updatePromotionValidationSchema = Yup.object({
    file: Yup.string(),
    title: Yup.string().required(),
    content: Yup.string().required(),
    // amount: Yup.string().required(),
    // start_date: Yup.date().required('start date is a required field'),
    // end_date: Yup.date().required('end date is a required field'),
    customer: Yup.string().required(),
});

const UpdatePromotion = ({ promotion, handleClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp state ################
    const [filePreview, setFilePreview] = useState(promotion.banner_image);
    const [selectedPackage, setselectedPackage] = useState({});

    // ############## Redux state ###################
    const { customers } = useSelector(promotionCustomersSelector);
    const { subscriptionPackages } = useSelector(subscriptionPackagesSelector);
    // => Promo connect packages
    const promoConnectPackages = filter(
        subscriptionPackages,
        ({ type, status }) => type === 'Promo connect' && status === 1,
    );

    // ############# FUNC ##################
    // => Selected package
    const getSelectedValue = (payload) => {
        const selectedPackage = filter(
            promoConnectPackages,
            ({ id }) => id === payload,
        );
        setselectedPackage(selectedPackage[0]);
    };
    // => Get exist package
    const getCurrPackage = () => {
        const currPackage = filter(
            promoConnectPackages,
            ({ id }) => id === promotion.currSubscription.package.id,
        );
        setselectedPackage(currPackage[0]);
    };

    // ############# RTK ##################
    const [
        updatePromotionRTK,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useUpdatePromotionMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleClose();
        dispatch(updatePromotion(data));
        dispatch(setPromotionDetails(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    // ############# useEffect #############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getCurrPackage();
        }

        return () => {
            isSubscribed = false;
        };
    }, []);

    return (
        <>
            <Box>
                <Formik
                    initialValues={{
                        file: '',
                        title: promotion ? promotion.title : '',
                        content: promotion ? promotion.content : '',
                        customer: promotion ? promotion.customer_id : '',
                        subscription_package_id: promotion?.currSubscription
                            ?.package.id
                            ? promotion.currSubscription.package.id
                            : '',
                    }}
                    validationSchema={updatePromotionValidationSchema}
                    onSubmit={(values) => {
                        let payload = new FormData();
                        payload.append('promotion_id', promotion.id);
                        payload.append('banner_image', values.file);
                        payload.append('title', values.title);
                        payload.append('content', values.content);
                        payload.append('customer_id', values.customer);
                        payload.append(
                            'subscription_package_id',
                            values.subscription_package_id,
                        );
                        updatePromotionRTK(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid
                                container
                                justifyContent={'center'}
                                columnSpacing={2}
                            >
                                <Grid item sm={12}>
                                    <Grid
                                        container
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Grid item sm={7} sx={{}}>
                                            {filePreview ? (
                                                <>
                                                    <img
                                                        src={filePreview}
                                                        alt="img"
                                                        style={{
                                                            width: '100%',
                                                            height: '10em',
                                                            objectFit:
                                                                'contain',
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Image
                                                        sx={{
                                                            width: '100%',
                                                            height: '7em',
                                                            objectFit:
                                                                'contain',
                                                            opacity: 0.7,
                                                            color:
                                                                colors.secondary,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            sm={5}
                                            sx={{
                                                height: '100%',
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                            }}
                                        >
                                            {!isEmpty(selectedPackage) && (
                                                <Box
                                                    sx={{
                                                        height: 60,
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                        mb: 5,
                                                        borderTopLeftRadius: 25,
                                                        borderTopRightRadius: 25,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            p: 0.5,
                                                            ml: 1,
                                                            color:
                                                                colors.warning,
                                                        }}
                                                    >
                                                        Subscription package
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: 18,
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            textTransform:
                                                                'uppercase',
                                                            color: colors.info,
                                                            opacity: 0.8,
                                                        }}
                                                    >
                                                        {selectedPackage.name}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                            justifyContent:
                                                                'center',
                                                            border: `2px solid ${colors.secondary}`,
                                                            boxShadow: `1px 1px 3px 2px ${colors.bgColor4}`,
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: 16,
                                                                textAlign:
                                                                    'center',
                                                                p: 0.5,
                                                                fontWeight:
                                                                    'bold',
                                                                color:
                                                                    colors.info,
                                                            }}
                                                        >
                                                            {numFormater(
                                                                selectedPackage.price,
                                                            )}
                                                            <sup
                                                                style={{
                                                                    fontSize: 11,
                                                                    color:
                                                                        colors.success,
                                                                }}
                                                            >
                                                                TZS
                                                            </sup>
                                                        </Typography>
                                                        <Typography>
                                                            {'/'}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 16,
                                                                textAlign:
                                                                    'center',
                                                                p: 0.5,
                                                                fontWeight:
                                                                    'bold',
                                                                color:
                                                                    colors.info,
                                                            }}
                                                        >
                                                            {
                                                                selectedPackage.duration
                                                            }{' '}
                                                            Days
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )}
                                            <Box>
                                                <CustomFileInput
                                                    size={'small'}
                                                    name="file"
                                                    type="file"
                                                    formik={formik}
                                                    accept="image/*"
                                                    setFilePreview={
                                                        setFilePreview
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <CustomTextField
                                        name="title"
                                        type={'text'}
                                        label={'Promotion title'}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomSelectInput
                                        type="text"
                                        name="customer"
                                        label="Select customer"
                                        formik={formik}
                                        color="primary"
                                    >
                                        {customers.map((customer) => (
                                            <MenuItem
                                                value={customer.id}
                                                key={customer.id}
                                            >
                                                {customer.name}
                                            </MenuItem>
                                        ))}
                                    </CustomSelectInput>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomSelectInput
                                        type="text"
                                        name="subscription_package_id"
                                        label="Promo conect package"
                                        formik={formik}
                                        color="primary"
                                        handler={getSelectedValue}
                                    >
                                        {promoConnectPackages.map(
                                            (promoPackage) => (
                                                <MenuItem
                                                    value={promoPackage.id}
                                                    key={promoPackage.id}
                                                >
                                                    {promoPackage.name}
                                                </MenuItem>
                                            ),
                                        )}
                                    </CustomSelectInput>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <CustomTextArea
                                        name="content"
                                        type={'text'}
                                        label={'Promotion content'}
                                        rows={2}
                                        maxChar={100}
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        startIcon={<Save />}
                                        endIcon={<ArrowRightAltOutlined />}
                                        onClick={formik.handleSubmit}
                                        btnColor={'info'}
                                        sx={{ py: 2 }}
                                    >
                                        Update promotion
                                    </CustomSubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default UpdatePromotion;
