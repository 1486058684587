import React from "react";
import {
    AttachEmailOutlined,
    LocationOn,
    Person3Sharp,
    PhoneForwardedOutlined,
    RemoveRedEye,
} from "@mui/icons-material";
import {
    Avatar,
    Badge,
    Box,
    Button,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Tooltip,
    Typography,
    Chip,
} from "@mui/material";
import { colors } from "../../assets/utils/constants";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

const AdminWidget = ({ admin }) => {
    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    borderRadius: 4,
                    overflow: "hidden",
                    border: `4px solid ${colors.bgColor4}`,
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    transition: ".3s all ease-in-out",
                    ":hover": {
                        transition: ".3s all ease-in-out",
                        border: `4px solid ${colors.secondary}`,
                    },
                }}
            >
                <ListItemButton
                    sx={{
                        transition: ".3s all ease-in-out",
                        "& .dp": {
                            mr: 2,
                            transition: ".3s all ease-in-out",
                        },
                        bgcolor: colors.primary,
                        ":hover": {
                            cursor: "default",
                            bgcolor: colors.primary,
                        },
                    }}
                >
                    <ListItem
                        sx={{
                            p: 0,
                            position: "relative",
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                src={admin.profile_picture}
                                className="dp"
                                sx={{
                                    height: 70,
                                    width: 70,
                                    border: `2px solid ${colors.secondary}`,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography
                                    noWrap
                                    sx={{
                                        fontWeight: 500,
                                        color: colors.secondary,
                                        opacity: 0.8,
                                    }}
                                >
                                    {`${admin.first_name} ${
                                        !isEmpty(admin.middle_name)
                                            ? admin.middle_name
                                            : ""
                                    } ${admin.last_name}`}
                                </Typography>
                            }
                            secondary={
                                <Box sx={{}}>
                                    <Typography
                                        noWrap
                                        sx={{
                                            fontSize: 12,
                                            display: "flex",
                                            alignItems: "center",
                                            opacity: 0.8,
                                            color: colors.secondary,
                                        }}
                                    >
                                        <Person3Sharp
                                            color="secondary"
                                            sx={{ fontSize: 14 }}
                                        />{" "}
                                        {admin.gender}
                                    </Typography>
                                    <Chip
                                        label={admin.role}
                                        color={
                                            admin.role === "Super admin"
                                                ? "info"
                                                : "secondary"
                                        }
                                        size="small"
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: "bold",
                                            height: 14,
                                            mt: 0.5,
                                            ml: 0.2,
                                        }}
                                    />
                                </Box>
                            }
                        />
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                        <Chip
                            label={admin.status}
                            color={
                                admin.status === "in-active"
                                    ? "warning"
                                    : admin.status === "active"
                                    ? "success"
                                    : "error"
                            }
                            size="small"
                            sx={{ position: "absolute", right: 0 }}
                        />
                    </ListItem>
                </ListItemButton>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        py: 1,
                        pb: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                height: "100%",
                                width: "100%",
                                borderRadius: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                opacity: 0.9,
                            }}
                        >
                            <a href={`tel:${admin.phone_number}`}>
                                <Button fullWidth size="small" color="info">
                                    <PhoneForwardedOutlined
                                        sx={{ fontSize: 20 }}
                                    />
                                </Button>
                            </a>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                                color: colors.white,
                                borderRadius: 1,
                                opacity: 0.9,
                            }}
                        >
                            <Link to={`/admins/${admin.id}`}>
                                <Tooltip title={"admin details"} arrow>
                                    <IconButton
                                        sx={{
                                            borderRadius: `50px !important`,
                                            transform: `scale(1.2)`,
                                            border: `1px dotted ${colors.primary}`,
                                            background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                                        }}
                                    >
                                        <RemoveRedEye
                                            sx={{
                                                fontSize: 15,
                                                color: colors.primary,
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </Box>
                        <Box
                            sx={{
                                height: "100%",
                                width: "100%",
                                color: colors.white,
                                borderRadius: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                opacity: 0.9,
                            }}
                        >
                            <a href={`mailTo:${admin.email}`}>
                                <Button fullWidth size="small" color="info">
                                    <AttachEmailOutlined
                                        sx={{ fontSize: 20 }}
                                    />
                                </Button>
                            </a>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AdminWidget;
