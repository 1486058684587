import React from 'react';

import { colors } from '../../assets/utils/constants';
import {
    Box,
    Chip,
    Typography,
    styled,
    IconButton,
    Tooltip,
} from '@mui/material';
import { Delete, RemoveRedEye } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const NotCard = styled(Box)({
    'border': `1px solid ${colors.bgColor}`,
    'overflow': 'hidden',
    'marginBottom': 15,
    'position': 'relative',
    'transition': `.3s all ease-in-out`,
    ':hover': {
        borderBottom: `1px solid ${colors.primary} !important`,
        transition: `.3s all ease-in-out`,
    },
});

const NotHeader = styled(Box)({
    'paddingLeft': 15,
    'paddingRight': 10,
    'paddingTop': 10,
    '.head': {
        fontSize: 13,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    '.body': {
        fontSize: 14,
        color: 'gray',
        padding: 10,
    },
});

const ActionMenu = styled(Box)({
    'position': 'absolute',
    'right': 10,
    'top': 10,
    'cursor': 'pointer',
    '& .icon': {
        fontSize: 14,
    },
    '& .icon-btn': {
        marginRight: 5,
        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
    },
    '.container': {
        background: colors.bgColor,
        width: 120,
        position: 'absolute',
        right: 10,
        top: 20,
        borderRadius: 1,
        border: `2px solid ${colors.bgColor}`,
    },
    '.list-btn': {
        'paddingLeft': 10,
        'paddingTop': 0,
        'paddingBottom': 0,
        '.list-text': {
            ' .MuiListItemText-secondary': {
                fontSize: 12,
                fontWeight: 'bold',
            },
        },
        '.icon-con': {
            'position': 'absolute',
            'right': -33,
            '.icon': {
                fontSize: 14,
            },
        },
    },
});

const NotFooter = styled(Box)({
    'height': 25,
    'display': 'flex',
    'justifyContent': 'space-between',
    'paddingLeft': 20,
    'paddingRight': 20,
    'alignItems': 'center',
    'opacity': 0.8,
    '.text': {
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.primary,
        opacity: 0.8,
    },
});

const NotificationWidget = ({ notification }) => {
    return (
        <React.Fragment>
            <NotCard
                sx={{
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    border: `1px solid ${colors.bgColor5}`,
                }}
            >
                {/* ####################### HEAD AND BODY ######################### */}
                <NotHeader>
                    <Chip
                        variant="outlined"
                        color={
                            notification.notification_type ===
                            'Verification approve'
                                ? 'info'
                                : 'error'
                        }
                        label={notification.title}
                        className="head"
                        size="small"
                    />
                    {/* ######################### ACTION MENU ########################## */}
                    <ActionMenu>
                        <Tooltip title="View notification" arrow>
                            <Link to={'/notifications/212'}>
                                <IconButton
                                    size="small"
                                    className="icon-btn"
                                    color="info"
                                >
                                    <RemoveRedEye className="icon" />
                                </IconButton>
                            </Link>
                        </Tooltip>
                        <Tooltip title="Delete notification" arrow>
                            <IconButton
                                size="small"
                                className="icon-btn"
                                color="error"
                            >
                                <Delete className="icon" />
                            </IconButton>
                        </Tooltip>
                    </ActionMenu>
                    {/* ########################## BODY ########################### */}
                    <Typography className="body" noWrap>
                        {notification.body}
                    </Typography>
                </NotHeader>
                {/* ########################## FOOTER ########################### */}
                <NotFooter>
                    <Typography className="text">
                        From: {notification.from}
                    </Typography>
                    <Typography className="text">
                        {notification.date}
                    </Typography>
                </NotFooter>
            </NotCard>
        </React.Fragment>
    );
};

export default NotificationWidget;
