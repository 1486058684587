import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { colors } from '../../assets/utils/constants';

const CustomSelectFilter = ({
    children,
    width,
    label,
    compValue,
    handleFilter,
}) => {
    const [value, setValue] = React.useState(compValue);

    const handleChange = (event) => {
        setValue(event.target.value);
        handleFilter(event.target.value);
    };

    return (
        <FormControl
            sx={{
                'mx': 1,
                'width': width,
                '& .MuiInputBase-root': {
                    height: 35,
                },
                '& .MuiSelect-select': {
                    fontSize: 12,
                },
                'bgcolor': colors.bgColor1,
            }}
            size="small"
        >
            {label && <InputLabel sx={{ fontSize: 13 }}>{label}</InputLabel>}
            <Select
                value={value}
                label={label}
                onChange={handleChange}
                displayEmpty
            >
                {children}
            </Select>
        </FormControl>
    );
};

export default CustomSelectFilter;
