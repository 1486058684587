import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { organizationEndpoints } from './organizationEndpoints';

export const organizationAPI = createApi({
    reducerPath: 'organizationAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Organizations', 'Organization', 'ORGJobs'],
    endpoints: (builder) => ({
        // => GET all
        getAllOrganizations: builder.query({
            query: () => `${organizationEndpoints.GET_ALL_ORG}`,
            providesTags: ['Organizations'],
        }),
        // => GET org profile
        getORGProfile: builder.query({
            query: (payload) =>
                `${organizationEndpoints.GET_ORG_PROFILE}/` + payload,
            providesTags: ['Organization'],
            invalidatesTags: ['ORGJobs'],
        }),
        // => GET org jobs
        getSingleORGJobs: builder.query({
            query: (payload) =>
                `${organizationEndpoints.GET_ORG_JOBS}/` + payload,
            providesTags: ['ORGJobs'],
        }),
        // => Approve org docs
        approveORGDocs: builder.mutation({
            query: (payload) => ({
                url: `${organizationEndpoints.APPROVE_ORG_DOCS}/` + payload,
                method: `PUT`,
            }),
            invalidatesTags: ['Organization'],
        }),
        // => Deny org docs
        denyORGDocs: builder.mutation({
            query: (payload) => ({
                url:
                    `${organizationEndpoints.DENY_ORG_DOCS}/` +
                    payload.organization_id,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: ['Organizations', 'Organization'],
        }),
        // => Approve key person docs
        approveKeyPersonDocs: builder.mutation({
            query: (payload) => ({
                url:
                    `${organizationEndpoints.APPROVE_KEY_PERSON_DOCS}/` +
                    payload,
                method: `PUT`,
            }),
            invalidatesTags: ['Organization'],
        }),
        // => Deny key person docs
        denyKeyPersonDocs: builder.mutation({
            query: (payload) => ({
                url:
                    `${organizationEndpoints.DENY_KEY_PERSON_DOCS}/` +
                    payload.organization_id,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: ['Organizations', 'Organization'],
        }),
        // => Approve key person docs
        approveOrganization: builder.mutation({
            query: (payload) => ({
                url: `${organizationEndpoints.APPROVE_ORGANIZATION}/` + payload,
                method: `PUT`,
            }),
            invalidatesTags: ['Organizations', 'Organization'],
        }),
    }),
});

export const {
    useGetAllOrganizationsQuery,
    useGetORGProfileQuery,
    useGetSingleORGJobsQuery,
    useApproveORGDocsMutation,
    useDenyORGDocsMutation,
    useApproveKeyPersonDocsMutation,
    useDenyKeyPersonDocsMutation,
    useApproveOrganizationMutation,

    // => Lazy
    useLazyGetAllOrganizationsQuery,
} = organizationAPI;
