import React from 'react';
import { Add } from '@mui/icons-material';
import CustomCard2 from '../../../components/cards/CustomCard2';
import { colors } from '../../../assets/utils/constants';
import { Box, Grid, IconButton } from '@mui/material';
import SocialMediaWidget from '../../../components/widgets/SocialMediaWidget';
import CustomDialog from '../../../components/Dialogies/CustomDialog';
import AddSocialNet from './CRUD/AddSocialNet';
import { useGetSocialNetworksQuery } from '../../../api/helpCenter/helpCenterAPI';
import { setSocialNetworks } from '../../../states/features/helpCenter/helpCenterSlice';
import { helpCenterSelector } from '../../../states/features/selectors';
import { useDispatch, useSelector } from 'react-redux';
import useRTK from '../../../hooks/useRTK';

const SocialNetworks = () => {
    // ############ CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# COMP State ###############
    const [open, setOpen] = React.useState(false);

    // ############ Redux state ##############
    const { socialNetworks } = useSelector(helpCenterSelector);

    // ############# FUNC ###############
    // => Open dialog
    const handleClickOpen = () => {
        setOpen(true);
    };
    // => Close dialog
    const handleClose = () => {
        setOpen(false);
    };

    // ############## RTK ##############
    const {
        // isLoading,
        isSuccess,
        data: contacstData,
    } = useGetSocialNetworksQuery();
    // => Action
    const rtkAction = (data) => {
        dispatch(setSocialNetworks(data));
    };
    // => Response
    RTK.useRTKFetch(isSuccess, contacstData, rtkAction);

    return (
        <div>
            {/* ############## Dialogy ############## */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`Add social networks`}
            >
                <AddSocialNet handleClose={handleClose} />
            </CustomDialog>

            <CustomCard2
                head={'Social networks links'}
                height={'35vh'}
                action={
                    <Box>
                        <IconButton
                            size="small"
                            color="warning"
                            onClick={() => {
                                handleClickOpen();
                            }}
                            sx={{
                                p: 1,
                                fontSize: 13,
                                fontWeight: 'bold',
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                            }}
                        >
                            Add
                            <Add sx={{ fontSize: 16 }} />
                        </IconButton>
                    </Box>
                }
            >
                <Grid container spacing={2}>
                    {socialNetworks?.map((media, index) => (
                        <Grid item sm={6} key={index}>
                            <SocialMediaWidget media={media} />
                        </Grid>
                    ))}
                </Grid>
            </CustomCard2>
        </div>
    );
};

export default SocialNetworks;
