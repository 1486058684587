// => Remove special character
export const removeSpclChar = (string) => {
    return string.replace(/[^a-zA-Z ]/g, " ");
};

const strHelper = () => {
    const strCapitalizeFirstChar = (string) => {
        let capitalizedStr =
            string.charAt(0).toUpperCase() + string.substring(1);
        return capitalizedStr;
    };
    return {
        strCapitalizeFirstChar,
    };
};

export default strHelper;
