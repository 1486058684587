import React, { useState } from 'react';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import BlogsSidebar from '../../components/sidebars/BlogsSidebar';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { Edit } from '@mui/icons-material';
import CustomDialog from '../../components/Dialogies/CustomDialog';
import UpdateBlogPost from './CRUD/UpdateBlogPost';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import { blogsSelector } from '../../states/features/selectors';
import { useGetBlogQuery, useGetBlogsQuery } from '../../api/blogs/blogsAPI';
import {
    setBlogDetails,
    setBlogs,
} from '../../states/features/blogs/blogsSlice';
import ContentLoader from '../../components/ContentLoader';
import InnerDataFetchingError from '../../components/InnerDataFetchingError';
import moment from 'moment/moment';
import ActivateDeactivateBlog from './CRUD/ActivateDeactivateBlog';
import DeleteBlog from './CRUD/DeleteBlog';

const BlogDetails = () => {
    // ############# Const ##############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const { blog_id } = useParams();

    // ############# Comp State ############
    const [dialogOpen, setDialogOpen] = useState(false);
    const { blogDetails: blog } = useSelector(blogsSelector);

    // ############# FUNC ###############
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    // ############# RTK ##################
    // => Blog
    const {
        isLoading,
        isSuccess,
        isError,
        refetch,
        data: responseData,
    } = useGetBlogQuery(blog_id);
    const rtkAction = (data) => {
        dispatch(setBlogDetails(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkAction);
    // => Blogs
    const {
        isLoading: blogLoading,
        isSuccess: blogSuccess,
        data: blogsData,
    } = useGetBlogsQuery();
    const rtkBlogActions = (data) => {
        dispatch(setBlogs(data));
    };
    RTK.useRTKFetch(blogSuccess, blogsData, rtkBlogActions);

    return (
        <>
            {/* ############## Dialogy ############## */}
            <CustomDialog
                openDialog={dialogOpen}
                handleDialogClose={handleDialogClose}
                head={`Update blog post`}
            >
                <UpdateBlogPost blog={blog} handleClose={handleDialogClose} />
            </CustomDialog>

            <PageLayout head={blog.title} sidebar={<BlogsSidebar />}>
                <CustomCard2 head={'Post details'} height={562} borderBottom>
                    {isLoading || blogLoading ? (
                        <>
                            <ContentLoader />
                        </>
                    ) : isError ? (
                        <>
                            <InnerDataFetchingError
                                handleDataRefetch={refetch}
                            />
                        </>
                    ) : (
                        <Box sx={{}}>
                            <Grid container spacing={4}>
                                <Grid item sm={5} xs={12}>
                                    <Box sx={{ height: { md: 400, xs: 200 } }}>
                                        <Avatar
                                            src={blog.blog_image}
                                            variant="square"
                                            sx={{
                                                width: '100%',
                                                height: 250,
                                                img: {
                                                    objectFit: 'contain',
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item sm={7} xs={12}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontSize: 20,
                                                fontWeight: 'bold',
                                                opacity: 0.7,
                                                p: 1,
                                                borderRadius: 1,
                                                background: `linear-gradient(90deg, ${colors.secondary}, ${colors.bgColor2})`,
                                            }}
                                        >
                                            {blog.title}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                opacity: 0.9,
                                                py: 3,
                                            }}
                                        >
                                            {blog.content}
                                        </Typography>
                                        <Box
                                            sx={{
                                                mb: 4,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 15,
                                                    color: colors.info,
                                                }}
                                            >
                                                <strong>Posted:</strong>{' '}
                                                {moment(blog.created_at).format(
                                                    'MMM Do YYYY',
                                                )}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sm={12}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <ActivateDeactivateBlog blog={blog} />
                                        <Button
                                            variant="outlined"
                                            color="warning"
                                            startIcon={<Edit />}
                                            sx={{ mx: 2 }}
                                            onClick={() => {
                                                handleDialogOpen();
                                            }}
                                        >
                                            Edit
                                        </Button>
                                        <DeleteBlog blog={blog} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default BlogDetails;
