import React from 'react';
import { Subject } from '@mui/icons-material';
import { Box, Chip, styled, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { colors } from '../../../assets/utils/constants';
import NoContent from '../../../components/NoContent';

// ########### STYLED COMPONENT #################
const JobTagCard = styled(Box)({
    'padding': 20,
    'position': 'relative',
    '.title': {
        'fontSize': 15,
        'fontWeight': 'bold',
        'display': 'flex',
        'alignItems': 'center',
        'pb': 0.5,
        'color': colors.warning,
        'opacity': 0.8,
        'marginBottom': 2,
        '.icon': {
            marginRight: 1,
            fontSize: 14,
            color: colors.warning,
        },
    },
    '.res-con': {
        'display': 'flex',
        'border': `1px solid ${colors.bgColor5}`,
        'justifyContent': 'space-between',
        'padding': 5,
        'borderRadius': 5,
        ':hover': {
            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
        },
    },
});

// !################## MAIN FUNC ##################
const JobTags = ({ jobTags }) => {
    return (
        <>
            <JobTagCard>
                <Typography className="title">
                    <Subject className="icon" />
                    Job tags
                </Typography>
                {!isEmpty(jobTags) ? (
                    <>
                        {/* ######################### CONTENT #################### */}
                        <Box>
                            {jobTags?.map((tag) => (
                                <Chip
                                    key={tag}
                                    label={tag}
                                    variant="outlined"
                                    size="small"
                                    sx={{ m: 1, textTransform: 'capitalize' }}
                                />
                            ))}
                        </Box>
                    </>
                ) : (
                    <>
                        <NoContent height={100} message={'No tag added'} />
                    </>
                )}
            </JobTagCard>
        </>
    );
};

export default JobTags;
