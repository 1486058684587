import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { filter, isEmpty } from "lodash";
import { size } from "lodash";
import { adminsSelector } from "../../states/features/selectors";
import CountBadge from "../../components/CountBadge";
import { useParams } from "react-router-dom";
import PageLayout from "../layouts/PageLayout";
import CustomCard2 from "../../components/cards/CustomCard2";
import PromotionWidget from "../../components/widgets/PromotionWidget";
import NoContent from "../../components/NoContent";
import AdminWidget from "../../components/widgets/AdminWidget";

const AdminByStatus = () => {
    // ################# CONST #####################
    const { status } = useParams();

    // ############## Redux state ###################
    const { admins } = useSelector(adminsSelector);

    const getAdmins = () => {
        let filteredAdmins = [];
        if (status === "suspended") {
            filteredAdmins = filter(
                admins,
                ({ status }) => status === "suspended"
            );
        } else if (status === "active") {
            filteredAdmins = filter(
                admins,
                ({ status }) => status === "active"
            );
        } else if (status === "in-active") {
            filteredAdmins = filter(
                admins,
                ({ status }) => status === "inactive"
            );
        }
        return filteredAdmins;
    };

    const adminByStatus = getAdmins();

    return (
        <>
            <PageLayout head={`${status} system admins`} noSidebar>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard2
                            height={"65vh"}
                            head={`${status}  system admins`}
                            borderBottom
                            action={
                                <CountBadge
                                    item={`${status} Admins`}
                                    total={size(adminByStatus)}
                                />
                            }
                        >
                            {isEmpty(adminByStatus) ? (
                                <>
                                    <NoContent
                                        message={`No ${status} system admin added`}
                                    />
                                </>
                            ) : (
                                <>
                                    <Grid container spacing={2}>
                                        {adminByStatus.map((admin) => (
                                            <Grid item sm={4} key={admin.id}>
                                                <AdminWidget admin={admin} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </>
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default AdminByStatus;
