import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { helpCenterEndpoints } from './helpCenterEndpoints';

export const helpCenterAPI = createApi({
    reducerPath: 'helpCenterAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Contacts', 'SocialNets'],
    endpoints: (builder) => ({
        // => GET contacts
        getContacts: builder.query({
            query: () => `${helpCenterEndpoints.GET_CONTACTS}`,
            providesTags: ['Contacts'],
        }),
        // => Update contacts
        updateContacts: builder.mutation({
            query: (payload) => ({
                url: `${helpCenterEndpoints.UPDATE_CONTACTS}`,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ['Contacts']),
        }),

        // => GET social net's
        getSocialNetworks: builder.query({
            query: () => `${helpCenterEndpoints.GET_SOCIAL_NET}`,
            providesTags: ['SocialNets'],
        }),
        // => Add social net
        addSocialNetwork: builder.mutation({
            query: (payload) => ({
                url: `${helpCenterEndpoints.ADD_SOCIAL_NET}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ['SocialNets']),
        }),
        // => Update social net
        updateSocialNetwork: builder.mutation({
            query: (payload) => ({
                url: `${helpCenterEndpoints.UPDATE_SOCIAL_NET}/${payload.social_id}`,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ['SocialNets']),
        }),
        // => Activate social net
        activateSocialNetwork: builder.mutation({
            query: (payload) => ({
                url: `${helpCenterEndpoints.ACTIVATE_SOCIAL_NET}/${payload}`,
                method: `PUT`,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ['SocialNets']),
        }),
        // => Deactivate social net
        deactivateSocialNetwork: builder.mutation({
            query: (payload) => ({
                url: `${helpCenterEndpoints.DEACTIVATE_SOCIAL_NET}/${payload}`,
                method: `PUT`,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ['SocialNets']),
        }),
    }),
});

export const {
    useGetContactsQuery,
    useUpdateContactsMutation,
    useGetSocialNetworksQuery,
    useAddSocialNetworkMutation,
    useUpdateSocialNetworkMutation,
    useActivateSocialNetworkMutation,
    useDeactivateSocialNetworkMutation,
} = helpCenterAPI;
