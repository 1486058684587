import React from 'react';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ManageAccounts } from '@mui/icons-material';
import * as Yup from 'yup';
import CustomTextArea from '../../../components/Forms/CustomTextArea';
import { useDispatch } from 'react-redux';
import { DialogActions, Grid } from '@mui/material';
import useRTK from '../../../hooks/useRTK';
import { addExperienceLevel } from '../../../states/features/settings/experienceLevelsSlice';
import { useAddExperienceLevelMutation } from '../../../api/settings/experienceLevelAPI';

// -> validate settings form
const validate = Yup.object({
    experience: Yup.string().required(),
    percentage: Yup.number().required().min(0).max(100),
    description: Yup.string().nullable(),
});

const AddExperienceLevel = ({ handleClose, refetch }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        createExperienceLevel,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useAddExperienceLevelMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        refetch();
        handleClose();
        dispatch(addExperienceLevel(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    experience: '',
                    percentage: '',
                    description: '',
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    createExperienceLevel(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container columnSpacing={2}>
                            <Grid item sm={6}>
                                <CustomTextField
                                    label="Experience level"
                                    name="experience"
                                    type="text"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    label="Percentage (For job posting)"
                                    name="percentage"
                                    type="number"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomTextArea
                                    label="Description"
                                    name="description"
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <CustomSubmitButton
                                startIcon={<ManageAccounts />}
                                onClick={formik.handleSubmit}
                                loading={isLoading}
                            >
                                Add setting
                            </CustomSubmitButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddExperienceLevel;
