import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    organizationTypes: [],
};

// !########## SLICE #############
const organizationTypesSlice = createSlice({
    name: 'organizationTypesSlice',
    initialState,
    reducers: {
        setOrganizationTypes: (state, actions) => {
            state.organizationTypes = actions.payload;
        },
        addOrganizationType: (state, actions) => {
            const organization_type = actions.payload;
            isEmpty(state.organizationTypes)
                ? (state.organizationTypes = [organization_type, ...[]])
                : (state.organizationTypes = [
                      organization_type,
                      ...state.organizationTypes,
                  ]);
        },
    },
});

export const {
    setOrganizationTypes,
    addOrganizationType,
} = organizationTypesSlice.actions;
export default organizationTypesSlice.reducer;
