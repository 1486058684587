import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import CustomTextField from "../../../components/Forms/CustomTextField";
import CustomSelectInput from "../../../components/Forms/CustomSelectInput";
import { DialogActions, Grid, MenuItem } from "@mui/material";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { ManageAccounts } from "@mui/icons-material";
import * as Yup from "yup";
import { useUpdateProfileMutation } from "../../../api/auth/profileAPI";
import { errorNotify, successNotify } from "../../../helpers/notify";
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from "../../../helpers/RTKHelpers";
import { setUpdatedProfile } from "../../../states/features/auth/authSlice";
import { useDispatch } from "react-redux";

// ============= update profile validation =====================
const profileUpdateValidation = Yup.object({
    first_name: Yup.string().required("This field is required"),
    middle_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
    birth_date: Yup.date().required("Date of birth is required field"),
    gender: Yup.string().required("This field is required"),
    email: Yup.string().email().required("This field is required"),
    phone_number: Yup.string()
        .required("This field is required")
        .max(13, "Phone number should contain 10 integers")
        .min(9, "Phone number should contain 10 integers"),
});

const UpdateProfile = ({ user, dialogClose }) => {
    // ################ CONST #################
    const dispatch = useDispatch();

    // ################ RTK #################
    const [
        updateProfile,
        { isLoading, isSuccess, isError, error, data: updatedProfile },
    ] = useUpdateProfileMutation();

    // ############### useEffect ##############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dialogClose();
                successNotify(getRTKSuccessMessage(updatedProfile));
                dispatch(setUpdatedProfile(getRTKResponseData(updatedProfile)));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, error, updatedProfile]);

    return (
        <>
            <Formik
                initialValues={{
                    first_name: user.first_name,
                    middle_name: user.middle_name ? user.middle_name : "",
                    last_name: user.last_name,
                    birth_date: user.birth_date,
                    phone_number: user.phone_number,
                    email: user.email,
                    gender: user.gender,
                }}
                validationSchema={profileUpdateValidation}
                onSubmit={(payload) => {
                    updateProfile(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container columnSpacing={2}>
                            <Grid item sm={4}>
                                <CustomTextField
                                    className="form-input"
                                    label="First name"
                                    type="text"
                                    name="first_name"
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <CustomTextField
                                    className="form-input"
                                    label="Middle name"
                                    type="text"
                                    name="middle_name"
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <CustomTextField
                                    className="form-input"
                                    label="Last name"
                                    type="text"
                                    name="last_name"
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2}>
                            <Grid item sm={6}>
                                <CustomTextField
                                    className="form-input"
                                    label="Phone number"
                                    type="number"
                                    name="phone_number"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    className="form-input"
                                    label="Email"
                                    type="emai"
                                    name="email"
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2}>
                            <Grid item sm={6}>
                                <CustomTextField
                                    className="form-input"
                                    label="Birth date"
                                    type="date"
                                    name="birth_date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomSelectInput
                                    label="Gender"
                                    type="text"
                                    name="gender"
                                    formik={formik}
                                    color={"primary"}
                                >
                                    <MenuItem value={"male"}>Male</MenuItem>
                                    <MenuItem value={"female"}>Female</MenuItem>
                                </CustomSelectInput>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    loading={isLoading}
                                    startIcon={<ManageAccounts />}
                                    onClick={formik.handleSubmit}
                                    btnColor={"info"}
                                    sx={{ py: 2 }}
                                >
                                    Update profile
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateProfile;
