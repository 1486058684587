import React from 'react';
import { colors } from '../../assets/utils/constants';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

const SubConfigurationWidget = ({ configuration }) => {
    return (
        <>
            <Link to={configuration.url}>
                <Box
                    sx={{
                        height: 100,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 3,
                        border: `5px solid ${colors.bgColor3}`,
                        boxShadow: `2px 2px 5px 0px ${colors.bgColor5}`,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    }}
                >
                    <Typography
                        sx={{
                            'display': 'flex',
                            'alignItems': 'center',
                            'fontWeight': 'bold',
                            'fontSize': 14,
                            'opacity': 0.8,
                            'color': colors.warning,
                            '& .icon': {
                                fontSize: 35,
                                mr: 2,
                                color: colors.secondary,
                            },
                        }}
                    >
                        {configuration.icon}
                        {configuration.total}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            opacity: 0.8,
                        }}
                    >
                        {configuration.title}
                    </Typography>
                </Box>
            </Link>
        </>
    );
};

export default SubConfigurationWidget;
