import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { removeArrObj, updateArrObj } from '../../../helpers/arrayHelpers';

const initialState = {
    faqs: [],
};

// !########## SLICE #############
const faqsSlice = createSlice({
    name: 'faqsSlice',
    initialState,
    reducers: {
        setFaqs: (state, actions) => {
            state.faqs = actions.payload;
        },
        addFaq: (state, actions) => {
            let faq = actions.payload;
            isEmpty(state.faqs)
                ? (state.faqs = [faq, ...[]])
                : (state.faqs = [faq, ...state.faqs]);
        },
        updateFaq: (state, actions) => {
            let newFaq = actions.payload;
            let faqs = updateArrObj('id', newFaq, state.faqs);
            state.faqs = faqs;
        },
        removeFaq: (state, actions) => {
            let payload = actions.payload;
            let faqs = removeArrObj('id', payload, state.faqs);
            state.faqs = faqs;
        },
    },
});

export const { setFaqs, addFaq, updateFaq, removeFaq } = faqsSlice.actions;
export default faqsSlice.reducer;
