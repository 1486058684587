import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    verificationTypes: [],
};

// !########## SLICE #############
const verificationTypesSlice = createSlice({
    name: 'verificationTypesSlice',
    initialState,
    reducers: {
        setVerificationTypes: (state, actions) => {
            state.verificationTypes = actions.payload;
        },
        addVerificationTypes: (state, actions) => {
            const verification_type = actions.payload;
            isEmpty(state.verificationTypes)
                ? (state.verificationTypes = [verification_type, ...[]])
                : (state.verificationTypes = [
                      verification_type,
                      ...state.verificationTypes,
                  ]);
        },
    },
});

export const {
    setVerificationTypes,
    addVerificationTypes,
} = verificationTypesSlice.actions;
export default verificationTypesSlice.reducer;
