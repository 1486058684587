import React from "react";
import PageLayout from "../layouts/PageLayout";
// import PromotionSidebar from '../../components/sidebars/PromotionSidebar';
import { Box, Button, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/constants";
import Infobox1 from "../../components/infoboxes/InfoBox1";
import { Add } from "@mui/icons-material";
import CustomCard2 from "../../components/cards/CustomCard2";
import PromotionWidget from "../../components/widgets/PromotionWidget";
import AddPromotion from "./CRUD/AddPromotion";
import CustomDialog from "../../components/Dialogies/CustomDialog";
import CountBadge from "../../components/CountBadge";
import { useSelector } from "react-redux";
import { promotionsSelector } from "../../states/features/selectors";
import { filter, isEmpty, size } from "lodash";
import NoContent from "../../components/NoContent";
import PromotionSidebar from "../../components/sidebars/PromotionSidebar";

const Promotions = () => {
    // ############## Comp state ###################
    const [open, setOpen] = React.useState(false);

    // ############## Redux state ###################
    const { promotions } = useSelector(promotionsSelector);

    // ############## FUNC ###################
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    // => Get subscribed promotions
    const getSubscribedPromos = () => {
        let subscribedPromos = filter(
            promotions,
            ({ currSubscription }) => currSubscription.status !== "expired"
        );
        return subscribedPromos;
    };
    const subscribedPromos = getSubscribedPromos();

    const infoBoxData = [
        {
            id: 1,
            title1: "All",
            title2: "promotions",
            total: size(promotions),
            url: "/promotions/status/all",
        },
        {
            id: 2,
            title1: "Active",
            title2: "promotions",
            total: size(filter(promotions, ({ isActive }) => isActive === 1)),
            url: "/promotions/status/active",
        },
        {
            id: 3,
            title1: "In-active",
            title2: "promotions",
            total: size(filter(promotions, ({ isActive }) => isActive === 0)),
            url: "/promotions/status/in-active",
        },
        // {
        //     id: 4,
        //     title1: 'promotion',
        //     title2: 'customers',
        //     total: size(customers),
        //     url: '/promotions/customers',
        // },
    ];

    return (
        <>
            {/* ############## Dialogy ############## */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`Add promotion`}
                // customWidth={'md'}
            >
                <AddPromotion handleClose={handleClose} />
            </CustomDialog>

            <PageLayout
                head={"Promotions managements"}
                sidebar={<PromotionSidebar />}
            >
                <Grid container spacing={2}>
                    {infoBoxData.map((data, index) => (
                        <Grid item xs key={index}>
                            <Infobox1 data={data} height={80} />
                        </Grid>
                    ))}
                    <Grid item xs>
                        <Button
                            fullWidth
                            sx={{
                                height: 80,
                                border: `2px dotted ${colors.bgColor5}`,
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                borderRadius: `50px !important`,
                            }}
                            onClick={() => handleClickOpen()}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Add
                                    color="info"
                                    sx={{
                                        p: 1,
                                        fontSize: 35,
                                        borderRadius: 50,
                                        border: `2px dotted ${colors.info}`,
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        opacity: 0.8,
                                    }}
                                >
                                    Create promotion
                                </Typography>
                            </Box>
                        </Button>
                    </Grid>
                    <Grid item sm={12}>
                        <CustomCard2
                            height={"57vh"}
                            head={"Subscribed promotions"}
                            borderBottom
                            action={
                                <CountBadge
                                    item={"Promotions"}
                                    total={size(subscribedPromos)}
                                />
                            }
                        >
                            {isEmpty(subscribedPromos) ? (
                                <>
                                    <NoContent
                                        message={"No subscribed promotion"}
                                    />
                                </>
                            ) : (
                                <>
                                    <Grid container spacing={2}>
                                        {subscribedPromos.map((promotion) => (
                                            <Grid
                                                item
                                                sm={6}
                                                key={promotion.id}
                                            >
                                                <PromotionWidget
                                                    promotion={promotion}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </>
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Promotions;
