import React from "react";
import {
    AttachEmailOutlined,
    RemoveRedEye,
    LocationOn,
    PhoneForwardedOutlined,
} from "@mui/icons-material";
import {
    Avatar,
    Tooltip,
    Box,
    Button,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
    IconButton,
} from "@mui/material";
import { colors } from "../../assets/utils/constants";
import { Link } from "react-router-dom";
import { isEmpty, size } from "lodash";

const EmployerWidget = ({ employer }) => {
    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    borderRadius: 3,
                    overflow: "hidden",
                    border: `4px solid ${colors.secondary}`,
                    background: `linear-gradient(180deg, ${colors.secondary}, ${colors.bgColor3})`,
                    transition: ".3s all ease-in-out",
                    ":hover": {
                        transition: ".3s all ease-in-out",
                        border: `4px solid ${colors.secondary}`,
                    },
                }}
            >
                <ListItemButton
                    sx={{
                        px: 0.6,
                        mb: 1,
                        transition: ".3s all ease-in-out",
                        "& .dp": {
                            mr: 2,
                            transition: ".3s all ease-in-out",
                        },
                        bgcolor: colors.primary,
                        ":hover": {
                            cursor: "default",
                            bgcolor: colors.primary,
                        },
                    }}
                >
                    <ListItem
                        sx={{
                            p: 0,
                            position: "relative",
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                src={`${employer.profile_picture}`}
                                className="dp"
                                sx={{
                                    height: 50,
                                    width: 50,
                                    border: `2px solid ${colors.secondary}`,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            disableTypography
                            primary={
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        color: colors.secondary,
                                    }}
                                >
                                    {`${employer.first_name} ${employer.last_name}`}
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        display: "flex",
                                        alignItems: "center",
                                        color: colors.secondary,
                                        width: "80%",
                                    }}
                                    noWrap
                                >
                                    <LocationOn
                                        color="secondary"
                                        sx={{ fontSize: 14 }}
                                    />{" "}
                                    {`${employer.region} - ${employer.district}`}
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            width: 40,
                            mr: 0.5,
                            flexDirection: "column",
                        }}
                    >
                        <Tooltip title="employer details" arrow>
                            <Link to={`${employer.id}`}>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    sx={{
                                        mt: 0.5,
                                        background: `rgba(255,255,255,.1)`,
                                    }}
                                >
                                    <RemoveRedEye sx={{ fontSize: 18 }} />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </Box>
                </ListItemButton>
                <Box>
                    <Typography
                        sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: 16,
                            color: colors.success,
                        }}
                    >
                        {`${employer.registration_number}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        py: 1,
                        pb: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: 45,
                                mx: 0.5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                opacity: 0.9,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: 13,
                                        mt: 0.5,
                                    }}
                                >
                                    Jobs
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        color: colors.info,
                                        mt: -0.5,
                                    }}
                                >
                                    {size(employer.jobs)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                height: 45,
                                mx: 0.5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                opacity: 0.9,
                                "& .icon": {
                                    transition: `.3s ease-in-out all`,
                                },
                                ":hover": {
                                    "& .icon": {
                                        color: colors.warning,
                                        transition: `.3s ease-in-out all`,
                                    },
                                },
                            }}
                        >
                            <Tooltip title={employer.phone_number} arrow>
                                <a href={`tel:${employer.phone_number}`}>
                                    <Button fullWidth size="small" color="info">
                                        <PhoneForwardedOutlined
                                            sx={{ fontSize: 20 }}
                                            className="icon"
                                        />
                                    </Button>
                                </a>
                            </Tooltip>
                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                height: 45,
                                mx: 0.5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: colors.white,
                                borderRadius: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                opacity: 0.9,
                                ":hover": {
                                    "& .icon": {
                                        color: colors.warning,
                                    },
                                },
                            }}
                        >
                            {isEmpty(employer.email) ? (
                                <Tooltip title={"Email not available"} arrow>
                                    <Button fullWidth size="small" color="info">
                                        <AttachEmailOutlined
                                            sx={{ fontSize: 20 }}
                                            className="icon"
                                        />
                                    </Button>
                                </Tooltip>
                            ) : (
                                <Tooltip title={employer.email} arrow>
                                    <a
                                        href={`mailTo:${employer.email}`}
                                        aria-disabled
                                    >
                                        <Button
                                            fullWidth
                                            size="small"
                                            color="info"
                                        >
                                            <AttachEmailOutlined
                                                sx={{ fontSize: 20 }}
                                                className="icon"
                                            />
                                        </Button>
                                    </a>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default EmployerWidget;
