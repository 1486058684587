import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { studentEndpoints } from './studentEndpoints';

export const studentsAPI = createApi({
    reducerPath: 'studentsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Students', 'Student'],
    endpoints: (builder) => ({
        // => GET all
        getAllStudents: builder.query({
            query: () => `${studentEndpoints.GET_ALL_STUDENTS}`,
            providesTags: ['Students'],
        }),

        // => GET org
        getORGStudents: builder.query({
            query: (payload) => `${studentEndpoints.GET_ORG_STUDENTS}/${payload}`,
            providesTags: ['Students'],
        }),

        // => GET single
        getSingleStudent: builder.query({
            query: (payload) =>
                `${studentEndpoints.GET_SINGLE_STUDENT}/${payload}`,
            providesTags: ['Student'],
        }),
    }),
});

export const {
    useGetAllStudentsQuery,
    useGetORGStudentsQuery,
    useGetSingleStudentQuery,

    // => Lazy
    useLazyGetAllStudentsQuery,
} = studentsAPI;
