import React from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import logo from '../../assets/media/images/logo.png';
import HotToastfy from '../../components/HotToastfy';

// -> Auth container
const AuthContainer = styled(Grid)(({ theme }) => ({
    'overflowY': 'auto',
    'overflowX': 'hidden',
    '& .form-con': {
        '& .inner-con': {
            height: '100vh',
            background: colors.secondary,
            borderTopLeftRadius: 50,
            borderBottomRightRadius: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    [theme.breakpoints.down('md')]: {
        '& .logo-con': {
            display: 'none',
        },
        '& .form-con': {
            '& .inner-con': {
                background: colors.primary,
            },
        },
    },
}));

const AuthLayout = ({ children }) => {
    return (
        <>
            <HotToastfy />
            <AuthContainer container sx={{ bgcolor: colors.primary }}>
                <Grid item sm={5} className="logo-con">
                    <Box
                        sx={{
                            height: '100vh',
                            bgcolor: colors.primary,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={2}>
                                <img
                                    src={logo}
                                    alt=""
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Typography
                                    style={{
                                        fontSize: 50,
                                        textAlign: 'center',
                                        color: colors.secondary,
                                    }}
                                >
                                    Wazawa Max
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item sm={7} className="form-con">
                    <Box className="inner-con">{children}</Box>
                </Grid>
            </AuthContainer>
        </>
    );
};

export default AuthLayout;
