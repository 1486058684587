import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, Upload } from '@mui/icons-material';
import useRTK from '../../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import { usePublishPromotionMutation } from '../../../api/promotions/promotionsAPI';
import { setPromotionDetails } from '../../../states/features/promotions/promotionsSlice';

const PublishPromotion = ({ promotion, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        publishAction,
        {
            isLoading: publishLoading,
            isSuccess: publishSuccess,
            isError: publishIsError,
            error: publishError,
            data: publishData,
        },
    ] = usePublishPromotionMutation();

    // => Activate res
    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setPromotionDetails(data));
    };
    RTK.useRTKResponse(
        publishSuccess,
        publishIsError,
        publishError,
        publishData,
        rtkActions,
    );

    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Box sx={{ mb: 2 }} className="warning-message">
                        <Typography>
                            Please confirm promotion publication. This process
                            is irreversible. The start date of subscription
                            timeline will start today. Promotion publication can
                            not be undone.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <CustomSubmitButton
                        startIcon={<Upload />}
                        endIcon={<ArrowRightAltOutlined />}
                        btnColor="success"
                        variant={'contained'}
                        loading={publishLoading}
                        onClick={() => {
                            publishAction(promotion.id);
                        }}
                        sx={{ py: 2, fontSize: 16 }}
                    >
                        Publish promotion
                    </CustomSubmitButton>
                </Grid>
            </Grid>
        </>
    );
};

export default PublishPromotion;
