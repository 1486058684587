import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import useRTK from "../../../hooks/useRTK";
import { useDispatch } from "react-redux";
import { useDeleteAdminMutation } from "../../../api/admins/adminsAPI";
import { removeAdmin } from "../../../states/features/admins/adminsSlice";
import { colors } from "../../../assets/utils/constants";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { ArrowRightAltSharp, DeleteForever } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ApproveORGDocs = ({ handleClose, user }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // ############### RTK #####################
    const [
        deleteAdmin,
        { isLoading, isSuccess, isError, error, data: deleteData },
    ] = useDeleteAdminMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = () => {
        handleClose();
        setTimeout(() => {
            navigate(`/admins`);
        }, 1000);
        dispatch(removeAdmin(user.id));
    };
    RTK.useRTKResponse(isSuccess, isError, error, deleteData, rtkActions);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    textAlign: "center",
                    flexDirection: "column",
                }}
            >
                <Grid container>
                    <Grid item sm={12}>
                        {/* <DoneAll
                            sx={{
                                fontSize: 33,
                                fontWeight: "bold",
                                color: colors.success,
                                opacity: 0.8,
                                m: 0,
                                p: 0,
                            }}
                        /> */}
                        <Typography
                            sx={{
                                fontSize: 22,
                                fontWeight: "bold",
                                color: "red",
                                opacity: 0.8,
                            }}
                        >
                            Please confirm admin account deletion
                        </Typography>
                    </Grid>
                    <Grid item sm={12} sx={{ mt: 2 }}>
                        <Typography
                            sx={{
                                opacity: 0.8,
                            }}
                        >
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit. Commodi eos recusandae architecto saepe cum
                            suscipit, eveniet vero ipsa doloribus aliquam
                            exercitationem iste, ullam necessitatibus doloremque
                            excepturi nesciunt culpa! Sequi, officia.
                        </Typography>
                    </Grid>
                    <Grid item sm={12} sx={{ mt: 2 }}>
                        <CustomSubmitButton
                            startIcon={<DeleteForever />}
                            endIcon={<ArrowRightAltSharp />}
                            btnColor={"error"}
                            loading={isLoading}
                            onClick={() => {
                                deleteAdmin(user.id);
                            }}
                            sx={{ py: 2, mb: 1.5 }}
                        >
                            Delete account
                        </CustomSubmitButton>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ApproveORGDocs;
