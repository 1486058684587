import React from 'react';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../../components/Forms/CustomSubmitButton';
import { ManageAccounts } from '@mui/icons-material';
import * as Yup from 'yup';
import CustomTextArea from '../../../../components/Forms/CustomTextArea';
import { useDispatch } from 'react-redux';
import { DialogActions, Grid } from '@mui/material';
import useRTK from '../../../../hooks/useRTK';
import { useAddSocialNetworkMutation } from '../../../../api/helpCenter/helpCenterAPI';
import { setSocialNetworks } from '../../../../states/features/helpCenter/helpCenterSlice';

// -> validate settings form
const validate = Yup.object({
    name: Yup.string().required(),
    link: Yup.string().required().url(),
});

const AddSocialNet = ({ handleClose, socialNetwork }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        addSocialNet,
        { isLoading, isSuccess, isError, error, data: updateData },
    ] = useAddSocialNetworkMutation();

    // ############# RTK response ##################
    const rtkActions = (data) => {
        handleClose();
        dispatch(setSocialNetworks(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updateData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    id: socialNetwork ? socialNetwork.id : '',
                    name: socialNetwork ? socialNetwork.name : '',
                    link: socialNetwork ? socialNetwork.link : '',
                }}
                validationSchema={validate}
                onSubmit={(payload) => {
                    addSocialNet(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container columnSpacing={2}>
                            <Grid item sm={12}>
                                <CustomTextField
                                    label="Social network name"
                                    name="name"
                                    type="text"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomTextArea
                                    label="Social network link"
                                    name="link"
                                    rows={2}
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <CustomSubmitButton
                                startIcon={<ManageAccounts />}
                                onClick={formik.handleSubmit}
                                loading={isLoading}
                            >
                                Add social network
                            </CustomSubmitButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddSocialNet;
