import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../../assets/utils/constants';
import { AllInclusive } from '@mui/icons-material';
import { practicalTrainingsSelector } from '../../../states/features/selectors';
import { useSelector } from 'react-redux';
import { filter, isEmpty, size } from 'lodash';
import ContentLoader from '../../../components/ContentLoader';

const StudentApprovalStatus = ({ training, applicantIsLoading }) => {
    // ############### Redux state #################
    const { practicalTrainingApplicants } = useSelector(
        practicalTrainingsSelector,
    );

    return (
        <>
            <Box
                sx={{
                    mt: 4,
                    border: `1px dotted ${colors.info}`,
                    borderRadius: 4,
                    overflow: 'hidden',
                }}
            >
                <Grid container>
                    <Grid item sm={12}>
                        <Box sx={{ bgcolor: 'green' }}>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        pt: 1.5,
                                        // borderRadius: 2,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        textAlign: 'center',
                                        position: 'relative',
                                        height: 120,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            pb: 1,
                                            borderBottom: `1px solid ${colors.bgColor5}`,
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                            color: colors.info,
                                        }}
                                    >
                                        Student apply stats
                                    </Typography>
                                    {applicantIsLoading ? (
                                        <ContentLoader height={160} />
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                p: 1.5,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                    color: colors.warning,
                                                }}
                                            >
                                                <strong>
                                                    <small>Applied</small>
                                                </strong>
                                                <Typography>
                                                    {size(
                                                        practicalTrainingApplicants,
                                                    )}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                    color: colors.success,
                                                }}
                                            >
                                                <strong>
                                                    <small>Approved</small>
                                                </strong>
                                                <Typography>
                                                    {size(
                                                        filter(
                                                            practicalTrainingApplicants,
                                                            ({
                                                                appliedTraining,
                                                            }) =>
                                                                appliedTraining?.status ===
                                                                'approved',
                                                        ),
                                                    )}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                    color: colors.info,
                                                }}
                                            >
                                                <strong>
                                                    <small>Available</small>
                                                </strong>
                                                <Typography>
                                                    {!isEmpty(
                                                        training.positions,
                                                    ) ||
                                                    training.positions > 0 ? (
                                                        <Typography>
                                                            {training.positions -
                                                                size(
                                                                    filter(
                                                                        practicalTrainingApplicants,
                                                                        ({
                                                                            appliedTraining,
                                                                        }) =>
                                                                            appliedTraining?.status ===
                                                                            'approved',
                                                                    ),
                                                                )}
                                                        </Typography>
                                                    ) : (
                                                        <AllInclusive />
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default StudentApprovalStatus;
