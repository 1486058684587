import React, { useState } from 'react';
import {
    Badge,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { colors } from '../../../assets/utils/constants';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from '../../../components/ContentLoader';
import ConfigurationLayout from '../../layouts/ConfigurationLayout';
import CustomDialog from '../../../components/Dialogies/CustomDialog';
import { isEmpty } from 'lodash';
import CustomSettingsCard from '../../../components/cards/CustomSettingsCard';
import { Add } from '@mui/icons-material';
import NoContent from '../../../components/NoContent';
import ActionButtons from './ActionButtons';
import useRTK from '../../../hooks/useRTK';
import { useGetDepartmentsQuery } from '../../../api/settings/departmentsAPI';
import { setDepartments } from '../../../states/features/settings/departmentsSlice';
import {
    departmentsSelector,
    sectionsSelector,
} from '../../../states/features/selectors';
import { useGetSectionsQuery } from '../../../api/settings/sectionsAPI';
import { setSections } from '../../../states/features/settings/sectionsSlice';
import UpdateSection from './UpdateSection';
import AddSection from './AddSection';

const Sections = () => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp state #############
    const [open, setOpen] = React.useState(false);
    const [editedValues, setEditedValues] = useState({});
    const [isEdit, setIsedit] = useState(false);

    // ############# Redux state #############
    const { departments } = useSelector(departmentsSelector);
    const { sections } = useSelector(sectionsSelector);

    // ############# RTK ##################
    const {
        isLoading,
        isSuccess,
        refetch: refetchSections,
        data: responseData,
    } = useGetSectionsQuery();
    const {
        isSuccess: departmentSuccess,
        data: departmentData,
    } = useGetDepartmentsQuery();

    // ############## FUNC #####################
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleAdd = (value) => {
        setIsedit(false);
    };
    const handleEdit = (value) => {
        setIsedit(true);
        let payload = {
            id: value.id,
            name: value.name,
            description: value.description,
            department: value.department,
        };
        setEditedValues(payload);
        handleClickOpen();
    };

    // ############# RTK response ##################
    const rtkActions = (data) => {
        dispatch(setSections(data));
    };
    const rtkActions2 = (data) => {
        dispatch(setDepartments(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkActions);
    RTK.useRTKFetch(departmentSuccess, departmentData, rtkActions2);

    return (
        <>
            {/* =============== Dialogy ================== */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={`${isEdit ? 'Edit' : 'Add'} section`}
            >
                {isEdit ? (
                    <UpdateSection
                        departments={departments}
                        editedValues={editedValues}
                        handleClose={handleClose}
                        refetch={refetchSections}
                    />
                ) : (
                    <AddSection
                        departments={departments}
                        handleClose={handleClose}
                        refetch={refetchSections}
                    />
                )}
            </CustomDialog>

            <ConfigurationLayout head={`Manage sections`}>
                <CustomSettingsCard
                    head={'Manage sections'}
                    action={
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<Add />}
                            onClick={() => {
                                handleAdd();
                                handleClickOpen();
                            }}
                        >
                            Add
                        </Button>
                    }
                >
                    {isLoading ? (
                        <>
                            <ContentLoader />
                        </>
                    ) : (
                        <>
                            <TableContainer
                                component={Paper}
                                sx={{ boxShadow: 'none' }}
                            >
                                {isEmpty(sections) ? (
                                    <NoContent message={'No section added'} />
                                ) : (
                                    <>
                                        <Table>
                                            <TableHead
                                                sx={{
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                <TableRow
                                                    sx={{
                                                        '&:last-child td, &:last-child th': {
                                                            border: 0,
                                                            fontWeight: 'bold',
                                                            padding: 1.5,
                                                        },
                                                    }}
                                                >
                                                    <TableCell
                                                        sx={{
                                                            width: 0,
                                                        }}
                                                    >
                                                        S/N
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Name
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Department
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Description
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Status
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {sections.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': {
                                                                border: 0,
                                                            },
                                                            'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                                        }}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {++index}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row.department.name}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row.description}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Badge
                                                                className="badge"
                                                                badgeContent=""
                                                                color={
                                                                    parseInt(
                                                                        row.status,
                                                                    ) === 1
                                                                        ? 'success'
                                                                        : 'error'
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <ActionButtons
                                                                row={row}
                                                                refetch={
                                                                    refetchSections
                                                                }
                                                                handleEdit={
                                                                    handleEdit
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </>
                                )}
                            </TableContainer>
                        </>
                    )}
                </CustomSettingsCard>
            </ConfigurationLayout>
        </>
    );
};
export default Sections;
