import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { subscriptionPackageEndpoints } from './subscriptionPackageEndpoints';

export const subscriptionPackagesAPI = createApi({
    reducerPath: 'subscriptionPackagesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Packages'],
    endpoints: (builder) => ({
        // => GET all packages
        getAllPackages: builder.query({
            query: () => `${subscriptionPackageEndpoints.GET_ALL_PACKAGEs}`,
            providesTags: ['Packages'],
        }),

        // => CREATE package
        createSubscriptionPackage: builder.mutation({
            query: (payload) => ({
                url: `${subscriptionPackageEndpoints.CREATE_PACKAGE}`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (args, error) => (error ? [] : ['Packages']),
        }),

        // => UPDATE package
        updateSubscriptionPackage: builder.mutation({
            query: (payload) => ({
                url: `${subscriptionPackageEndpoints.UPDATE_PACKAGE}/${payload.package_id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (args, error) => (error ? [] : ['Packages']),
        }),

        // => ACTIVATE package
        activateSubscriptionPackage: builder.mutation({
            query: (payload) => ({
                url: `${subscriptionPackageEndpoints.ACTIVATE_PACKAGE}/${payload}`,
                method: 'PUT',
            }),
            invalidatesTags: (args, error) => (error ? [] : ['Packages']),
        }),

        // => DEACTIVATE package
        deactivateSubscriptionPackage: builder.mutation({
            query: (payload) => ({
                url: `${subscriptionPackageEndpoints.DEACTIVATE_PACKAGE}/${payload}`,
                method: 'PUT',
            }),
            invalidatesTags: (args, error) => (error ? [] : ['Packages']),
        }),

        // => DELETE package
        deleteSubscriptionPackage: builder.mutation({
            query: (payload) => ({
                url: `${subscriptionPackageEndpoints.DELETE_PACKAGE}/${payload}`,
                method: 'DELETE',
            }),
            invalidatesTags: (args, error) => (error ? [] : ['Packages']),
        }),
    }),
});

export const {
    useGetAllPackagesQuery,
    useCreateSubscriptionPackageMutation,
    useUpdateSubscriptionPackageMutation,
    useActivateSubscriptionPackageMutation,
    useDeactivateSubscriptionPackageMutation,
    useDeleteSubscriptionPackageMutation,

    // => Lazy
    useLazyGetAllPackagesQuery,
} = subscriptionPackagesAPI;
