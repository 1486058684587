import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { regionsEndpoints } from './settingEndpoints';
import { settingAPI } from './settingAPI';

export const regionsAPI = createApi({
    reducerPath: 'regionsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getRegions: builder.query({
            query: () => `${regionsEndpoints.GET_REGIONS}`,
        }),
        // => ADD
        addRegion: builder.mutation({
            query: (payload) => ({
                url: `${regionsEndpoints.ADD_REGION}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
        // => UPDATE
        updateRegion: builder.mutation({
            query: (payload) => ({
                url:
                    `${regionsEndpoints.UPDATE_REGION}/` +
                    payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateRegion: builder.mutation({
            query: (payload) => ({
                url:
                    `${regionsEndpoints.ACTIVATE_REGION}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateRegion: builder.mutation({
            query: (payload) => ({
                url:
                    `${regionsEndpoints.DEACTIVATE_REGION}/` +
                    payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteRegion: builder.mutation({
            query: (payload) => ({
                url: `${regionsEndpoints.DELETE_REGION}/` + payload,
                method: `DELETE`,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
    }),
});

export const {
    useGetRegionsQuery,
    useAddRegionMutation,
    useUpdateRegionMutation,
    useActivateRegionMutation,
    useDeactivateRegionMutation,
    useDeleteRegionMutation,
} = regionsAPI;
