import React from "react";

// ================ custom import =================
import { colors } from "../../assets/utils/constants";
import banner from "../../assets/media/images/banner.jpg";

// ================ MUI import =================
import { Button, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// ============= icon import =======================
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useSelector } from "react-redux";
import UpdateProfile from "./CRUD/UpdateProfile";
import CustomDialog from "../../components/Dialogies/CustomDialog";
import { authSelector } from "../../states/features/selectors";
import ChangePassword from "./CRUD/ChangePassword";
import GeneralDetails from "./GeneralDetails";
import LeftbarDetails from "./LeftbarDetails";
import PageLayout from "../layouts/PageLayout";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const CustomTabPanel = styled(TabPanel)({});

const Profile = () => {
    // ################# Comp state ###################
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);

    // ################ Redux state ##################
    const { user } = useSelector(authSelector);

    // ################# FUNC ###################
    // => handle tabs change
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // => handle dialogy open
    const handleClickOpen = () => {
        setOpen(true);
    };
    // => handle dialogy close
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <PageLayout head={"Profile update"} noSidebar>
                {/* ################## Update profile Dialogy ################ */}
                <CustomDialog
                    openDialog={open}
                    handleDialogClose={handleClose}
                    head={"Update profile"}
                >
                    <UpdateProfile user={user} dialogClose={handleClose} />
                </CustomDialog>
                
                {/* ################## bannner ############################## */}
                <Grid
                    container
                    sx={{
                        position: "relative",
                        height: 200,
                        left: 0,
                        right: 0,

                        justifyContent: "space-between",
                        backgroundImage: `url(${banner})`,
                        borderRadius: 4,
                    }}
                >
                    <Grid item sm={12}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: "flex",
                                alignItems: "center",
                                p: 10,
                                background: `rgba(178, 218, 200, .6)`,
                            }}
                        >
                            <Box sx={{ textAlign: "end", width: "100%" }}>
                                <Button
                                    variant="contained"
                                    startIcon={<ManageAccountsIcon />}
                                    onClick={handleClickOpen}
                                >
                                    Update profile
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container justifyContent={"center"} sx={{ mb: 5 }}>
                    {/* ################## leftbar ############################## */}
                    <Grid
                        item
                        sm={3}
                        style={{
                            background: colors.primary,
                            height: "70vh",
                            borderBottomLeftRadius: 20,
                            overflow: "hidden",
                        }}
                    >
                        <LeftbarDetails user={user} />
                    </Grid>
                    {/* ################### main content ######################## */}
                    <Grid
                        item
                        sm={8}
                        style={{
                            background: colors.bgColor1,
                            height: "70vh",
                            borderBottomRightRadius: 20,
                        }}
                    >
                        <Box sx={{ width: "100%" }}>
                            <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <Tabs value={value} onChange={handleChange}>
                                    <Tab
                                        label={
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    textTransform: "uppercase",
                                                    opacity: 0.9,
                                                    fontSize: 13,
                                                }}
                                            >
                                                Personal information
                                            </Typography>
                                        }
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        label={
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    textTransform: "uppercase",
                                                    opacity: 0.9,
                                                    fontSize: 13,
                                                }}
                                            >
                                                Change password
                                            </Typography>
                                        }
                                        {...a11yProps(1)}
                                    />
                                </Tabs>
                            </Box>
                            {/* ################ General details ################# */}
                            <CustomTabPanel value={value} index={0}>
                                <GeneralDetails user={user} />
                            </CustomTabPanel>

                            {/* ################ Change password ################# */}
                            <CustomTabPanel value={value} index={1}>
                                <ChangePassword />
                            </CustomTabPanel>
                        </Box>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};
export default Profile;
