import React from "react";
import ReportLayout from "../../layouts/ReportLayout";
import {
    CuTableHeader,
    CuTableRow,
    CuTableSN,
    CuTableColumn,
    CuTableHead,
    CuTableBody,
    CuReportTable,
} from "../../../components/Tables/ReportTableComp";
import { isEmpty } from "lodash";
import moment from "moment";
import ReportNoData from "../../../components/ReportNoData";

const ORGJobReport = ({ jobs }) => {
    return (
        <ReportLayout
            head={"Wazawa Max organization report"}
            orientation={"landscape"}
        >
            {isEmpty(jobs) ? (
                <>
                    <ReportNoData />
                </>
            ) : (
                <CuReportTable>
                    <CuTableHeader>
                        <CuTableRow>
                            <CuTableSN value={"S/N"} />
                            <CuTableHead
                                value={"Job-id"}
                                style={{
                                    width: "230px",
                                    textAlign: "center",
                                }}
                            />
                            <CuTableHead value={"Position"} />
                            <CuTableHead
                                value={"Posted"}
                                style={{
                                    width: "300px",
                                }}
                            />
                            <CuTableHead
                                value={"status"}
                                style={{
                                    width: "200px",
                                    textAlign: "center",
                                }}
                            />
                        </CuTableRow>
                    </CuTableHeader>
                    {/* Table body */}
                    <CuTableBody>
                        {jobs.map((job, index) => (
                            <CuTableRow key={index}>
                                <CuTableSN value={++index} />
                                <CuTableColumn
                                    value={job.job_number}
                                    style={{
                                        width: "230px",
                                        textAlign: "center",
                                    }}
                                />
                                <CuTableColumn value={job.title} />
                                <CuTableColumn
                                    value={moment(job.created_at).format("LL")}
                                    style={{
                                        width: "300px",
                                    }}
                                />
                                <CuTableColumn
                                    value={job.status}
                                    style={{
                                        width: "200px",
                                        whiteSpace: "nowrap",
                                        textAlign: "center",
                                        color:
                                            job.status === "draft"
                                                ? "brown"
                                                : job.status === "published"
                                                ? "green"
                                                : job.status === "suspended" ||
                                                  job.status === "expired"
                                                ? "red"
                                                : job.status === "closed"
                                                ? "brown"
                                                : "black",
                                    }}
                                />
                            </CuTableRow>
                        ))}
                    </CuTableBody>
                </CuReportTable>
            )}
        </ReportLayout>
    );
};

export default ORGJobReport;
