import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import NoContent from '../NoContent';
import { colors } from '../../assets/utils/constants';
import CustomCard from '../cards/CustomCard';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        'id': `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

// !################ MAIN FUNC ##################
const CustomTabs = ({ tabs, variant, height, noHeight, padding }) => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{}}>
            <CustomCard
                bodyPadding={padding ? padding : 2}
                height={height}
                borderBottom
                head={
                    <AppBar
                        position="sticky"
                        sx={{
                            'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            'position': 'sticky',
                            'top': 64,
                            'zIndex': 999,
                            '.MuiSvgIcon-root': {
                                color: colors.primary,
                            },
                            'boxShadow': `1px 1px 5px 0px ${colors.bgColor5}`,
                        }}
                        elevation={2}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="inherit"
                            variant={variant ? variant : 'standard'}
                        >
                            {tabs?.map((tab, index) =>
                                tab.displayNone ? (
                                    ''
                                ) : (
                                    <Tab
                                        key={index}
                                        label={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        fontSize: 16,
                                                        mr: 2,
                                                        color: colors.primary,
                                                        fontWeight: 'bold',
                                                    }}
                                                    component={'div'}
                                                >
                                                    {tab.icon}
                                                    <span>
                                                        &nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    {tab.title}
                                                </Typography>
                                                {tab.chipColor && (
                                                    <Chip
                                                        color={tab.chipColor}
                                                        label={tab.total}
                                                        size="small"
                                                    />
                                                )}
                                            </Box>
                                        }
                                        {...a11yProps(index)}
                                    />
                                ),
                            )}
                        </Tabs>
                    </AppBar>
                }
            >
                {tabs?.map((tab, index) => (
                    <TabPanel
                        key={index}
                        value={value}
                        index={index}
                        dir={theme.direction}
                    >
                        {tab.total === 0 ? (
                            <>
                                <NoContent
                                    message={
                                        tab.message
                                            ? tab.message
                                            : `No ${tab.title}`
                                    }
                                    height={noHeight ? noHeight : 350}
                                />
                            </>
                        ) : (
                            tab.tabsContent
                        )}
                    </TabPanel>
                ))}
            </CustomCard>
        </Box>
    );
};

export default CustomTabs;
