import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { sectionsEndpoints } from './settingEndpoints';
import { settingAPI } from './settingAPI';

export const sectionsAPI = createApi({
    reducerPath: 'sectionsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all
        getSections: builder.query({
            query: () => `${sectionsEndpoints.GET_SECTIONS}`,
        }),
        // => ADD
        addSection: builder.mutation({
            query: (payload) => ({
                url: `${sectionsEndpoints.ADD_SECTION}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
        // => UPDATE
        updateSection: builder.mutation({
            query: (payload) => ({
                url: `${sectionsEndpoints.UPDATE_SECTION}/` + payload.id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE
        activateSection: builder.mutation({
            query: (payload) => ({
                url: `${sectionsEndpoints.ACTIVATE_SECTION}/` + payload,
                method: `PUT`,
            }),
        }),
        // => DEACTIVATE
        deactivateSection: builder.mutation({
            query: (payload) => ({
                url: `${sectionsEndpoints.DEACTIVATE_SECTION}/` + payload,
                method: `PUT`,
            }),
        }),
        // => DELETE
        deleteSection: builder.mutation({
            query: (payload) => ({
                url: `${sectionsEndpoints.DELETE_SECTION}/` + payload,
                method: `DELETE`,
            }),
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(settingAPI.util.invalidateTags(['Settings']));
            },
        }),
    }),
});

export const {
    useGetSectionsQuery,
    useAddSectionMutation,
    useUpdateSectionMutation,
    useActivateSectionMutation,
    useDeactivateSectionMutation,
    useDeleteSectionMutation,
} = sectionsAPI;
