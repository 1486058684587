import { createSlice } from '@reduxjs/toolkit';
import { removeArrObj, updateArrObj } from '../../../helpers/arrayHelpers';

const initialState = {
    shops: [],
    shopDetails: {},
    planPackages: [],
};

// !########## SLICE #############
const shopsSlice = createSlice({
    name: 'shopsSlice',
    initialState,
    reducers: {
        // => Shops
        setShops: (state, actions) => {
            state.shops = actions.payload;
        },
        setShopDetails: (state, actions) => {
            state.shopDetails = actions.payload;
        },
        updateShop: (state, actions) => {
            let newShop = actions.payload;
            let shops = updateArrObj('id', newShop, state.shops);
            state.shops = shops;
        },

        // => Packages
        setShopPlanPackages: (state, actions) => {
            state.planPackages = actions.payload;
        },
        addShopPlanPackage: (state, actions) => {
            state.planPackages = actions.payload;
        },
        updateShopPlanPackage: (state, actions) => {
            let newShop = actions.payload;
            let planPackages = updateArrObj('id', newShop, state.planPackages);
            state.planPackages = planPackages;
        },
        removeShopPlanPackage: (state, actions) => {
            let planPackage = actions.payload;
            let planPackages = removeArrObj(
                'id',
                planPackage,
                state.planPackages,
            );
            state.planPackages = planPackages;
        },
    },
});

export const {
    setShops,
    updateShop,
    setShopDetails,
    setShopPlanPackages,
    addShopPlanPackage,
    updateShopPlanPackage,
    removeShopPlanPackage,
} = shopsSlice.actions;
export default shopsSlice.reducer;
