import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    transactions: [],
    transactionsOverview: [],
};

const transactionsSlice = createSlice({
    name: "transactionsSlice",
    initialState,
    reducers: {
        // => Set transactions
        setTransactions: (state, action) => {
            state.transactions = action.payload.transactions;
            state.transactionsOverview = action.payload.transactionsOverviews;
        },
    },
});

export const { setTransactions } = transactionsSlice.actions;
export default transactionsSlice.reducer;
