import React from "react";
import { GridOn, Subject } from "@mui/icons-material";
import { AppBar, Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { colors } from "../../../assets/utils/constants";
import { useTheme } from "@mui/material/styles";
// import JobTableCard from '../../../components/cards/JobTableCard';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageLoader from "../../../components/PageLoader";
import PageLayout from "../../layouts/PageLayout";
import { useGetSingleJobQuery } from "../../../api/job/jobAPI";
import useRTK from "../../../hooks/useRTK";
import { jobsSelector } from "../../../states/features/selectors";
import CustomCard from "../../../components/cards/CustomCard";
import { setJobDetails } from "../../../states/features/jobs/jobsSlice";
import { isEmpty } from "lodash";
import JobTags from "./JobTags";
import JobRequirement from "./JobRequirement";
import JobResponsibility from "./JobResponsibility";
import JobTopInfoWidget from "../../../components/widgets/JobTopInfoWidget";
import CustomCard4 from "../../../components/cards/CustomCard4";
import ORGJobDetailsSidebar from "../../../components/sidebars/ORGJobDetailsSidebar";
// import ORGJobDetailsSidebar from '../../../components/sidebars/ORGJobDetailsSidebar';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        height: "100%",
                        overflow: "auto",
                        borderRadius: 2.5,
                        borderBottom: `15px solid ${colors.bgColor5}`,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

// !######################### MAIL FUNC #####################
const ORGJobDetails = () => {
    // ################## CONST #####################
    const RTK = useRTK();
    const theme = useTheme();
    const { job_id } = useParams();
    const dispatch = useDispatch();

    // ################## Comp state ################
    const [value, setValue] = useState(0);

    // ################## Redux state ##############
    const { jobDetails } = useSelector(jobsSelector);
    const jobResponsibilities = jobDetails.responsibilities;
    const jobRequirements = jobDetails.requirements;
    const jobTags = jobDetails.tags ? JSON.parse(jobDetails.tags) : [];

    // ################## FUNC ################
    // -> Tab state
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // ############### RTK ####################
    const {
        isLoading,
        isSuccess,
        data: jobData,
    } = useGetSingleJobQuery(job_id);
    // => Response
    const rtkAction = (data) => {
        dispatch(setJobDetails(data));
    };
    RTK.useRTKFetch(isSuccess, jobData, rtkAction);

    return (
        <React.Fragment>
            <>
                {/* ##################### CONTENTS ################## */}
                {isLoading ? (
                    <PageLoader />
                ) : (
                    <PageLayout
                        head={"Job details"}
                        sidebar={
                            !isEmpty(jobDetails) && (
                                <ORGJobDetailsSidebar jobDetails={jobDetails} />
                            )
                        }
                    >
                        {/* ##################### TOP DETAILS ################## */}
                        {!isEmpty(jobDetails) && (
                            <JobTopInfoWidget jobDetails={jobDetails} />
                        )}

                        {/* ##################### MAIN CONTENT ################## */}
                        <CustomCard
                            head={
                                <AppBar
                                    position="static"
                                    sx={{
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        position: "sticky",
                                        top: 64,
                                        zIndex: 999,
                                        ".MuiSvgIcon-root": {
                                            color: colors.primary,
                                        },
                                        boxShadow: `1px 1px 5px 0px ${colors.bgColor5}`,
                                    }}
                                >
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Tab
                                            sx={{
                                                color: colors.primary,
                                            }}
                                            label={
                                                <Typography
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        fontSize: 13,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    <GridOn
                                                        sx={{
                                                            fontSize: 16,
                                                            mr: 1,
                                                        }}
                                                    />
                                                    Job details
                                                </Typography>
                                            }
                                            {...a11yProps(0)}
                                        />
                                    </Tabs>
                                </AppBar>
                            }
                        >
                            {/* ######################## JOB DETAILS #################### */}
                            <TabPanel
                                value={value}
                                index={0}
                                dir={theme.direction}
                            >
                                <Grid container spacing={2} padding={2}>
                                    {/* #################### DESCRIPTION #################### */}
                                    <Grid item sm={12}>
                                        <CustomCard4>
                                            <Box sx={{ p: 2 }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: 15,
                                                        fontWeight: "bold",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        pb: 0.5,
                                                        color: colors.warning,
                                                        opacity: 0.8,
                                                    }}
                                                >
                                                    <Subject
                                                        sx={{
                                                            mr: 1,
                                                            fontSize: 14,
                                                            color: colors.warning,
                                                        }}
                                                    />
                                                    Job description
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    {jobDetails.description}
                                                </Typography>
                                            </Box>
                                        </CustomCard4>
                                    </Grid>

                                    {/* #################### RESPONSIBILITY #################### */}
                                    <Grid item sm={12}>
                                        <CustomCard4>
                                            <JobResponsibility
                                                job_id={job_id}
                                                jobResponsibilities={
                                                    jobResponsibilities
                                                }
                                            />
                                        </CustomCard4>
                                    </Grid>

                                    {/* #################### REQUIREMENT #################### */}
                                    <Grid item sm={12}>
                                        <CustomCard4>
                                            <JobRequirement
                                                job_id={job_id}
                                                jobRequirements={
                                                    jobRequirements
                                                }
                                            />
                                        </CustomCard4>
                                    </Grid>

                                    {/* #################### TAGS #################### */}
                                    <Grid item sm={12}>
                                        <CustomCard4>
                                            <JobTags
                                                job_id={job_id}
                                                jobTags={jobTags}
                                            />
                                        </CustomCard4>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </CustomCard>
                    </PageLayout>
                )}
            </>
        </React.Fragment>
    );
};

export default ORGJobDetails;
