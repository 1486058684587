import React from "react";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { BlockOutlined, VerifiedOutlined } from "@mui/icons-material";
import useRTK from "../../../hooks/useRTK";
import { useDispatch } from "react-redux";
import {
    useActivateAdminMutation,
    useSuspendAdminMutation,
} from "../../../api/admins/adminsAPI";
import { updateAdminDetails } from "../../../states/features/admins/adminsSlice";

const ActivateSuspendAdmin = ({ admin }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        activateAdmin,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivateAdminMutation();
    const [
        suspendAdmin,
        {
            isLoading: suspendLoading,
            isSuccess: suspendSuccess,
            isError: suspendIsError,
            error: suspendError,
            data: suspendData,
        },
    ] = useSuspendAdminMutation();
    // ############# useEffect ##################
    const rtkActions = (data) => {
        dispatch(updateAdminDetails(data));
    };
    RTK.useRTKResponse(
        activateSuccess,
        activateIsError,
        activateError,
        activateData,
        rtkActions
    );
    RTK.useRTKResponse(
        suspendSuccess,
        suspendIsError,
        suspendError,
        suspendData,
        rtkActions
    );

    return (
        <>
            {admin.status === "active" ? (
                <CustomSubmitButton
                    startIcon={<BlockOutlined />}
                    btnColor={"warning"}
                    loading={suspendLoading}
                    onClick={() => {
                        suspendAdmin(admin.id);
                    }}
                    sx={{ py: 0.5, mb: 1.5 }}
                >
                    Suspend account
                </CustomSubmitButton>
            ) : (
                <CustomSubmitButton
                    startIcon={<VerifiedOutlined />}
                    btnColor={"success"}
                    loading={activateLoading}
                    onClick={() => {
                        activateAdmin(admin.id);
                    }}
                    sx={{ py: 0.5, mb: 1.5 }}
                >
                    Activate account
                </CustomSubmitButton>
            )}
        </>
    );
};

export default ActivateSuspendAdmin;
