import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { AppBar, Tabs, Tab, Box } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { Form, Formik } from 'formik';
import { Button, Grid, Typography } from '@mui/material';
import { Dangerous, DoneAll, PriceCheck } from '@mui/icons-material';
import CustomPhoneTextField from '../Forms/CustomPhoneTextField';
import * as Yup from 'yup';

// -> Payment method logo import
import mpesa from '../../assets/media/images/mpesa.png';
import tigopesa from '../../assets/media/images/tigopesa.png';
import airtel from '../../assets/media/images/airtel.png';
import halopesa from '../../assets/media/images/halopesa.png';
import azampesa from '../../assets/media/images/azampesa.png';
import { useDispatch, useSelector } from 'react-redux';
import {
    paymentMethodsSelector,
    promotionsSelector,
    subscriptionPackagesSelector,
} from '../../states/features/selectors';
import CheckOut from './CheckOut';
import { useState } from 'react';
import { phoneCODEVerify } from '../../helpers/phoneIDVerify';
import { useEffect } from 'react';
import { first, isEmpty } from 'lodash';
import CustomDialog from '../Dialogies/CustomDialog';
import PromotionSubPackageWidget from '../widgets/PromotionSubPackageWidget';
import CustomCard2 from '../cards/CustomCard2';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        p: 3,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    }}
                >
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        'id': `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

// ############### validationSchema
const phoneValidationSchema = Yup.object({
    phone_number: Yup.string()
        .required('Phone number is required field')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'Positions must be a number',
        )
        .max(10, 'Phone number must be at most 10 characters')
        .min(10, 'Phone number must be at least 10 characters'),
});

export default function MoblilePaymentOptions({
    promotion,
    handleDialogClose: handleDialogClose2,
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const [openDialog, setOpenDialogy] = React.useState(false);
    const [selectedMethod, setSelectedMethod] = React.useState('');
    const [selectedProvider, setSelectedProvider] = React.useState('');
    const [checkoutDetails, setCheckoutDetails] = useState({});
    const [phoneVerify, setPhoneVerify] = useState('');
    const [phoneNUmber, setPhoneNUmber] = useState('');
    const [mobilePaymentProviders, setMobilePaymentProviders] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // -> Dialogy open
    const handleDialogyOpen = () => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    // -> Handle selected method
    const handleSelectedMethod = (method, proider) => {
        setSelectedMethod(method);
        setSelectedProvider(proider);
    };

    // => Handle phone verify
    const handlephoneVerify = (value) => {
        let isVerified = phoneCODEVerify(selectedMethod, value);
        if (isVerified) {
            setPhoneVerify(true);
        } else {
            setPhoneVerify(false);
        }
        setPhoneNUmber(value);
    };
    // => setPaymentDetails
    const setPaymentDetails = () => {
        let paymentPayload = {
            paymentMethod: selectedMethod,
            paymentNumber: phoneNUmber,
        };
        // dispatch(addPaymentDetails(paymentPayload));
    };

    // ############ Redux state #################
    const { promoSubscriptionDetails } = useSelector(promotionsSelector);
    const { subscriptionPackages } = useSelector(subscriptionPackagesSelector);
    const currentSubscription = promotion?.currSubscription;

    const { paymentMethods } = useSelector(paymentMethodsSelector);
    const mobilePayments = [
        {
            id: 1,
            type: 'Mpesa',
            provider: 'Mpesa',
            tab: (
                <Tab
                    key={1}
                    label={
                        <Box>
                            <img
                                src={`${mpesa}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${mpesa}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={'m-pesa'}
                                loading="lazy"
                                style={{
                                    objectFit: 'contain',
                                    width: 120,
                                    borderRadius: 10,
                                }}
                            />
                        </Box>
                    }
                    {...a11yProps(0)}
                    onClick={() => {
                        setPhoneNUmber('');
                        setPhoneVerify(false);
                        handleSelectedMethod('Mpesa', 'Mpesa');
                    }}
                />
            ),
        },
        {
            id: 2,
            type: 'airtel',
            provider: 'Airtel',
            tab: (
                <Tab
                    key={2}
                    label={
                        <Box>
                            <img
                                src={`${airtel}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${airtel}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={'airtelmoney'}
                                loading="lazy"
                                style={{
                                    objectFit: 'contain',
                                    width: 120,
                                    borderRadius: 10,
                                }}
                            />
                        </Box>
                    }
                    {...a11yProps(0)}
                    onClick={() => {
                        setPhoneNUmber('');
                        setPhoneVerify('');
                        handleSelectedMethod('airtel', 'Airtel');
                    }}
                />
            ),
        },
        {
            id: 3,
            type: 'tigo',
            provider: 'Tigo',
            tab: (
                <Tab
                    key={3}
                    label={
                        <Box>
                            <img
                                src={`${tigopesa}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${tigopesa}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={'tigopesa'}
                                loading="lazy"
                                style={{
                                    objectFit: 'contain',
                                    width: 120,
                                    borderRadius: 10,
                                }}
                            />
                        </Box>
                    }
                    {...a11yProps(0)}
                    onClick={() => {
                        setPhoneNUmber('');
                        setPhoneVerify('');
                        handleSelectedMethod('tigo', 'Tigo');
                    }}
                />
            ),
        },
        {
            id: 4,
            type: 'halotel',
            provider: 'Halopesa',
            tab: (
                <Tab
                    key={4}
                    label={
                        <Box>
                            <img
                                src={`${halopesa}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${halopesa}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={'halopesa'}
                                loading="lazy"
                                style={{
                                    objectFit: 'contain',
                                    width: 120,
                                    borderRadius: 10,
                                }}
                            />
                        </Box>
                    }
                    {...a11yProps(0)}
                    onClick={() => {
                        setPhoneNUmber('');
                        setPhoneVerify('');
                        handleSelectedMethod('halotel', 'Halopesa');
                    }}
                />
            ),
        },
        {
            id: 5,
            type: 'azam',
            provider: 'Azampesa',
            tab: (
                <Tab
                    key={5}
                    label={
                        <Box>
                            <img
                                src={`${azampesa}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${azampesa}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={'azampesa'}
                                loading="lazy"
                                style={{
                                    objectFit: 'contain',
                                    width: 120,
                                    borderRadius: 10,
                                }}
                            />
                        </Box>
                    }
                    {...a11yProps(0)}
                    onClick={() => {
                        setPhoneNUmber('');
                        setPhoneVerify('');
                        handleSelectedMethod('azam', 'Azampesa');
                    }}
                />
            ),
        },
    ];

    // ############# FUNC ######################
    const getActiveMobilePayment = () => {
        const activePaymentMethods = mobilePayments.filter((payment) => {
            return paymentMethods.find(
                (method) => method.provider === payment.provider,
            );
        });
        if (!isEmpty(activePaymentMethods)) {
            setSelectedMethod(first(activePaymentMethods).type);
            setSelectedProvider(first(activePaymentMethods).provider);
        }
        return activePaymentMethods;
    };

    // ############## useEffect ##################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setMobilePaymentProviders(getActiveMobilePayment());
        }
        return () => {
            isSubscribed = false;
        };
    }, [paymentMethods]);

    return (
        <Box sx={{ bgcolor: colors.bgColor2 }}>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={'Make payment'}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
                noDropClose
            >
                <CheckOut
                    promotion={promotion}
                    checkoutDetails={checkoutDetails}
                    handleDialogClose={handleDialogClose}
                    handleDialogClose2={handleDialogClose2}
                />
            </CustomDialog>
            <AppBar
                position="static"
                sx={{
                    boxShadow: 'none',
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    py: 1,
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="none"
                    textColor="inherit"
                    sx={{
                        '.Mui-selected': {
                            'border': `3px solid ${colors.success}`,
                            'boxShadow': `1px 1px 5px 0px ${colors.success}`,
                            'position': 'relative',
                            ':before': {
                                content: "''",
                                position: 'absolute',
                                height: 10,
                                width: 30,
                                bgcolor: `${colors.success}`,
                                top: 0,
                                left: 0,
                                borderBottomRightRadius: 50,
                            },
                        },
                    }}
                >
                    {mobilePaymentProviders.map((method) => method.tab)}
                </Tabs>
            </AppBar>
            <Formik
                enableReinitialize
                initialValues={{
                    phone_number: phoneNUmber,
                }}
                onSubmit={(payload, { resetForm }) => {
                    let checkoutDetails = {
                        selectedMethod,
                        selectedProvider,
                        // jobPostingFee,
                        phone_number: payload.phone_number,
                    };
                    setPaymentDetails();
                    handleDialogyOpen();
                    setCheckoutDetails(checkoutDetails);
                }}
                validationSchema={phoneValidationSchema}
            >
                {(formik) => (
                    <Form>
                        <TabPanel value={0} index={0} dir={theme.direction}>
                            <Box sx={{}}>
                                <Grid
                                    container
                                    justifyContent={'center'}
                                    columnSpacing={4}
                                >
                                    <Grid item sm={5}>
                                        <Box sx={{ height: `100%` }}>
                                            <CustomCard2
                                                head={'Subscribed package'}
                                            >
                                                <PromotionSubPackageWidget
                                                    subPackage={
                                                        currentSubscription
                                                    }
                                                />
                                            </CustomCard2>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={7}>
                                        {phoneVerify ? (
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        pb: 1,
                                                        color: 'green',
                                                        opacity: 0.8,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    Valid{' '}
                                                    <Typography
                                                        component={'span'}
                                                        sx={{
                                                            textTransform:
                                                                'uppercase',
                                                            fontWeight: 'bold',
                                                            mx: 1,
                                                        }}
                                                    >
                                                        {selectedMethod}
                                                    </Typography>{' '}
                                                    number
                                                    <DoneAll
                                                        sx={{
                                                            ml: 1,
                                                            fontSize: 17,
                                                        }}
                                                    />
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        pb: 1,
                                                        color: 'red',
                                                        opacity: 0.8,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    Please provide a valid{' '}
                                                    <Typography
                                                        component={'span'}
                                                        sx={{
                                                            textTransform:
                                                                'uppercase',
                                                            fontWeight: 'bold',
                                                            mx: 1,
                                                        }}
                                                    >
                                                        {selectedMethod}
                                                    </Typography>{' '}
                                                    number{' '}
                                                    <Dangerous
                                                        sx={{
                                                            ml: 1,
                                                            fontSize: 17,
                                                        }}
                                                    />
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box
                                            sx={{
                                                position: 'relative',
                                            }}
                                        >
                                            <CustomPhoneTextField
                                                label="Phone number"
                                                name="phone_number"
                                                cuPadding={14}
                                                getInputValue={
                                                    handlephoneVerify
                                                }
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                textAlign: 'center',
                                                mt: 2,
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                disabled={
                                                    phoneVerify ? false : true
                                                }
                                                sx={{
                                                    py: 1.5,
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                    textTransform:
                                                        'uppercase !important',
                                                }}
                                                startIcon={<PriceCheck />}
                                                onClick={formik.handleSubmit}
                                            >
                                                Checkout
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </TabPanel>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}
