import React, { useEffect } from 'react';
import { ClearAll, Window } from '@mui/icons-material';
import {
    Box,
    List,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';
import CustomCard2 from '../cards/CustomCard2';
import ConfigurationFilter from '../filters/ConfigurationFilter';

const ConfigarationSidebar = ({ settings }) => {
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [filteredSettings, setFilteredSettings] = React.useState([]);

    const handleListItemClick = (index) => {
        setSelectedIndex(index);
    };

    // ============ settingsFilter ==============
    const settingsFilter = (query) => {
        let currentSettings = Object.values(settings);
        if (query) {
            currentSettings = currentSettings.filter((data) => {
                return data.name.toLowerCase().includes(query.toLowerCase());
            });
        }
        return setFilteredSettings(currentSettings);
    };

    // ============ setSettings =================
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setFilteredSettings(Object.values(settings));
        }
        return () => {
            isSubscribed = false;
        };
    }, [settings, setFilteredSettings]);

    return (
        <>
            <CustomCard2 head={'System configurations'} height={'69.5vh'}>
                <Box
                    sx={{
                        position: 'sticky',
                        top: -15,
                        zIndex: 50,
                        p: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        mt: -2,
                    }}
                >
                    <ConfigurationFilter handleFilter={settingsFilter} />
                </Box>
                <List component="nav" sx={{ py: 0 }}>
                    <NavLink to={'/site-settings'}>
                        {({ isActive }) => (
                            <ListItemButton
                                sx={{
                                    background: isActive
                                        ? `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`
                                        : `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                }}
                            >
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    <Window
                                                        sx={{
                                                            fontSize: 17,
                                                            color:
                                                                colors.warning,
                                                            mr: 1,
                                                        }}
                                                    />
                                                    All configuration
                                                </Typography>
                                            </Box>
                                        </Box>
                                    }
                                />
                            </ListItemButton>
                        )}
                    </NavLink>
                    {filteredSettings?.map((setting) => (
                        <NavLink to={setting.url} key={setting.name}>
                            {({ isActive }) => (
                                <ListItemButton
                                    selected={selectedIndex === setting.name}
                                    onClick={() =>
                                        handleListItemClick(setting.name)
                                    }
                                    sx={{
                                        background: isActive
                                            ? `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`
                                            : `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        <ClearAll
                                                            sx={{
                                                                fontSize: 17,
                                                                color:
                                                                    colors.warning,
                                                                mr: 1,
                                                            }}
                                                        />
                                                        {setting.name}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: colors.info,
                                                        }}
                                                    >
                                                        ({setting.total})
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                    />
                                </ListItemButton>
                            )}
                        </NavLink>
                    ))}
                </List>
            </CustomCard2>
        </>
    );
};

export default ConfigarationSidebar;
