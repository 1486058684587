import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    sections: [],
};

// !########## SLICE #############
const sectionsSlice = createSlice({
    name: 'sectionsSlice',
    initialState,
    reducers: {
        setSections: (state, actions) => {
            state.sections = actions.payload;
        },
        addSection: (state, actions) => {
            const section = actions.payload;
            isEmpty(state.sections)
                ? (state.sections = [section, ...[]])
                : (state.sections = [section, ...state.sections]);
        },
    },
});

export const { setSections, addSection } = sectionsSlice.actions;
export default sectionsSlice.reducer;
