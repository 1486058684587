// -> get all
const GET_ALL = '/blogs';

// -> get one
const GET_ONE = '/blogs';

// -> add status
const CREATE_BLOG = '/blogs';

// -> update status
const UPDATE_BLOG = '/blogs';

// -> activate status
const ACTIVATE_BLOG = '/blogs/publish';

// -> deactivate status
const DEACTIVATE_BLOG = '/blogs/unpublish';

// -> delete status
const DELETE_BLOG = '/blogs';

export const blogsEndpoints = {
    GET_ALL,
    GET_ONE,
    CREATE_BLOG,
    UPDATE_BLOG,
    ACTIVATE_BLOG,
    DEACTIVATE_BLOG,
    DELETE_BLOG,
};
