// => GET contacts
const GET_CONTACTS = `/help-center/contacts`;

// => UPDATE contacts
const UPDATE_CONTACTS = `/help-center/contacts`;

// => Get Social nets
const GET_SOCIAL_NET = `/help-center/social-networks`;

// => ADD social net
const ADD_SOCIAL_NET = `/help-center/social-networks`;

// => UPDATE social net
const UPDATE_SOCIAL_NET = `/help-center/social-networks`;

// => ACTIVATE social net
const ACTIVATE_SOCIAL_NET = `/help-center/social-networks/activate`;

// => ACTIVATE social net
const DEACTIVATE_SOCIAL_NET = `/help-center/social-networks/deactivate`;

export const helpCenterEndpoints = {
    GET_CONTACTS,
    UPDATE_CONTACTS,
    GET_SOCIAL_NET,
    ADD_SOCIAL_NET,
    UPDATE_SOCIAL_NET,
    ACTIVATE_SOCIAL_NET,
    DEACTIVATE_SOCIAL_NET,
};
