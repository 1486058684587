import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { orgBaseURL } from '../baseUrl/baseURL';
import { notificationsEndpoints } from './notificationsEndpoints';
import { headers } from '../baseUrl/setHeaders';

export const notificationsAPI = createApi({
    reducerPath: 'notificationsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: orgBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Notifications', 'Notification'],
    endpoints: (builder) => ({
        // => GET all
        getAllNotifications: builder.query({
            query: () => `${notificationsEndpoints.GET_ALL}`,
            providesTags: ['Notifications'],
        }),

        // => GET single
        getSingleNotification: builder.query({
            query: (payload) =>
                `${notificationsEndpoints.GET_SINGLE}/${payload}`,
            providesTags: ['Notification'],
            invalidatesTags: (arg, error) => (error ? [] : ['Notifications']),
        }),

        // => Mark all read
        markAllReady: builder.mutation({
            query: () => ({
                url: `${notificationsEndpoints.MARK_ALL_READ}`,
                method: `PUT`,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ['Notifications']),
        }),

        // => Delete single
        deleteSingleNotification: builder.mutation({
            query: (payload) => ({
                url: `${notificationsEndpoints.DELETE_SINGLE}/${payload}`,
                method: `DELETE`,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ['Notifications']),
        }),

        // => Delete all
        deleteAllNotifications: builder.mutation({
            query: () => ({
                url: `${notificationsEndpoints.DELETE_ALL}`,
                method: `DELETE`,
            }),
            invalidatesTags: (arg, error) => (error ? [] : ['Notifications']),
        }),
    }),
});

export const {
    useGetAllNotificationsQuery,
    useGetSingleNotificationQuery,
    useMarkAllReadyMutation,
    useDeleteSingleNotificationMutation,
    useDeleteAllNotificationsMutation,

    // => Lazy
    useLazyGetAllNotificationsQuery,
} = notificationsAPI;
