import { Box, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { Search } from '@mui/icons-material';
import { colors } from '../../assets/utils/constants';

const CustomTextFieldFilter = ({ width, label, handleFilter }) => {
    return (
        <>
            <Box
                sx={{
                    borderRadius: 2,
                    border: `2px solid ${colors.bgColor2}`,
                    width: width ? width : 250,
                    display: {
                        xs: 'none',
                        sm: 'block',
                    },
                }}
            >
                <TextField
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    size="small"
                    placeholder={label}
                    onKeyUp={(e) => handleFilter(e.target.value)}
                    sx={{
                        '& input': {
                            fontSize: 14,
                        },
                        '& .MuiInputBase-root': {
                            height: 35,
                        },
                    }}
                />
            </Box>
        </>
    );
};

export default CustomTextFieldFilter;
