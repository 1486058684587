import React from 'react';
import { colors } from '../../../assets/utils/constants';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
    Avatar,
    Box,
    Divider,
    Grid,
    Icon,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    styled,
    Typography,
} from '@mui/material';

import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ImageIcon from '@mui/icons-material/Image';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { isEmpty } from 'lodash';

// -> custom MUI
const LogoCard = styled(Grid)(({ theme }) => ({
    'position': 'relative',
    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
    'justifyContent': 'space-around',
    'alignItems': 'center',
    '& .logo': {
        height: '6.5em',
        width: '100%',
        borderRadius: 15,
        border: `4px solid ${colors.secondary}`,
        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
        objectFit: 'contain',
    },
    '& .logo-title': {
        textAlign: 'center',
        fontWeight: 'bold',
    },
}));

const InfoListCard = styled(List)({
    'marginTop': 40,
    'padding': 0,
    'overflow': 'hidden',
    '& .MuiListItemButton-root': {
        'paddingLeft': 20,
        'paddingRight': 20,
        'background': colors.secondary,
        '& span': {
            color: colors.primary,
        },
    },
    '& .list-item': {
        'background': colors.secondary,
        'color': colors.primary,
        'margin': 0,
        'padding': 0,
        ' p': {
            color: colors.primary,
        },
        ' .icon': {
            color: colors.primary,
            fontSize: 16,
        },
        ' .action-icon': {
            fontSize: 25,
        },
        ' .avatar': {
            background: colors.gray,
        },
    },
});

const LeftBar = ({ organization }) => {
    return (
        <React.Fragment>
            <Grid
                item
                md={12}
                sm={12}
                sx={{
                    background: colors.secondary,
                    position: 'relative',
                    pb: 5,
                    height: '100%'
                }}
            >
                <Box
                    sx={{
                        background: colors.secondary,
                        position: 'sticky',
                        top: 64,
                    }}
                >
                    {/* ####################### LOGO ################### */}
                    <LogoCard
                        container
                        justifyContent={'space-between'}
                        sx={{
                            padding: 2,
                            paddingBottom: 6,
                        }}
                    >
                        <Grid
                            item
                            sm={6}
                            sx={{
                                background: colors.primary,
                                height: 100,
                                borderRadius: 5,
                            }}
                        >
                            {!isEmpty(organization.org_logo) ? (
                                <>
                                    <img
                                        className="logo"
                                        src={organization.org_logo}
                                        alt="dp"
                                    />
                                </>
                            ) : (
                                <>
                                    <Typography sx={{ textAlign: 'center' }}>
                                        <ImageIcon
                                            sx={{
                                                fontSize: 100,
                                                color: colors.bgColor5,
                                            }}
                                        />
                                    </Typography>
                                </>
                            )}

                            <Typography className="logo-title">
                                {organization.org_type} logo
                            </Typography>
                        </Grid>
                    </LogoCard>

                    {/* ####################### ORG ID ################### */}
                    <Grid
                        container
                        justifyContent={'center'}
                        sx={{ background: colors.secondary }}
                    >
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                    marginTop: 2,
                                }}
                            >
                                {organization.login_id}
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}
                            >
                                Registration ID
                            </Typography>
                            <Divider />
                        </Grid>
                        {/* ####################### PROFILE COMPLETION ################### */}
                        <Grid
                            item
                            md={10}
                            sm={0}
                            xs={10}
                            sx={{ position: 'relative' }}
                        >
                            <Typography
                                sx={{
                                    textAlign: 'left',
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    marginTop: 2,
                                }}
                            >
                                Profile completion
                            </Typography>
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 20,
                                    fontWeight: 'bold',
                                }}
                            >
                                {organization.percentage_complete}%
                            </Typography>
                            <Typography
                                component={'div'}
                                sx={{
                                    width: '100%',
                                    height: 20,
                                    background: colors.primary,
                                    borderRadius: 1,
                                }}
                            >
                                <Typography
                                    sx={{
                                        width: `${organization.percentage_complete}%`,
                                        height: 20,
                                        background: 'green',
                                        borderRadius: 1,
                                    }}
                                ></Typography>
                            </Typography>
                        </Grid>
                        {/* ####################### CONTACT INFO ################### */}
                        <Grid item md={11} sm={11} xs={11}>
                            <InfoListCard
                                sx={{
                                    width: '100%',
                                }}
                            >
                                <Box
                                    component="h1"
                                    sx={{
                                        cursor: 'default',
                                        m: 0,
                                        p: 1,
                                    }}
                                >
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary="Contact infos"
                                        primaryTypographyProps={{
                                            fontSize: 17,
                                            letterSpacing: 0,
                                            fontWeight: 'bold',
                                        }}
                                    />
                                </Box>
                                <Divider />
                                {/* #### LOCATION ### */}
                                <ListItem className="list-item">
                                    <ListItemAvatar>
                                        <Avatar className="avatar">
                                            <LocationOnIcon className="icon" />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        secondaryTypographyProps={{
                                            fontWeight: 'bold',
                                        }}
                                        primary={`${organization.location}`}
                                        secondary="Location"
                                    />
                                </ListItem>
                                {/* #### Email ### */}
                                <ListItem
                                    className="list-item"
                                    secondaryAction={
                                        <Icon edge="end" aria-label="edit">
                                            {!isEmpty(
                                                organization.email_verified_at,
                                            ) ? (
                                                <>
                                                    <VerifiedIcon
                                                        color="success"
                                                        className="action-icon"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <HighlightOffIcon
                                                        color="error"
                                                        className="action-icon"
                                                    />
                                                </>
                                            )}
                                        </Icon>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar className="avatar">
                                            <MailIcon className="icon" />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        secondaryTypographyProps={{
                                            fontWeight: 'bold',
                                        }}
                                        primary={`${organization.email}`}
                                        secondary="email"
                                    />
                                </ListItem>
                                {/* #### Phone ### */}
                                <ListItem
                                    className="list-item"
                                    secondaryAction={
                                        <Icon edge="end" aria-label="edit">
                                            {!isEmpty(
                                                organization.email_verified_at,
                                            ) ? (
                                                <>
                                                    <VerifiedIcon
                                                        color="success"
                                                        className="action-icon"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <HighlightOffIcon
                                                        color="error"
                                                        className="action-icon"
                                                    />
                                                </>
                                            )}
                                        </Icon>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar className="avatar">
                                            <LocalPhoneIcon className="icon" />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        secondaryTypographyProps={{
                                            fontWeight: 'bold',
                                        }}
                                        primary={`${
                                            organization.phone_number
                                                ? organization.phone_number
                                                : 'No phone details'
                                        }`}
                                        secondary="Phone"
                                    />
                                </ListItem>
                            </InfoListCard>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </React.Fragment>
    );
};

export default LeftBar;
