import React from 'react';
import { colors } from '../../assets/utils/constants';
import { Box, Chip, Typography, styled } from '@mui/material';

const NotCard = styled(Box)({
    'border': `1px solid ${colors.bgColor}`,
    'overflow': 'hidden',
    'position': 'relative',
    'marginBottom': 2.5,
    'marginTop': 2.5,
    'transition': `.3s all ease-in-out`,
    ':hover': {
        borderBottom: `1px solid ${colors.primary} !important`,
        transition: `.3s all ease-in-out`,
    },
});

const NotHeader = styled(Box)({
    'paddingLeft': 15,
    'paddingRight': 10,
    'paddingTop': 10,
    '.head': {
        fontSize: 13,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    '.body': {
        fontSize: 14,
        color: 'gray',
        padding: 10,
    },
});

const NotFooter = styled(Box)({
    'height': 25,
    'display': 'flex',
    'justifyContent': 'space-between',
    'paddingLeft': 20,
    'paddingRight': 20,
    'alignItems': 'center',
    'opacity': 0.8,
    '.text': {
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.primary,
        opacity: 0.8,
    },
});

const NotificationWidget2 = ({ notification }) => {
    return (
        <React.Fragment>
            <NotCard
                sx={{
                    borderRadius: 2,
                    border: `1px solid ${colors.bgColor5}`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                }}
            >
                {/* ####################### HEAD AND BODY ######################### */}
                <NotHeader>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Chip
                            variant="outlined"
                            color={
                                notification.notification_type ===
                                'Verification approve'
                                    ? 'info'
                                    : 'error'
                            }
                            label={notification.title}
                            className="head"
                            size="small"
                        />
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: colors.danger,
                                px: 1,
                                borderRadius: 2,
                                border: `1px dotted ${colors.danger}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            }}
                        >
                            New
                        </Typography>
                    </Box>

                    {/* ########################## BODY ########################### */}
                    <Typography className="body" noWrap>
                        {notification.body}
                    </Typography>
                </NotHeader>
                {/* ########################## FOOTER ########################### */}
                <NotFooter>
                    <Typography className="text">
                        From: {notification.from}
                    </Typography>
                    <Typography className="text">
                        {notification.date}
                    </Typography>
                </NotFooter>
            </NotCard>
        </React.Fragment>
    );
};

export default NotificationWidget2;
