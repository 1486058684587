import { createTheme } from '@mui/material';

export const colors = {
    primary: '#17233C',
    secondary: '#B2DAC8',
    white: '#fff',
    gray: '#eee',
    linkHover: '#4a4aec',
    bgColor: '#F8F9FC',
    bgColor1: '#F8F9FC',
    bgColor2: '#E9EDF7',
    bgColor3: '#DAE2F1',
    bgColor4: '#cbd6eb',
    bgColor5: '#bdcae6',
    warning: '#EB6B02',
    success: '#2E7D32',
    info: '#2e77ae',
    danger: '#ff8f8f',
};

export const borderRadius = {
    br1: 2.5,
    br2: 5,
};

export const primaryTheme = createTheme({
    palette: {
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: colors.secondary,
        },
    },
});
