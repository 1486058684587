import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initialState = {
    organizationCategories: [],
};

// !########## SLICE #############
const organizationCategoriesSlice = createSlice({
    name: 'organizationCategoriesSlice',
    initialState,
    reducers: {
        setOrganizationCategories: (state, actions) => {
            state.organizationCategories = actions.payload;
        },
        addOrganizationCategory: (state, actions) => {
            const organization_category = actions.payload;
            isEmpty(state.organizationCategories)
                ? (state.organizationCategories = [
                      organization_category,
                      ...[],
                  ])
                : (state.organizationCategories = [
                      organization_category,
                      ...state.organizationCategories,
                  ]);
        },
    },
});

export const {
    setOrganizationCategories,
    addOrganizationCategory,
} = organizationCategoriesSlice.actions;
export default organizationCategoriesSlice.reducer;
