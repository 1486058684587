import React from "react";
import PageLayout from "../layouts/PageLayout";
import CustomDialog from "../../components/Dialogies/CustomDialog";
import { adminsSelector } from "../../states/features/selectors";
import { useSelector } from "react-redux";
import { Box, Button, Grid, Typography } from "@mui/material";
import Infobox1 from "../../components/infoboxes/InfoBox1";
import { filter, isEmpty, size } from "lodash";
import { PersonAddAlt1Outlined } from "@mui/icons-material";
import { colors } from "../../assets/utils/constants";
import AdminWidget from "../../components/widgets/AdminWidget";
import NoContent from "../../components/NoContent";
import CountBadge from "../../components/CountBadge";
import CustomCard2 from "../../components/cards/CustomCard2";
import AddAdmin from "./CRUD/AddAdmin";

const Admins = () => {
    // ################# Comp state ###################
    const [open, setOpen] = React.useState(false);

    // ################ Redux state ##################
    const { admins } = useSelector(adminsSelector);

    // ################# FUNC ###################
    // => handle dialogy open
    const handleClickOpen = () => {
        setOpen(true);
    };
    // => handle dialogy close
    const handleClose = () => {
        setOpen(false);
    };

    const infoBoxData = [
        {
            id: 2,
            title1: "Active",
            title2: "admins",
            total: size(filter(admins, ({ status }) => status === "active")),
            url: "/admins/status/active",
        },
        {
            id: 3,
            title1: "In-active",
            title2: "admins",
            total: size(filter(admins, ({ status }) => status === "in-active")),
            url: "/admins/status/in-active",
        },
        {
            id: 1,
            title1: "Suspended",
            title2: "suspended",
            total: size(filter(admins, ({ status }) => status === "suspended")),
            url: "/admins/status/suspended",
        },
    ];

    return (
        <PageLayout head={"Wazawa Max system admins"} noSidebar>
            {/* ################## Update profile Dialogy ################ */}
            <CustomDialog
                openDialog={open}
                handleDialogClose={handleClose}
                head={"Add system admin"}
            >
                <AddAdmin handleClose={handleClose} />
            </CustomDialog>

            <Grid container spacing={2}>
                {infoBoxData.map((data, index) => (
                    <Grid item xs key={index}>
                        <Infobox1 data={data} height={80} />
                    </Grid>
                ))}
                <Grid item xs>
                    <Button
                        fullWidth
                        sx={{
                            height: 80,
                            border: `2px dotted ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                            borderRadius: `50px !important`,
                        }}
                        onClick={() => handleClickOpen()}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <PersonAddAlt1Outlined
                                color="info"
                                sx={{
                                    p: 1,
                                    fontSize: 35,
                                    borderRadius: 50,
                                    border: `2px dotted ${colors.info}`,
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                            >
                                Add system admin
                            </Typography>
                        </Box>
                    </Button>
                </Grid>
                <Grid item sm={12}>
                    <CustomCard2
                        height={"57vh"}
                        head={"All system admins"}
                        borderBottom
                        action={
                            <CountBadge item={"Admins"} total={size(admins)} />
                        }
                    >
                        {isEmpty(admins) ? (
                            <>
                                <NoContent message={"No system admin added"} />
                            </>
                        ) : (
                            <>
                                <Grid container spacing={2}>
                                    {admins.map((admin) => (
                                        <Grid item sm={4} key={admin.id}>
                                            <AdminWidget admin={admin} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        )}
                    </CustomCard2>
                </Grid>
            </Grid>
        </PageLayout>
    );
};

export default Admins;
