import React from 'react';
import {  Chip, Grid, styled, Typography } from '@mui/material';
import banner from '../../../assets/media/images/banner.jpg';
import { colors } from '../../../assets/utils/constants';
import { Pending, Verified } from '@mui/icons-material';
import { Box } from '@mui/system';

// -> custom MUI
const BannerCard = styled(Grid)(({ theme }) => ({
    'display': 'flex',
    'position': 'relative',
    'background': colors.primary,
    'height': 150,
    'left': 0,
    'right': 0,
    'flexDirection': 'column',
    'justifyContent': 'center',
    'backgroundRepeat': 'no-repeat',
    'backgroundAttachment': 'fixed',
    'backgroundSize': `100% 100vh`,
    '& .name': {
        textAlign: 'left',
        fontSize: 30,
        fontWeight: 'bold',
        color: colors.bgColor1,
    },
    '& .short-name': {
        textAlign: 'left',
        fontSize: 20,
        fontWeight: 'bold',
        color: colors.bgColor1,
    },
    '& .edit-name-btn': {
        fontSize: 12,
        color: colors.gray,
        border: '1px solid #eee',
        paddingTop: 2,
        paddingBottom: 2,
        marginTop: 4,
    },
    [theme.breakpoints.down('md')]: {
        height: 255,
        paddingLeft: 20,
        paddingRight: 20,
    },
}));

function Banner({ organization }) {
    return (
        <React.Fragment>
            <BannerCard
                item
                sm={12}
                xs={12}
                sx={{
                    backgroundImage: `${
                        organization.org_banner
                            ? `url(${organization.org_banner})`
                            : `url(${banner})`
                    }`,
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        px: 5,
                        background: `linear-gradient(360deg, rgba(23, 35, 60, .4), rgba(23, 35, 60, .4))`,
                    }}
                >
                    <Grid
                        container
                        justifyContent={'space-between'}
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Grid item md={5} sm={12} xs={12}>
                            <Typography className="name">
                                {organization.organization_name}
                            </Typography>
                            <Typography className="short-name">
                                ({' '}
                                {organization.org_short_name
                                    ? organization.org_short_name
                                    : 'short name'}{' '}
                                )
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            md={4}
                            sm={12}
                            xs={12}
                            sx={{ position: 'relative' }}
                        >
                            <Box
                                sx={{
                                    minWidth: '100%',
                                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
                                    borderRadius: 4,
                                    p: 1,
                                }}
                            >
                                <Grid container>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Box
                                            sx={{
                                                padding: 0.6,
                                                paddingLeft: 1.2,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: colors.primary,
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                Organization type
                                            </Typography>
                                            <Chip
                                                size="small"
                                                color="info"
                                                label={
                                                    organization
                                                        .organization_type.name
                                                }
                                                sx={{
                                                    fontSize: 10,
                                                    fontWeight: 'bold',
                                                    width: 100,
                                                    color: colors.primary,
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                padding: 0.6,
                                                paddingLeft: 1.2,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: colors.primary,
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                Organization category
                                            </Typography>
                                            <Chip
                                                size="small"
                                                color="info"
                                                label={
                                                    organization
                                                        .organization_category
                                                        .name
                                                }
                                                sx={{
                                                    fontSize: 10,
                                                    fontWeight: 'bold',
                                                    width: 100,
                                                    color: colors.primary,
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                padding: 0.6,
                                                paddingLeft: 1.2,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: colors.primary,
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                Verification status
                                            </Typography>
                                            <Chip
                                                icon={
                                                    organization.is_verified ===
                                                    'verified' ? (
                                                        <Verified />
                                                    ) : (
                                                        <Pending />
                                                    )
                                                }
                                                size="small"
                                                color={
                                                    organization.is_verified ===
                                                    'pending'
                                                        ? 'warning'
                                                        : organization.is_verified ===
                                                          'requested'
                                                        ? 'info'
                                                        : organization.is_verified ===
                                                          'on_progress'
                                                        ? 'info'
                                                        : organization.is_verified ===
                                                          'denied'
                                                        ? 'error'
                                                        : 'success'
                                                }
                                                label={
                                                    organization.is_verified ===
                                                    'pending'
                                                        ? 'Pending'
                                                        : organization.is_verified ===
                                                          'requested'
                                                        ? 'Requested'
                                                        : organization.is_verified ===
                                                          'on_progress'
                                                        ? 'On-progress'
                                                        : organization.is_verified ===
                                                          'denied'
                                                        ? 'Denied'
                                                        : 'Verified'
                                                }
                                                sx={{
                                                    fontSize: 10,
                                                    fontWeight: 'bold',
                                                    width: 100,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </BannerCard>
        </React.Fragment>
    );
}

export default Banner;
