import { LocationOn } from '@mui/icons-material';
import {
    Avatar,
    Box,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { filter, size } from 'lodash';

const PromotionCustomerWidget = ({ isActive, customer }) => {
    return (
        <>
            <ListItemButton
                sx={{
                    'borderRadius': 2,
                    'p': 0,
                    'pl': 0.5,
                    'py': 0.5,
                    'borderBottom': `2px solid ${colors.bgColor3}`,
                    'mb': 1,
                    'border': `2px solid ${colors.bgColor4}`,
                    'transition': '.3s all ease-in-out',
                    'background': isActive
                        ? `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`
                        : `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    '& .dp': {
                        transition: '.3s all ease-in-out',
                    },
                    ':hover': {
                        '& .dp': {
                            transition: '.3s all ease-in-out',
                            border: `2px solid ${colors.secondary}`,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                        },
                        'transition': '.3s all ease-in-out',
                        'border': `2px solid ${colors.secondary}`,
                    },
                }}
            >
                <ListItem
                    sx={{
                        p: 0,
                        position: 'relative',
                    }}
                >
                    <ListItemAvatar>
                        <Avatar
                            src={customer.logo_url}
                            className="dp"
                            sx={{
                                height: 50,
                                width: 50,
                                border: `2px solid ${colors.bgColor5}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    color: colors.primary,
                                    opacity: 0.8,
                                }}
                            >
                                {customer.name}
                            </Typography>
                        }
                        secondary={
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    display: 'flex',
                                    alignItems: 'center',
                                    opacity: 0.8,
                                }}
                            >
                                <LocationOn
                                    color="secondary"
                                    sx={{ fontSize: 14 }}
                                />{' '}
                                Tanga, Tanzania
                            </Typography>
                        }
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            width: 20,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 1,
                                mb: 0.2,
                                background: `linear-gradient(180deg, ${colors.success}, ${colors.success})`,
                                opacity: 0.9,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    color: colors.white,
                                    fontWeight: 'bold',
                                }}
                            >
                                {size(
                                    filter(
                                        customer.promotions,
                                        ({ status }) => status === 'active',
                                    ),
                                )}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                mt: 0.2,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: colors.white,
                                borderRadius: 1,
                                background: `linear-gradient(180deg, ${colors.warning}, ${colors.warning})`,
                                opacity: 0.9,
                            }}
                        >
                            <Typography
                                sx={{ fontSize: 12, fontWeight: 'bold' }}
                            >
                                {size(
                                    filter(
                                        customer.promotions,
                                        ({ status }) => status === 'inactive',
                                    ),
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </ListItem>
            </ListItemButton>
        </>
    );
};

export default PromotionCustomerWidget;
