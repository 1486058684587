import React, { useState } from 'react';
import {
    Groups2Outlined,
    AllInclusive,
    Lock,
    LockOpen,
} from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import CustomCard2 from '../cards/CustomCard2';
import CustomSubmitButton from '../Forms/CustomSubmitButton';
import OrganizationFieldWidget from '../widgets/OrganizationFieldWidget';
import CustomCard from '../cards/CustomCard';
import CustomDialog from '../Dialogies/CustomDialog';
import { useSelector } from 'react-redux';
import { practicalTrainingsSelector } from '../../states/features/selectors';
import ContentLoader from '../ContentLoader';
import { filter, isEmpty, size } from 'lodash';
import SuspendTraining from '../../pages/practicalTrainings/CRUD/SuspendTraining';
import ResumeTraining from '../../pages/practicalTrainings/CRUD/ResumeTraining';

const PracticalTrainingDetailsSidebar = ({ training, applicantIsLoading }) => {
    // ############### CONST ###################
    const organization = training.organization;

    // ############### Comp State ##################
    const [openDialog, setOpenDialogy] = useState(false);
    const [actionType, setActionType] = useState('');

    // ############### Redux state #################
    const { practicalTrainingApplicants } = useSelector(
        practicalTrainingsSelector,
    );

    // ############### FUNC ##################
    // -> Dialogy closer
    const handleDialogOpen = (actionType) => {
        setOpenDialogy(true);
        setActionType(actionType);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            <CustomDialog
                head={`${actionType} practical training`}
                openDialog={openDialog}
                customWidth={actionType === 'update' ? 'lg' : 'sm'}
                handleDialogClose={handleDialogClose}
            >
                {actionType === 'Suspend' ? (
                    <>
                        <SuspendTraining
                            training={training}
                            handleClose={handleDialogClose}
                        />
                    </>
                ) : actionType === 'Resume' ? (
                    <>
                        <ResumeTraining
                            training={training}
                            handleClose={handleDialogClose}
                        />
                    </>
                ) : (
                    ''
                )}
            </CustomDialog>
            <Grid container mb={2} spacing={3}>
                <Grid item sm={12}>
                    <CustomCard head={'Posted by'}>
                        <OrganizationFieldWidget organization={organization} />
                    </CustomCard>
                </Grid>
                <Grid item sm={6}>
                    <Box
                        className={
                            training.status === 'draft'
                                ? 'warning-box'
                                : training.status === 'published'
                                ? 'success-box'
                                : training.status === 'closed'
                                ? 'warning-box'
                                : training.status === 'expired'
                                ? ''
                                : training.status === 'suspended'
                                ? 'error-box'
                                : ''
                        }
                        sx={{
                            borderRadius: 2,
                            flexDirection: 'column',
                            p: 0.4,
                            boxShadow: '3px 3px 3px 3px rgba(46, 119, 174,0.2)',
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                p: 0.5,
                            }}
                        >
                            status
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                p: 0.5,
                                textTransform: 'capitalize',
                            }}
                        >
                            {training.status}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    <Box
                        className={
                            training.type === 'private'
                                ? 'success-box'
                                : training.type === 'public'
                                ? 'info-box'
                                : ''
                        }
                        sx={{
                            borderRadius: 2,
                            flexDirection: 'column',
                            p: 0.4,
                            boxShadow: '3px 3px 3px 3px rgba(46, 119, 174,0.2)',
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                p: 0.5,
                            }}
                        >
                            Type
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                p: 0.5,
                                textTransform: 'capitalize',
                            }}
                        >
                            {training.type}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <Box
                        sx={{
                            pt: 1.5,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            textAlign: 'center',
                            height: 120,
                            position: 'relative',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                pb: 1,
                                borderBottom: `1px solid ${colors.bgColor5}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                color: colors.info,
                            }}
                        >
                            Overall training applicants
                        </Typography>
                        {applicantIsLoading ? (
                            <ContentLoader height={160} />
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    p: 1.5,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        mr: 1,
                                        color: colors.warning,
                                    }}
                                >
                                    <strong>
                                        <small>Applied</small>
                                    </strong>
                                    <Typography>
                                        {size(practicalTrainingApplicants)}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        mr: 1,
                                        color: colors.success,
                                    }}
                                >
                                    <strong>
                                        <small>Approved</small>
                                    </strong>
                                    <Typography>
                                        {size(
                                            filter(
                                                practicalTrainingApplicants,
                                                ({ appliedTraining }) =>
                                                    appliedTraining?.status ===
                                                    'approved',
                                            ),
                                        )}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        mr: 1,
                                        color: colors.info,
                                    }}
                                >
                                    <strong>
                                        <small>Available</small>
                                    </strong>
                                    {!isEmpty(training.positions) ||
                                    training.positions > 0 ? (
                                        <Typography>
                                            {training.positions -
                                                size(
                                                    filter(
                                                        practicalTrainingApplicants,
                                                        ({ appliedTraining }) =>
                                                            appliedTraining?.status ===
                                                            'approved',
                                                    ),
                                                )}
                                        </Typography>
                                    ) : (
                                        <AllInclusive />
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <CustomCard2 head={'Action buttons'}>
                <Grid container>
                    {training.status === 'suspended' ? (
                        <Grid item sm={12}>
                            <CustomSubmitButton
                                startIcon={<LockOpen />}
                                btnColor="info"
                                sx={{ py: 2 }}
                                onClick={() => {
                                    let actionType = 'Resume';
                                    handleDialogOpen(actionType);
                                }}
                            >
                                Resume practical training
                            </CustomSubmitButton>
                        </Grid>
                    ) : (
                        <Grid item sm={12}>
                            <CustomSubmitButton
                                startIcon={<Lock />}
                                btnColor="warning"
                                sx={{ py: 2 }}
                                onClick={() => {
                                    let actionType = 'Suspend';
                                    handleDialogOpen(actionType);
                                }}
                            >
                                Suspend practical training
                            </CustomSubmitButton>
                        </Grid>
                    )}
                </Grid>
            </CustomCard2>
        </>
    );
};

export default PracticalTrainingDetailsSidebar;
