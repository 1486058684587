import { createSlice } from '@reduxjs/toolkit';
import { updateArrObj } from '../../../helpers/arrayHelpers';

const initialState = {
    practicalTrainings: [],
    practicalTrainingDetails: {},
    practicalTrainingApplicants: [],
};
const practicalTrainingsSlice = createSlice({
    name: 'practicalTrainingsSlice',
    initialState,
    reducers: {
        // => Set practical trainings
        setPracticalTrainings: (state, action) => {
            let practicalTrainings = action.payload;
            state.practicalTrainings = practicalTrainings;
        },

        // => Set practical training details
        setPracticalTrainingDetails: (state, action) => {
            let details = action.payload;
            state.practicalTrainingDetails = details;
        },

        // => Set practical training applicants
        setPracticalTrainingApplicants: (state, action) => {
            let applicants = action.payload;
            state.practicalTrainingApplicants = applicants;
        },

        // => Update practical training details
        updatePracticalTraining: (state, actions) => {
            let newTraining = actions.payload;
            let practicalTrainings = updateArrObj(
                'id',
                newTraining,
                state.practicalTrainings,
            );
            state.practicalTrainingDetails = actions.payload;
            state.practicalTrainings = practicalTrainings;
        },
    },
});

export const {
    setPracticalTrainings,
    setPracticalTrainingDetails,
    setPracticalTrainingApplicants,
    updatePracticalTraining,
} = practicalTrainingsSlice.actions;
export default practicalTrainingsSlice.reducer;
