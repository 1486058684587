import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authAPI } from "./authAPI";
import { headers } from "../baseUrl/setHeaders";
import { authEndpoints } from "./authEndpoints";
import { adminBaseURL } from "../baseUrl/baseURL";

export const profileAPI = createApi({
    reducerPath: "profileAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        getProfile: builder.query({
            query: () => `${authEndpoints.GET_PROFILE}`,
        }),
        updateProfile: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.UPDATE_PROFILE}`,
                method: `PUT`,
                body: payload,
            }),
        }),
        updateProfilePicture: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.UPDATE_PROFILE_PICTURE}`,
                method: `POST`,
                body: payload,
            }),
        }),
        changePassword: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.CHANGE_PASSWORD}`,
                method: `PUT`,
                body: payload,
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: `${authEndpoints.LOGOUT}`,
                method: "POST",
            }),
        }),
    }),
});

export const {
    useLogoutMutation,
    useLazyGetProfileQuery,
    useUpdateProfileMutation,
    useUpdateProfilePictureMutation,
    useChangePasswordMutation,
} = profileAPI;
