import React from 'react';
import { Grid } from '@mui/material';
import OrganizationTable from '../../../components/Tables/OrganizationTable';
import CustomCard from '../../../components/cards/CustomCard';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import { size } from 'lodash';
import PageLayout from '../../layouts/PageLayout';
import { organizationsSelector } from '../../../states/features/selectors';
import CountBadge from '../../../components/CountBadge';
import CompanyStatusesInfoboxes from './CompanyStatusesInfoboxes';
import OrganizationSidebar from '../../../components/sidebars/OrganizationSidebar';

const Companies = () => {
    // ############### REDUCER STATE #################
    const { organizations } = useSelector(organizationsSelector);
    const companies = filter(organizations, [
        'organization_type.name',
        'Company',
    ]);

    return (
        <>
            <PageLayout
                head={'Registered companies'}
                sidebar={
                    <OrganizationSidebar
                        orgType={'Company'}
                        organizations={companies}
                    />
                }
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CompanyStatusesInfoboxes />
                    </Grid>
                    <Grid item sm={12}>
                        <CustomCard
                            head={'All registered companies'}
                            action={
                                <CountBadge
                                    total={size(companies)}
                                    item={'Companies'}
                                />
                            }
                        >
                            <Grid container>
                                <Grid item sm={12}>
                                    <OrganizationTable
                                        organizations={companies}
                                    />
                                </Grid>
                            </Grid>
                        </CustomCard>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Companies;
