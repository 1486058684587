import React from 'react';
import { Update } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
    useSuspendJobMutation,
    useUnSuspendJobMutation,
} from '../../../../api/job/jobAPI';
import { useDispatch } from 'react-redux';
import CustomSubmitButton from '../../../../components/Forms/CustomSubmitButton';
import { updateJob } from '../../../../states/features/jobs/jobsSlice';
import useRTK from '../../../../hooks/useRTK';

const SuspendUnsuspendJob = ({ job }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        unSuspendAction,
        {
            isLoading: unSuspendLoading,
            isSuccess: unSuspendSuccess,
            isError: unSuspendIsError,
            error: unSuspendError,
            data: unSuspendData,
        },
    ] = useUnSuspendJobMutation();
    const [
        suspendAction,
        {
            isLoading: suspendLoading,
            isSuccess: suspendSuccess,
            isError: suspendIsError,
            error: suspendError,
            data: suspendData,
        },
    ] = useSuspendJobMutation();

    // ############# useEffect ##################
    // => unSuspend res
    const rtkActions = (data) => {
        dispatch(updateJob(data));
    };
    RTK.useRTKResponse(
        unSuspendSuccess,
        unSuspendIsError,
        unSuspendError,
        unSuspendData,
        rtkActions,
    );
    // => suspend res
    RTK.useRTKResponse(
        suspendSuccess,
        suspendIsError,
        suspendError,
        suspendData,
        rtkActions,
    );
    return (
        <>
            <Box sx={{ px: 1, pb: 1 }}>
                <CustomSubmitButton
                    loading={unSuspendLoading || suspendLoading}
                    startIcon={<Update />}
                    btnColor={job.status === 'banned' ? 'success' : 'warning'}
                    onClick={() =>
                        job.status === 'banned'
                            ? unSuspendAction(job.id)
                            : suspendAction(job.id)
                    }
                >
                    {job.status === 'banned' ? 'Un-suspend job' : 'Suspend job'}
                </CustomSubmitButton>
            </Box>
        </>
    );
};

export default SuspendUnsuspendJob;
