import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Grid } from "@mui/material";
import { colors } from "../../assets/utils/constants";
// import faker from "faker"

export function VerticalBarChart({ title, height }) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: false,
                text: title,
            },
        },
    };

    const labels = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Des",
    ];

    const data = {
        labels,
        datasets: [
            {
                label: "Dataset 1",
                data: [
                    800, 200, 300, 150, 900, 600, 700, 800, 200, 300, 150, 900,
                    600, 700,
                ],
                backgroundColor: colors.bgColor5,
                borderRadius: 5,
            },
        ],
    };

    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Box
                        sx={{
                            height: height ? height : 205,
                            width: { xs: "87vw", sm: "100%" },
                        }}
                    >
                        <Bar
                            options={options}
                            data={data}
                            style={{ height: "200px" }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
