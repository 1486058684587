import React from 'react';
import { Grid } from '@mui/material';

const SpacingContent = ({ children, maxPadding, bgcolor }) => {
    return (
        <>
            <Grid
                container
                justifyContent={'center'}
                sx={{ bgcolor: bgcolor ? bgcolor : '' }}
                padding={{ md: 0, xs: maxPadding ? maxPadding : 0 }}
            >
                <Grid item sm={10.5} xs={12}>
                    {children}
                </Grid>
            </Grid>
        </>
    );
};

export default SpacingContent;
