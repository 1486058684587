import React from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import NotificationSidebar from '../../components/sidebars/NotificationSidebar';
import { useSelector } from 'react-redux';
import { notificationsSelector } from '../../states/features/selectors';

const NotifcationLayout = ({ children }) => {
    // ############## CONST ######################
    const deviceWidth = useMediaQuery('(min-width: 768px)');

    // ############## Redux state ################
    const { notifications, notificationPage } = useSelector(
        notificationsSelector,
    );

    return (
        <>
            <Grid container>
                <Grid
                    item
                    sm={3}
                    xs={12}
                    order={
                        deviceWidth && notificationPage === 'home'
                            ? 1
                            : !deviceWidth && notificationPage === 'details'
                            ? 2
                            : 1
                    }
                >
                    <NotificationSidebar notifications={notifications} />
                </Grid>
                <Grid
                    item
                    sm={9}
                    xs={12}
                    order={
                        deviceWidth && notificationPage === 'home'
                            ? 2
                            : !deviceWidth && notificationPage === 'details'
                            ? 1
                            : 2
                    }
                >
                    <Box
                        sx={{
                            height: '80.4vh',
                            border: `10px solid ${colors.bgColor4}`,
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {children}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default NotifcationLayout;
