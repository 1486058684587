import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';

const StatsInfoBox = ({ stats }) => {
    return (
        <>
            <Box
                sx={{
                    height: 60,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderBottom: `4px solid ${
                        stats.borderColor ? stats.borderColor : colors.primary
                    }`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 13,
                        fontWeight: 'bold',
                        opacity: 0.8,
                    }}
                >
                    {stats.title}
                </Typography>
                <Typography sx={{ fontSize: 13 }}>{stats.stats}</Typography>
            </Box>
        </>
    );
};

export default StatsInfoBox;
