import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { blogsEndpoints } from './blogsEndpoints';

export const blogsAPI = createApi({
    reducerPath: 'blogsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Blogs', 'Blog'],
    endpoints: (builder) => ({
        // => GET all
        getBlogs: builder.query({
            query: () => `${blogsEndpoints.GET_ALL}`,
            providesTags: ['Blogs'],
        }),
        // => GET paginated
        getPaginatedBlogs: builder.query({
            query: (url) => `${url}`,
            providesTags: ['Blogs'],
        }),
        // => GET one
        getBlog: builder.query({
            query: (payload) => `${blogsEndpoints.GET_ONE}/${payload}`,
            providesTags: ['Blog'],
        }),
        // => CREATE
        createBlog: builder.mutation({
            query: (payload) => ({
                url: `${blogsEndpoints.CREATE_BLOG}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ['Blogs'],
        }),
        // => UPDATE
        updateBlog: builder.mutation({
            query: (payload) => ({
                url: `${blogsEndpoints.UPDATE_BLOG}/` + payload.get('blog_id'),
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ['Blogs', 'Blog'],
        }),
        // => ACTIVATE
        activateBlog: builder.mutation({
            query: (payload) => ({
                url: `${blogsEndpoints.ACTIVATE_BLOG}/` + payload,
                method: `PUT`,
            }),
            invalidatesTags: ['Blogs', 'Blog'],
        }),
        // => DEACTIVATE
        deactivateBlog: builder.mutation({
            query: (payload) => ({
                url: `${blogsEndpoints.DEACTIVATE_BLOG}/` + payload,
                method: `PUT`,
            }),
            invalidatesTags: ['Blogs', 'Blog'],
        }),
        // => DELETE
        deleteBlog: builder.mutation({
            query: (payload) => ({
                url: `${blogsEndpoints.DELETE_BLOG}/` + payload,
                method: `DELETE`,
            }),
            invalidatesTags: ['Blogs', 'Blog'],
        }),
    }),
});

export const {
    useGetBlogsQuery,
    useGetBlogQuery,
    useCreateBlogMutation,
    useUpdateBlogMutation,
    useActivateBlogMutation,
    useDeactivateBlogMutation,
    useDeleteBlogMutation,
    useLazyGetPaginatedBlogsQuery,

    // => Lazy
    useLazyGetBlogsQuery,
} = blogsAPI;
