import {
    Box,
    Button,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../../assets/utils/constants';
import {
    Cached,
    DoDisturb,
    Pending,
    Preview,
    QuestionMark,
    Verified,
} from '@mui/icons-material';
import NoContent from '../../../components/NoContent';
import DocumentPreviewDialog from '../../../components/Dialogies/DocumentPreviewDialog';
import DocumentPreview from '../../../components/DocumentPreview';
import { isEmpty } from 'lodash';

// -> BoxTable
const BoxTable = styled(Box)({
    'border': `1px solid ${colors.bgColor5}`,
    'paddingTop': 5,
    'paddingBottom': 5,
    'paddingLeft': 15,
    'borderRadius': 2,
    '.title': {
        fontSize: 15,
        fontWeight: 'bold',
    },
    '.value': {
        fontSize: 14,
    },
});

// -> docs status
const DocsStatus = styled(Box)({
    'height': '100%',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'flexDirection': 'column',
    '.text': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '.icon': {
        fontSize: 25,
        margin: 2,
    },
});

const ShopDocument = ({ shop }) => {
    const [openDocsDialog, setOpenDocsDialogy] = useState(false);

    // => Dialogy closer
    const handleDialogClose = () => {
        setOpenDocsDialogy(false);
    };

    // => Handle docs preview
    const handleDocsPreview = () => {
        setOpenDocsDialogy(true);
    };

    return (
        <div>
            {/* ##################### DIALOG ################# */}
            <DocumentPreviewDialog
                head={'Shop document preview'}
                openDialog={openDocsDialog}
                handleDialogClose={handleDialogClose}
            >
                <DocumentPreview document={shop.docs_attachment} />
            </DocumentPreviewDialog>

            <Grid container justifyContent={'center'} padding={2}>
                <>
                    {!isEmpty(shop.docs_attachment) ? (
                        <>
                            <Grid item md={12} sm={12}>
                                <Grid container>
                                    <Grid item sm={8}>
                                        <BoxTable>
                                            <Typography className="title">
                                                Verification type
                                            </Typography>
                                            <Typography className="value">
                                                {'TIN'}
                                            </Typography>
                                        </BoxTable>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Preview />}
                                            color="info"
                                            size="small"
                                            onClick={() => handleDocsPreview()}
                                        >
                                            Preview document
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12}>
                                {shop.status === 'drafted' ? (
                                    <>
                                        <DocsStatus className="warning-message">
                                            <Typography className="text">
                                                <Pending className="icon" />
                                                Wait for submission
                                            </Typography>
                                        </DocsStatus>
                                    </>
                                ) : shop.status === 'pending' ? (
                                    <>
                                        <DocsStatus className="info-message">
                                            <Typography className="text">
                                                <Cached className="icon" />
                                                Verification requested
                                            </Typography>
                                        </DocsStatus>
                                    </>
                                ) : shop.status === 'verified' ||
                                  shop.status === 'suspended' ? (
                                    <>
                                        <DocsStatus className="success-message">
                                            <Typography className="text">
                                                <Verified className="icon" />
                                                Document verified
                                            </Typography>
                                        </DocsStatus>
                                    </>
                                ) : (
                                    <>
                                        <DocsStatus className="error-message">
                                            <Typography className="text">
                                                <DoDisturb className="icon" />
                                                Document denied
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    <Typography>
                                                        {shop?.deny_reason}
                                                    </Typography>
                                                }
                                                arrow
                                                placement="top"
                                            >
                                                <IconButton
                                                    color="error"
                                                    size="small"
                                                    sx={{
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    }}
                                                >
                                                    <QuestionMark
                                                        sx={{ fontSize: 15 }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </DocsStatus>
                                    </>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <>
                            <NoContent
                                height={'15vh'}
                                message={'Shop document do not uploaded'}
                            />
                        </>
                    )}
                </>
            </Grid>
        </div>
    );
};

export default ShopDocument;
