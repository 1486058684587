import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Box, Card, CardActions, Grid, Typography } from '@mui/material';

// -> material icons
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// -> helpers functions imports
import { colors } from '../../assets/utils/constants';
import { ClearAll } from '@mui/icons-material';

// -> custom styled components
const CardActionsLink = styled(CardActions)(({ theme }) => ({
    'justifyContent': 'space-between',
    'paddingRight': theme.spacing(3),
    'paddingLeft': theme.spacing(2),
    'backgroundColor': colors.secondary,
    '& .icon': {
        marginRight: 3,
        transition: 'all ease-in-out 0.3s',
    },
    '&:hover .icon': {
        marginRight: -6,
        transition: 'all ease-in-out 0.3s',
    },
}));

const SettingWidget = ({ setting }) => {
    return (
        <>
            <Grid item md={3} sm={12} xs={6}>
                <Card
                    sx={{
                        padding: 0,
                        borderRadius: 1.5,
                        boxShadow: `1px 1px 5px 0px ${colors.bgColor5}`,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { xs: 11, sm: 12 },
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                opacity: 0.9,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            noWrap
                        >
                            <ClearAll
                                sx={{
                                    fontSize: 17,
                                    color: colors.warning,
                                    mr: 1,
                                }}
                            />
                            {setting.name}
                        </Typography>
                        <Typography
                            sx={{ fontWeight: 'bold', color: colors.info }}
                        >
                            ({setting.total})
                        </Typography>
                    </Box>
                    <Link to={setting.url}>
                        <CardActionsLink
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                p: 1,
                            }}
                        >
                            <Typography color="text.secondary" variant="body2">
                                Manage
                            </Typography>
                            <ChevronRightIcon className="icon" />
                        </CardActionsLink>
                    </Link>
                </Card>
            </Grid>
        </>
    );
};

export default SettingWidget;
